import React, {useEffect, Fragment} from 'react';
import './faq.style.scss';
import { MDBRow, MDBCol } from 'mdbreact';
import { connect } from 'react-redux';
import { getFaqItems, addNewFaqItem } from '../../redux/faq-items/faq-items.action';
import FaqItemComponent from './faq-item/faq-item.component';
// for waiting load
import { loadPage } from '../../redux/user/user.action';
import { useState } from 'react';
import FormButton from '../../components/form-button/form-button.component';
import FormInput from '../../components/form-input/form-input.component';
import { useAlert } from 'react-alert';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';

const FaqPage = ({items, getFaqItems, addNewFaqItem, loadPage, isShowSideBar}) => {

    const alert = useAlert();

    const [isAddNewClicked, setIsAddNewClicked] = useState(false);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    
    const [newFaqInfo, setNewFaqInfo] = useState({
        question: "",
        answer: ""
    });

    const [isMobileSize, setIsMobileSize] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 767)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        setIsEdit(JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'faqEdit'));
    }, []);

    useEffect(() => {
        async function loadData() {
            loadPage(true);
            await getFaqItems();
            loadPage(false);
        }
        loadData();
    }, [isAddNewClicked]);

    const addNewFaqFunc = async event => {
        if (isLoadingBtn)
            return;

        event.preventDefault();
        const obj = {
            question: newFaqInfo.question,
            answer: newFaqInfo.answer
        }
        setIsLoadingBtn(true);
        const result = await addNewFaqItem(obj);
        setIsLoadingBtn(false);
        if (result) {
            alert.success("Added successfully");
            setIsAddNewClicked(false);
        }
        else
            alert.error("Failed adding");
        
    }

    const cancelNewFaqFunc = () => {
        if (isLoadingBtn)
            return;
        setIsAddNewClicked(false);
    }
    return (
        <div className = 'faq-page' style={{width: isShowSideBar && !isMobileSize && 'calc(100% - 300px)'}}>
            {
                isAddNewClicked ? <div className="add-new">
                    <h2 className="text-white text-center font-weight-bold">Add New FAQ</h2>
                    <form onSubmit={addNewFaqFunc} className="add-section">
                        <FormInput type="text" label = 'FAQ Question' value = {newFaqInfo.question} handleChange = {(event) => setNewFaqInfo({...newFaqInfo, question: event.target.value})} required/>
                        <FormInput type="text" label = 'FAQ Answer' value = {newFaqInfo.answer} handleChange = {(event) => setNewFaqInfo({...newFaqInfo, answer: event.target.value})} required/>
                        <MDBRow center>
                            <MDBCol size="6" sm="6" md="4" lg="3">
                                <FormButton type="submit" isLoading = {isLoadingBtn}>SAVE NOW</FormButton>
                            </MDBCol>
                            <MDBCol size="6" sm="6" md="4" lg="3">
                                <FormButton greyCol={true} onClickFunc={()=>cancelNewFaqFunc()}>CANCEL</FormButton>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </div>
                :
                <Fragment>
                    <MDBRow center className="headerRow">
                        <MDBCol size="12" sm="12" md="8" lg="6" className="mb-3">
                            <h2 className="text-white text-center font-weight-bold pb-0 mb-0">Frequently Asked Questions</h2>
                        </MDBCol>
                        {isEdit && <MDBCol size="8" sm="8" md="4" lg="3">
                            <FormButton onClickFunc={() => setIsAddNewClicked(true)}>ADD NEW</FormButton>
                        </MDBCol>}
                    </MDBRow>
                    
                    <MDBRow>
                        <MDBCol size="12" sm="12" md="12" lg="6">
                            <MDBRow>
                            {
                                items && items.length > 0 && items.map( (item, i) => i%2 === 0 && <MDBCol size="12" key={item.id}>
                                    <FaqItemComponent isEdit={isEdit} item={item}/>        
                                </MDBCol> )
                            }     
                            </MDBRow>
                        </MDBCol>
                        <MDBCol size="12" sm="12" md="12" lg="6">
                            <MDBRow>
                            {
                                items && items.length > 0 && items.map( (item, i) => i%2 === 1 && <MDBCol size="12" key={item.id}>
                                    <FaqItemComponent isEdit={isEdit} item={item}/>     
                                </MDBCol> )
                            }     
                            </MDBRow>
                        </MDBCol>                 
                    </MDBRow>
                </Fragment>
            }
            
        </div>
    )
};

const MapStateToProps = ({faq_items: {items}, user: {isShowSideBar}}) => ({
    items,
    isShowSideBar
})

const MapDispatchToProps = dispatch => ({
    getFaqItems: getFaqItems(dispatch),
    addNewFaqItem: addNewFaqItem(dispatch),
    loadPage: flag => dispatch(loadPage(flag))    // for waiting load
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(FaqPage));