import React, { useState, useEffect, Fragment, useRef } from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { useAlert } from 'react-alert';
import FormButton from '../../../components/form-button/form-button.component';
import FormInput from '../../../components/form-input/form-input.component';
import FormSelect from '../../../components/form-select/form-select.component';
import { useHistory } from 'react-router-dom';
import { groupingItem, convertStatus, getShippingItems } from '../../../redux/shipping/shipping.action';
import { connect } from 'react-redux';
import { loadPage } from '../../../redux/user/user.action';
import './distributors-row-item.style.scss';
import BookNumberComponent from './distributors-book-number.component';
import NotesComponent from './distributors-notes.component';
import { Storage } from 'aws-amplify';

const ShippingRowItem = ({ item, index, statusFilter, pageLimit, pageOffset, isMobile, loadPage, groupingItem, convertStatus, getShippingItems }) => {

    const historyURL = useHistory();

    const alert = useAlert();

    const [isShowWonList, setIsShowWonList] = useState();
    const [isAllShow, setIsAllShow] = useState(false);
    const [isOpenTable, setIsOpenTable] = useState(false);


    const [trackingNum, setTrackingNum] = useState("");

    // for select box of convert status
    const stateFilter = ["Not Printed", "Printed", "Shipped"];
    const [stateFilterShow, setStateFilterShow] = useState(false);
    const [currentStateFilter, setCurrentStateFilter] = useState("Not Printed");

    const [isConvertDropdownOpened, setIsConvertDropdownOpened] = useState(false);

    const [changingStatusDlg, setChanginStatusDlg] = useState(false);

    // display ffl image
    const [realImageUrl, setRealImageUrl] = useState(null);
    const [isShowFullSizeImage, setIsShowFullSizeImage] = useState(false);

    useEffect(() => {
        if (item && item.shipping_status) {
            let statusStr = "";
            statusStr = item.shipping_status === "not_printed" ? "Not Printed" : item.shipping_status === "printed" ? "Printed" : "Shipped";
            setCurrentStateFilter(statusStr);
        }
        setIsConvertDropdownOpened(false);
    }, [item]);

    useEffect(() => {
        function load() {

            const compareStr = item.shipping_status === "not_printed" ? "Not Printed" : item.shipping_status === "printed" ? "Printed" : "Shipped";

            if (compareStr !== currentStateFilter) {

                if (item.children.length === 1) {
                    if (!item.children[0].book_number && !item.ffl_not_required) {
                        alert.info("Please enter book number");
                        setCurrentStateFilter(compareStr);
                        return;
                    }
                }
                else {
                    for (let index = 0; index < item.children.length; index++) {
                        if (!item.children[index].book_number && !item.ffl_not_required) {
                            alert.info("Please enter all book numbers of current group");
                            setCurrentStateFilter(compareStr);
                            return;
                        }
                    }
                }
                setChanginStatusDlg(true);
            }
        }
        item && item.children && item.children.length > 0 && isConvertDropdownOpened && load()
    }, [currentStateFilter]);

    const addToGroupFunc = async (id) => {
        loadPage(true);
        const result = await groupingItem(id, true);
        if (result === "success") {
            alert.success("Added into group successfully");
            await getShippingItems(
                statusFilter === "Not Printed" ? "not_printed" : statusFilter === "Printed" ? "printed" : statusFilter === "Shipped" ? "shipped" : "all",
                pageLimit,
                pageOffset
            );
        }
        else
            alert.error("Adding failed");
        loadPage(false);
    }
    const [isLoading, setIsLoading] = useState({
        note: false,
    });


    const removeFromGroupFunc = async (id) => {
        loadPage(true);
        const result = await groupingItem(id, false);
        if (result === "success") {
            alert.success("Removed from group successfully");
            await getShippingItems(
                statusFilter === "Not Printed" ? "not_printed" : statusFilter === "Printed" ? "printed" : statusFilter === "Shipped" ? "shipped" : "all",
                pageLimit,
                pageOffset
            );
        }
        else
            alert.error("Removing failed");
        loadPage(false);
    }

    // const [changingStatus, setChangingStatus] = useState();

    const changeStatusFunc = async (event) => {
        event.preventDefault();

        const obj = {
            id: [],
            shipping_status: currentStateFilter === "Not Printed" ? "not_printed" : currentStateFilter === "Printed" ? "printed" : currentStateFilter === "Shipped" ? "shipped" : "all"
        }
        item.children.map(item => obj.id.push(item.id));

        loadPage(true);
        const result = await convertStatus(obj);
        if (result === "success") {
            alert.success("Convert status successfully");
            setChanginStatusDlg(false);
            await getShippingItems(
                statusFilter === "Not Printed" ? "not_printed" : statusFilter === "Printed" ? "printed" : statusFilter === "Shipped" ? "shipped" : "all",
                pageLimit,
                pageOffset
            );
        }
        else
            alert.error("Converting failed");
        loadPage(false);
    }

    const showFullImageFunc = async () => {
        item?.ffl_image_url && setRealImageUrl(await Storage.get(item.ffl_image_url))
        setIsShowFullSizeImage(true);
    }

    const renderTable = () => <div style={{
        marginTop: "10px",
        marginBottom: "10px",
    }}>
        {
            changingStatusDlg && <div className="removeDialogWrapper">
                <div className="removeDialog">
                    <form onSubmit={changeStatusFunc} >
                        <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={() => {
                            setChanginStatusDlg(false);
                            setCurrentStateFilter(item.shipping_status === "not_printed" ? "Not Printed" : item.shipping_status === "printed" ? "Printed" : "Shipped");
                        }} />
                        <br />
                        <h3 className="text-white text-center font-weight-bold mb-4">Are you sure you want to change the status?</h3>
                        <MDBRow center className="mb-4 mobile-row">
                            <MDBCol size="6">
                                <FormButton type="submit">YES</FormButton>
                            </MDBCol>
                            <MDBCol size="6">
                                <FormButton greyCol={true} onClickFunc={() => {
                                    setChanginStatusDlg(false);
                                    setCurrentStateFilter(item.shipping_status === "not_printed" ? "Not Printed" : item.shipping_status === "printed" ? "Printed" : "Shipped");
                                }}>NO</FormButton>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </div>
            </div>
        }
        {
            isMobile ?
                <Fragment>
                    <MDBRow className="table-row" style={{
                        backgroundColor: item.shipping_status === "not_printed" ? '#0808084f' : item.shipping_status === "printed" ? '#a5873e6e' : '#53893eb3',
                        color: item.shipping_status === "not_printed" ? '#a3a3a3' : "#cccccc"
                    }}>
                        <MDBCol size="6" middle className="text-center">
                            {
                                item.real_shipping_address
                            }
                        </MDBCol>
                        <MDBCol size="4" middle className="text-center fflNameLabel mobile email-col" onClick={showFullImageFunc}>
                            {
                                item.ffl_not_required ? "Non-FFL" : item.real_businessName || item.real_licenseName
                            }
                        </MDBCol>
                        <MDBCol size="2" middle className="text-center">
                            <button className="openBtn" onClick={() => {
                                setIsOpenTable(true);
                            }}><MDBIcon icon="plus" /></button>
                        </MDBCol>
                    </MDBRow>
                    {
                        isOpenTable && <Fragment>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Shipping Address</MDBCol>
                                <MDBCol size="5" middle className="text-center">
                                    {
                                        item.real_shipping_address
                                    }
                                </MDBCol>
                                <MDBCol size="2" middle className="text-center">
                                    <button className="openBtn" onClick={() => {
                                        setIsOpenTable(false);
                                        setIsAllShow(false);
                                    }}><MDBIcon icon="minus" /></button>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">FFL Name</MDBCol>
                                <MDBCol size="7" middle className="text-center">
                                    {
                                        item.ffl_not_required ? "Non-FFL" : item.real_businessName || item.real_licenseName
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Item Count</MDBCol>
                                <MDBCol size="7" middle className="text-center">
                                    {
                                        item.children.length
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Convert Status</MDBCol>
                                <MDBCol size="7" middle className="text-center" onClick={() => setIsConvertDropdownOpened(true)}>
                                    {
                                        <FormSelect options={stateFilter} showSelectBox={() => setStateFilterShow(!stateFilterShow)} selectOption={(event) => {
                                            setStateFilterShow(false);
                                            setCurrentStateFilter(event.target.id);
                                        }} optionShow={stateFilterShow} placeholder={currentStateFilter} />
                                    }
                                </MDBCol>
                            </MDBRow>
                            {
                                item.children && item.children.length > 0 && item.children.map((each, i) => <div className="mobile-child-wrapper" key={i}>
                                    <Fragment>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle className="text-center text-white">Buyer/Winner Name</MDBCol>
                                            <MDBCol size="7" middle className="text-center" onClick={() => {
                                                setIsAllShow(!isAllShow);
                                                setIsShowWonList((index + 1) * (i + 1));
                                            }}>
                                                {
                                                    showBuyerName(each)
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle className="text-center text-white">Product Name</MDBCol>
                                            <MDBCol size="7" middle className="text-center">
                                                {
                                                    showProductName(each)
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle className="text-center text-white">Book Number</MDBCol>
                                            <MDBCol size="7" middle className="text-center">
                                                <BookNumberComponent key={i} id={each.id} bookNum={each.book_number} status={statusFilter} convertState={item.shipping_status} isNotFFL={each.ffl_not_required} limit={pageLimit} offset={pageOffset} />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle className="text-center text-white">Product Type</MDBCol>
                                            <MDBCol size="7" middle className="text-center">
                                                {
                                                    showProductType(each)
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle className="text-center text-white">Notes</MDBCol>
                                            <MDBCol size="7" middle className="text-center">
                                                <NotesComponent key={i} id={each.id} notesString={each.notes} status={statusFilter} convertState={item.shipping_status} isNotFFL={each.ffl_not_required} limit={pageLimit} offset={pageOffset} />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle className="text-center text-white">Group/Ungroup</MDBCol>
                                            <MDBCol size="7" middle className="text-center">
                                                {
                                                    each.is_grouped ? <button className="ungroupBtn" disabled={each.shipping_status === "not_printed" ? false : true} onClick={() => removeFromGroupFunc(each.id)}>Ungroup</button>
                                                        :
                                                        <button className="ungroupBtn" onClick={() => addToGroupFunc(each.id)} disabled={each.shipping_status === "not_printed" ? false : true}>Group</button>
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                        {
                                            isAllShow && isShowWonList && isShowWonList === (index + 1) * (i + 1) && <Fragment>
                                                <MDBRow className="ffl-row">
                                                    <MDBCol size="3">
                                                        Name
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center">
                                                        Address
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center">
                                                        Phone
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center">
                                                        Email
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="ffl-row bottom-border">
                                                    <MDBCol size="3" className="email-col">
                                                        {showBuyerName(each)}
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center">
                                                        {showAddress(each)}
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center">
                                                        {
                                                            showPhone(each)
                                                        }
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center email-col">
                                                        {showEmail(each)}
                                                    </MDBCol>
                                                </MDBRow>

                                            </Fragment>
                                        }
                                    </Fragment>
                                </div>)
                            }

                        </Fragment>
                    }
                </Fragment>
                :
                <Fragment>
                    <MDBRow className="table-row" style={{
                        backgroundColor: item.shipping_status === "not_printed" ? '#0808084f' : item.shipping_status === "printed" ? '#a5873e6e' : '#53893eb3',
                        color: item.shipping_status === "not_printed" ? '#a3a3a3' : "#cccccc"
                    }}>
                        <MDBCol size="4">
                            {
                                item.real_shipping_address
                            }
                        </MDBCol>
                        <MDBCol size="3">
                            <div className="fflNameLabel text-center" onClick={showFullImageFunc}>
                                {
                                    item.ffl_not_required ? "Non-FFL" :  item.real_businessName || item.real_businessName || item.real_licenseName
                                }
                            </div>
                        </MDBCol>
                        <MDBCol size="2" className="text-center">
                            {
                                item.children && item.children.length
                            }
                        </MDBCol>
                        <MDBCol size="3" className="text-center" onClick={() => setIsConvertDropdownOpened(true)}>
                            <FormSelect options={stateFilter} showSelectBox={() => setStateFilterShow(!stateFilterShow)} selectOption={(event) => {
                                setStateFilterShow(false);
                                setCurrentStateFilter(event.target.id);
                            }} optionShow={stateFilterShow} placeholder={currentStateFilter} />
                        </MDBCol>
                    </MDBRow>

                    <Fragment>
                        <MDBRow className="ffl-row table-names">
                            <MDBCol size="2">
                                Buyer/Winner Name
                            </MDBCol>
                            <MDBCol size="2" >
                                Product Name
                            </MDBCol>
                            <MDBCol size="2" >
                                Book Number
                            </MDBCol>
                            <MDBCol size="1" className="text-right">
                                Product Type
                            </MDBCol>
                            <MDBCol size="3" className="text-right pr-5">
                                Notes
                            </MDBCol>
                            <MDBCol size="2" className="text-right">
                                Group/Ungroup
                            </MDBCol>
                        </MDBRow>
                        {
                            item.children && item.children.length > 0 ? item.children.map((each, i) =>
                                <Fragment>
                                    <MDBRow className="ffl-row bottom-border table-elements" key={i}>
                                        <MDBCol size="2" onClick={() => {
                                            setIsAllShow(!isAllShow);
                                            setIsShowWonList((index + 1) * (i + 1));
                                        }} style={{ cursor: "pointer" }}>
                                            {showBuyerName(each)}
                                        </MDBCol>
                                        <MDBCol size="2">
                                            {showProductName(each)}
                                        </MDBCol>
                                        <MDBCol size="2">
                                            <BookNumberComponent key={i} id={each.id} bookNum={each.book_number} status={statusFilter} convertState={item.shipping_status} isNotFFL={each.ffl_not_required} limit={pageLimit} offset={pageOffset} />
                                        </MDBCol>
                                        <MDBCol size="2">
                                            {showProductType(each)}
                                        </MDBCol>
                                        <MDBCol size="3" >
                                            <NotesComponent key={i} id={each.id} notesString={each.notes} status={statusFilter} convertState={item.shipping_status} isNotFFL={each.ffl_not_required} limit={pageLimit} offset={pageOffset} />
                                        </MDBCol>
                                        <MDBCol size="1">
                                            {
                                                each.is_grouped ? <button className="ungroupBtn" disabled={each.shipping_status === "not_printed" ? false : true} onClick={() => removeFromGroupFunc(each.id)}>Ungroup</button>
                                                    :
                                                    <button className="ungroupBtn" onClick={() => addToGroupFunc(each.id)} disabled={each.shipping_status === "not_printed" ? false : true}>Group</button>
                                            }

                                        </MDBCol>
                                    </MDBRow>
                                    {
                                        isAllShow && isShowWonList && isShowWonList === (index + 1) * (i + 1) && <Fragment>
                                            <MDBRow className="ffl-row">
                                                <MDBCol size="2">
                                                    Name
                                                </MDBCol>
                                                <MDBCol size="4" className="text-center">
                                                    Address
                                                </MDBCol>
                                                <MDBCol size="3" className="text-center">
                                                    Phone
                                                </MDBCol>
                                                <MDBCol size="3" className="text-center">
                                                    Email
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow className="ffl-row bottom-border">
                                                <MDBCol size="2">
                                                    {showBuyerName(each)}
                                                </MDBCol>
                                                <MDBCol size="4" className="text-center">
                                                    {showAddress(each)}
                                                </MDBCol>
                                                <MDBCol size="3" className="text-center">
                                                    {
                                                        showPhone(each)
                                                    }
                                                </MDBCol>
                                                <MDBCol size="3" className="text-center email-col">
                                                    {showEmail(each)}
                                                </MDBCol>
                                            </MDBRow>

                                        </Fragment>
                                    }
                                </Fragment>
                            )
                                :
                                <div className="non-ffl-row text-center">
                                    No Won Items
                                </div>
                        }
                    </Fragment>
                </Fragment>
        }
    </div>
    const showBuyerName = (each) => {
        if (each.product_type === "webinar") {
            return each.webinar_shipping?.user_data?.username
        }
        else if (each.product_type === "physical") {
            return each.physical_shipping?.buyer?.username
        }
        else {
            return each.offline_shipping?.buyer_name
        }
    }
    const showProductName = (each) => {
        if (each.product_type === "webinar") {
            return each.webinar_shipping?.webinar.name
        }
        else if (each.product_type === "physical") {
            return each.physical_shipping?.productInfo?.productName
        }
        else {
            return each.offline_shipping?.product_name
        }
    }

    const showProductType = (each) => {
        return capitalizeFirstLetter(each.product_type);
    }
    const showAddress = (each) => {
        if (each.product_type === "webinar") {
            return each.webinar_shipping?.user_data?.address
        }
        else if (each.product_type === "physical") {
            return each.physical_shipping?.buyer?.address
        }
        else {
            return each.offline_shipping?.shipping_address
        }
    }
    const showPhone = (each) => {
        if (each.product_type === "webinar") {
            return <span>({each.webinar_shipping.user_data.phone_number.slice(2, 5)}) {each.webinar_shipping.user_data.phone_number.slice(5, 8)}-{each.webinar_shipping.user_data.phone_number.slice(8)}</span>
        }
        else if (each.product_type === "physical") {
            return <span>({each.physical_shipping.buyer.phone_number.slice(2, 5)}) {each.physical_shipping.buyer.phone_number.slice(5, 8)}-{each.physical_shipping.buyer.phone_number.slice(8)}</span>
        }
        else {
            return <span>({each.offline_shipping.buyer_phone.slice(2, 5)}) {each.offline_shipping.buyer_phone.slice(5, 8)}-{each.offline_shipping.buyer_phone.slice(8)}</span>
        }
    }

    const showEmail = (each) => {
        if (each.product_type === "webinar") {
            return each.webinar_shipping.user_data.email
        }
        else if (each.product_type === "physical") {
            return each.physical_shipping.buyer.email
        }
        else {
            return each.offline_shipping.buyer_email
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return (
        <Fragment>
            {
                isShowFullSizeImage && <div className="full-size-img-wrapper">
                    <div className="full-size-img">
                        <MDBIcon className="float-right closeIcon" icon="times" onClick={() => setIsShowFullSizeImage(false)} />
                        {
                            realImageUrl ?
                                <img src={realImageUrl} alt={item.name} />
                                :
                                <div className="non-img" style={{ margin: "auto", fontSize: "100px" }}>
                                    <MDBIcon far icon="file-image" />
                                </div>
                        }
                    </div>
                </div>
            }
            {item && renderTable()}
        </Fragment>
    )
}

const MapDispatchToProps = dispatch => ({
    loadPage: flag => dispatch(loadPage(flag)),
    groupingItem: groupingItem(dispatch),
    convertStatus: convertStatus(dispatch),
    getShippingItems: getShippingItems(dispatch)
})

export default connect(null, MapDispatchToProps)(ShippingRowItem);