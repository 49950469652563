import LiveStreamActionTypes from './live-stream.types';

const INITIAL_STATE = {
    product_items: {
        rows: [],
        count: 1,
    },
    filterStr: "",
    limitPerPage: "All",
    pageNum: 1,
    winnerID: {
        seatID: "",
        userID: "",
        userName: "",
        seatNum: 0
    }
}

const LiveStreamReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LiveStreamActionTypes.CLEAR_PRODUCT_LIST_ITEMS:
            return {
                ...state, 
                product_items : [], 
            }
        case LiveStreamActionTypes.SET_PRODUCT_LIST_ITEMS:
            return {
                ...state, 
                product_items : action.payload, 
            }
        case LiveStreamActionTypes.SET_FILTER_STRING:
            return {
                ...state, filterStr: action.payload
            }
        case LiveStreamActionTypes.SET_LIMIT_PER_PAGE:
            return {
                ...state, limitPerPage: action.payload
            }         
        case LiveStreamActionTypes.SET_PRODUCT_LIST_PAGE_NUM:
            return {
                ...state, pageNum: action.payload
            }
        case LiveStreamActionTypes.SET_WINNER_ID:
            return {
                ...state, winnerID: action.payload
            }
        case LiveStreamActionTypes.CLEAR_WINNER_ID:
            return {
                ...state,
                winnerID: INITIAL_STATE.winnerID
            }
        default:
            return state;
    }
}

export default LiveStreamReducer;