import React, {useState, useEffect} from 'react';
import './faq-item.style.scss';
import { MDBIcon } from 'mdbreact';
import { connect } from 'react-redux';
import { deleteFaqItem, getFaqItems } from '../../../redux/faq-items/faq-items.action';
import { loadPage } from '../../../redux/user/user.action';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import router from '../../../router';

const FaqItemComponent = ({isEdit, item, getFaqItems, deleteFaqItem, loadPage}) => {
    
    const historyURL = useHistory();
    const alert = useAlert();

    const [answerShow, setAnswerShow] = useState(false);

    const deleteFaqFunc = async () => {
        loadPage(true);
        const result = await deleteFaqItem(item.id);
        if (result === "success") {
            alert.success("Deleted successfully");
            await getFaqItems();
        }
        else
            alert.error("Failed deleting");
            
        loadPage(false);
    }
    
    const editFaqItemFunc = () => {
        historyURL.push(router.editFaq.path, {item: item});
    }

    return (
        <div className="faq-item-wrapper">
            <div className="faq-item">
                <div className="question">
                    <p>{item.question}</p>
                    <button className="openBtn text-center" onClick={()=>setAnswerShow(!answerShow)}>{ answerShow ? <i class="fa fa-minus" aria-hidden="true"></i> : <i class="fa fa-plus" aria-hidden="true"></i>}</button>
                </div>
                {
                    answerShow && <div className="answer">
                        <p>{item.answer}</p>
                    </div>
                }
            </div>
            {isEdit && <MDBIcon icon="pencil-alt" onClick={()=>editFaqItemFunc()} className="faqIcon"/>}
            {isEdit && <MDBIcon icon="times" onClick={()=>deleteFaqFunc()} className="faqIcon del"/>}
        </div>
    )
}

const MapDispatchToProps = dispatch => ({
    deleteFaqItem: deleteFaqItem(dispatch),
    getFaqItems: getFaqItems(dispatch),
    loadPage: flag => dispatch(loadPage(flag))
})

export default connect(null, MapDispatchToProps)(FaqItemComponent);