import React, { useState, useEffect } from 'react';
import './dashboard-page.style.scss';
import { MDBRow, MDBCol } from 'mdbreact';

import RevenueGraph from './revenue-graph/revenue-graph.component';
import MemeberGraph from './member-graph/member-graph.component';
import CategoryGraph from './category-graph/category-graph.component';
import { connect } from 'react-redux';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';

const LipseyDashboardPage = ({isShowSideBar}) => {

    const [isMobileSize, setIsMobileSize] = useState(false);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 767)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <div className="dashboard-page" style={{width: isShowSideBar && !isMobileSize && 'calc(100% - 300px)'}}>
            <MDBRow>
                <MDBCol size="12" sm="12" md="12" lg="6">                    
                    <RevenueGraph isLipsey={true} isProfit = {false}/>
                </MDBCol>
                <MDBCol size="12" sm="12" md="12" lg="6">                                   
                    <RevenueGraph isLipsey={true} isProfit = {true}/>
                </MDBCol>          
            </MDBRow>        
        </div>
    )
}

const MapStateToProps = ({ user: {isShowSideBar}}) => ({
    isShowSideBar
})

export default withPermissionChecking(connect(MapStateToProps)(LipseyDashboardPage));