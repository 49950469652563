import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getEmailSettings = dispatch => async () => {
    console.log("getEmailSettings")
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + "admin/email/settings", { 
            headers: (await getMyTokenFunc())
        });
        return result.data.data;
    } catch (error) {
        console.log(error.message);
    }
}
export const updateEmailSettings = dispatch => async (params) => {
    console.log("params")
    console.log(params)
    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + "admin/email/settings", params, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
        
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}
