import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import FormButton from '../form-button/form-button.component'
import {saveFFLInformation} from "../../redux/ffl-db/ffl-db.action";

const FFLModalSave = ({ fflScopeInfo, closeModal1, changeFFL, onlyPermanent = false, onlyOneTime = false, setLoading, loading,setErrorWithFFL}) => {
  
  const changeFFLFunc = async (oneTimeChange,id) => {
    const isEdit=true;
    try {
      setLoading(true)
      const result = await changeFFL({
        ...fflScopeInfo,
        id
      },isEdit,oneTimeChange,false)
      setErrorWithFFL(false)
      return result;
    } catch (error) {
      setErrorWithFFL(true)
      console.log(error)
    }
    setLoading(false)
  }
  
  const saveFFL = async (oneTimeChange) => {
    try {
      setLoading(true)
      const result = await saveFFLInformation(fflScopeInfo)
      let id = result?.value?.id;

      if(result && id){
        await changeFFLFunc(oneTimeChange,id)
        closeModal1()
        setErrorWithFFL(false)
        setLoading(false)
      }
    } catch (error) {
      setErrorWithFFL(true)
      setLoading(false)
    }
    setLoading(false)
  }

  return (
    <div className="modal-profile-form">
      <div className="content">
        <div className="removeDialogWrapper">
          <div className="removeDialog">
            <div>
            <div className="close-icon">
            <i onClick={() => closeModal1()} class="far fa-times-circle"></i>
          </div>
              <h2 className="text-white text-center ">
                How do you want to save this change?                  
              </h2>
              <span style={{
                fontSize: "14px",
                margin: "3px auto",
                textAlign: "center",
                opacity: "0.8",
                width: "100%",
                display: "block"
              }}>
                This item will be automatically sent to the shipping page.
              </span>
              <p></p>

              <MDBRow center className="mt-4">
                {
                  onlyOneTime ? null : (
                    <MDBCol size="8" sm="6" md="5" lg="6">
                      <FormButton  
                        isLoading={loading}
                        onClickFunc={()=>{
                            saveFFL(false)
                          }}>
                        <h5>Permanent Change</h5>
                      </FormButton>
                    </MDBCol>
                  )
                }
                {
                  onlyPermanent ? null : (
                    <MDBCol size="8" sm="6" md="5" lg="6">
                      <FormButton 
                        isLoading={loading}
                        greyCol={true} onClickFunc={()=>{
                          saveFFL(true)
                        }}> 
                        <h5>One-time Change</h5>
                      </FormButton>
                    </MDBCol>
                  )
                }
              </MDBRow>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FFLModalSave
