import React, { useEffect, useCallback, useMemo } from 'react'
import { useState } from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import FormInput from '../form-input/form-input.component'
import FormButton from '../form-button/form-button.component'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { MDBIcon } from 'mdbreact'
import Map from '../../components/check-out-form/Map'
import moment from 'moment-timezone';
import FFLModalSave from '../../components/ffl-modal-save/ffl-modal-save.component'

import './ffl-modal.style.scss'
import FormSelect from '../form-select/form-select.component';
import { isValidLoaExpirationDate } from '../../utils/fflUtils'

const FFLModal = ({ closeModal, getFFLLicencesFiltered, alert, changeFFL, onlyPermanent = false, onlyOneTime = false, setLoading, loading, errorWithFFL, setErrorWithFFL }) => {
  const [fontColors, setFontColors] = useState({
    header1: 'white',
    header2: 'white',
    form: '#a3a3a3',
    paragraph: '#a3a3a3',
  })

  const [userHasAlreadyFFL, setUserHasAlreadyFFL] = useState(true)
  const [selectedFFL, setSelectedFFL] = useState(false)
  const [isOnSearchModeFFL, setIsOnSearchModeFFL] = useState(true)
  const [lat, setLat] = useState(28)
  const [lng, setLng] = useState(-82.5)
  const [searchZipCode, setSearchZipCode] = useState('')
  const [searchRadius, setSearchRadius] = useState('')
  const [searchName, setSearchName] = useState('')
  const [searchNumber, setSearchNumber] = useState('')
  const [isvisibleTable, setIsvisibleTable] = useState(false)
  const [question, setQuestion] = useState('')

  const [fflScopeInfo, setFFLScopeInfo] = useState({
    fflNumber: '',
    licenseName: '',
    businessName: '',
    expiration: '',
    loaExpirationDate: '',
    premiseStreet: '',
    premiseZipCode: '',
    premiseCity: '',
    premiseLat: '',
    premiseLon: '',
    voiceTelephone: '',
    verified: '',
    fflVerificationProof: '',
    premiseState: '',
  })

  const [view, setView] = useState({
    center: {
      lat: lat,
      lng: lng,
    },
    zoomLevel: 10,
  })

  const [locations, setLocations] = useState([''])
  const [licenseffl, setLicenseffl] = useState(null)
  const [loadButton, setLoadButton] = useState(false)
  const [clicList, setClicList] = useState(null)
  const [errorLength, setErrorLength] = useState(false)
  const [searchType, setSearchType] = useState('zipcode')
  const [optionShow, setOptionShow] = useState(false)
  const [placeholder, setPlaceholder] = useState('Zip Code')
  const [emptyFields, setEmptyFields] = useState(false)

  const hasLoaExpirationDate = useMemo(() => isValidLoaExpirationDate(fflScopeInfo), [fflScopeInfo])

  const handleZIPCodeChange = zipCodeNum => {
    let zipCodeRegExp = /^[0-9]*$/

    if (zipCodeNum === '' || zipCodeRegExp.test(zipCodeNum)) {
      setSearchZipCode(zipCodeNum)
    }
  }

  const handleRadiusCodeChange = radius => {
    let radiusRegExp = /^[0-9]*$/

    if (radius === '' || radiusRegExp.test(radius)) {
      if (parseInt(radius) > 25) return
      setSearchRadius(radius)
    }
  }

  const handleFormSubmit = event => {
    event.preventDefault();
  };

  const handleFFLNameChange = name => {
    if (name !== '') {
      setSearchName(name)
    } else{
      setSearchName('')
    }
  }

  const handleFFLNumberChange = number => {
    if (number !== '') {
      setSearchNumber(number)
    } else{
      setSearchNumber('')
    }
  }

  const render = status => {
    return <h1>{status}</h1>
  }

  useEffect(() => {
    if (searchType === 'zipcode') {
      if (searchZipCode === '' || searchZipCode === null) {
        setEmptyFields(true)
      } else {
        setEmptyFields(false)
      }
    } else if (searchType === 'name') {
      if (searchName === '' || searchName === null) {
        setEmptyFields(true)
      } else {
        setEmptyFields(false)
      }
    } else if (searchType === 'number') {
      if (searchNumber === '' || searchNumber === null) {
        setEmptyFields(true)
      } else {
        setEmptyFields(false)
      }
    }
  }, [searchZipCode, searchName, searchNumber, searchType])

  const searchNearbyFFL = async () => {
    setSelectedFFL(false)
    setLoadButton(true)
    let zipCode = ''
    let radius = 10
    if (searchType === 'zipcode') {
      if (searchZipCode != null && searchZipCode !== '') {
        zipCode = searchZipCode.replace(/\D/g, '')
        radius = searchRadius.toString().replace(/\D/g, '')
        if (radius === '' || radius === undefined) {
          radius = 10
          setSearchRadius(10)
        }
      }
    }

    const result = await getFFLLicencesFiltered({
      zipcode: zipCode,
      radius,
      name: searchName,
      number: searchNumber,
      type: searchType,
    })

    if (result?.data?.length === 0) {
      setErrorLength(true)
      setLoadButton(false)
      setLicenseffl([])
      return
    }
    setLicenseffl(result)
    if (result === undefined) {
      setLoadButton(false)
      setLicenseffl([])
      setErrorLength(true)
      return
    }
    setErrorLength(false)
    setIsOnSearchModeFFL(false)
    setLoadButton(false)
    setLocations(result.data)
    setIsvisibleTable(true)
    setView({
      center: {
        lat: searchType === 'zipcode' ? result.lat : lat,
        lng: searchType === 'zipcode' ? result.lng : lng,
      },
      zoomLevel: 10,
    })
  }

  const renderLicenseFFLItem = useCallback(
    (item) => {
      const hasLoaExpirationDate = isValidLoaExpirationDate(item)
      let description = `Address: 
        ${item.premiseStreet} 
         - 
        ${item.premiseCity} 
         - 
        ${item.premiseZipCode}
        , State:  
        ${item.premiseState}`
      if (hasLoaExpirationDate) {
        description = `Loa Expiration Date: ${item.loaExpirationDate}, ${description}`
      }
      description = `Expiration Date: ${item.expiration}, ${description}`
      description = `${(item.businessName != null ? item.businessName : item.licenseName)}, ${description}`
      
      const className = item.fflNumber === fflScopeInfo?.fflNumber ? "leaderboard__name1" : "leaderboard__name"
      const itemId = item.fflNumber === fflScopeInfo?.fflNumber ? "selectFFL" : ""
      return (
        <article class="leaderboard__profile">
          <span
            class={className}
            id={itemId}
            key={item.fflNumber}
            onClick={() => {
              setSelectedFFL(true)
              setFFLScopeInfo(item)
              setClicList(item)
              setView({
                center: {
                  lat: item?.premiseLat,
                  lng: item?.premiseLon,
                },
                zoomLevel: 10,
              })
            }}
          >
            {description}
          </span>
        </article>
      );
    },
    [fflScopeInfo],
  )

console.log(fflScopeInfo);

  return (
    <div className="modal-profile-form">
      <div className="content">
      {
        question && (
          <FFLModalSave setErrorWithFFL={setErrorWithFFL} loading={loading} setLoading={setLoading} onlyPermanent={onlyPermanent} onlyOneTime={onlyOneTime} changeFFL={changeFFL} fflScopeInfo={fflScopeInfo} closeModal1={() => {setQuestion(false); closeModal()}} />
        )
      }
        <form onSubmit={handleFormSubmit}>
          <div className="close-icon">
            <i onClick={() => closeModal()} class="far fa-times-circle"></i>
          </div>

          {loadButton && <div className="loading"></div>}

          {!userHasAlreadyFFL && (
            <div className="removeDialogWrapper">
              <div className="removeDialog">
                <MDBIcon
                  className="float-right text-white closeIcon"
                  icon="times"
                  onClick={() => {
                    setUserHasAlreadyFFL(true)
                  }}
                />
                <div>
                  <h2 className="text-white text-center ">
                    You have not selected any FFL!
                  </h2>
                </div>
                <br></br>
              </div>
            </div>
          )}

          <p
            className="font-weight-bold mb-4"
            style={{
              color: fontColors.header1,
              fontSize: '22px',
              textAlign: 'center',
            }}
          >
            SEARCH FFL INFORMATION
          </p>

          {selectedFFL && (
            <div
              style={{
                backgroundColor: '#1c1c1c',
                borderRadius: '10px',
                width: '100%',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                margin: '20px 0',
              }}
            >
              {fflScopeInfo.businessName != null &&
                fflScopeInfo.businessName != '' && (
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: fontColors.paragraph,
                          fontSize: '16px',
                        }}
                      >
                        Selected FFL:{' '}
                      </span>
                    </div>
                    <div>
                      <p
                        style={{ color: fontColors.header1, fontSize: '20px' }}
                        className="mb-1 pb-0"
                      >
                        {fflScopeInfo.businessName}
                      </p>
                    </div>
                  </div>
                )}
              <div
                style={{
                  width: '100%',
                }}
              >
                <div>
                  <span
                    style={{ color: fontColors.paragraph, fontSize: '16px' }}
                  >
                    License Name:{' '}
                  </span>
                </div>
                <div>
                  <p
                    style={{ color: fontColors.header1, fontSize: '20px' }}
                    className="mb-1 pb-0"
                  >
                    {fflScopeInfo.licenseName}
                  </p>
                </div>
              </div>
              { hasLoaExpirationDate && 
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <div>
                    <span
                      style={{ color: fontColors.paragraph, fontSize: '16px' }}
                    >
                      Loa Expiration Date:{' '}
                    </span>
                  </div>
                  <div>
                    <p
                      style={{ color: fontColors.header1, fontSize: '18px' }}
                      className="mb-1 pb-0"
                    >
                      {moment(fflScopeInfo?.loaExpirationDate).utc().format('MM/DD/YYYY')}
                      
                    </p>
                  </div>
                </div>
              }
              <div
                style={{
                  width: '100%',
                }}
              >
                <div>
                  <span
                    style={{ color: fontColors.paragraph, fontSize: '16px' }}
                  >
                    Expiration Date:{' '}
                  </span>
                </div>
                <div>
                  <p
                    style={{ color: fontColors.header1, fontSize: '18px' }}
                    className="mb-1 pb-0"
                  >
                    {moment(fflScopeInfo.expiration).utc().format('MM/DD/YYYY')}
                    
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                }}
              >
                <div>
                  <span
                    style={{ color: fontColors.paragraph, fontSize: '16px' }}
                  >
                    Address:{' '}
                  </span>
                </div>
                <div>
                  <p
                    style={{ color: fontColors.header1, fontSize: '18px' }}
                    className="mb-1 pb-0"
                  >
                    {fflScopeInfo.premiseStreet}, {fflScopeInfo.premiseCity}, {fflScopeInfo.premiseState != null ? fflScopeInfo.premiseState + ", " : ' '}
                    {fflScopeInfo.premiseZipCode}
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                }}
              >
                <div>
                  <span
                    style={{ color: fontColors.paragraph, fontSize: '16px' }}
                  >
                    Contact Number:{' '}
                  </span>
                </div>
                <div>
                  <p
                    style={{ color: fontColors.header1, fontSize: '18px' }}
                    className="mb-1 pb-0"
                  >
                    {fflScopeInfo.voiceTelephone == null
                      ? 'N/A'
                      : '('+ (fflScopeInfo.voiceTelephone.slice(0, 3)) +') '+ fflScopeInfo.voiceTelephone.slice(3, 6) + '-' + fflScopeInfo.voiceTelephone.slice(6)}

                  </p>
                </div>
              </div>
            </div>
          )}
          {isOnSearchModeFFL ? (
            <MDBCol
              size="12"
              sm="12"
              md="12"
              xl="12"
              className="mb-4"
              style={{
                marginTop: '30px',
              }}
            >
              <MDBRow>
                <MDBCol
                  size="12"
                  lg="6"
                  className="modal-input-item modal-input-item-small"
                >
                  <MDBRow>
                    <p
                      className="font-weight-bold"
                      style={{
                        color: fontColors.paragraph,
                        fontSize: '16px',
                        margin: 0,
                      }}
                    >
                      Search type
                    </p>
                  </MDBRow>
                  <MDBRow>
                    <FormSelect
                      options={['ZIPCode', 'Name', 'Number']}
                      selectOption={event => {
                        let temp = event.target.id
                        setSearchType(temp?.toLowerCase())
                        setOptionShow(false)
                        setPlaceholder(event.target.id)
                        setEmptyFields(false)
                        setSearchName('')
                        setSearchNumber('')
                        setSearchZipCode('')
                        setSearchRadius('')
                      }}
                      showSelectBox={() => setOptionShow(!optionShow)}
                      optionShow={optionShow}
                      placeholder={placeholder}
                    />
                  </MDBRow>
                </MDBCol>
                <MDBCol>
                {searchType === 'name' && (
                  <MDBCol
                    size="12"
                    sm="12"
                    md="12"
                    
                    className="modal-input-item"
                  >
                    <MDBRow>
                      <p
                        className="font-weight-bold mb-2"
                        style={{
                          color: fontColors.paragraph,
                          fontSize: '16px',
                          margin: 0,
                        }}
                      >
                        FFL Name
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <FormInput
                        type="text"
                        required={true}
                        style={{ margin: 0 }}
                        value={searchName}
                        handleChange={event =>
                          handleFFLNameChange(event.target.value)
                        }
                        placeholder="Enter the name of the FFL"
                      />
                    </MDBRow>
                  </MDBCol>
                )}
                {searchType === 'number' && (
                  <MDBCol
                    size="12"
                    sm="12"
                    md="12"
                    className="modal-input-item"
                  >
                    <MDBRow>
                      <p
                        className="font-weight-bold mb-2"
                        style={{
                          color: fontColors.paragraph,
                          fontSize: '16px',
                          margin: 0,
                        }}
                      >
                        FFL Number
                      </p>
                    </MDBRow>
                    <MDBRow>
                      <FormInput
                        type="text"
                        required={true}
                        style={{ margin: 0 }}
                        value={searchNumber}
                        handleChange={event =>
                          handleFFLNumberChange(event.target.value)
                        }
                        placeholder="Enter the number of the FFL"
                      />
                    </MDBRow>
                  </MDBCol>
                )}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                {searchType === 'zipcode' && (
                  <>
                    <MDBCol
                      size="12"
                      sm="12"
                      md="12"
                      lg="6"
                      className="modal-input-item"
                    >
                      <MDBRow>
                        <p
                          className="font-weight-bold"
                          style={{
                            color: fontColors.paragraph,
                            fontSize: '16px',
                            margin: 0,
                          }}
                        >
                          Search by ZIP Code
                        </p>
                      </MDBRow>
                      <MDBRow>
                        <FormInput
                          type="text"
                          style={{ margin: 0 }}
                          required={true}
                          value={searchZipCode}
                          placeholder="Enter your zip code"
                          handleChange={event =>
                            handleZIPCodeChange(event.target.value)
                          }
                        />
                      </MDBRow>
                    </MDBCol>
                    <MDBCol
                      size="12"
                      sm="12"
                      md="12"
                      lg="6"
                      className="modal-input-item"
                    >
                      <MDBRow>
                        <p
                          className="font-weight-bold mb-2"
                          style={{
                            color: fontColors.paragraph,
                            fontSize: '16px',
                            margin: 0,
                          }}
                        >
                          Radius: (default 10 miles)
                        </p>
                      </MDBRow>
                      <MDBRow>
                        <FormInput
                          type="text"
                          style={{ margin: 0 }}
                          value={searchRadius}
                          handleChange={event =>
                            handleRadiusCodeChange(event.target.value)
                          }
                          placeholder="Enter the radius to look around"
                        />
                      </MDBRow>
                    </MDBCol>
                  </>
                )}
              </MDBRow>
              <MDBCol
                size="12"
                sm="12"
                md="12"
                lg="6"
                xl="6"
                className="mb-4"
                style={{ margin: '0 auto' }}
              >
                <FormButton
                  isLoading={loadButton}
                  type="button"
                  disabled={emptyFields}
                  onClickFunc={() => {
                    searchNearbyFFL()
                  }}
                >
                  Search
                </FormButton>
              </MDBCol>
            </MDBCol>
          ) : (
            <MDBCol size="12" sm="12" md="12" lg="12" xl="12" className="mb-4">
              <MDBCol
                size="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="mb-4"
              >
                {selectedFFL && (
                  <div
                    style={{
                      margin: '10px 0',
                    }}
                  >
                    <FormButton
                      type="button"
                      onClickFunc={() => {
                        // setIsOnSearchModeFFL(true)
                        // setSelectedFFL(false)
                        // setIsvisibleTable(true)
                        // saveFFL()
                        //setIsOnSearchModeFFL(true)
                        setQuestion(true)
                      }}
                    >
                      Next
                    </FormButton>
                  </div>
                )}
                <FormButton
                  onClickFunc={() => {
                    setIsOnSearchModeFFL(true)
                    setSelectedFFL(false)
                    setIsvisibleTable(true)
                    setFFLScopeInfo({
                      fflNumber: '',
                      licenseName: '',
                      businessName: '',
                      expiration: '',
                      premiseStreet: '',
                      premiseZipCode: '',
                      premiseCity: '',
                      premiseLat: '',
                      premiseLon: '',
                      voiceTelephone: '',
                      verified: '',
                      fflVerificationProof: '',
                      premiseState: '',
                    })
                    setClicList(null)
                    setLicenseffl([])
                    setSearchName('')
                    setSearchNumber('')
                    setSearchRadius('')
                    setSearchZipCode('')
                    setLocations([])
                  }}
                >
                  Search again?
                </FormButton>
              </MDBCol>
            </MDBCol>
          )}

          {
            errorWithFFL && (
              <p
                style={{
                  color: 'red',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  margin: '10px 0',
                }}
              >
                Error assigning FFL
              </p>
            )
          }

          {isvisibleTable && licenseffl?.data?.length > 0 ? (
            <div class="leaderboard__profiles">
              {licenseffl.data?.map((item) => renderLicenseFFLItem(item))}
            </div>
          ) : (
            errorLength && (
              <div class="error-message">
                <h3>No results found</h3>
              </div>
            )
          )}

          <div className="modal-map-container">
            <Wrapper apiKey={''} render={render} className="wrapper-map-modal">
              <Map
                locations={locations}
                view={view}
                setFFLScopeInfo={setFFLScopeInfo}
                setSelectedFFL={setSelectedFFL}
                clicList={clicList}
                fflScopeInfo={fflScopeInfo}
              ></Map>
            </Wrapper>
          </div>

          {/* <FormButton
          type="submit"
          isLoading={isLoading}
          setIsvisibleTable={false}
          onClickFunc={() => saveFFL()}
        >
          SAVE NOW
        </FormButton> */}
        </form>
      </div>
    </div>
  )
}

export default FFLModal
