import React, { useState, useEffect, Fragment } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { Storage } from "aws-amplify";
import { useAlert } from "react-alert";
import "./sold-physical-row.style.scss";
import Select from "react-select";
import { connect } from "react-redux";
import { addFFLItem } from "../../../redux/sold-physical/sold-physical.action";
import { loadPage } from "../../../redux/user/user.action";
import { useHistory } from "react-router-dom";
import router from "../../../router";
import moment from "moment-timezone";

import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import FormButton from "../../../components/form-button/form-button.component";
import LongItemWithTooltip from "../../../components/tooltip/long-item-with-tooltip";
import { isValidLoaExpirationDate } from "../../../utils/fflUtils";

const getFflBgColor = (fflItem) => {
  const expDate = isValidLoaExpirationDate(fflItem)? new Date(fflItem.loaExpirationDate) : new Date(fflItem.expiration);
  const now = new Date(Date.now());
  const isExpired = expDate < now;
  return isExpired ? red : white;
};
const red = "#912c2c";
const white = "white";

const SoldPhysicalItemRow = ({
  isEdit,
  item,
  isMobile,
  ffl,
  addFFLItem,
  loadPage,
  loadDataOnSuccess,
  setShowModal,
  showEditFFLModal,
  loadParent,
  setCurrentRow,
  setItemToEditFunc,
  setLoading,
  loading,
}) => {
  const alert = useAlert();
  const historyUrl = useHistory();

  const [fflList, setFflList] = useState([]);
  const [selectedFflItem, setSelectedFflItem] = useState(null);

  const [showFFLModal, setShowFFLModal] = useState(false);
  const [showConfirmNotRequiredModal, setShowConfirmNotRequiredModal] =
    useState(false);
  const [showConfirmSendModal, setShowConfirmSendModal] = useState(false);
  const [validationSendToShipping, setValidationSendToShipping] =
    useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [isOpenTable, setIsOpenTable] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [isShowWonList, setShowWonList] = useState(false);

  const dropDownCustomStyles = {
    option: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.bgColor,
      color: data.bgColor === red ? white : undefined,
      borderBottom: "1px solid #c0c0c0",
    }),
  };

  const setAllStatesToFalse = () => {
    setShowFFLModal(false);
    setShowConfirmNotRequiredModal(false);
    setShowConfirmSendModal(false);
    setValidationSendToShipping(false);
  };

  useEffect(() => {
    if (ffl && ffl.length > 0) {
      let tempArray = [];

      item?.buyer?.ffl_scope_id &&
        tempArray.push({
          value: item.buyer.ffl_scope_id,
          label: (
            <p style={{ margin: "0", color: "blue", fontWeight: "700" }}>
              {ffl.find((el) => el.id === item.buyer.ffl_scope_id)?.licenseName}
            </p>
          ),
          expDate: ffl.find((el) => el.id === item.buyer.ffl_scope_id)
            ?.expiration,
        });

      tempArray.push({
        value: "non-ffl",
        label: "FFL not required",
      });

      if (item?.buyer?.ffl_scope_id)
        ffl.map((el) => {
          el.id !== item?.buyer?.ffl_scope_id &&
            tempArray.push({
              value: el.id,
              label: el.licenseName,
              bgColor: getFflBgColor(el),
              expDate: el.expiration,
            });
        });
      else
        ffl.map((el) => {
          tempArray.push({
            value: el.id,
            label: el.licenseName,
            bgColor: getFflBgColor(el),
            expDate: el.expiration,
          });
        });
      setFflList([...tempArray]);
    }
    if (item && item.ffl_database && item.ffl_database.id) {
      setSelectedFflItem({
        value: item.ffl_database.id,
        label: item.ffl_database.businessName || item.ffl_database.licenseName,
      });
    } else if (item && item?.assigned_ffl && item?.assigned_ffl?.id) {
      setSelectedFflItem({
        value: item?.assigned_ffl?.id,
        label:
          item?.assigned_ffl?.businessName || item?.assigned_ffl?.licenseName,
      });
    }

    if (item && item.ffl_not_required) {
      setSelectedFflItem({
        value: "non-ffl",
        label: "FFL not required",
      });
    }
  }, [item]);

  const setGreen = (item) => {
    if (item?.ffl_assignment_completed)
      return { backgroundColor: "#53893eb3", borderBottom: "1px solid grey" };
  };

  useEffect(() => {
    async function load() {
      try {
        item?.productInfo.thumbnail_300.image_url
          ? setImageUrl(
              await Storage.get(item?.productInfo.thumbnail_300.image_url)
            )
          : setImageUrl(
              await Storage.get(item?.productInfo.main_image.image_url)
            );
      } catch (error) {
        console.log(
          "🚀 ~ file: sold-physical-row.component.jsx:103 ~ load ~ error",
          error
        );
      }
    }
    load();
  }, [item.productInfo]);

  const getCustomDate = () => {
    const customDate = new Date(item.createdAt);
    Date.shortMonths = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return (
      Date.shortMonths[customDate.getMonth()] +
      " " +
      customDate.getDate() +
      ", " +
      customDate.getFullYear()
    );
  };

  const handleFFLNotRequired = async ({
    modifiedFFL,
    isEdit = false,
    oneTimeChange,
    fflNotRequired,
  }) => {
    let purchase_id;
    let ffl_id;

    purchase_id = modifiedFFL?.id;
    oneTimeChange = null;
    ffl_id = null;

    const obj = {
      purchase_id,
      ffl_id: ffl_id,
      ffl_not_required: fflNotRequired,
      ffl_one_time: oneTimeChange,
      ffl_edited: isEdit,
    };
    try {
      setLoading(true);
      const result = await addFFLItem(obj);
      setLoading(false);
      if (result === "success") {
        setLoading(true);
        await loadParent();
        setLoading(false);
        alert.success(
          "FFL status updated successfully and will be sent to shipping",
          {
            delay: 1000,
            timeout: 3200,
          }
        );
        setAllStatesToFalse();
      } else {
        setLoading(false);
        alert.error(result || "Failed assigning");
        setAllStatesToFalse();
      }
    } catch (error) {
      alert.error("Failed assigning");
    }

    setAllStatesToFalse();
  };

  const sendToShippingFunc = async ({
    modifiedFFL,
    isEdit = false,
    oneTimeChange,
    fflNotRequired,
  }) => {
    setShowFFLModal(false);

    let purchase_id;
    let ffl_id;
    let ffl_one_time;
    let ffl_not_required;

    purchase_id = modifiedFFL?.id;
    oneTimeChange = null;
    ffl_id = null;

    if (modifiedFFL.assigned_ffl) {
      ffl_id = modifiedFFL?.assigned_ffl?.id;
      oneTimeChange ? (ffl_one_time = true) : (ffl_one_time = false);
    }

    const obj = {
      purchase_id,
      ffl_id,
      ffl_not_required: fflNotRequired,
      ffl_one_time: oneTimeChange,
      ffl_edited: isEdit,
      sendToShipping: true,
    };

    try {
      setLoading(true);
      const result = await addFFLItem(obj);
      setLoading(false);
      if (result === "success") {
        setLoading(true);
        await loadParent();
        setLoading(false);
        alert.success("Item was sent to shipping", {
          delay: 1000,
          timeout: 3200,
        });
        setAllStatesToFalse();
      } else {
        alert.error(result || "Failed assigning");
        setAllStatesToFalse();
      }
    } catch (error) {
      alert.error("Failed assigning");
    }
    setAllStatesToFalse();
  };

  const getFFLStatus = (item) => {
    let fflStatus = "";
    if (item.ffl_not_required) {
      fflStatus = "FFL NOT REQUIRED";
    }

    if (item?.ffl_id && item?.ffl_database) {
      fflStatus = item?.ffl_database?.businessName
        ? item?.ffl_database?.businessName
        : item?.ffl_database?.licenseName;
    } else if (item?.buyer?.ffl_scope_id && item?.assigned_ffl) {
      fflStatus = item?.assigned_ffl?.businessName
        ? item?.assigned_ffl?.businessName
        : item?.assigned_ffl?.licenseName;
    } else {
      fflStatus = "FFL NOT SELECTED";
    }
    return <LongItemWithTooltip text={fflStatus} maxlenght={40} />;

    /* if(item.ffl_assignment_completed){
            if(item.ffl_not_required){
                return "FFL NOT REQUIRED"
            } else if(item.assigned_ffl?.businessName){
                return item.assigned_ffl?.businessName
            } else if(item.assigned_ffl?.licenseName){
                return item.assigned_ffl?.licenseName
            }
        } else{	
            if(!item.buyer?.ffl_scope_id){
                return "FFL NOT SELECTED"
            } else if(item.ffl_database?.businessName){
                return item.ffl_database?.businessName
            } else if(item.ffl_database?.licenseName){
                return item.ffl_database?.licenseName
            }
        } */
  };

  const getFFLExpirationDate = (item) => {
    let color = "white";
    let value = "";

    if (item?.ffl_database?.id) {
      const expDate = isValidLoaExpirationDate(item.ffl_database)? item.ffl_database.loaExpirationDate : item.ffl_database.expiration;
      value =
        expDate !== "" &&
        expDate !== null
          ? moment(expDate).utc().format("MM/DD/YYYY")
          : "";

      if (Date.parse(expDate) > Date.parse(new Date())) {
        color = "white";
      } else {
        color = "red";
      }
    } else if (item?.assigned_ffl?.id) {
      const expDate = isValidLoaExpirationDate(item.assigned_ffl)? item.assigned_ffl.loaExpirationDate : item.assigned_ffl.expiration;
      value =
        expDate !== "" &&
        expDate !== null
          ? moment(expDate).utc().format("MM/DD/YYYY")
          : "";
      if (Date.parse(expDate) > Date.parse(new Date())) {
        color = "white";
      } else {
        color = "red";
      }
    }
    return {
      color,
      value,
    };
  };

  return (
    <Fragment>
      {validationSendToShipping && (
        <ConfirmationModal
          isLoading={loading}
          message="Are you sure you want to proceed?"
          closeModal={() => {
            setShowConfirmSendModal(false);
            setValidationSendToShipping(false);
          }}
          editFFL={() => {
            setValidationSendToShipping(false);
            setShowModal(true);
          }}
          notRequired={() => {}}
          noResponse={() => {
            setShowConfirmNotRequiredModal(false);
          }}
          style="send"
        />
      )}
      {showConfirmNotRequiredModal && (
        <ConfirmationModal
          isLoading={loading}
          message="Are you sure you want to proceed?"
          closeModal={() => setShowConfirmNotRequiredModal(false)}
          editFFL={() => {}}
          notRequired={() => {
            handleFFLNotRequired({
              modifiedFFL: itemToEdit,
              isEdit: false,
              oneTimeChange: null,
              fflNotRequired: true,
            });
          }}
          noResponse={() => {
            setShowConfirmNotRequiredModal(false);
          }}
          style="basic"
        />
      )}

      {showConfirmSendModal && (
        <div className="removeDialogWrapper">
          <div className="removeDialog">
            <MDBIcon
              className="float-right text-white closeIcon"
              icon="times"
              onClick={() => {
                setShowConfirmSendModal(false);
              }}
            />
            <br />
            <h3 className="text-white text-center font-weight-bold mb-4">
              Are you sure you want to change the status?
            </h3>
            <MDBRow center className="mb-4 mobile-row">
              <MDBCol size="6">
                <FormButton
                  isLoading={loading}
                  onClickFunc={() => {
                    sendToShippingFunc({
                      modifiedFFL: itemToEdit,
                      isEdit: true,
                      oneTimeChange: null,
                      fflNotRequired: false,
                    });
                  }}
                  type="submit"
                >
                  YES
                </FormButton>
              </MDBCol>
              <MDBCol size="6">
                <FormButton
                  greyCol={true}
                  onClickFunc={() => {
                    setShowConfirmSendModal(false);
                  }}
                >
                  NO
                </FormButton>
              </MDBCol>
            </MDBRow>
          </div>
        </div>
      )}

      {isMobile ? (
        <Fragment>
          <MDBRow className="table-row" style={setGreen(item)}>
            <MDBCol size="4" middle className="text-center">
              {imageUrl ? (
                <img src={imageUrl} alt={item.name} />
              ) : (
                <div className="non-img">
                  <MDBIcon far icon="file-image" />
                </div>
              )}
            </MDBCol>
            <MDBCol size="6" middle className="text-center">
              {item &&
                item.productInfo &&
                item.productInfo.productName &&
                item.productInfo.productName}
            </MDBCol>
            <MDBCol size="2" middle className="text-center">
              <button
                className="openBtn"
                onClick={() => {
                  setIsOpenTable(true);
                }}
              >
                <MDBIcon icon="plus" />
              </button>
            </MDBCol>
          </MDBRow>
          {isOpenTable && (
            <Fragment>
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  Thumbnail
                </MDBCol>
                <MDBCol size="6" middle className="text-center">
                  {imageUrl ? (
                    <img src={imageUrl} alt={item.name} />
                  ) : (
                    <div className="non-img">
                      <MDBIcon far icon="file-image" />
                    </div>
                  )}
                </MDBCol>
                <MDBCol size="2" middle className="text-center">
                  <button
                    className="openBtn"
                    onClick={() => {
                      setIsOpenTable(false);
                      setShowWonList(false);
                    }}
                  >
                    <MDBIcon icon="minus" />
                  </button>
                </MDBCol>
              </MDBRow>
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  UPC
                </MDBCol>
                <MDBCol size="8" middle className="text-center">
                  {item?.productInfo?.upc_code
                    ? item.productInfo.upc_code
                    : "unassigned"}
                </MDBCol>
              </MDBRow>
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  Name
                </MDBCol>
                <MDBCol size="8" middle className="text-center">
                  {item && item.productInfo && item.productInfo.productName && (
                    <LongItemWithTooltip
                      text={item.productInfo.productName}
                      maxlenght={30}
                    />
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  Buyer's Name
                </MDBCol>
                <MDBCol size="8" middle className="text-center">
                  {item && item.buyer && item.buyer.username && (
                    <LongItemWithTooltip
                      text={item.buyer.username}
                      maxlenght={30}
                    />
                  )}
                </MDBCol>
              </MDBRow>
              {/* <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">Buyer's Email</MDBCol> 
                            <MDBCol size="8" middle className="text-center email-col">
                            { item && item.buyer && item.buyer.email && item.buyer.email}
                            </MDBCol>               
                        </MDBRow> */}
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  Sold Date
                </MDBCol>
                <MDBCol size="8" middle className="text-center">
                  {item && item.createdAt && getCustomDate()}
                </MDBCol>
              </MDBRow>
              {isEdit && (
                <MDBRow className="table-row mobile-opend-table">
                  <MDBCol size="4" middle className="text-center text-white">
                    Duplicate
                  </MDBCol>
                  <MDBCol size="8" middle className="text-center">
                    <button
                      onClick={() =>
                        historyUrl.push(router.editProduct.path, {
                          prodType: "physical",
                          id: item?.productID,
                          duplicate: true,
                        })
                      }
                    >
                      Duplicate
                    </button>
                  </MDBCol>
                </MDBRow>
              )}
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  FFL expiration
                </MDBCol>
                <MDBCol
                  size="8"
                  className={`text-white text-center text-${
                    getFFLExpirationDate(item).color
                  }`}
                >
                  {getFFLExpirationDate(item).value}
                </MDBCol>
              </MDBRow>
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  FFL Selected
                </MDBCol>
                <MDBCol size="8" className="text-white text-center">
                  {getFFLStatus(item)}
                </MDBCol>
              </MDBRow>
              {!item.ffl_assignment_completed && (
                <>
                  <MDBRow className="table-row mobile-opend-table">
                    <MDBCol size="4" middle className="text-center text-white">
                      Edit FFL
                    </MDBCol>
                    <MDBCol size="8" className="text-white text-center">
                      <div
                        class="fas fa-pencil-alt"
                        onClick={() => {
                          setShowModal(true);
                          setItemToEdit(item);
                          setItemToEditFunc(item);
                        }}
                      >
                        {" "}
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="table-row mobile-opend-table">
                    <MDBCol size="5" middle className="text-center text-white">
                      Confirm
                    </MDBCol>
                    <MDBCol size="7" className="text-center">
                      <button
                        title="You must select a valid FFL."
                        className="streamBtnShip"
                        onClick={() => {
                          setItemToEdit(item);
                          setItemToEditFunc(item);
                          if (
                            item?.ffl_id ||
                            item?.assigned_ffl_id ||
                            item?.ffl_not_required ||
                            item?.buyer?.ffl_scope_id
                          ) {
                            setShowConfirmSendModal(true);
                          } else {
                            setValidationSendToShipping(true);
                          }
                        }}
                      >
                        Send to shipping
                      </button>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="table-row mobile-opend-table">
                    <MDBCol size="5" middle className="text-center text-white">
                      Not Required
                    </MDBCol>
                    <MDBCol size="7" className="text-center">
                      <button
                        className="streamBtnShip"
                        onClick={() => {
                          setShowConfirmNotRequiredModal(true);
                          setItemToEdit(item);
                        }}
                      >
                        FFL Not Required
                      </button>
                    </MDBCol>
                  </MDBRow>
                </>
              )}

              {/* <MDBCol size="1" className="text-white text-center">
                            <div class="fas fa-pencil-alt" onClick={() => {}}> </div>
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            <div class="fas fa-times" onClick={() => {}}> </div>
                        </MDBCol> */}
              {/* <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">FFL Name</MDBCol> 
                            <MDBCol size="8" middle className="text-center">
                            <Select value={selectedFflItem} isDisabled={!isEdit} onChange={selectedItem => handleFFLChange(selectedItem)} options={fflList} styles={dropDownCustomStyles} />
                            </MDBCol>               
                        </MDBRow> */}
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  More Detail
                </MDBCol>
                <MDBCol size="8" middle className="text-center">
                  <button onClick={() => setShowWonList(!isShowWonList)}>
                    Show / Hide
                  </button>
                </MDBCol>
              </MDBRow>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <MDBRow
          className="table-row"
          style={setGreen(item)}
          onClick={() => setShowWonList(!isShowWonList)}
        >
          <MDBCol size="1">
            {imageUrl ? (
              <img src={imageUrl} alt={item.name} />
            ) : (
              <div className="non-img">
                <MDBIcon far icon="file-image" />
              </div>
            )}
          </MDBCol>
          <MDBCol size={"1"} className="text-center email-col">
            {item?.productInfo?.upc_code
              ? item.productInfo.upc_code
              : "unassigned"}
          </MDBCol>
          <MDBCol size="1" className="text-center">
            {item &&
              item.productInfo &&
              item.productInfo.productName &&
              <LongItemWithTooltip text={item.productInfo.productName} />}
          </MDBCol>
          <MDBCol size="2" className="text-center">
            {item && item.buyer && item.buyer.username && <LongItemWithTooltip text={item.buyer.username} />}
          </MDBCol>

          {/* <MDBCol size="2" className="text-center email-col">
                    { item && item.buyer && item.buyer.email && item.buyer.email}
                </MDBCol> */}
          <MDBCol size="1" className="text-center">
            {item &&
              item.createdAt &&
              moment(item.createdAt).format("MM/DD/YYYY")}
          </MDBCol>
          {/* <MDBCol size="2" className="text-center">
                <Select value={selectedFflItem} isDisabled={!isEdit} onChange={selectedItem => handleFFLChange(selectedItem)} options={fflList} styles={dropDownCustomStyles} />
                </MDBCol> */}

          <MDBCol
            size="1"
            className={`text-white text-center text-${
              getFFLExpirationDate(item).color
            }`}
          >
            {getFFLExpirationDate(item).value}
          </MDBCol>
          <MDBCol size="1" className="text-white text-center">
            {getFFLStatus(item)}
          </MDBCol>
          {!item.ffl_assignment_completed ? (
            <>
              <MDBCol size="1" className="text-white text-center">
                <div
                  className="fas fa-pencil-alt"
                  onClick={() => {
                    setShowModal(true);
                    setItemToEdit(item);
                    setItemToEditFunc(item);
                  }}
                >
                  {" "}
                </div>
              </MDBCol>
              <MDBCol size="1" className="text-center">
                <button
                  title="You must select a valid FFL."
                  className="streamBtnShip"
                  onClick={() => {
                    setItemToEdit(item);
                    setItemToEditFunc(item);
                    if (
                      item?.ffl_id ||
                      item?.assigned_ffl_id ||
                      item?.ffl_not_required ||
                      item?.buyer?.ffl_scope_id
                    ) {
                      setShowConfirmSendModal(true);
                    } else {
                      setValidationSendToShipping(true);
                    }
                  }}
                >
                  Send to shipping
                </button>
              </MDBCol>
              <MDBCol size="1" className="text-center">
                <button
                  className="streamBtnShip"
                  onClick={() => {
                    setShowConfirmNotRequiredModal(true);
                    setItemToEdit(item);
                  }}
                >
                  FFL Not Required
                </button>
              </MDBCol>
            </>
          ) : (
            <>
              <MDBCol size="1" className="text-white text-center">
                <div> </div>
              </MDBCol>
              <MDBCol size="1" className="text-center">
                <div> </div>
              </MDBCol>
              <MDBCol size="1" className="text-center">
                <div> </div>
              </MDBCol>
            </>
          )}
          {isEdit && (
            <MDBCol size="1" className="text-center">
              <button
                onClick={() =>
                  historyUrl.push(router.editProduct.path, {
                    prodType: "physical",
                    id: item?.productID,
                    duplicate: true,
                  })
                }
              >
                Duplicate
              </button>
            </MDBCol>
          )}
        </MDBRow>
      )}
      {isShowWonList && (
        <Fragment>
          <MDBRow className="ffl-row">
            <MDBCol>Name</MDBCol>
            <MDBCol className="text-center">Address</MDBCol>
            <MDBCol className="text-center">Phone</MDBCol>
            <MDBCol className="text-center">Email</MDBCol>
          </MDBRow>

          <MDBRow className="ffl-row bottom-border">
            <MDBCol>
              {item && item.buyer && item.buyer.username && item.buyer.username}
            </MDBCol>
            <MDBCol className="text-center">
              {item && item.buyer && item.buyer.address && (
                <LongItemWithTooltip text={item.buyer.address} maxlenght={30} />
              )}
            </MDBCol>
            <MDBCol className="text-center">
              {item && item.buyer && item.buyer.phone_number && (
                <span>
                  ({item.buyer.phone_number.slice(2, 5)}){" "}
                  {item.buyer.phone_number.slice(5, 8)}-
                  {item.buyer.phone_number.slice(8)}
                </span>
              )}
            </MDBCol>
            <MDBCol className="text-center email-col">
              {item && item.buyer && item.buyer.email && item.buyer.email}
            </MDBCol>
          </MDBRow>
        </Fragment>
      )}
    </Fragment>
  );
};

const MapDispatchToProps = (dispatch) => ({
  addFFLItem: addFFLItem(dispatch),
  loadPage: (flag) => dispatch(loadPage(flag)),
  addFFLItem: addFFLItem(dispatch),
});

export default connect(null, MapDispatchToProps)(SoldPhysicalItemRow);
