import ShippingActionTypes from './shipping.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getShippingItems = dispatch => async (shipping_status, limit, offset, filterString = "", isDistributor, orderBy = 'ASC') => {

    try {
        let parseOrderBy = orderBy === 'new' ? 'ASC' : orderBy;

        let isPhysical = false;
        const routeWebinar = "admin/shipping_items?"
        const routePhysical = "admin/shipping_items_physical?"

        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `${isPhysical ? routePhysical: routeWebinar}shipping_status=${shipping_status}&limit=${limit}&offset=${offset}&filterString=${filterString}&distributor=${isDistributor}&orderBy=${parseOrderBy}`, { 
            headers: (await getMyTokenFunc())
        });
        // const result1 = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/shipping_items_count_not_shipped`, { 
        //      headers: (await getMyTokenFunc())
        //  });
        dispatch(setProductItems({
            rows: [...result.data.data],
            count: result.data.count,
            not_printed_count: result.data.not_printed_count,
            // pendingItems:result1.data.count,
            pendingItems:0,
        }));
        return {
            rows: [...result.data.data],
            count: result.data.count,
            not_printed_count: result.data.not_printed_count,
            // pendingItems:result1.data.count,
            pendingItems:0,
        }
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const getShippingItemsCustom = dispatch => async (shipping_status, limit, offset, filterString = "", isDistributor, orderBy = 'ASC') => {

    try {
        let parseOrderBy = orderBy === 'new' ? 'ASC' : orderBy;

        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/shipping_items/custom?shipping_status=${shipping_status}&limit=${limit}&offset=${offset}&filterString=${filterString}&distributor=${isDistributor}&orderBy=${parseOrderBy}`, { 
            headers: (await getMyTokenFunc())
        });
        return {
            rows: [...result.data.data],
            count: result.data.count,
        }
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const sendToShippingFiltered = dispatch => async (obj) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/shipping_items/editFFL`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
     
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}


export const groupingItem = dispatch => async (id, is_grouped) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/shipping_items/grouping`, {
            id: id,
            is_grouped: is_grouped
        }, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
     
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const convertStatus = dispatch => async (obj) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/shipping_items/status`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
     
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const setBookNumberAction = dispatch => async (obj) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/shipping_items/book`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
     
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const setNotesAction = dispatch => async (obj) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/shipping_items/notes`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
     
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}
export const setLimitPerPage = limit => ({
    type: ShippingActionTypes.SET_LIMIT_PER_PAGE,
    payload: limit
})

export const setProductPageNum = num => ({
    type: ShippingActionTypes.SET_LIST_PAGE_NUM,
    payload: num
})

export const setCurrentStateFilter = str => ({
    type: ShippingActionTypes.SET_SHIPPING_CURRENT_STATE,
    payload: str
})

const setProductItems = items => ({
    type: ShippingActionTypes.SET_PRODUCT_LIST_ITEMS,
    payload: items
})

export const setFilterStr = str => ({
    type: ShippingActionTypes.SET_FILTER_STRING,
    payload: str,
})

export const setOrder = order => ({
    type: ShippingActionTypes.SET_ORDER,
    payload: order,
})