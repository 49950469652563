import ProductListActionTypes from './product-list.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';
import { useAlert } from 'react-alert';

export const getLipseysProducts = dispatch => async () => {
    console.log("getLipseysProducts")     
    try {
        console.log("TRY")
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + "admin/products/LipseysProducts", { 
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        console.log("ERROR")
        return error.response.data;   
    }    
}

export const getProductItems = dispatch => async ( filterStr = null, statusFilter = null, type = null, limit = 10, offset = 0) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/product/listings?filterString=${filterStr}&filterByStatus=${statusFilter}&limit=${limit}&offset=${offset}&product_type=${type}`, { 
            headers: (await getMyTokenFunc())
        });
        dispatch(setProductItems(result.data));
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}
export const getProductItemsCount = dispatch => async ( statusFilter = null) => {
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/product/listings/count?filterByStatus=${statusFilter}`, { 
            headers: (await getMyTokenFunc())
        });
        dispatch(setProductItemsCount(result.data.count));
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const getCurrentProductItem = dispatch => async ( id, prodType ) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/product?id=${id}&product_type=${prodType}`, { 
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const getFreeWebinarParticipants = dispatch => async ( obj ) => {

    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + `admin/products/freewebinarparticipants`, obj ,{ 
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const addNewWebinar = dispatch => async ( type, obj ) => {
    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + "admin/products/"+type, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;

    } catch (error) {
        return error.response.data;   
    }
}
export const addFreeWebinar = dispatch => async ( type, obj ) => {
    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + "admin/products/free"+type, obj, { 
            headers: (await getMyTokenFunc())   
        });
        return result.data.message;

    } catch (error) {
        return error.response.data;   
    }
}


export const addNewOfflineSale = dispatch => async (obj) => {

    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + "admin/products/offlinesale/create", obj, {
            headers: (await getMyTokenFunc())
        });
        return result.data.message;

    } catch (error) {
        return error.response.data;
    }
}

export const addGalleryImages = dispatch => async ( obj ) => {
    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + "admin/products/images", obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const removeGalleryImage = dispatch => async ( obj ) => {
  
    try {
        const result = await axios.delete( RestAPI.ORIGINAL_ENDPOINT + "admin/products/images", { 
            data: obj,
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const updateProduct = dispatch => async ( obj ) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + "admin/products/product", obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {  
        return error.response.data;                   
    }
}


export const deleteProduct = dispatch => async ( obj ) => {
    try {
        const result = await axios.delete( RestAPI.ORIGINAL_ENDPOINT + "admin/products/product",{ 
            headers: (await getMyTokenFunc()),
            data:{obj}
        });
       // console.log("🚀 ~ file: product-list.action.js ~ line 156 ~ deleteProduct ~ result", result)
        return result;
    } catch (error) {
        console.log("🚀 ~ file: product-list.action.js ~ line 153 ~ deleteProduct ~ error", error)
    }         
}


export const setFilterString = filterStr => ({
    type: ProductListActionTypes.SET_FILTER_STRING,
    payload: filterStr
})

export const setLimitPerPage = limit => ({
    type: ProductListActionTypes.SET_LIMIT_PER_PAGE,
    payload: limit
})

export const setProductPageNum = num => ({
    type: ProductListActionTypes.SET_PRODUCT_LIST_PAGE_NUM,
    payload: num
})

export const setAddNewClicked = flag => ({
    type: ProductListActionTypes.SET_ADD_NEW_CLICKED,
    payload: flag
})

export const setCurrentTypeFilter = str => ({
    type: ProductListActionTypes.SET_TYPE_FILTER,
    payload: str
})

export const setCurrentStateFilter = str => ({
    type: ProductListActionTypes.SET_STATE_FILTER,
    payload: str
})


const setProductItems = items => ({
    type: ProductListActionTypes.SET_PRODUCT_LIST_ITEMS,
    payload: items
})
const setProductItemsCount = items => ({
    type: ProductListActionTypes.SET_PRODUCT_LIST_ITEMS_COUNT,
    payload: items
})
