import React, { useState, useEffect, Fragment } from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { editUserInfo, getProductItems, deleteUserInfo, removeFFLSelected } from '../../../redux/user-management/user-management.action';
import { loadPage } from '../../../redux/user/user.action';
import Select from 'react-select';
import router from '../../../router';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
// import FormButton from '../../../components/form-button/form-button.component';
import './item-row.style.scss';
import LongItemWithTooltip from '../../../components/tooltip/long-item-with-tooltip';

const UserItemRow = ({ isEdit, item, ffl, isMobile, editUserInfo, deleteUserInfo, getProductItems, loadPage, showModal, removeFFLSelected, setLoading, loading, loadData }) => {

    const historyURL = useHistory();

    const alert = useAlert();

    const [isShowWonList, setShowWonList] = useState(false);

    const [fflList, setFflList] = useState([]);
    const [selectedFflItem, setSelectedFflItem] = useState(null);
    const [purchases, setPurchases] = useState([]);
    const [arrFilter, setArrFilter] = useState([])
    const [showValidationRemoveFFL, setShowValidationRemoveFFL] = useState(false);
    useEffect(() => {
        if (item && item.purchase_history.length > 0) {
            let tempArray = [];

            item.purchase_history.forEach(item =>
                tempArray.push({
                    id: item.productID,
                    seatNo: item.seatsNo,
                    createdAt: item.createdAt,
                    price: item.price,
                    product_type: item.product_type,
                    orderStatus: item.orderStatus,
                    webinar_product: item?.webinar_product?.productName
                })
            );


            tempArray.forEach(item => {
                let newItem = {
                    id: item.id,
                    seatNo: [],
                    createdAt: item.createdAt,
                    price: item.price,
                    product_type: item.product_type,
                    orderStatus: item.orderStatus,
                    webinar_product: item.webinar_product
                };
                tempArray.forEach(innerItem => {
                    if (innerItem.id == item.id) {
                        newItem.seatNo = newItem.seatNo.concat(innerItem.seatNo);
                    }
                });
                purchases.push(newItem)
            });

            // purchases.filter((_purchase, index, self) => 
            //     index === self.findIndex((t) => (
            //         t.id === purchases.id
            //     ))
            // )

            const filteredArr = purchases.reduce((acc, current) => {
                const x = acc.find(item => item.id === current.id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            setArrFilter(filteredArr);
        }

    }, []);

    useEffect(() => {
        if (ffl && (ffl.length > 0)) {
            let tempArray = [];

            ffl.map(item => {
                tempArray.push({
                    value: item.id,
                    label: (item.assigned_ffl?.businessName!=='' && item.assigned_ffl?.businessName!==null) ? item.assigned_ffl?.businessName : item.assigned_ffl?.licenseName
                });
            })
            setFflList([...tempArray]);
        }

        if (item?.ffl_scope_id) {
            let fflFiltered = ffl.find(el => el.id === item.ffl_scope_id);

            if(fflFiltered){
                setSelectedFflItem({
                    value: item.ffl_scope_id,
                    label: (fflFiltered?.businessName!=='' && fflFiltered?.businessName!==null) ? fflFiltered?.businessName : fflFiltered?.licenseName
                })
            }
        }

    }, []);

    const handleFFLChange = async selectedItem => {
        setSelectedFflItem(selectedItem);

        const obj = {
            id: item.id,
            prefered_ffl_id: selectedItem.value
        };

        loadPage(true);
        const result = await editUserInfo(obj);
        if (result === "success") {
            alert.success("FFL assigned successfully");
        }
        else {
            alert.error("FFL assigning failed");
            setSelectedFflItem(null);
        }
        loadPage(false);
    }

    const getCustomDate = (dateStr) => {
        const customDate = new Date(dateStr);
        Date.shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return Date.shortMonths[customDate.getMonth()] + " " + customDate.getDate() + ", " + customDate.getFullYear();
    }

    const editUserFunc = async () => {
        historyURL.push(router.userEdit.path, { item: item });
    }

    const disableUserFunc = async () => {
        const obj = {
            id: item.id,
            auth_banned: !item.auth_banned
        };

        loadPage(true);
        const result = await editUserInfo(obj);
        if (result === "success") {
            alert.success(item.auth_banned ? "Enabled successfully" : "Disabled successfully");
        }
        else
            alert.error(item.auth_banned ? "Failed enabling" : "Failed disabling");
        await getProductItems("", 10, 0);
        loadPage(false);

    }

    // const deleteUserFunc = async (event) => {
    //     event.preventDefault();

    //     loadPage(true);
    //     const result = await deleteUserInfo(item.id);
    //     if (result === "success") {
    //         alert.success("Deleted successfully");
    //         await getProductItems("", 10, 0);
    //     }
    //     else
    //         alert.error("Failed deleting");
    //     loadPage(false);
    // }

    const [isOpenTable, setIsOpenTable] = useState(false);
    const [isOpenRowWon, setIsOpenRowWon] = useState(false);
    const [isOpenRowPurchased, setIsOpenRowPurchased] = useState(false);
    const [isOpenRowHot, setIsOpenRowHot] = useState(false);
    const [isShowWebinarList, setShowWebinarList] = useState(false);
    const [isOpenRowPurchasedItem, setIsOpenRowPurchasedItem] = useState(false);

    // const [isDeleteUser, setIsDeleteUser] = useState(false);

    const removeFFLOfUser = async (user) => {
        if(user && user?.id){
            setLoading(true);
            const result = await removeFFLSelected({id: user?.id});
            setLoading(false);
            if (result === "success" && !loading) {
                setLoading(true);
                await loadData();
                setLoading(false);
                alert.success("FFL removed successfully",{
                    delay: 2000
                }
                );
            } else{
                alert.error(result || "FFL removing failed");
            }
            setLoading(false);
        }
    }

    return (
        <Fragment>

            {
                showValidationRemoveFFL && (
                    <ConfirmationModal
                        isLoading={loading}
                        message="Are you sure you want to proceed?"
                        closeModal={() => {
                            setShowValidationRemoveFFL(false)
                        }}
                        notRequired={() => {
                            setShowValidationRemoveFFL(false)
                            removeFFLOfUser(item)
                        }}
                        noResponse={() => {
                            setShowValidationRemoveFFL(false)
                        }}
                        style="basic"
                    />
                )
            }
            {/* {                
                isDeleteUser && <div className="removeDialogWrapper">
                    <div className="removeDialog">
                    <form onSubmit={deleteUserFunc} >
                    <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={()=>setIsDeleteUser(false)}/>
                    <br/>
                    <h3 className="text-white text-center font-weight-bold mb-4">Are you sure to delete user?</h3>
                    <MDBRow center className="mb-4 mobile-row">                    
                        <MDBCol size="6">
                            <FormButton type="submit">YES</FormButton>
                        </MDBCol>
                        <MDBCol size="6">
                            <FormButton greyCol={true} onClickFunc={()=>setIsDeleteUser(false)}>NO</FormButton>
                        </MDBCol>
                    </MDBRow>
                    </form>                    
                    </div>
                </div>
            } */}
            {
                isMobile ? <Fragment>
                    <MDBRow className="table-row">
                        <MDBCol size="4" middle className="text-center">
                            <LongItemWithTooltip text={item.username && item.username} />
                        </MDBCol>
                        <MDBCol size="6" middle className="text-center email-col">
                            <LongItemWithTooltip text={item.email && item.email} />
                        </MDBCol>
                        <MDBCol size="2" middle className="text-center">
                            <button className="openBtn" onClick={() => {
                                setIsOpenTable(true);
                            }}><MDBIcon icon="plus" /></button>
                        </MDBCol>
                    </MDBRow>
                    {
                        isOpenTable && <Fragment>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Username</MDBCol>
                                <MDBCol size="5" middle className="text-center">
                                    <LongItemWithTooltip text={item.username && item.username} />
                                </MDBCol>
                                <MDBCol size="2" middle className="text-center">
                                    <button className="openBtn" onClick={() => {
                                        setIsOpenTable(false);
                                        setShowWonList(false);
                                    }}><MDBIcon icon="minus" /></button>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Address</MDBCol>
                                <MDBCol size="7" middle className="text-center">
                                    <LongItemWithTooltip text={item.address && item.address} maxlenght={40} />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Phone</MDBCol>
                                <MDBCol size="7" middle className="text-center">
                                    {item.phone_number && <span>({item.phone_number.slice(2, 5)}) {item.phone_number.slice(5, 8)}-{item.phone_number.slice(8)}</span>}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Email</MDBCol>
                                <MDBCol size="7" middle className="text-center email-col">
                                    {item.email && item.email}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Change FFL</MDBCol>
                                <button style={{
                                    margin: '0 auto'
                                }} onClick={() => {
                                showModal(item);
                            }}>
                                Assign FFL
                            </button>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Selected FFL</MDBCol>
                                <MDBCol className="text-center custom-elipsis-text" style={{
                                    width: '100%'
                                }}>
                                    {selectedFflItem?.label || ""}
                                </MDBCol>
                            </MDBRow>
                            {
                                selectedFflItem?.label && (
                                    <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="5" middle className="text-center text-white">Remove FFL</MDBCol>
                                        <MDBCol className="text-center custom-elipsis-text" style={{
                                            width: '100%'
                                        }}>
                                            <MDBIcon far icon="times-circle" className="user-edit-icon" onClick={() => setShowValidationRemoveFFL(true)} />
                                        </MDBCol>
                                    </MDBRow>
                                )
                                
                            }
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Role</MDBCol>
                                <MDBCol size="7" middle className="text-center email-col">
                                    {item.role && item.role.name}
                                </MDBCol>
                            </MDBRow>
                            {isEdit && <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Edit</MDBCol>
                                <MDBCol size="7" middle className="text-center">
                                    <MDBIcon icon="user-edit" className="user-edit-icon" onClick={() => editUserFunc()} />
                                </MDBCol>
                            </MDBRow>}
                            {isEdit && <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Disable/Enable</MDBCol>
                                <MDBCol size="7" middle className="text-center">
                                    {
                                        item.auth_banned ?
                                            <MDBIcon icon="user-check" className="user-unban-icon" onClick={() => disableUserFunc()} />
                                            :
                                            <MDBIcon icon="user-slash" className="user-ban-icon" onClick={() => disableUserFunc()} />
                                    }
                                </MDBCol>
                            </MDBRow>}
                            {/* <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="5" middle className="text-center text-white">Delete</MDBCol> 
                            <MDBCol size="7" middle className="text-center">
                                <MDBIcon icon="user-times" className="user-del-icon" onClick={()=>setIsDeleteUser(true)}/>
                            </MDBCol>               
                        </MDBRow> */}
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Purchase History</MDBCol>
                                <MDBCol size="7" middle className="text-center">
                                    <button onClick={() => setShowWonList(!isShowWonList)}>Show / Hide</button>
                                </MDBCol>
                            </MDBRow>
                        </Fragment>
                    }
                </Fragment>
                    :
                    <MDBRow className="table-row">
                        <MDBCol size={'1'} onClick={() => setShowWonList(!isShowWonList)}>
                            <LongItemWithTooltip text={item.username && item.username} />
                        </MDBCol>
                        <MDBCol size={'2'} className="text-center" onClick={() => setShowWonList(!isShowWonList)}>
                            <LongItemWithTooltip text={item.address && item.address} maxlenght={40} />
                        </MDBCol>
                        <MDBCol size="1" className="text-center" onClick={() => setShowWonList(!isShowWonList)}>
                            {item.phone_number && <span>({item.phone_number.slice(2, 5)}) {item.phone_number.slice(5, 8)}-{item.phone_number.slice(8)}</span>}
                        </MDBCol>
                        <MDBCol size="2" className="text-center email-col" onClick={() => setShowWonList(!isShowWonList)}>
                            {item.email && item.email}
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            <button onClick={() => {
                                showModal(item);
                            }}>
                                Assign FFL
                            </button>
                        </MDBCol>
                        <MDBCol size="1" className="text-center custom-elipsis-text">
                            {selectedFflItem?.label || ""}
                        </MDBCol>
                        <MDBCol size="1" className="text-center custom-elipsis-text">
                            {
                                selectedFflItem?.label ? (
                                    <MDBIcon far icon="times-circle" className="user-edit-icon" onClick={() => setShowValidationRemoveFFL(true)} />
                                )
                                :
                                (
                                    <div></div>
                                )

                            }
                        </MDBCol>
                        {/* <MDBCol size="2" className="text-center" onClick={() => setShowWonList(!isShowWonList)}>
                            <Select value={selectedFflItem} isDisabled={!isEdit} onChange={selectedItem => handleFFLChange(selectedItem)} options={fflList} />
                        </MDBCol> */}
                        <MDBCol size="1" className="text-center" onClick={() => setShowWonList(!isShowWonList)}>
                            {item.role && item.role.name}
                        </MDBCol>
                        {isEdit && <MDBCol size="1" className="text-center">
                            <MDBIcon icon="user-edit" className="user-edit-icon" onClick={() => editUserFunc()} />
                        </MDBCol>}
                        {isEdit && <MDBCol size="1" className="text-center">
                            {
                                item.auth_banned ?
                                    <MDBIcon icon="user-check" className="user-unban-icon" onClick={() => disableUserFunc()} />
                                    :
                                    <MDBIcon icon="user-slash" className="user-ban-icon" onClick={() => disableUserFunc()} />
                            }
                        </MDBCol>}
                        {/* <MDBCol size="1" className="text-center">
                    <MDBIcon icon="user-times" className="user-del-icon" onClick={()=>setIsDeleteUser(true)}/>
                </MDBCol> */}
                    </MDBRow>
            }
            {
                isShowWonList && <Fragment>
                    <MDBRow className="ffl-row">
                        <MDBCol size="3">
                            Won Item
                        </MDBCol>
                        <MDBCol size="3" className="text-center">
                            Date
                        </MDBCol>
                        <MDBCol size="2" className="text-center">
                            Seat Number
                        </MDBCol>
                        <MDBCol size="3" className="text-center">
                            Price
                        </MDBCol>
                        <MDBCol size="1" className="text-center">
                            {
                                item.winner_history.length > 0 ?
                                    isOpenRowWon ?
                                        <button className="openBtn" onClick={() => {
                                            setIsOpenRowWon(false);
                                        }}><MDBIcon icon="minus" /></button>
                                        :
                                        <button className="openBtn" onClick={() => {
                                            setIsOpenRowWon(true);
                                        }}><MDBIcon icon="plus" /></button>
                                    :
                                    ""
                            }
                        </MDBCol>
                    </MDBRow>
                    {
                        isOpenRowWon && item.winner_history.length > 0 ? item.winner_history.map(wonItem =>
                            <MDBRow className="ffl-row bottom-border" key={wonItem.id}>
                                <MDBCol size="3">
                                    <LongItemWithTooltip text={wonItem?.webinar?.name} maxlenght={60} />
                                </MDBCol>
                                <MDBCol size="3" className="text-center">
                                    {getCustomDate(wonItem?.createdAt)}
                                </MDBCol>
                                <MDBCol size="3" className="text-center">
                                    {wonItem?.seatNo + 1}
                                </MDBCol>
                                <MDBCol size="1" className="text-center">
                                    {wonItem?.webinar?.price_per_seats}
                                </MDBCol>
                            </MDBRow>
                        )
                            :
                            ""
                    }
                    {
                        item.winner_history.length <= 0 ?
                            <div className="non-ffl-row text-center">
                                No Won Items
                            </div>
                            :
                            ""
                    }
                    <MDBRow className="ffl-row no-border-top">
                        <MDBCol size="2">
                            Purchased Item
                        </MDBCol>
                        <MDBCol size="2" className="text-center">
                            Product Type
                        </MDBCol>
                        <MDBCol size="4" className="text-center">
                          Seats Bought
                        </MDBCol>
                        <MDBCol size="2" className="text-center">
                            Date
                        </MDBCol>
                        <MDBCol size="1" className="text-center">
                            Price
                        </MDBCol>
                        <MDBCol size="1" className="text-center">
                            {
                                item.purchase_history.length > 0 ?
                                    isOpenRowPurchased ?
                                        <button className="openBtn" onClick={() => {
                                            setIsOpenRowPurchased(false);
                                        }}><MDBIcon icon="minus" /></button>
                                        :
                                        <button className="openBtn" onClick={() => {
                                            setIsOpenRowPurchased(true);
                                        }}><MDBIcon icon="plus" /></button>
                                    :
                                    null
                            }
                        </MDBCol>
                    </MDBRow>

                    {
                        isOpenRowPurchased && arrFilter.length > 0 ? arrFilter.map(item =>


                            <MDBRow className="ffl-row bottom-border" key={item.id}>
                                <MDBCol size="2" className="text-left">
                                    <LongItemWithTooltip text={item?.webinar_product} maxlenght={60}/>
                                </MDBCol>
                                <MDBCol size="2" className="text-center">
                                    {item.product_type}
                                </MDBCol>
                                <MDBCol size="4" className="text-center overflow-text">
                                    {item?.seatNo.map((item, index) => ( (index ? ',': '') + (item + 1)))} 
                                </MDBCol>
                                <MDBCol size="2" className="text-center">
                                    {getCustomDate(item.createdAt)}
                                </MDBCol>
                                <MDBCol size="1" className="text-center">
                                    {item.price}
                                </MDBCol>
                            </MDBRow>

                        )
                            :
                            null
                        // <MDBRow className="ffl-row bottom-border" key={item.id}>
                        // <MDBCol size="4" className="text-center" >
                        //     Name: {item && item.webinar_product.productName}
                        // </MDBCol>
                        // <MDBCol size="4" className="text-center">
                        //     Type: Webinar
                        // </MDBCol>
                        // <MDBCol size="4" className="text-center">
                        //     Date: {getCustomDate(item.createdAt)}
                        // </MDBCol>


                        //     <MDBCol size="6" className="text-center">
                        //         Seat Number
                        //     </MDBCol>
                        //     <MDBCol size="6" className="text-center">
                        //         Price
                        //     </MDBCol>


                        //     <MDBCol size="6" className="text-center">
                        //         {item.rowsBought}
                        //     </MDBCol>
                        //     <MDBCol size="6" className="text-center">
                        //         {item && item.price}
                        //     </MDBCol>
                        // </MDBRow>
                    }



                    {
                        item.purchase_history.length <= 0 ?
                            <div className="non-ffl-row text-center">
                                No Won Items
                            </div>
                            :
                            ""
                    }
                    <MDBRow className="ffl-row no-border-top">
                        <MDBCol size="3">
                            Hot Seats Won
                        </MDBCol>
                        <MDBCol size="4" className="text-center">
                            Date
                        </MDBCol>
                        <MDBCol size="4" className="text-center">
                            Hot Seat Number
                        </MDBCol>
                        <MDBCol size="1" className="text-center">
                            {
                                item.winner_hot_seat.length > 0 ?
                                    isOpenRowHot ?
                                        <button className="openBtn" onClick={() => {
                                            setIsOpenRowHot(false);
                                        }}><MDBIcon icon="minus" /></button>
                                        :
                                        <button className="openBtn" onClick={() => {
                                            setIsOpenRowHot(true);
                                        }}><MDBIcon icon="plus" /></button>
                                    :
                                    ""

                            }
                        </MDBCol>

                    </MDBRow>
                    {
                        isOpenRowHot && item.winner_hot_seat.length > 0 ? item.winner_hot_seat.map(item =>
                            <MDBRow className="ffl-row bottom-border" key={item.id}>
                                <MDBCol size="3">
                                    <LongItemWithTooltip text={item && item?.name} maxlenght={60} />
                                </MDBCol>
                                <MDBCol size="4" className="text-center">
                                    {item && getCustomDate(item?.updatedAt)}
                                </MDBCol>
                                <MDBCol size="4" className="text-center">
                                    {item.hot_seat_number}
                                </MDBCol>
                            </MDBRow>
                        )
                            :
                            null
                    }
                    {
                        item.winner_hot_seat.length <= 0 ?
                            <div className="non-ffl-row text-center">
                                No Hot Seat Items
                            </div>
                            :
                            ""

                    }
                </Fragment>
            }
        </Fragment>
    )
}

const MapDispatchToProps = dispatch => ({
    editUserInfo: editUserInfo(dispatch),
    deleteUserInfo: deleteUserInfo(dispatch),
    getProductItems: getProductItems(dispatch),
    loadPage: flag => dispatch(loadPage(flag)),
    removeFFLSelected: removeFFLSelected(dispatch),
})

export default connect(null, MapDispatchToProps)(UserItemRow);