import React from 'react';
import './tooltip.style.scss';

const Tooltip = ({ text, color, closeModal, backgroundColor, innerRef }) => {
  
  const setColorClass = (color) => {
    if(color && color?.toLowerCase()?.includes('gray')) {
      return 'tooltip-custom__back-gray';
    } else if(color && color?.toLowerCase()?.includes('white')) {
      return 'tooltip-custom__back-white';
    } else if(color && color?.toLowerCase()?.includes('blue')) {
      return 'tooltip-custom__back-blue';
    } else{
      return 'tooltip-custom__back-white';
    }
  }

  return (
    <div className={`tooltip-custom ${setColorClass(color)}`} ref={innerRef}>
      <div className='tooltip-custom__content'>
        <p className={`tooltip-custom__content__description tooltip-custom__content__description__back-${backgroundColor}`}>
          {text}
        </p>
        <i onClick={() => closeModal()} class="far fa-times-circle"></i>
      </div>
    </div>
  );
};

export default Tooltip;