import FFLModificationsActionTypes from './ffl-modifications.types';

const INITIAL_STATE = {
    report_items: null,
    isSyncing: false,
    syncingSuccess: false,
    syncingError: null,
}

const FFLModificationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FFLModificationsActionTypes.CLEAR_SYNCING:
            return {
                ...state,
                isSyncing: false,
                syncingSuccess: false,
                syncingError: null,
            }
        case FFLModificationsActionTypes.SET_SYNCING_START:
            return {
                ...state,
                isSyncing: true,
                syncingSuccess: false,
                syncingError: null,
            }
        case FFLModificationsActionTypes.SET_SYNCING_END:
            return {
                ...state,
                isSyncing: false,
                syncingSuccess: true,
                syncingError: null,
            }
        case FFLModificationsActionTypes.SET_SYNCING_ERROR:
            return {
                ...state,
                isSyncing: false,
                syncingSuccess: false,
                syncingError: action.payload,
            }
        case FFLModificationsActionTypes.SET_REPORT_ITEMS:
            return {
                ...state, 
                report_items : action.payload, 
            }
        default:
            return state;
    }
}

export default FFLModificationsReducer;