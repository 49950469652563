import React from 'react';
import DatePicker from 'react-datepicker';
import { MDBRow, MDBCol } from 'mdbreact';

const SpecificPeriodComponent = (props) => {
  const { 
    startDate, 
    setStartDate, 
    endDate, 
    setEndDate, 
    saveSpecificPeriod, 
    setIsShowCustDate, 
    setSelectedRevenuePeriod } = props;
  return (
    <div className="customDatePicker">
      <MDBRow>
          <MDBCol size="12" sm="7">
              <h6 className="text-center text-white mb-2">Select Specific Dates</h6>
          </MDBCol>
          <MDBCol size="12" sm="5" className="text-right mb-2">
              <button className="mr-2" onClick={saveSpecificPeriod}>Select</button>
              <button onClick={() => {
                  setIsShowCustDate(false);
                  setSelectedRevenuePeriod('Selected period');
              }}>Cancel</button>
          </MDBCol>
      </MDBRow>                  
      <div className="dateWrapper">
          <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={endDate}                          
              inline
          />
          <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate} 
              maxDate={new Date()}                      
              inline
          />
      </div>                    
  </div> 
  )
}

export default SpecificPeriodComponent;