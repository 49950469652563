import React, { useState, useEffect, Fragment } from 'react';
import FormButton from '../../../../components/form-button/form-button.component';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import DatePicker from "react-datepicker";
import { 
  addBackgroundImage, 
  getBackgroundImages, 
  deleteBackgroundImage, 
  editBackgroundImage } from '../../../../redux/colorChnage/colorChange.action';
import { loadPage } from '../../../../redux/user/user.action';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { Storage } from 'aws-amplify';
import { useAlert } from 'react-alert';

const LandingImageChange = ({
	addBackgroundImage,
	getBackgroundImages,
	deleteBackgroundImage,
	editBackgroundImage,
	loadPage,
    isEdit
}) => {

	const alert = useAlert();

  const startDatePickerLink = React.createRef();
  const [startDate, setStartDate] = useState();

  const endDatePickerLink = React.createRef();
  const [endDate, setEndDate] = useState();

  const [backgroundImage, setBackgroundImage] = useState(null);

  const [backgroundImageList, setBackgroundImageList] = useState([]);

  const [selectedBackImg, setSelectedBackImg] = useState(null);
  const [activeIndex, setActiveIndex] = useState();

  const {getRootProps, getInputProps} = useDropzone({
    accept: '.jpg, .jpeg, .png, .webp, .tiff, .tif, .gif, .svg',
    multiple : false,
    onDrop: acceptedFiles => {
            setActiveIndex(null);
            setSelectedBackImg(null);
            setStartDate(null);
            setEndDate(null);      
            setBackgroundImage(Object.assign(acceptedFiles[0], {
                    preview: URL.createObjectURL(acceptedFiles[0])
                }));
            }
  });

  useEffect(() => {
    async function load() {
        const backImgList = await getBackgroundImages('landingpage');
        if ( backImgList && backImgList.length > 0) {
            const galleryImgs = [];
            for (let i=0; i<backImgList.length; i++) {
                    galleryImgs.push({
                            id: backImgList[i].id,
                            origin_url: backImgList[i].image_url,
                            url: await Storage.get(backImgList[i].image_url),
                            start: backImgList[i].start_from && backImgList[i].start_from.toString(),
                            end: backImgList[i].end_to && backImgList[i].end_to.toString()
                    });
            }
            setBackgroundImageList([...galleryImgs]);
        }
    }
    load();
  }, []);

  const backgoundImageUpload = async file => {
    const stored = await Storage.put("landing-image-"+Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)+"-"+file.name, file, {
        contentType: file.type,
        cacheControl: 'max-age=31536000'
    });
    return stored.key;
  }

  const addBackgroundImgFunc = async () => {

    if(!startDate || !endDate || (startDate.getTime() > endDate.getTime())) {
        alert.error("Invalid Date Range");
        return;
    }

    if(!backgroundImage) {
        alert.error("Please select the file");
        return;
    }

    startDate.setHours(0);
    startDate.setMinutes(0);
    endDate.setHours(23);
    endDate.setMinutes(59);
    
    const obj ={
        image_url: backgroundImage,
        start_from: startDate.toISOString(),
        end_to: endDate.toISOString()
    }
    loadPage(true);
    if (backgroundImage){
        const imgUrl = await backgoundImageUpload(backgroundImage);
        obj.image_url = imgUrl;
    }
    const result = await addBackgroundImage(obj, 'landingpage');

    if (result === "success") {
        alert.success("Added successfully");
        const backImgList = await getBackgroundImages('landingpage'); 
        if (backImgList.length > 0) {
            const galleryImgs = [];
            for (let i=0; i<backImgList.length; i++) {
                galleryImgs.push({
                    id: backImgList[i].id,
                    url: await Storage.get(backImgList[i].image_url),
                    start: backImgList[i].start_from.toString(),
                    end: backImgList[i].end_to.toString()
                });
            }
            setBackgroundImageList([...galleryImgs]);
        }
        else
            setBackgroundImageList([]);
    }
    else
        alert.error(result);
    loadPage(false);
  }

  const deleteBackImgFunc = async () => {

    loadPage(true);
    const result = await deleteBackgroundImage(selectedBackImg.id, 'landingpage');
    if (result === "success") {
        alert.success("Removed successfully");
        const backImgList = await getBackgroundImages('landingpage');
        if (backImgList.length > 0) {
            const galleryImgs = [];
            for (let i=0; i<backImgList.length; i++) {
                galleryImgs.push({
                    id: backImgList[i].id,
                    url: await Storage.get(backImgList[i].image_url),
                    start: backImgList[i].start_from.toString(),
                    end: backImgList[i].end_to.toString()
                });
            }
            setBackgroundImageList([...galleryImgs]);
        }
        else
            setBackgroundImageList([]);
        setSelectedBackImg(null);
    }
    else
        alert.error(result);
    loadPage(false);
  }

  const editBackImgFunc = async () => {

    startDate.setHours(0);
    startDate.setMinutes(0);
    endDate.setHours(23);
    endDate.setMinutes(59);

    if(!startDate || !endDate || (startDate.getTime() > endDate.getTime())) {
        alert.error("Invalid Date Range");
        return;
    }

    const obj = {
        start_from: startDate.toISOString(),
        end_to: endDate.toISOString()
    }

    console.log(obj);

    loadPage(true);
    const result = await editBackgroundImage(selectedBackImg.id, obj, 'landingpage');
    if (result === "success") {
        alert.success("Edited successfully");
        const backImgList = await getBackgroundImages('landingpage');
        if (backImgList.length > 0) {
            const galleryImgs = [];
            for (let i=0; i<backImgList.length; i++) {
                galleryImgs.push({
                    id: backImgList[i].id,
                    url: await Storage.get(backImgList[i].image_url),
                    start: backImgList[i].start_from.toString(),
                    end: backImgList[i].end_to.toString()
                });
            }
            setBackgroundImageList([...galleryImgs]);
        }
    }
    else
        alert.error(result);
    loadPage(false);
  }

  return(
    <>
      <h4 className="text-white font-weight-bolder mt-4">Change Landing Page Background</h4>
      <p className="grey-text mb-4">The admin can change the landing page background with a temporary image and put a start and end date.</p>
      <div className="select-back-img-wrapper mb-4">
        <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()}/>
            <div className="select-upload-img">
                <div>
                    {
                      backgroundImage ? 
                        <Fragment>
                            <label><MDBIcon far icon="check-circle" style={{color: '#99ffee'}}/></label>
                            <label className="text-center">{backgroundImage.name}</label>
                        </Fragment>
                      :    
                        <Fragment>
                            <label>+</label>
                            <label className="text-center">Select File</label>
                        </Fragment>
                    }
                </div>
            </div>
        </div>
        {
          backgroundImageList && backgroundImageList.length > 0 ? backgroundImageList.map(
            (image,i) => <div className={`${activeIndex === i+1 ? "active" : ""} pre-back-img`} key={i} onClick={()=> {
              setActiveIndex(i+1);
              setSelectedBackImg({
                      id: image.id,
                      origin_url: image.origin_url,
                      url: image.url,
                      start: image.start,
                      end: image.end
                  });
              setStartDate(new Date(image.start));
              setEndDate(new Date(image.end));
              }}>
                  <img src={image.url} alt="background image"/>
              </div>
          )
          :
          null
        }
      </div>
      <MDBRow>
        <MDBCol size="8" sm="8" md="4" lg="2" className="background-date-wrapper ml-3">
          <DatePicker
              ref={startDatePickerLink} 
              minDate={new Date()} 
              selected={startDate} 
              onChange={date => setStartDate(date)}
              placeholderText="Start Date"
              style={{backgroundColor: "transparent"}}
          />
          <MDBIcon icon="calendar-alt" className="datePickerIcon" onClick={()=>startDatePickerLink.current.setOpen(true)}/>  
        </MDBCol>
        <MDBCol size="8" sm="8" md="4" lg="2" className="background-date-wrapper ml-3">
          <DatePicker
              ref={endDatePickerLink} 
              minDate={new Date()} 
              selected={endDate} 
              onChange={date => setEndDate(date)}
              placeholderText="End Date"
              style={{backgroundColor: "transparent"}}
          />
          <MDBIcon icon="calendar-alt" className="datePickerIcon" onClick={()=>endDatePickerLink.current.setOpen(true)}/>  
        </MDBCol>
        {
          selectedBackImg ? <Fragment>
              {isEdit && <MDBCol size="8" sm="6" md="4" lg="2">
                  <FormButton onClickFunc={()=>editBackImgFunc()}>EDIT</FormButton>
              </MDBCol>}
              {isEdit && <MDBCol size="8" sm="6" md="4" lg="2">
                  <FormButton onClickFunc={()=>deleteBackImgFunc()}>DELETE</FormButton>
              </MDBCol>}
          </Fragment>
          :
          <MDBCol size="8" sm="6" md="4" lg="2">
              {isEdit && <FormButton onClickFunc={()=>addBackgroundImgFunc()}>ADD</FormButton>}
          </MDBCol>
        }          
      </MDBRow>
    </>
  )
}

const MapDispatchToProps = dispatch => ({
	addBackgroundImage: addBackgroundImage(dispatch),
	getBackgroundImages: getBackgroundImages(dispatch),
	deleteBackgroundImage: deleteBackgroundImage(dispatch),
	editBackgroundImage: editBackgroundImage(dispatch),
	loadPage: flag => dispatch(loadPage(flag)),
})

export default connect(null, MapDispatchToProps)(LandingImageChange);