import SoldPhysicalActionTypes from './sold-physical.types';

const INITIAL_STATE = {
    product_items_count : 1,
    product_items: {
        count: 1,
        ffl_data: [],
        rows: [],
    },
    filterStr: "",
    limitPerPage: 10,
    pageNum: 1,
    rowsToCounter: 0,
    fflFilter: "",
}

const SoldPhysicalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SoldPhysicalActionTypes.SET_PRODUCT_LIST_ITEMS:
            return {
                ...state, 
                product_items : action.payload,
            }
        case SoldPhysicalActionTypes.SET_PRODUCT_LIST_ITEMS_COUNT:
            return {
                ...state, 
                product_items_count : action.payload,
            }
        case SoldPhysicalActionTypes.SET_FILTER_STRING:
            return {
                ...state, filterStr: action.payload
            }
        case SoldPhysicalActionTypes.SET_LIMIT_PER_PAGE:
            return {
                ...state, limitPerPage: action.payload
            }         
        case SoldPhysicalActionTypes.SET_PRODUCT_LIST_PAGE_NUM:
            return {
                ...state, pageNum: action.payload
            }
        case SoldPhysicalActionTypes.SET_PRODUCT_COUNT:
            return {
                ...state, 
                rowsToCounter: action.payload,
            }
        case SoldPhysicalActionTypes.SET_FFL_FILTER:
            return {
                ...state,
                fflFilter: action.payload
            }
        default:
            return state;
    }
}

export default SoldPhysicalReducer;