import DashboardPage from './pages/dashboard/dashboard-page';
import LipseyDashboardPage from './pages/dashboard/lipsey-dashboard-page';
import CategoriesPage from './pages/categories/categories-page.component';
import ProductListingPage from './pages/product-listing/product-listing-page.component';
import WebinarQueuesPage from './pages/webinar-queues/webinar-queues-page.component';
import SoldWebinarPage from './pages/sold-webinar/sold-webinar-page.component';
import FFLDatabasePage from './pages/ffl-database/ffl-database-page.component';
import SoldPhysicalProductsPage from './pages/sold-physical/sold-physical.page';
import OfflineSalesPage from './pages/offline-sales/offline-sales.page';
import UserManagementPage from './pages/user-management/user-management.page';
import UserEditPage from './pages/user-management/edit/user-edit.component';
import FaqPage from './pages/faq/faq.page';
import SettingsPage from './pages/settings/settings.page';
import CompletedWebinarPage from './pages/completed-webinars/completed-webinars.page';
import ProductEditPage from './pages/product-listing/product-edit/product-edit.component';
import FflEditPage from './pages/ffl-database/ffl-edit/ffl-edit.component.jsx';
import AlertsPage from './pages/alerts-page/alerts-page.component';
import LiveStreamModePage from './pages/sold-webinar/live-stream/lilve-stream.component';
import FaqEditPage from './pages/faq/faq-edit/faq-edit.component';
import ReportPage from './pages/report/report-page.component';
import FFLModificationsPage from './pages/ffl-modifications/ffl-modifications-page.component';
import SeatRefundPage from './pages/seat-refund/seat-refund-page.component';
import ShippingPage from './pages/shipping-page/shipping-page.component';
import DistributorsShippingPage from './pages/distributors-shipping-page/distributors-shipping-page.component';
import AdminRolesPage from './pages/admin-roles/admin-roles.component';
import ProvidersMarkupPage from './pages/markups/markups.page';
import EmailSettingsPage from './pages/email-settings/email-settings.page';
import SoldDistributorsProductsPage from './pages/sold-distributors-products/sold-distributors-products.page';
import ForgotPassword from './pages/forgot-password/forgot-password.component';
import ResetPasswordPage from './pages/reset-password/reset-password.component';
import SignInPage from './pages/signin/signin-page.component';
import ZoomMeetingsPage from './pages/zoom-meetings/zoom-meetings-page.component';
import ZoomEditPage from './pages/zoom-meetings/meeting-edit/zoom-edit.component';
import AddNewMeetingZoom from './pages/sold-webinar/live-stream/add-meeting/add-new-zoom-meeting-component'

const router = {  
  setting: {
    path: '/setting',
    component: SettingsPage,
    exact: true,
    permission: 'settingsView',
  },
  ffl: {
    path: '/ffl',
    component: FFLModificationsPage,
    // exact: true,
  },
  report: {
    path: '/product/report',
    exact: true,
    component: ReportPage,
  },
  shipping: {
    path: '/product/shipping',
    exact: true,
    component: ShippingPage,
  },
  faq: {
    path: '/faq',
    component: FaqPage,
    permission: 'faqView',
    exact: true,
  },
  editFaq: {
    path: '/faq/edit',
    component: FaqEditPage,
    permission: 'faqEdit',
    exact: true,
  },
  dashboard: {
    path: '/',
    component: DashboardPage,
    exact: true,
    permission: 'dashboardView',
  },
  lipseyDashboard: {
    path: '/lipseys/dashboard',
    component: LipseyDashboardPage,
    exact: true,
    permission: 'dashboardView',
  },
  lipseyProducts: {
    path: '/lipseys/products',
    component: LipseyDashboardPage,
    exact: true,
    permission: 'dashboardView',
  },
  lipseyMarkup: {
    path: '/lipseys/markup',
    component: ProvidersMarkupPage,
    exact: true,
    permission: 'dashboardView',
  },
  lipseySoldProducts: {
    path: '/lipseys/sold-distributors-products',
    component: SoldDistributorsProductsPage,
    exact: true,
    permission: 'dashboardView',
  },
  lipseyShipping: {
    path: '/lipseys/shipping',
    // :product_type
    component: DistributorsShippingPage,
    exact: true,
    permission: 'dashboardView',
  },
  emailNewProduct: {
    path: '/email/new-product',
    // :product_type
    component: EmailSettingsPage,
    exact: true,
    permission: 'dashboardView',
  },
  emailWinner: {
    path: '/email/winner',
    // :product_type
    component: EmailSettingsPage,
    exact: true,
    permission: 'dashboardView',
  },
  categories: {
    path: '/product/category',
    component: CategoriesPage,
    exact: true,
    permission: 'categoryView',
  },
  editFfl: {
    path: '/product/ffl-db/edit',
    component: FflEditPage,
    exact: true,
    permission: 'fflEdit',
  },
  editProduct: {
    path: '/product/product/edit',
    component: ProductEditPage,
    exact: true,
    permission: 'productEdit',
  },
  soldOutWebinars: {
    path: '/product/sold-webinar',
    component: SoldWebinarPage,
    exact: true,
    permission: 'soldOutWebinarsView',
  },
  liveStreamMode: {
    path: '/product/sold-webinar/live-stream/:webinarID',
    component: LiveStreamModePage,
    permission: 'soldOutWebinarsView',
    exact: true,
  },
  productListing: {
    path: '/product/product/listing',
    component: ProductListingPage,
    permission: 'productView',
    exact: true,
  },
  webinarQueues: {
    path: '/product/product/webinar',
    component: WebinarQueuesPage,
    permission: 'webinarQueueView',
    exact: true,
  },
  seatRefund: {
    path: '/seat-refund',
    component: SeatRefundPage,
    permission: 'seatsRefundView',
    exact: true,
  },
  soldPhysicalProducts: {
    path: '/product/sold-physical',
    component: SoldPhysicalProductsPage,
    permission: 'soldOutPhysicalView',
    exact: true,
  },
  offlineSales: {
    path: '/product/offline-sales',
    component: OfflineSalesPage,
    permission: 'soldOutPhysicalView',
    exact: true,
  },
  fflDb: {
    path: '/product/ffl-db',
    component: FFLDatabasePage,
    permission: 'fflView',
    exact: true,
  },
  alertPage: {
    path: '/alerts_page',
    component: AlertsPage,
    permission: 'soldOutWebinarsView',
    exact: true,
  },
  completedWebinars: {
    path: '/product/completed-webinar',
    component: CompletedWebinarPage,
    permission: 'completedWebinarsView',
    exact: true,
  },
  zoomMeetings: {
    path: '/product/zoom-meetings',
    component: ZoomMeetingsPage,
    exact: true,
  },
  createZoom: {
    path: '/product/sold-webinar/create',
    component: AddNewMeetingZoom,
    exact: true,
  },
  editZoom: {
    path: '/product/zoom-meetings/edit',
    component: ZoomEditPage,
    exact: true
  },
  userManagement: {
    path: '/user',
    component: UserManagementPage,
    exact: true,
    permission: 'usersView',
  },
  userEdit: {
    path: '/user/edit',
    component: UserEditPage,
    permission: 'usersEdit',
    exact: true,
  },
  roles: {
    path: '/roles',
    component: AdminRolesPage,
    permission: 'rolesView',
    exact: true,
  },
  forgotPassword: {
    path: '/forgot-password',
    exact: true,
    component: ForgotPassword,
  },
  resetPassword: {
    path: '/resetpassword/:code/:userID',
    exact: true,
    component: ResetPasswordPage,
  },
  logIn : {
    path: '/login',
    exact: true,
    component: SignInPage,
  },
};

export default router;
