import React, { useState, useMemo } from "react";
import "./tooltip.style.scss";

const LongItemWithTooltip = ({ text , maxlenght= 25}) => {
  const [showMore, setShowMore] = useState(null);
  const numberOfCharacters = useMemo(() => (showMore ? 250 : maxlenght), [showMore]);
  return (
    <div title={text} className="long-item-with-tooltip">
      {text?.length > maxlenght ? (
        <div>
          {text.slice(0, numberOfCharacters)}
          <a
            className="show-more-button"
            onClick={() => setShowMore(showMore === null ? text.length : null)}
          >
            {showMore ? " show less" : "... show more"}
          </a>
        </div>
      ) : (
        text
      )}
    </div>
  );
};

export default LongItemWithTooltip;
