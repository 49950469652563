import React, { useState, useEffect, Fragment } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { Storage } from "aws-amplify";
import { loadPage } from "../../../redux/user/user.action";
import {
  hideOtherGoLives,
  setWebinarStartLink,
} from "../../../redux/sold-out-webinars/sold-out-webinars.action";
import {
  getEmailList,
  changeWebinarLink,
} from "../../../redux/completed-webinar/completed-webinar.action";
import { connect, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import "./sold-webinar-row.style.scss";
import FormButton from "../../../components/form-button/form-button.component";
import router from "../../../router";
// import DateTimePicker from 'react-datetime-picker';
import moment from "moment-timezone";
import LongItemWithTooltip from "../../tooltip/long-item-with-tooltip";

const SoldWebinarListeRow = ({
  isEdit,
  item,
  isMobile,
  loadPage,
  isHideOtherGoLives,
  hideOtherGoLives,
  setWebinarStartLink,
  getEmailList,
  changeWebinarLink,
  getItemsAgain,
}) => {
  const [showMore, setShowMore] = useState(null);
  const numberOfCharacters = showMore ? 250 : 30;
  // currentUser,
  // setZoomLink,
  const urlHistory = useHistory();

  const alert = useAlert();

  const [imageUrl, setImageUrl] = useState(null);
  const [isGoLiveShow, setIsGoLiveShow] = useState(false);
  const [goLiveLink, setGoLiveLink] = useState("");
  // const [zoomTopic, setZoomTopic] = useState("");
  // const [zoomDateTime, setZoomDateTime] = useState("");
  // const [zoomAgenda, setZoomAgenda] = useState("");

  useEffect(() => {
    async function load() {
      item.thumbnail_300 && item.thumbnail_300?.image_url
        ? setImageUrl(await Storage.get(item.thumbnail_300.image_url))
        : setImageUrl(await Storage.get(item.main_image.image_url));

      checkImage(
        await Storage.get("thumbnail-" + item.main_image.image_url),
        async function () {
          setImageUrl(
            await Storage.get("thumbnail-" + item.main_image.image_url)
          );
        },
        async function () {
          item.thumbnail_300 && item.thumbnail_300?.image_url
            ? setImageUrl(await Storage.get(item.thumbnail_300.image_url))
            : setImageUrl(await Storage.get(item.main_image.image_url));
        }
      );
    }
    load();
  }, [item.main_image]);

  async function checkImage(imageSrc, good, bad) {
    var img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = imageSrc;
  }

  const goToSelectWinner = async () => {
    hideOtherGoLives(item.id);
    if (item.webinar_link === null || item.webinar_link == "") {
      urlHistory.push(router.createZoom.path, {
        id: item?.id,
        name: item?.name,
      });
    } else
      urlHistory.push(`/product/sold-webinar/live-stream/${item.id}`, {
        webinarName: item.name,
        imageUrl: imageUrl,
      });
  };

  const sendGoLiveLink = async (url) => {
    if (goLiveLink.trim() === "" || goLiveLink.slice(0, 8) !== "https://") {
      alert.error("Invalid Link");
      return;
    } else {
      const obj = {
        webinar_link: goLiveLink,
      };
      loadPage(true);
      const result = await setWebinarStartLink(item.id, obj);
      setIsGoLiveShow(false);
      loadPage(false);
      if (result === "success")
        urlHistory.push(`/product/sold-webinar/live-stream/${item.id}`, {
          webinarName: item.name,
          imageUrl: imageUrl,
        });
      else alert.error("Failed");
    }
  };
  // const launchMeeting = async () => {
  //         const obj = {
  //             email: "mateohoxha26@gmail.com",
  //             topic: zoomTopic,
  //             start_time: zoomDateTime,
  //             agenda: zoomAgenda,
  //         }
  // urlHistory.push(router.createZoom.path, { item: item })
  //         loadPage(true);
  //         const result = await setZoomLink(item.id, obj);
  //         loadPage(false);
  // }

  const launchZoom = async (item) => {
    const obj = { webinar_link: item.webinar_link };
    loadPage(true);
    const result = await setWebinarStartLink(item.id, obj);
    loadPage(false);
    if (result === "success") {
      window.open(item.webinar_link, "_blank");
    } else alert.error("Failed");
  };

  const [isOpenTable, setIsOpenTable] = useState(false);

  //for extracting emails of all users
  const [isExtractDlgShow, setIsExtractDlgShow] = useState(false);
  const [extractEmailList, setExtractEmailList] = useState(null);

  const extractEmailsFunc = async () => {
    setIsExtractDlgShow(true);
    const result = await getEmailList(item?.id);
    if (result) {
      setExtractEmailList(result.message);
    }
  };

  // for changing webinar link
  const [newLink, setNewLink] = useState("");
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteLinkFunc = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const result = await changeWebinarLink(item?.id, "");
    setIsLoading(false);
    if (result) {
      if (result.message === "success") {
        alert.success("Deleted");
        getItemsAgain();
      } else alert.error(result.message);
      setIsConfirmDelete(false);
    }
  };

  const addNewLinkFunc = async () => {
    if (newLink.trim() === "" || newLink.slice(0, 8) !== "https://") {
      alert.error("Invalid Link");
      return;
    }
    const result = await changeWebinarLink(item?.id, newLink);
    if (result) {
      if (result.message === "success") {
        alert.success("Added");
        getItemsAgain();
      } else alert.error(result.message);
    }
  };

  return (
    <Fragment>
      {isExtractDlgShow && (
        <div className="removeDialogWrapper">
          <div className="removeDialog">
            <MDBIcon
              className="float-right text-white closeIcon"
              icon="times"
              onClick={() => setIsExtractDlgShow(false)}
            />
            <br />
            <h3 className="text-white text-center font-weight-bold mb-4">
              Emails of users
            </h3>
            <p className="text-white text-center mb-4 emailList">
              {extractEmailList ? extractEmailList : "Loading..."}
            </p>
          </div>
        </div>
      )}
      {isConfirmDelete && (
        <div className="removeDialogWrapper">
          <div className="removeDialog">
            <MDBIcon
              className="float-right text-white closeIcon"
              icon="times"
              onClick={() => setIsConfirmDelete(false)}
            />
            <br />
            <h3 className="text-white text-center font-weight-bold mb-4">
              Are you sure to delete the link?
            </h3>
            <br />
            <MDBRow center className="mb-4 mobile-row">
              <MDBCol size="6" md="4">
                <FormButton onClickFunc={deleteLinkFunc} isLoading={isLoading}>
                  YES
                </FormButton>
              </MDBCol>
              <MDBCol size="6" md="4">
                <FormButton
                  greyCol={true}
                  onClickFunc={() => setIsConfirmDelete(false)}
                >
                  NO
                </FormButton>
              </MDBCol>
            </MDBRow>
          </div>
        </div>
      )}
      {isMobile ? (
        <Fragment>
          <MDBRow className="table-row">
            <MDBCol size="4" middle className="text-center">
              {imageUrl ? (
                <img src={imageUrl} alt={item.name} />
              ) : (
                <div className="non-img">
                  <MDBIcon far icon="file-image" />
                </div>
              )}
            </MDBCol>
            <MDBCol size="6" middle className="text-center">
              {item.name && item.name}
            </MDBCol>
            <MDBCol size="2" middle className="text-center">
              <button
                className="openBtn"
                onClick={() => {
                  setIsOpenTable(true);
                }}
              >
                <MDBIcon icon="plus" />
              </button>
            </MDBCol>
          </MDBRow>
          {isOpenTable && (
            <Fragment>
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  Thumbnail
                </MDBCol>
                <MDBCol size="6" middle className="text-center">
                  {imageUrl ? (
                    <img src={imageUrl} alt={item.name} />
                  ) : (
                    <div className="non-img">
                      <MDBIcon far icon="file-image" />
                    </div>
                  )}
                </MDBCol>
                <MDBCol size="2" middle className="text-center">
                  <button
                    className="openBtn"
                    onClick={() => {
                      setIsOpenTable(false);
                    }}
                  >
                    <MDBIcon icon="minus" />
                  </button>
                </MDBCol>
              </MDBRow>
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  UPC
                </MDBCol>
                <MDBCol size="8" middle className="text-center">
                  {item?.upc_code ? item.upc_code : "unassigned"}
                </MDBCol>
              </MDBRow>
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  Name
                </MDBCol>
                <MDBCol size="8" middle className="text-center">
                  {item.name && item.name}
                </MDBCol>
              </MDBRow>
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  Description
                </MDBCol>
                <MDBCol size="8" middle className="text-center">
                  {item.shortDescription.length > 30 ? (
                    <div>
                      {item.shortDescription.slice(0, numberOfCharacters)}
                      <a
                        className="show-more-button"
                        onClick={() =>
                          setShowMore(
                            showMore === null
                              ? item.shortDescription.length
                              : null
                          )
                        }
                      >
                        ... show {showMore ? "less" : "more"}
                      </a>
                    </div>
                  ) : (
                    item.shortDescription
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  Price
                </MDBCol>
                <MDBCol size="8" middle className="text-center">
                  {item.price && item.price}
                </MDBCol>
              </MDBRow>
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  No. of Seats
                </MDBCol>
                <MDBCol size="8" middle className="text-center">
                  {item.seats && item.seats}
                </MDBCol>
              </MDBRow>
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  Sold Date
                </MDBCol>
                <MDBCol size="8" middle className="text-center">
                  {moment(item?.soldout_date).format("MM/DD/YYYY")}
                </MDBCol>
              </MDBRow>
              {isEdit && (
                <MDBRow className="table-row mobile-opend-table">
                  <MDBCol size="5" middle className="text-center text-white">
                    Remove/Add Link
                  </MDBCol>
                  <MDBCol size="7" middle className="text-center">
                    {!item?.webinar_link || item?.webinar_link.length < 1 ? (
                      <div className="trackingWrapper">
                        <input
                          type="text"
                          value={newLink}
                          onChange={(event) => setNewLink(event.target.value)}
                        />
                        <button onClick={addNewLinkFunc}>
                          <MDBIcon icon="check" />
                        </button>
                      </div>
                    ) : (
                      <button
                        className="streamBtn"
                        onClick={() => setIsConfirmDelete(true)}
                      >
                        Delete
                      </button>
                    )}
                  </MDBCol>
                </MDBRow>
              )}
              <MDBRow className="table-row mobile-opend-table">
                <MDBCol size="4" middle className="text-center text-white">
                  Extract
                </MDBCol>
                <MDBCol size="8" middle className="text-center">
                  <button onClick={extractEmailsFunc}>Extract</button>
                </MDBCol>
              </MDBRow>
              {isEdit && (
                <MDBRow className="table-row mobile-opend-table">
                  <MDBCol size="4" middle className="text-center text-white">
                    Duplicate
                  </MDBCol>
                  <MDBCol size="8" middle className="text-center">
                    <button
                      onClick={() =>
                        urlHistory.push(router.editProduct.path, {
                          prodType: "webinar",
                          id: item?.id,
                          duplicate: true,
                        })
                      }
                    >
                      Duplicate
                    </button>
                  </MDBCol>
                </MDBRow>
              )}
              {isEdit && (
                <MDBRow className="table-row mobile-opend-table">
                  <MDBCol size="4" middle className="text-center text-white">
                    Live Stream
                  </MDBCol>
                  {isEdit && (
                    <MDBCol size="1" className="text-center goLiveWrapper">
                      {item.product_status && (
                        <button
                          className="streamBtn"
                          onClick={() => {
                            goToSelectWinner();
                          }}
                        >{`${
                          item.webinar_link === null
                            ? "CREATE MEETING"
                            : "SELECT WINNER"
                        }`}</button>
                      )}
                    </MDBCol>
                  )}
                </MDBRow>
              )}
            </Fragment>
          )}
        </Fragment>
      ) : (
        <MDBRow className="table-row">
          <MDBCol size="1">
            {imageUrl ? (
              <img src={imageUrl} alt={item.name} />
            ) : (
              <div className="non-img">
                <MDBIcon far icon="file-image" />
              </div>
            )}
          </MDBCol>
          <MDBCol size={isEdit ? "1" : "2"} className="text-center email-col">
            {item?.upc_code ? item.upc_code : "unassigned"}
          </MDBCol>
          <MDBCol size={isEdit ? "1" : "2"} className="text-center">
            {item.name && <LongItemWithTooltip text={item.name} />}
          </MDBCol>
          <MDBCol size="1" className="text-center">
            <LongItemWithTooltip text={item.shortDescription} maxlenght={30} />
          </MDBCol>
          <MDBCol size="1" className="text-center">
            {item.price && item.price}
          </MDBCol>
          <MDBCol size="1" className="text-center">
            {item.seats && item.seats}
          </MDBCol>
          <MDBCol size="1" className="text-center">
            {moment(item?.soldout_date).format("MM/DD/YYYY")}
          </MDBCol>
          {isEdit && (
            <MDBCol size="1" className="text-center">
              {item?.product_status === "progress" ? (
                !item?.webinar_link || item?.webinar_link.length < 1 ? (
                  <div className="trackingWrapper">
                    <input
                      type="text"
                      value={newLink}
                      onChange={(event) => setNewLink(event.target.value)}
                    />
                    <button onClick={addNewLinkFunc}>
                      <MDBIcon icon="check" />
                    </button>
                  </div>
                ) : (
                  <button
                    className="streamBtn"
                    onClick={() => setIsConfirmDelete(true)}
                  >
                    Delete
                  </button>
                )
              ) : null}
            </MDBCol>
          )}
          <MDBCol size="1" className="text-center pl-4">
            <i
              class="fas fa fa-rocket"
              style={
                item.webinar_link !== null &&
                item.webinar_link !== "" &&
                item.product_status !== "progress"
                  ? { color: "orange" }
                  : { color: "#a3a3a3" }
              }
              onClick={() => launchZoom(item)}
            />
          </MDBCol>
          <MDBCol size={isEdit ? "1" : "2"} className="text-center">
            <button onClick={extractEmailsFunc}>Extract</button>
          </MDBCol>
          {isEdit && (
            <MDBCol size="1" className="text-center">
              <button
                onClick={() =>
                  urlHistory.push(router.editProduct.path, {
                    prodType: "webinar",
                    id: item?.id,
                    duplicate: true,
                  })
                }
              >
                Duplicate
              </button>
            </MDBCol>
          )}
          {isEdit && (
            <MDBCol size="1" className="text-center goLiveWrapper">
              {item.product_status && (
                <button
                  className={
                    item.webinar_link === null || item.webinar_link === ""
                      ? "color: #a3a3a3"
                      : "color: green"
                  }
                  onClick={() => {
                    goToSelectWinner();
                  }}
                >{`${
                  item.webinar_link === null || item.webinar_link == ""
                    ? "CREATE MEETING"
                    : "SELECT WINNER"
                }`}</button>
              )}
            </MDBCol>
          )}
        </MDBRow>
      )}
    </Fragment>
  );
};

const MapStateToPorps = ({
  user: { currentUser },
  soldOutWebinarList: { isHideOtherGoLives },
}) => ({
  currentUser,
  isHideOtherGoLives,
});

const MapDispatchToProps = (dispatch) => ({
  loadPage: (flag) => dispatch(loadPage(flag)),
  hideOtherGoLives: (id) => dispatch(hideOtherGoLives(id)),
  setWebinarStartLink: setWebinarStartLink(dispatch),
  // setZoomLink: setZoomLink(dispatch),
  getEmailList: getEmailList(dispatch),
  changeWebinarLink: changeWebinarLink(dispatch),
});

export default connect(
  MapStateToPorps,
  MapDispatchToProps
)(SoldWebinarListeRow);
