// import ReportActionTypes from './report.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI } from '../api-config';
import FFLModificationsActionTypes from './ffl-modifications.types';

export const createFFL = async (body) => {
    try {
        console.log("RECEIVED DATA",body)
        const data={
            ffl_number:body
        }

        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + "admin/ffl-modifications", data, { 
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
    }
}

export const syncFFLs = dispatch => async () => {
    dispatch(setSyncingStart());
    let offsetNumber = 0;
    let canContinue = true;
    let waitTime = 30000;
    while (canContinue) {
        try {
            const syncFFLsResponse = await axios.get( RestAPI.ORIGINAL_ENDPOINT + "admin/sync-ffl-databases/" + offsetNumber, { 
                headers: (await getMyTokenFunc())
            });
            if (syncFFLsResponse.data.success) {
                offsetNumber++;
            } else if(syncFFLsResponse.data.status === 429) {
                waitTime += 5000;
            } else {
                canContinue = false;
                dispatch(setSyncingEnd());
                return {success: true, message: 'Sync FFLs successfully'};
            }
            await new Promise(resolve => setTimeout(resolve, waitTime));
        } catch (error) {
            canContinue = false;
            console.log('Error handleSyncFFLs', error);
            dispatch(setSyncingError(error));
            throw new Error('Sync FFLs failed');
        }
    }
}

export const clearSyncing = dispatch => () => dispatch({
    type: FFLModificationsActionTypes.CLEAR_SYNCING,
    payload: null,
})

const setSyncingStart = () => ({
    type: FFLModificationsActionTypes.SET_SYNCING_START,
})

const setSyncingEnd = () => ({
    type: FFLModificationsActionTypes.SET_SYNCING_END,
})

const setSyncingError = error => ({
    type: FFLModificationsActionTypes.SET_SYNCING_ERROR,
    payload: error,
})
