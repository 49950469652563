import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { checkPermission } from '../checkPermissions';
import router from '../../router';

const withPermissionChecking = (WrappedComponent) => ({ ...props }) => {
  const [comp, setComp] = useState(null);
  const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')) || null);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userData')) || null);

  let zeroPath = null;
  let res = null;

  if ( permissions?.length > 0 ) {
    zeroPath = Object.values(router).find(({ permission }) => permissions?.includes(permission))?.path;
    res = checkPermission(props.match.path, permissions);
  }

  useEffect(() => {
    if ( userData && permissions?.length > 0 ) {
      if (res) {
        setComp(<WrappedComponent {...props} />);
      } else {
        setComp(<Redirect to={zeroPath} />);
      }
    }
    else
      setComp(<Redirect to={router.logIn.path} />)
  }, [permissions, userData]);

  return comp;
};

export default withPermissionChecking;
