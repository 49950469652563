import React from 'react';
import './alerts-page.style.scss';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { connect } from 'react-redux';
import { removeReadItem, readNotifications } from '../../redux/alerts/alerts.action';
import { loadPage } from '../../redux/user/user.action';
import NewProductAlertItem from './newProdAlertItem/newProdAlertItem.component';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';

const AlertsPage = ({ alertItems, removeReadItem, readNotifications, loadPage, isShowSideBar }) => {

    const handleChange = async (id) => {
        loadPage(true);
        const isSuccess = await readNotifications(id);
        if (isSuccess === "Success") {
            removeReadItem(id);
            loadPage(false);
        }
        else {
            loadPage(false);
            return;
        }
    };

    return (   
        <div className="alerts-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
            <h2 className="text-white font-weight-bold">Sold Out Webinar Products Alerts</h2>
            <div className="alerts-container">
                <MDBContainer>
                    <p className="font-weight-bolder text-white" >Alerts</p>
                    {
                        alertItems.length > 0 ?
                            alertItems.map(alertItem => 
                                (
                                    <NewProductAlertItem 
                                        key={alertItem.id} 
                                        alertItem={alertItem} 
                                        clickFunc={() => handleChange(alertItem.id)} 
                                        />
                                )
                                
                            )
                            :
                            <h3 className="text-center text-white font-weight-bolder">No new notifications</h3>
                    }
                </MDBContainer>
            </div>
        </div>   
    )
};

const MapStateToProps = ({ alerts, user: { isShowSideBar } }) => ({
    alertItems: alerts.alertItems,
    isShowSideBar
})

const MapDispatchToProps = dispatch => ({
    removeReadItem: (id) => dispatch(removeReadItem(id)),
    readNotifications: readNotifications(dispatch),
    loadPage: flag => dispatch(loadPage(flag))
})


export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(AlertsPage));