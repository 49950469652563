const LiveStreamActionTypes = {
    CLEAR_PRODUCT_LIST_ITEMS: "CLEAR_PRODUCT_LIST_ITEMS",
    SET_PRODUCT_LIST_ITEMS: "SET_LIVE_STREAM_LIST_ITEMS",
    SET_FILTER_STRING: "SET_LIVE_STREAM_FILTER_STRING",
    SET_LIMIT_PER_PAGE: "SET_LIVE_STREAM_LIMIT_PER_PAGE",
    SET_PRODUCT_LIST_PAGE_NUM: "SET_LIVE_STREAM_PAGE_NUM",
    SET_WINNER_ID: "SET_LIVE_STREAM_WINNER_ID",
    SET_HOT_SEAT_WINNER_ID: "SET_LIVE_STREAM_HOT_SEAT_WINNER_ID",
    CLEAR_WINNER_ID: "CLEAR_WINNER_ID"
};

export default LiveStreamActionTypes;
