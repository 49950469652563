import SoldOutWebinarsActionTypes from './sold-out-webinars.types';

const INITIAL_STATE = {
    product_complete: 1,
    product_items: {
        rows: [],
        count: 1,
    },
    filterStr: "",
    limitPerPage: 10,
    pageNum: 1,
    linkFilter: "",
    isHideOtherGoLives: null,
    rowsToCounter: [],
}

const SoldOutWebinarsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SoldOutWebinarsActionTypes.SET_PRODUCT_LIST_ITEMS:
            return {
                ...state, 
                product_items : action.payload, 
            }
        case SoldOutWebinarsActionTypes.SET_PRODUCT_LIST_ITEMS_COUNT:
            return {
                ...state, 
                rowsToCounter : action.payload,
            }
        case SoldOutWebinarsActionTypes.SET_FILTER_STRING:
            return {
                ...state, filterStr: action.payload
            }
        case SoldOutWebinarsActionTypes.SET_LIMIT_PER_PAGE:
            return {
                ...state, limitPerPage: action.payload
            }         
        case SoldOutWebinarsActionTypes.SET_LINK_FILTER:
            return {
                ...state, linkFilter: action.payload
            }

        case SoldOutWebinarsActionTypes.SET_PRODUCT_LIST_PAGE_NUM:
            return {
                ...state, pageNum: action.payload
            }
        case SoldOutWebinarsActionTypes.HIDE_OTHER_GO_LIVES:
            return {
                ...state, isHideOtherGoLives: action.payload
            }
            case SoldOutWebinarsActionTypes.SET_PRODUCT_LIST_COMPLETE:
                return {
                    ...state, product_complete : action.payload
                }
        default:
            return state;
    }
}

export default SoldOutWebinarsReducer;