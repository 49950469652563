import React, {useState, Fragment, useEffect, useRef} from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import CountPerPage from '../../components/table/count-per-page/count-per-page.component';
import TableFilterInput from '../../components/table/filter-input/filter-input.component';
import PageButtons from '../../components/table/pagination/pagination.component';
import { connect } from 'react-redux';
import { getProductItems, setLimitPerPage, setFilterString, setProductPageNum,getCountSoldOutWebinar,setCountSoldOutWebinars, getProductItemsCount, setLinkFilter } from '../../redux/sold-out-webinars/sold-out-webinars.action';
import { loadPage, setIsFromDuplicate } from '../../redux/user/user.action';
import './sold-webinar-page.style.scss';
import SoldWebinarListeRow from '../../components/table/sold-webinar-row/sold-webinar-row.component';
import {useAlert} from 'react-alert';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';
import { formatDateToSearch } from '../../utils/formatDateToSearch';
import LoaderComponent from '../../components/Loader/loader.component';

const SoldOutWebinarPage = ({
    filterStr, 
    limitPerPage, 
    pageNum,
    getProductItems,
    setLimitPerPage,
    isShowSideBar,
    setFilterString,
    setProductPageNum,
    setIsFromDuplicate,
    isFromDuplicate,
    getProductItemsCount,
    product_complete,
    getCountSoldOutWebinar,
    setLinkFilter,
    linkFilter
    }) => {

    const alert = useAlert();
    let sum;
    sum = product_complete || 0;
    const isMounted = useRef(false);

    // for row counts per page
    const countSelect = [10,20,30,50];
    const [countSelectShow, setCountSelectShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    
    useEffect(() => {
        return () => {
            /* setLimitPerPage(10) */
            setFilterString("")
        }
    }, [])
    

    useEffect(() => {
        if(isMounted.current) {
            async function load() {
                setLoading(true);
                await getCountSoldOutWebinar();
                await getProductItemsCount();
                const result = await getProductItems(
                    filterStr,   
                    limitPerPage === "All" ? "all" : limitPerPage, 
                    limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1)
                    );
                setLoading(false);
                setProducts(result);
            }
            load();
        } else {
            isMounted.current = true;
        }
    }, [pageNum]);

    useEffect(() => {
        let tempDate = formatDateToSearch(filterStr)
        async function load() {
            setProductPageNum(1);
            setLoading(true);
            await getCountSoldOutWebinar();
            await getProductItemsCount();
            const result = await getProductItems(
                tempDate !== "" ? tempDate : filterStr,
                limitPerPage === "All" ? "all" : limitPerPage, 
                0,
                '',
                linkFilter
                );
            setLoading(false);
            setProducts(result);
        }
        !isFromDuplicate && load();
    }, [limitPerPage, filterStr]);

    const getItemsWithLinkFilter = (link) => {
        async function load() {
            setProductPageNum(1);
            setLoading(true);
            const result = await getProductItems(
                filterStr,
                limitPerPage === "All" ? "all" : limitPerPage, 
                0,
                '',
                link
                );
            setLoading(false);
            setProducts(result);
        }
        load()
    }

    const getItemsFromRow = async () => {

        setLoading(true);
        const result = await getProductItems(
            filterStr,   
            limitPerPage === "All" ? "all" : limitPerPage, 
            limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1),
            '',
            linkFilter
        );
        setProducts(result);
        await getProductItemsCount()
        setLoading(false);
    }

    const [isMobileSize, setIsMobileSize] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setIsFromDuplicate(false);
        function handleResize() {
            if (window.innerWidth < 900)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        setIsEdit(JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'soldOutWebinarsEdit'));
    }, []);

    const [isSortASC, setIsSortASC] = useState(true);
    const sortByDateFunc = async () => {        
        setLoading(true);
        const result = await getProductItems(
            filterStr,   
            limitPerPage === "All" ? "all" : limitPerPage, 
            limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1),
            isSortASC ? 'true' : '',
            linkFilter
        );
        setProducts(result);
        setIsSortASC(!isSortASC);      
        setLoading(false);
    }

    return (
       <>
       {
        loading && <LoaderComponent />
       }
        <div className="sold-webinar-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
        <MDBRow className="headerRow">
            <MDBCol size="4" className="text-white text-left">
            <h2 className="text-white font-weight-bold">Sold Out Webinars</h2>
            </MDBCol>
            <MDBCol size="6" className="text-white text-center">
            <h3 className="text-white font-weight-bold">Total: {sum}</h3>
            </MDBCol>
        </MDBRow>
            <div className="section">
                <div className="toolbar">
                    <div className="leftDiv">
                        <div>
                            <CountPerPage 
                                options={countSelect} 
                                showSelectBox={()=>setCountSelectShow(!countSelectShow)} 
                                selectOption={(event)=>{
                                    setCountSelectShow(false);
                                    setLimitPerPage(event.target.id);
                                }} 
                                optionShow={countSelectShow} 
                                placeholder={limitPerPage}
                            />
                            <label className="ml-1">Per Page</label>
                        </div>
                        <TableFilterInput str={filterStr} setFilterString={setFilterString}/>
                        <div className="table-filter-input">

                        <select 
                        value={linkFilter}
                        name="opts" className='select-filter-opts' onChange={(e) => {
                                setLinkFilter(e.target.value);
                                getItemsWithLinkFilter(e.target.value);
                            }} >
                                <option value="all">All</option>
                                <option value="link">Only with link</option>
                                <option value="non-link">Only without link</option>
                            </select>

                            
                        </div>
                    </div>
                    <PageButtons 
                        count={ limitPerPage === "All" ? 1 : Math.ceil(products.count/limitPerPage) } 
                        currentIndex = {pageNum}
                        setProductPageNum={setProductPageNum}/>  
                </div>
                {
                    isMobileSize ? 
                        <MDBRow className="table-header">
                            <MDBCol size="4" className="text-white text-center">
                                Thumbnail
                            </MDBCol>
                            <MDBCol size="6" className="text-white text-center">
                                Name
                            </MDBCol>
                        </MDBRow>
                    :            
                    <MDBRow className="table-header">
                        <MDBCol size="1" className="text-white">
                            Thumbnail
                        </MDBCol>
                        <MDBCol size={isEdit ? '1' : '2'} className="text-white text-center">
                            UPC
                        </MDBCol>
                        <MDBCol size={isEdit ? '1' : '2'} className="text-white text-center">
                            Name
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Description
                        </MDBCol>                                              
                        <MDBCol size="1" className="text-white text-center">
                            Price
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            No. of Seats
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center pointer" onClick={ () => sortByDateFunc() }>
                            Sold Date { isSortASC ? <MDBIcon icon="caret-down"/> : <MDBIcon icon="caret-up" />}
                        </MDBCol> 
                        {isEdit && <MDBCol size='1' className="text-white text-center">
                            Remove/ Add Link
                        </MDBCol>}
                        <MDBCol size='1' className="text-white text-right">
                            Launch
                        </MDBCol>
                        <MDBCol size={isEdit ? '1' : '2'} className="text-white text-center">
                            Extract
                        </MDBCol>
                        {isEdit && <MDBCol size="1" className="text-white text-center">
                            Duplicate
                        </MDBCol> }                       
                        {isEdit && <MDBCol size="1" className="text-white text-center">
                            
                        </MDBCol> }            
                    </MDBRow>
                }
                
                {
                    products && products.rows && products.rows.length > 0 ? products.rows.map( item => <SoldWebinarListeRow
                        key = {item.id}
                        item = {item}
                        filterStr = {filterStr}
                        pageLimit = {limitPerPage}
                        offset = {limitPerPage*(pageNum-1)}
                        isMobile={isMobileSize}
                        getItemsAgain={getItemsFromRow}
                        isEdit = {isEdit}
                        />  )
                        :
                        <div className="non-row text-center">
                            No Data
                        </div>
                }
            </div>
            
        </div>
       </>
    )
}

const MapStateToProps = ({ soldOutWebinarList: { filterStr, product_items, product_complete, limitPerPage, pageNum, linkFilter }, user: {isShowSideBar, isFromDuplicate}}) => ({
    filterStr,
    product_items,
    product_complete,
    limitPerPage,
    pageNum,
    isShowSideBar,
    isFromDuplicate,
    linkFilter
})

const MapDispatchToProps = dispatch => ({
    getProductItems: getProductItems(dispatch),
    loadPage: flag => dispatch(loadPage(flag)),
    setLimitPerPage: count => dispatch(setLimitPerPage(count)),
    setFilterString: str => dispatch(setFilterString(str)),
    setLinkFilter: str => dispatch(setLinkFilter(str)),
    setProductPageNum: num => dispatch(setProductPageNum(num)),
    setIsFromDuplicate: flag => dispatch(setIsFromDuplicate(flag)),
    getProductItemsCount: getProductItemsCount(dispatch),
    getCountSoldOutWebinar: getCountSoldOutWebinar(dispatch)
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(SoldOutWebinarPage));
