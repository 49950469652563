import React from 'react';
import './ffl-edit.style.scss';
import { MDBRow, MDBCol } from 'mdbreact';
import FormButton from '../../../components/form-button/form-button.component';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadPage } from '../../../redux/user/user.action';
import withPermissionChecking from '../../../utils/HOC/withPermissionCheck';
import { updateFFL } from '../../../redux/ffl-db/ffl-db.action';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Autocomplete from "react-google-autocomplete";
import FormInput from '../../../components/form-input/form-input.component';
import Switch from 'react-switch';
import Input from 'react-phone-number-input/input';

const FflEditPage = withRouter(({
	location,
	loadPage,
	isShowSideBar,
	updateFFL
}) => {
	const alert = useAlert();
	const [isEdit, setIsEdit] = useState(false);
	const [temrmsAndConditions, setTermsAndConditions] = useState('');
	const [isValidPhone, setIsValidPhone] = useState(true);
	const [flag, setFlag] = useState(location?.state?.item.NFAFlag);
	const [transferFlag, setTransferFlag] = useState(location?.state?.item.TransferFlag);
	const [phone, setPhone] = useState(location?.state?.item.secondary_phone_number ? location?.state?.item.secondary_phone_number  : '');
	const urlHistory = useHistory();
	const [updateInfo, setUpdateInfo] = useState({
		name: "",
		address: "",
		contactPhone: "",
		notes: "",
		billingAddress: "",
		secondaryPhone: "",
		NFAFlag: flag,
		TransferFlag: transferFlag,
	})

	const getAddressFunc = (place) => {
		const newArray = place.formatted_address.split(", ");
	
		setUpdateInfo({
		  ...updateInfo,
		  billingAddress: newArray.join(" ") ,

		}); 
	  };


	
	useEffect(() => {

		async function loadCategories() {

			//loadPage(true);
			if (location?.state?.item) {
				console.log("🚀 ~ file: ffl-edit.component.jsx:62 ~ loadCategories ~ location?.state?.item:", ((location?.state?.item?.secondary_phone_number)  !== null))
				
				setUpdateInfo({
					...updateInfo,
					name: (location?.state?.item?.businessName!== null) ?   location?.state?.item?.businessName : location?.state?.item?.licenseName, 
					address: location?.state?.item.premiseStreet + ' '+ location?.state?.item.premiseStreet+' '+location?.state?.item.premiseCity + (location?.state?.item.premiseState != null ? ' '+ location?.state?.item.premiseState +", USA" : ", USA"),
					fflNumber: location.state.item.fflNumber,
					expiration:	moment(location.state.item?.expiration.substring(0,10)).format('MM/DD/YYYY') ,
					loaExpirationDate: location.state.item?.loaExpirationDate ? moment(location.state.item?.loaExpirationDate.substring(0,10)).format('MM/DD/YYYY') : null,
					contactPhone:  ' ('+location?.state?.item?.voiceTelephone.slice(0, 3) +') '+location?.state?.item?.voiceTelephone.slice(3, 6)+' -'+location?.state?.item?.voiceTelephone.slice(6),
					billingAddress: (location?.state?.item?.billing_address !== null) ?   location?.state?.item?.billing_address : '',
			 	 	secondaryPhone:  (location?.state?.item?.secondary_phone_number  !== null) ?    location?.state?.item?.secondary_phone_number : '',  
					NFAFlag: (location?.state?.item?.nfa_flag  !== null) ?   location?.state?.item?.nfa_flag : '',
					TransferFlag: (location?.state?.item?.transfer_flag  !== null) ?   location?.state?.item?.transfer_flag : '',
					notes: (location?.state?.item?.notes  !== null) ?   location?.state?.item?.notes : '', 

				});
			}
			
			//loadPage(false);
		}
		loadCategories();
	}, []);

 	const updateFflDB = async () => {
		loadPage(true);
		setUpdateInfo({ ...updateInfo, NFAFlag :flag});
		setUpdateInfo({ ...updateInfo, TransferFlag :transferFlag});

		if(isValidPhone){
			const result = await updateFFL(location.state.item.id, {
			
				billingAddress:updateInfo.billingAddress,
				secondaryPhone: isValidPhone ? updateInfo.secondaryPhone: '+1'+updateInfo.secondaryPhone,
				NFAFlag: updateInfo.NFAFlag,
				TransferFlag: updateInfo.TransferFlag,

				notes:updateInfo.notes,
				
			}); 
			if (result === "success") {
				alert.success("Updated successfully");
				urlHistory.goBack();
			}
			else
				alert.error("Failed updating"); 
		}
		else{
			alert.error('Please enter a valid phone')
		}
	 		 
		
		loadPage(false);
	}
	function handleChange(event) {

	if (event){
		setIsValidPhone(false);
	
				if (  /^\+1[2-9][0-9]*$/.test(event)) {
					setPhone(event)
					if(event.length === 12){
						setIsValidPhone(true);
		
						try {
							setUpdateInfo({ ...updateInfo, secondaryPhone: event})
						  } catch (error) {
						  console.log("🚀 ~ file: ffl-edit.component.jsx:119 ~ handleChange ~ error:", error)
							
						  }
						}
				}else{
					alert.error('Please you do not use 0 or 1')
				}				
		}
	  }
	return (
		<div className="ffl-edit" style={{ width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)' }}>
			<MDBRow>
				<MDBCol size="12" sm="12" md="12" lg="12" className="section" >
				<h2 className="text-white font-weight-bold">EDIT FFL</h2>
					<h4 className="text-white mb-4">FFL Details</h4>
					<MDBRow className="mb-4">
						<MDBCol size="6" sm="6" md="6" lg="6" className="pl-0">
							<p className="label-p">FFL Name</p>
							
							<input className="custom-input mb-4" type="text" value={updateInfo.name} />
						</MDBCol>
						<MDBCol size="6" sm="6" md="6" lg="6" className="pr-0">
							<p className="label-p">FFL NUMBER</p>
							<input className="custom-input mb-4" type="text" value= {updateInfo.fflNumber} />
						</MDBCol>
					</MDBRow>
					<MDBRow className="mb-4">
						<MDBCol size="6" sm="6" md="6" lg="6" className="pl-0">
							<p className="label-p">Address</p>
							<input className="custom-input mb-4" type="text" value={updateInfo.address} />
						</MDBCol>
						<MDBCol size="6" sm="6" md="6" lg="6" className="pr-0">
							<p className="label-p">EXPERATION DATE</p>
							<input className="custom-input mb-4" type="text" value= {updateInfo.expiration} />
						</MDBCol>
					</MDBRow>
					
					<MDBRow className="mb-4">
						<MDBCol size="6" sm="6" md="6" lg="6" className="pl-0">
							<p className="label-p">Contact Phone</p>
							<input className="custom-input mb-4" type="text" value= {updateInfo.contactPhone} />
						</MDBCol>
						{updateInfo.loaExpirationDate && <MDBCol size="6" sm="6" md="6" lg="6" className="pr-0">
							<p className="label-p">LOA EXPIRATION DATE</p>
							<input className="custom-input mb-4" type="text" value= {updateInfo.loaExpirationDate} />
						</MDBCol>}
					</MDBRow>
				</MDBCol>		
				<MDBCol size="12" sm="12" md="12" lg="12" className="section-Edit" >
				
					<div className="phone-group">
						<MDBCol size="6" sm="6" md="6" lg="6" className="pl-0">
						<p className="label-p"> Billing address</p>

							<Autocomplete
										className="custom-input mb-4" 
							
										placeholder=""
										value={updateInfo.billingAddress}
										onChange={(e) =>
											setUpdateInfo({ ...updateInfo, billingAddress: e.target.value})
										}
										onPlaceSelected={(place) => getAddressFunc(place)}
										types={["address"]}
										componentRestrictions={{ country: "us" }}
										style={{ color: 'white' }}
										required
									/>
								
						</MDBCol>
						
					</div>
								
						<MDBCol size="6" sm="6" md="6" lg="6" className="pl-0">
							<div className="label-p" style={{color:'#a3a3a3'}}>Secondary Phone</div> 
							<div className='phone-group'>
								<Input
									className={`${
									!isValidPhone ? "red-outline" : ""
									} phone-input`}
									country="US"
									value={phone}
									//onChange={handleChange()}
									maxlength="14"
									onChange={(e) =>
										handleChange(e)
									}
								/>  
							</div>
							

						</MDBCol>
						
						<div className='phone-group'>
							
						
					<MDBCol size="12" sm="12" md="12" lg="12" className="pl-0">
							<p className="label-p">Note</p>
							<textarea className="mb-4"  maxlength="250" name="note" rows="3" value={updateInfo.notes} onChange={(event) => setUpdateInfo({ ...updateInfo, notes: event.target.value })} placeholder="Type here..."></textarea>
							
							<p className="label-p" style={{textAlign:'right'}} >Max. 250 characters</p>

						</MDBCol>
						</div>
						
			
					<MDBRow size="12" sm="12" md="12" lg="12" className="pl-0">
						<MDBCol size="3" sm="3" md="3" lg="3" className="pl-0">

							<p className="label-p">NFA</p>
							<div className="switch-div mt-4">

								<Switch onColor="#57bd7a"
										onChange={() =>{ setUpdateInfo({ ...updateInfo, NFAFlag :!flag}) ;  setFlag(!flag) } }
										
										checked={updateInfo.NFAFlag }
										 />
							</div>
							</MDBCol>
							<MDBCol size="6" sm="6" md="6" lg="6" className="pl-0">

							<p className="label-p">No Transfer</p>
							<div className="switch-div mt-4">

								<Switch onColor="#57bd7a"
										onChange={() =>{ setUpdateInfo({ ...updateInfo, TransferFlag :!transferFlag}) ;  setTransferFlag(!transferFlag) } }
										
										checked={!updateInfo.TransferFlag}
										 />
							</div>
							</MDBCol>

						</MDBRow>
				</MDBCol>
			</MDBRow>
			<MDBRow center className="mt-4">
				<MDBCol size="6" sm="6" md="5" lg="4">
					<FormButton onClickFunc={() => {updateFflDB()}}>UPDATE</FormButton>
				</MDBCol>
				<MDBCol size="6" sm="6" md="5" lg="4">
					<FormButton greyCol={true} onClickFunc={()=> urlHistory.goBack()}>CANCEL</FormButton>
				</MDBCol>
			</MDBRow>
		</div>
	)
});
const MapStateToProps = ({ user: { isShowSideBar } }) => ({
	isShowSideBar
})
const MapDispatchToProps = dispatch => ({
	loadPage: flag => dispatch(loadPage(flag)),
	updateFFL: updateFFL(dispatch)
})
export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(FflEditPage));
