
const ZoomMeetingsActionTypes = {
    SET_ZOOM_MEETINGS_LIST_ITEMS: "SET_ZOOM_MEETINGS_LIST_ITEMS",
    SET_FILTER_STRING: "SET_ZOOM_MEETINGS_FILTER_STRING",
    SET_LIMIT_PER_PAGE: "SET_ZOOM_MEETINGS_LIMIT_PER_PAGE",
    SET_PRODUCT_LIST_PAGE_NUM: "SET_ZOOM_MEETINGS_PAGE_NUM",
    SET_ZOOM_MEETINGS__COUNT: "SET_ZOOM_MEETINGS_COUNT",
    SET_TYPE_FILTER: "SET_ZOOM_MEETINGS_TYPE_FILTER",
    SET_ADD_NEW_CLICKED: "SET_ADD_NEW_FFL_CLICKED",
 };
 
 export default ZoomMeetingsActionTypes;