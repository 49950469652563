import React, {useState, useEffect} from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import CountPerPage from '../../components/table/count-per-page/count-per-page.component';
import PageButtons from '../../components/table/pagination/pagination.component';
import { connect } from 'react-redux';
import FormButton from '../../components/form-button/form-button.component';
import FormSelect from '../../components/form-select/form-select.component';
import ZoomListRow from '../../components/table/zoom-list-row/zoom-list-row.component';
import AddNewMeetingZoom from '../zoom-meetings/add-new-meeting/add-new-meeting-component';
import './zoom-meetings-page.style.scss';
import { loadPage} from '../../redux/user/user.action';
import {useAlert} from 'react-alert';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';
import { updateZoom, getZoomMeetings, deleteZoomMeeting,  setLimitPerPage, 
    setCurrentTypeFilter,
    setProductPageNum } from '../../redux/zoom-meetings/zoom-meetings.action';


const ZoomMeetingsPage = ({
    meetings,
    limitPerPage, 
    pageNum,
    totalCount,
    loadPage,
    setLimitPerPage,
    isShowSideBar,
    currentTypeFilter,
    setCurrentTypeFilter,
    setProductPageNum,
    getZoomMeetings
    }) => {

    const alert = useAlert();

    // for select box of list types
    const typeFilter = ["Previous", "Upcoming"];
    const [typeFilterShow, setTypeFilterShow] = useState(false);

    const [isChecked, setIsChecked] = useState(false);

    // for row counts per page
    const countSelect = [10,20,30,50];
    const [countSelectShow, setCountSelectShow] = useState(false);
    
    useEffect(() => {
        async function load() {
            loadPage(true);
            await getZoomMeetings('Support@matastacticalsupply.com',  currentTypeFilter.toLowerCase(), limitPerPage, pageNum);
            loadPage(false);
        }

        load();
    }, [ currentTypeFilter, limitPerPage, pageNum]);
    // "upcoming",
    // currentTypeFilter === "Previous" ? "scheduled" : "upcoming",

    const [isMobileSize, setIsMobileSize] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        console.log('Zoom Meetings v1!');
        function handleResize() {
            if (window.innerWidth < 900)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        // setIsEdit(JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'zoomMeetings' || item === 'webinarQueueEdit'));
    }, []);

    const handleOnChange = () => {
        setIsChecked(!isChecked);
    };
    

    
    return (
        <div className="webinar-queues-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
           {isChecked === true? <AddNewMeetingZoom onClose={handleOnChange} />:
           <>
        <MDBRow className="headerRow">
            <MDBCol size="12" sm="12" md="9" lg="6">
                <h2 className="text-white font-weight-bold">Zoom Webinars</h2>
            </MDBCol>
            <MDBCol size="12" sm="12" md="9" lg="2">
                <h2 className="text-white font-weight-bold">Total: {totalCount}</h2>
            </MDBCol>       
        </MDBRow>
        <div className="section">
            <MDBRow>
                <MDBCol middle size="12" sm="12" md="12" lg="5" className="filterIndex mt-2">
                        <label className="mobile-label">List type:</label>                        
                        <FormSelect options={typeFilter} showSelectBox={()=>setTypeFilterShow(!typeFilterShow)} selectOption={(event)=>{
                            setTypeFilterShow(false);
                            setCurrentTypeFilter(event.target.id);
                        }} optionShow={typeFilterShow} placeholder={currentTypeFilter}/>
            
                </MDBCol>        
            </MDBRow>
                <div className="toolbar">
                    <div className="leftDiv">
                        <div>
                            <CountPerPage 
                                options={countSelect} 
                                showSelectBox={()=>setCountSelectShow(!countSelectShow)} 
                                selectOption={(event)=>{
                                    setCountSelectShow(false);
                                    setLimitPerPage(event.target.id);
                                }} 
                                optionShow={countSelectShow} 
                                placeholder={limitPerPage}
                            />
                            <label className="ml-1">Per Page</label>
                        </div>
                    </div>
                    <PageButtons 
                        count={ currentTypeFilter === "Upcoming" ? 1 : Math.ceil(totalCount/limitPerPage)} 
                        currentIndex = {pageNum}
                        setProductPageNum={setProductPageNum}
                    />  
                </div>
               
                
                {
                    isMobileSize ? 
                        <MDBRow className="table-header">
                            <MDBCol size="3" className="text-white text-center">
                                Topic
                            </MDBCol>
                            <MDBCol size='5' className="text-white text-right">
                                Start Time
                            </MDBCol>
                        </MDBRow>
                    :
                    currentTypeFilter === "Upcoming" ?                     
                        <MDBRow className="table-header">
                            <MDBCol size="2" className="text-white">
                            Topic
                            </MDBCol>
                            <MDBCol size='2' className="text-white text-left">
                                Description
                            </MDBCol>
                            <MDBCol size='2' className="text-white text-left">
                                Start Time
                            </MDBCol>
                            <MDBCol size='2' className="text-white text-left">
                                Created Time
                            </MDBCol>
                            <MDBCol size='1' className="text-white text-left">
                                Duration
                            </MDBCol>
                            <MDBCol size='1' className="text-white text-right">
                                Launch
                            </MDBCol>
                            <MDBCol size="1" className="text-white text-right">
                                Edit
                            </MDBCol>
                            <MDBCol size="1" className="text-white text-right">
                                Delete
                            </MDBCol>
                            
                        </MDBRow>
                        :
                        <MDBRow className="table-header">                    
                            <MDBCol size="2" className="text-white">
                            Topic
                            </MDBCol>
                            <MDBCol size="3" className="text-white text-center">
                            Description
                            </MDBCol>
                            <MDBCol size="2" className="text-white text-center">
                                Date Created
                            </MDBCol> 
                            <MDBCol size="1" className="text-white text-center">
                                Duration
                            </MDBCol>
                            <MDBCol size="2" className="text-white text-center">
                                Video
                            </MDBCol>    
                            <MDBCol size="1" className="text-white text-center">
                                Edit
                            </MDBCol>
                            <MDBCol size="1" className="text-white text-center">
                                Delete
                            </MDBCol>        
                        </MDBRow>
                }
        
                
                {
                     meetings && meetings.length > 0 ? meetings.map( item => <ZoomListRow
                        key = {item.id}
                        item = {item}
                        type = {currentTypeFilter}
                        // pageLimit = {limitPerPage === "All" ? "all" : limitPerPage}
                        // offset = {limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1)}
                        isMobile={isMobileSize}
                        isEdit={isEdit}
                        />  )
                        :
                        <div className="non-row text-center">
                            No Data
                        </div>
                }


        
            </div>
           </>}
        
            
    
            
        </div>
        
    )
}

const MapStateToProps = ({ meetings: { meetings,  limitPerPage, pageNum, currentTypeFilter, totalCount }, user: {isShowSideBar}}) => ({
    limitPerPage,
    pageNum,
    isShowSideBar,
    currentTypeFilter,
    totalCount,
    meetings
})

const MapDispatchToProps = dispatch => ({
    loadPage: flag => dispatch(loadPage(flag)),
    setLimitPerPage: count => dispatch(setLimitPerPage(count)),
    setCurrentTypeFilter: str => dispatch(setCurrentTypeFilter(str)),
    setProductPageNum: num => dispatch(setProductPageNum(num)),
    getZoomMeetings: getZoomMeetings(dispatch)
})

export default connect(MapStateToProps, MapDispatchToProps)(ZoomMeetingsPage);