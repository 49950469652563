import OfflineSalesActionTypes from './offline-sales.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI } from '../api-config';

export const getProductItems = dispatch => async ( filterStr = null, limit = 10, offset = 0,byDate='',onlyFFl='') => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/offlinesale/soldout?filterString=${filterStr}&&limit=${limit}&offset=${offset}&byDate=${byDate}&onlyFFl=${onlyFFl}`, { 
            headers: (await getMyTokenFunc())

        
        });
        dispatch(setProductItems(result.data));
        return result.data;
 
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}
export const getCountProductsItems = dispatch => async () => {

    try {
        const result1 = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/offlinesaleCount/soldout`, { 
            headers: (await getMyTokenFunc())
        });
        dispatch(setProductItemsCount( parseInt(result1.data.data[0].count)) );
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const addFFLItem = dispatch => async ( obj ) => {
    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/products/offlinesale/soldout/ffl`, obj, { 
            headers: (await getMyTokenFunc())
        });
        console.log(result)
        return result.data.message;
    } catch (error) {
        return (error?.response?.data?.message);    
    }
}

export const setFilterString = filterStr => ({
    type: OfflineSalesActionTypes.SET_FILTER_STRING,
    payload: filterStr
})

export const setLimitPerPage = limit => ({
    type: OfflineSalesActionTypes.SET_LIMIT_PER_PAGE,
    payload: limit
})

export const setProductPageNum = num => ({
    type: OfflineSalesActionTypes.SET_PRODUCT_LIST_PAGE_NUM,
    payload: num
})

const setProductItems = items => ({
    type: OfflineSalesActionTypes.SET_PRODUCT_LIST_ITEMS,
    payload: items
})
const setProductItemsCount = items => ({
    type: OfflineSalesActionTypes.SET_PRODUCT_LIST_ITEMS_COUNT,
    payload: items
})

export const setFFLFilter = filter => ({
    type: OfflineSalesActionTypes.SET_FFL_FILTER,
    payload: filter
})