import React from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import FormButton from '../form-button/form-button.component';
function Popup(props){
    return(props.trigger) ? (
        <div className="popup">
            <div className="popup-inner">
                <div className="removeDialogWrapper">
					<div className="removeDialog">
                    <button className='close-btn'  style={{ float: 'right' }} onClick={() =>props.setTrigger()} >close</button>

                { props.children }
					<div>
					    <h2 className="text-white text-center ">
						    I am a future popup for FFL
						</h2>
									
						<MDBRow center className="mb-4 mobile-row"> 
							<MDBCol size="6" md="4">
								<FormButton greyCol={true}> Save FFL</FormButton>
							</MDBCol>
						</MDBRow>
											
					</div>
					<br></br>
				</div>
			</div>

            
            </div>
        </div>
    ) : ""
}

export default Popup