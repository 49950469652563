import React, {useState, useEffect, Fragment} from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { connect } from 'react-redux';
import { setWinnerId } from '../../../../redux/live-stream/live-stream.action';

import './live-stream-row.style.scss';

const LiveStreamRow = ({ item, winnerID, setWinnerId }) => {

    return (

        <MDBRow className="table-row">
            <MDBCol size="1" className="text-white">
                {
                    item && <div className={`customRadioBtn ${winnerID.seatID === item.id ? "active" : ""}`} onClick={() => setWinnerId({
                        seatID: item.id,
                        userID: item.user_id,
                        userName: item.user.username,
                        seatNum: item.seatNo
                    })}>
                        {
                            winnerID.seatID === item.id && <MDBIcon icon="circle" className="customRadioIcon"/>
                        }
                    </div>
                }                       
            </MDBCol>
            <MDBCol size="4" className="text-white text-center ft19">
                {item && item.seatNo + 1}
            </MDBCol>
            <MDBCol size="7" className="text-white text-center ft19">
                {item && item.user && item.user.first_name + " " + item.user.last_name}
            </MDBCol>                    
        </MDBRow>    
    )
}

const MapStateToProps = ({liveStrem: {winnerID}}) => ({
    winnerID
})

const MapDispatchToProps = dispatch => ({
    setWinnerId: id => dispatch(setWinnerId(id))
})
export default connect(MapStateToProps, MapDispatchToProps)(LiveStreamRow);