import React, {useState, useEffect, useRef} from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import FormSelect from '../../../components/form-select/form-select.component';
import { connect } from 'react-redux';
import { getCurrentCategoryGraph } from '../../../redux/dashboard/dashboard.action';
import { getCurrentCategories } from '../../../redux/category/category.action';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import SpecificPeriodComponent from '../specific-period/specific-period.component';

const CategoryGraph = ({ getCurrentCategoryGraph, getCurrentCategories }) => {

    const [category_data, setCategoryData] = useState([]);
    const [totalValue, setTotalValue] = useState();

    //for selectbox of category period
    const categoryPeriod = ["Day", "Week", "Month", "Year", "Specific Period"];
    const [categoryPeriodsShow, setCategoryPeriodsShow] = useState(false);
    const [selectedCategoryPeriod, setSelectedCategoryPeriod] = useState("Day");

    //for selectbox of category period
    const categoryListWithID = useRef(null);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryListShow, setCategoryListShow] = useState(false);
    const [selectedCategoryList, setSelectedCategoryList] = useState("Category");

    const tooltipstyle = {
        backgroundColor: 'lightgrey',
        border: '2px solid white'
    }

    useEffect(() => {
        async function loadCategories() {
            const categories = await getCurrentCategories(true);
            categoryListWithID.current = categories;
     
            if (categories) {
                const tempCategories = [];
                categories.map(category=>{
                    tempCategories.push(category.category_name);
                })
                setCategoryList([...tempCategories]);
                setSelectedCategoryList(tempCategories[0]);
            }   
        }
        loadCategories();
    }, []);

    const drawRevenueGraph = async () => {
        let startTime = new Date();
        let endTime = new Date();
        let periodUnit = "hour";
        let catID = getCurrentCategoryId();

        switch (selectedCategoryPeriod) {
            case "Day":
                periodUnit = "hour";
                break;

            case "Week":
                let day = startTime.getDay();
                let diff = startTime.getDate() - day + (day === 0 ? -6 : 1 );
                startTime.setDate(diff);
                periodUnit = "day";
                break;

            case "Month":
                startTime = new Date(startTime.getFullYear(), startTime.getMonth(), 1);
                // endTime.setDate(endTime.getDate()-1);
                periodUnit = "day";
                break;

            case "Year":
                startTime = new Date(startTime.getFullYear(), 0, 1);
                periodUnit = "month";
                break;
        
            default:
                break;
        }

        startTime.setHours(0);
        startTime.setMinutes(0);

        const result = await getCurrentCategoryGraph(startTime.toISOString(), endTime.toISOString(), periodUnit, catID);
        if (result) {
            let tempArray = [];
            let tempTotalVal = 0;
            result.map( data => {
                const tempDate = new Date(data.date.toString());
                tempTotalVal += data.amount;
                switch (selectedCategoryPeriod) {
                    case "Day":
                        tempArray.push({name: tempDate.getHours(), value: parseInt(data.amount)});
                        break;
                    case "Week":
                        const weekArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                        tempArray.push({name: weekArray[tempDate.getDay()-1], value: parseInt(data.amount)});
                        break;
                    case "Month":
                        tempArray.push({name: tempDate.getDate(), value: parseInt(data.amount)});
                        break;
                    case "Year":
                        tempArray.push({name: tempDate.getMonth()+1, value: parseInt(data.amount)});
                    default:
                        break;
                }                
            });
            setCategoryData([...tempArray]);
            tempTotalVal = tempTotalVal.toString();
            if (tempTotalVal.indexOf(".") > -1)
                tempTotalVal = tempTotalVal.slice(0, (tempTotalVal.indexOf("."))+3);
            setTotalValue(Number(tempTotalVal));
        }
    }
    const [isShowCustDate, setIsShowCustDate] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        if (selectedCategoryPeriod !== 'Selected period' && selectedCategoryList !== "Category") {
            async function loadMember() {
                await drawRevenueGraph();
            }
            selectedCategoryPeriod === 'Specific Period' ? setIsShowCustDate(true) : loadMember()
        }
    }, [selectedCategoryPeriod, selectedCategoryList]);

    const getCurrentCategoryId = () => {       
        const result = categoryListWithID.current.filter(category => category.category_name === selectedCategoryList);
        if (result.length > 0)
            return result[0].id;
    }

    const saveSpecificPeriod = async () => {
        setIsShowCustDate(false);
        setSelectedCategoryPeriod('Selected period');
        const periodUnit = "day";
        startDate.setHours(0);
        startDate.setMinutes(0);

        const result = await getCurrentCategoryGraph(startDate.toISOString(), endDate.toISOString(), periodUnit, getCurrentCategoryId());

        if (result) {
            let tempArray = [];
            let tempTotalVal = 0;
            result.map( data => {
                const tempDate = new Date(data.date.toString());
                tempTotalVal += data.amount;            
                tempArray.push({name: tempDate.getDate(), value: parseInt(data.amount)});                         
            });
            setCategoryData([...tempArray]);
            tempTotalVal = tempTotalVal.toString();
            if (tempTotalVal.indexOf(".") > -1)
                tempTotalVal = tempTotalVal.slice(0, (tempTotalVal.indexOf("."))+3);
            setTotalValue(Number(tempTotalVal));
        }
    }

    return (
        
            <div className="graph-wrapper">
                { 
                    isShowCustDate && <SpecificPeriodComponent
                        startDate={startDate} 
                        setStartDate={setStartDate} 
                        endDate={endDate} 
                        setEndDate={setEndDate} 
                        saveSpecificPeriod={saveSpecificPeriod} 
                        setIsShowCustDate={setIsShowCustDate}
                        setSelectedRevenuePeriod={setSelectedCategoryPeriod}
                    />
                }
                <MDBRow className="graph-header">
                    <MDBCol size="12" sm="12" md="12" lg="6">
                        <p className="text-white mb-2">Category Sales</p>
                    </MDBCol>
                    <MDBCol size="12" sm="12" md="12" lg="6">
                        <MDBRow end>
                            <MDBCol size="12" sm="12" md="6" lg="6" className="mb-2">
                            <FormSelect options={categoryList} showSelectBox={()=>setCategoryListShow(!categoryListShow)} selectOption={(event)=>{
                                setCategoryListShow(false);
                                setSelectedCategoryList(event.target.id);
                            }} optionShow={categoryListShow} placeholder={selectedCategoryList}/>
                            </MDBCol>
                            <MDBCol size="12" sm="12" md="6" lg="6" className="mb-2">
                            <FormSelect options={categoryPeriod} showSelectBox={()=>setCategoryPeriodsShow(!categoryPeriodsShow)} selectOption={(event)=>{
                                setCategoryPeriodsShow(false);
                                setSelectedCategoryPeriod(event.target.id);
                            }} optionShow={categoryPeriodsShow} placeholder={selectedCategoryPeriod}/>
                            </MDBCol>
                        </MDBRow>    
                    </MDBCol>                     
                </MDBRow>
                
                <div style={{ width: '100%', height: 260 }}>
                    <ResponsiveContainer>
                    <BarChart
                        data={category_data}
                        margin={{top: 10, right: 20, left: -10, bottom: 0}}>
                    <CartesianGrid stroke="grey" strokeDasharray="7 5" vertical={false}/>
                    <XAxis dataKey="name" stroke="white" tick={{fontSize: 11}} axisLine={{stroke: "grey"}} tickLine={false} tickMargin={5}/>
                    <YAxis stroke="white" tickLine={false} axisLine={false} tick={{fontSize: 11}} tickMargin={5}/>
                    <Tooltip contentStyle={tooltipstyle}/>
                    <Bar barSize={10} radius={10} dataKey="value" fill="#e41c39" />
                    {/* <Bar barSize={10} radius={10} dataKey="physical" fill="grey" />
                    <Bar barSize={10} radius={10} dataKey="webinar" fill="white" /> */}
                    </BarChart>
                    </ResponsiveContainer>
                </div>
                <p className="text-center text-white totalVal">Total Category: ${totalValue}</p>
            </div>

    )
}

const MapDispatchToProps = dispatch => ({
    getCurrentCategories: getCurrentCategories(dispatch),
    getCurrentCategoryGraph: getCurrentCategoryGraph(dispatch)
})

export default connect(null, MapDispatchToProps)(CategoryGraph);