import React, {useState, useEffect, Fragment} from 'react';
import './product-list-row.style.scss';
import FormCheckbox from '../../form-checkbox/form-checkbox.component';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import DatePicker from "react-datepicker"; 
import { useHistory } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { loadPage } from '../../../redux/user/user.action';
import { updateProduct, getProductItems,deleteProduct } from '../../../redux/product-list/product-list.action';
import { getSiteSettings } from '../../../redux/colorChnage/colorChange.action';
import moment from 'moment-timezone';
import { useAlert } from 'react-alert';
import { Storage } from 'aws-amplify';
import router from '../../../router';
import FormButton from '../../form-button/form-button.component';
import LongItemWithTooltip from '../../tooltip/long-item-with-tooltip';


const ProductListeRow = ({isEdit, loadPage, isMobile, updateProduct, getProductItems, item, time_zone, typeFilter, statusFilter, getSiteSettings,deleteProduct}, ) => {
    
    const [ showMore, setShowMore ] = useState(null)
    const numberOfCharacters = showMore ? 250 : 30;
    const alert = useAlert();
    const historyUrl = useHistory();
    const [startDate, setStartDate] = useState();
    const [isRemoveDialog, setIsRemoveDialog] = useState(false);
    
    useEffect(() => {
        async function load() {
            if (statusFilter === "Active" && time_zone) {
                const calculatedDate = new Date(moment(item.scheduled_time).tz(time_zone).format('MM/DD/YY HH:mm'));
                setStartDate(calculatedDate);
            }
            else {
                const date = new Date(item.scheduled_time.toString());
                setStartDate(date);
            }            
        }
        item && item.scheduled_time && load()
    }, []);
    
    const datePickerLink = React.createRef();

    // useEffect(() => {
    //     setIsChecked(isAllChecked);
    // }, [isAllChecked]);

    // useEffect(() => {
    //     if (isChecked)
    //         addToDelList(item.id);
    //     else
    //         removeFromDelList(item.id);
    // }, [isChecked]);
    const [imageUrl, setImageUrl] = useState(null);
    useEffect(() => {
        async function load() {
            
            try {
                checkImage( await Storage.get("thumbnail-"+(item.main_image.image_url)),  async function(){ setImageUrl( await Storage.get("thumbnail-"+(item.main_image.image_url))) }, async function(){ 
                    item.thumbnail_300 && item.thumbnail_300?.image_url
                 ?
                  setImageUrl(await Storage.get(item.thumbnail_300.image_url))
                 :
                    
                    setImageUrl( await Storage.get(item.main_image.image_url));} )
                
            } catch (error) {
                console.log("🚀 ~ file: product-list-row.component.jsx:68 ~ load ~ error", error)
                
            }
          
        }
        load();        
    }, [item.thumbnail_300]);

    async function checkImage(imageSrc, good, bad) {
        var img = new Image();
        img.onload = good; 
        img.onerror = bad;
        img.src = imageSrc;
    }
    async function deleteProductFunction() {
      
        const obj = {
            product_id: item.id,
            product_type: item.product_type.toLowerCase(),
        }
        const result = await deleteProduct(obj);
        
        if(result.data.message=='Deleted successfully '){
            alert.success(result.data.message)
            loadPage(true);
            setTimeout(function(){
                window.location.reload(true);
                loadPage(false);
            }, 3000);
           
        }
        else{
            alert.error(result.data.message)
        }
        setIsRemoveDialog(false)
    }

    // const activeProductFunc = async () => {
    //     loadPage(true);
    //     if (item.product_status === "active") {
    //         const obj = {
    //             product_id: item.id,
    //             product_type: item.product_type.toLowerCase(),
    //             product_status: "inactive"
    //         }
    //         const result = await updateProduct(obj);
    //         if (result === "success"){
    //             alert.success("Updated successfully");
    //             await getProductItems("", "active", "both", 10, 0);
    //         }
    //         else if ( result.message )
    //             alert.error(result.message);
    //         else
    //             alert.error("Updating failed");
    //     }
    //     else {
    //         const obj = {
    //             product_id: item.id,
    //             product_type: item.product_type.toLowerCase(),
    //             product_status: "active",
    //             publish_method: "instant"
    //         }
    //         const result = await updateProduct(obj);
    //         if (result === "success") {
    //             alert.success("Updated successfully");
    //             await getProductItems(
    //                 "", 
    //                 statusFilter.toLowerCase(), 
    //                 typeFilter === "All" ? "both" : typeFilter.toLowerCase(), 
    //                 10, 
    //                 0);
    //         }               
    //         else
    //             alert.error("Updating failed");
    //     }
    //     loadPage(false);
    // }

    const updateSchedulTime = async () => {
        loadPage(true);
        const obj = {
            product_id: item.id,
            product_type: item.product_type.toLowerCase(),
            scheduled_time: startDate.toISOString()
        }
        const result = await updateProduct(obj);
        if (result === "success") {
            alert.success("Updated successfully");
            await getProductItems(
                "", 
                statusFilter.toLowerCase(), 
                typeFilter === "All" ? "both" : typeFilter.toLowerCase(), 
                10, 
                0);
        }
        else if (result.message) {
            alert.error(result.message);
        }              
        else
            alert.error("Updating failed");
        
        loadPage(false);
    }

    const goToSeatRefundPage = () => {
        if (!isEdit.seatRefund)
            return;
        if ((statusFilter.toLowerCase() === "active") && (item.product_type.toLowerCase() === "webinar"))
            historyUrl.push(router.seatRefund.path, {id: item.id});
    }

    const [isOpenTable, setIsOpenTable] = useState(false);

    return (
        <Fragment>
             {
                isRemoveDialog &&
                <div className="removeDialogWrapper">
                    <div className="removeDialog">
                        <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={() => setIsRemoveDialog(false)} />
                        <br />
                        <h2 className="text-white text-center font-weight-bold mb-4">Are you sure you want to delete this item?</h2>
                        <MDBRow center className="mb-4 mobile-row">
                            <MDBCol >
                                <FormButton onClickFunc={() => deleteProductFunction()}>Yes</FormButton>
                            </MDBCol>
                            <MDBCol >
                                <FormButton  onClickFunc={() => setIsRemoveDialog(false)}>No</FormButton>
                            </MDBCol>
                        </MDBRow>

                        
                    </div>
                </div>
            }

            {
                isMobile ? <Fragment>
                <MDBRow className="table-row">
                    <MDBCol size="3" middle className="text-center thumb">
                        {
                            imageUrl ? <img src={imageUrl} alt={item.product_name}/>
                            :
                            <div className="non-img">
                                <MDBIcon far icon="file-image" />
                            </div>
                        }
                    </MDBCol>
                    <MDBCol size="7" middle className="text-center" onClick={()=>goToSeatRefundPage()}>
                        <LongItemWithTooltip text={item.product_name && item.product_name} />
                    </MDBCol>
                    <MDBCol size="2" middle className="text-center">
                        <button className="openBtn" onClick={()=>setIsOpenTable(true)}><MDBIcon icon="plus"/></button>
                    </MDBCol>  
                </MDBRow>
                {
                    isOpenTable &&  <Fragment>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="3" middle className="text-center text-white">Thumbnail</MDBCol> 
                            <MDBCol size="7" middle className="text-center">
                            {
                                imageUrl ? <img src={imageUrl} alt={item.product_name}/>
                                :
                                <div className="non-img">
                                    <MDBIcon far icon="file-image" />
                                </div>
                            }
                            </MDBCol>
                            <MDBCol size="2" middle className="text-center">
                                <button className="openBtn" onClick={()=>setIsOpenTable(false)}><MDBIcon icon="minus"/></button>
                            </MDBCol>                    
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">               
                            <MDBCol size="3" middle className="text-center text-white">UPC</MDBCol> 
                            <MDBCol size="9" middle className="text-center" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                                {item?.upc_code ? item.upc_code : 'unassigned'}
                            </MDBCol>               
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">               
                            <MDBCol size="3" middle className="text-center text-white">Name</MDBCol> 
                            <MDBCol size="9" middle className="text-center" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                                <LongItemWithTooltip text={item.product_name && item.product_name} />
                            </MDBCol>               
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="3" middle className="text-center text-white">Description</MDBCol> 
                            <MDBCol size="9" middle className="text-center" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                                <LongItemWithTooltip text={item.shortDescription} />
                            </MDBCol>               
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="3" middle className="text-center text-white">Type</MDBCol> 
                            <MDBCol size="9" middle className="text-center" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                                {item.product_type && item.product_type}
                            </MDBCol>               
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="3" middle className="text-center text-white">Price</MDBCol> 
                            <MDBCol size="9" middle className="text-center" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                                {item.product_price && item.product_price}
                            </MDBCol>               
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="3" middle className="text-center text-white">Quantity</MDBCol> 
                            <MDBCol size="9" middle className="text-center" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                                {item.Quantity && item.Quantity}
                            </MDBCol>               
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="3" middle className="text-center text-white">Live Date</MDBCol> 
                            <MDBCol size="9" middle className="text-center">
                            {
                                item.product_status === "hold" ?
                                <span className = {item?.hot_seat_number != null ? 'orange-text' : ''}>Hold</span>
                                :
                                item.scheduled_time ? 
                                <Fragment>
                                    <DatePicker
                                        ref={datePickerLink} 
                                        minDate={new Date()} 
                                        selected={startDate} 
                                        onChange={date => setStartDate(date)}
                                        onCalendarClose={updateSchedulTime}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        timeCaption="time"
                                        dateFormat="MM/dd/yy HH:mm"
                                        disabled={item.product_status === "active" && true}
                                        className = {item?.hot_seat_number != null ? 'orange-text' : ''}
                                    />
                                    {
                                    item.product_status !== "active" && <MDBIcon icon="calendar-alt" className="calIcon" onClick={()=>datePickerLink.current.setOpen(true)}/>
                                    }
                                </Fragment>
                                :
                                null 
                            }  
                            </MDBCol>               
                        </MDBRow>
                        {isEdit?.edit && <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="3" middle className="text-center text-white">Edit / Delete</MDBCol> 
                            <MDBCol size="9" middle className="text-center" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                                
                                
                                <MDBIcon  far icon="pen" size="0.5" className="fas fa-lg"  style={{color: '#94d5ff'}} far icon="pen" onClick={()=>historyUrl.push(router.editProduct.path, {prodType: item.product_type.toLowerCase(), id: item.id})}/>
                                    {
                                        item.product_status === "hold" &&
                                        <>
                                            &nbsp;&nbsp; / &nbsp;&nbsp;
                                            <MDBIcon far icon="trash-alt"  size="0.5" className="fas fa-lg"  style={{color: '#ed4a4a'}}  onClick={()=>{ setIsRemoveDialog(true)}}/>
                                        </>
                                    }
                            </MDBCol>              
                        </MDBRow>}
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="3" middle className="text-center text-white">Active/ Inactive</MDBCol> 
                            <MDBCol size="9" middle className="text-center" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                                {item.product_status && item.product_status[0].toUpperCase() + item.product_status.slice(1)}
                            </MDBCol>               
                        </MDBRow>
                        {isEdit?.edit && <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="3" middle className="text-center text-white">Duplicate</MDBCol> 
                            <MDBCol size="9" middle className="text-center">
                                <button onClick={() => historyUrl.push(router.editProduct.path, {
                                    prodType: item.product_type.toLowerCase(), 
                                    id: item.id,
                                    duplicate: true
                                })}
                                style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                                    Duplicate
                                </button>
                            </MDBCol>               
                        </MDBRow>}
                    </Fragment>
                }
                </Fragment>
                : 
                <MDBRow className="table-row">
                    {/* <MDBCol size="1">
                        <FormCheckbox Notif={isChecked} handleChange = { () => setIsChecked(!isChecked) } />
                    </MDBCol> */}
                    <MDBCol size="1" className="thumb">
                        {
                            imageUrl ? <img src={imageUrl} alt={item.product_name}/>
                            :
                            <div className="non-img">
                                <MDBIcon far icon="file-image" />
                            </div>
                        }
                    </MDBCol>
                    <MDBCol size="1" className="text-center email-col" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}  onClick={()=>goToSeatRefundPage()}>
                        {item?.upc_code ? item.upc_code : 'unassigned'}
                    </MDBCol>
                    <MDBCol size="1" className="text-center"  style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}} onClick={()=>goToSeatRefundPage()}>
                        <LongItemWithTooltip text={item.product_name && item.product_name} />
                    </MDBCol>
                    <MDBCol size="2" className="text-center" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                        <LongItemWithTooltip text={item.shortDescription} />
                    </MDBCol>
                    <MDBCol size="1" className="text-center" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                        {item.product_type && item.product_type}
                    </MDBCol>
                    <MDBCol size="1" className="text-center" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                        {item.product_price && item.product_price}
                    </MDBCol>
                    <MDBCol size="1" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}} className="text-center">
                        {item.Quantity && item.Quantity}
                    </MDBCol>
                    <MDBCol size={isEdit?.edit ? '1' : '3'} className="text-center dateWrapper">
                        {
                            item.product_status === "hold" ?
                            <span style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>Hold</span>
                            :
                            item.scheduled_time ? 
                            <Fragment>
                                {
                                    item.product_status !== "active" ?
                                    (
                                    <DatePicker
                                        ref={datePickerLink} 
                                        minDate={new Date()} 
                                        selected={startDate} 
                                        onChange={date => setStartDate(date)}
                                        onCalendarClose={updateSchedulTime}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={1}
                                        timeCaption="time"
                                        dateFormat="MM/dd/yyyy HH:mm"
                                        disabled={item.product_status === "active" && true}
                                        className = {item?.hot_seat_number != null ? 'orange-text' : ''}
                                    />
                                    )
                                    :
                                    (
                                    <span className = {item?.hot_seat_number != null ? 'orange-text' : ''}>
                                        {moment(item.scheduled_time).utc().format('MM/DD/YYYY HH:mm')}
                                    </span> 
                                    )
                                }
                                {
                                item.product_status !== "active" && <MDBIcon  icon="calendar-alt" className="calIcon" onClick={()=>datePickerLink.current.setOpen(true)}/>
                                }
                            </Fragment>
                            :
                            null 
                        }           
                                                    
                    </MDBCol>
                    
                    {isEdit?.edit && 
                    <MDBCol size="1" className="text-center fas" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}} >
                            <MDBIcon size="0.5" className="text-center fas fa-lg"  style={{color: '#94d5ff'}}  far icon="pen" onClick={()=>historyUrl.push(router.editProduct.path, {prodType: item.product_type.toLowerCase(), id: item.id})}/>
                            {
                                item.product_status === "hold" &&
                                <>
                                    &nbsp;&nbsp; / &nbsp;&nbsp;
                                    <MDBIcon  size="0.5" className="text-center fas fa-lg"  style={{color: '#ed4a4a'}}  far icon="trash-alt" onClick={()=>{ setIsRemoveDialog(true)}}/>
                                </>
                            }
                            </MDBCol>
                    }
                    <MDBCol size="1" className="text-center" style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                        {/* <button className="activeBtn" onClick={()=>activeProductFunc()}> */}
                            {
                                item.product_status && item.product_status[0].toUpperCase() + item.product_status.slice(1)
                            }
                        {/* </button> */}
                    </MDBCol>
                    {isEdit?.edit && <MDBCol size="1" className="text-center">
                        <button onClick={() => historyUrl.push(router.editProduct.path, {
                            prodType: item.product_type.toLowerCase(), 
                            id: item.id,
                            duplicate: true
                        })}
                        style={item?.hot_seat_number != null ? { color:'#FBA524'}: {}}>
                            Duplicate
                        </button>
                    </MDBCol>}
                </MDBRow>
            }
        </Fragment>
    )
}

const MapDispatchToProps = dispatch => ({
    loadPage: flag => dispatch(loadPage(flag)),
    updateProduct: updateProduct(dispatch),
    deleteProduct: deleteProduct(dispatch),
    getProductItems: getProductItems(dispatch),
    getSiteSettings: getSiteSettings(dispatch)
})

export default connect(null,MapDispatchToProps)(ProductListeRow);