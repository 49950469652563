import ReportActionTypes from './report.types';

const INITIAL_STATE = {
    report_items: null
}

const ReportItemsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReportActionTypes.SET_REPORT_ITEMS:
            return {
                ...state, 
                report_items : action.payload, 
            }
        default:
            return state;
    }
}

export default ReportItemsReducer;