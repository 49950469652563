import ZoomMeetingsActionTypes from './zoom-meetings.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const setZoomLink = dispatch => async (webinarId,obj) => {   
     try {
         const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + `admin/meetings/new-meeting?webinar_id=${webinarId}`, obj, { 
             headers: (await getMyTokenFunc())
         });
         return result.data;
     } catch (error) {
         console.log(error?.response?.data?.message);    
     }
 }

 export const getCurrentZoomMeeting = dispatch => async (userEmail) => {


    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/meetings/all?email=${userEmail}`, {
            headers: (await getMyTokenFunc())
        });
        console.log("redux result", result.data.result);
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}
export const getZoomMeetings = dispatch => async (userEmail, type, size, pageNum) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/meetings/all?email=${userEmail}&type=${type}&page_size=${size}&page_number=${pageNum}`, { 
            headers: (await getMyTokenFunc())
        });
        dispatch({
            type: ZoomMeetingsActionTypes.SET_ZOOM_MEETINGS_LIST_ITEMS,
            payload: result.data.result
        });
    } catch (error) {
        console.log(error);    
    }
}

export const getZoomTemplate = dispatch => async () => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/meetings/templates/Support@matastacticalsupply.com`, {
            headers: (await getMyTokenFunc())
        });
        console.log("redux result", result.data.result);
        return result.data.result.templates;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
 }

export const updateZoom = dispatch => async ( meetingId, webinarId, obj ) => {

    try {
        const result = await axios.patch( RestAPI.ORIGINAL_ENDPOINT + `admin/meetings/update/${meetingId}?webinar_id=${webinarId}`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {  
        return error.response.data;                   
    }
}

export const deleteZoomMeeting = dispatch => async ( meetingId,webinarID ) => {

    try {
        const result = await axios.delete( RestAPI.ORIGINAL_ENDPOINT + `admin/meetings/delete/${meetingId}?webinar_id=${webinarID}`, { 
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const setFilterString = filterStr => ({
    type: ZoomMeetingsActionTypes.SET_FILTER_STRING,
    payload: filterStr
})

export const setLimitPerPage = limit => ({
    type: ZoomMeetingsActionTypes.SET_LIMIT_PER_PAGE,
    payload: limit
})

export const setProductPageNum = num => ({
    type: ZoomMeetingsActionTypes.SET_PRODUCT_LIST_PAGE_NUM,
    payload: num
})
export const setCurrentTypeFilter = str => ({
    type: ZoomMeetingsActionTypes.SET_TYPE_FILTER,
    payload: str
})

