import React from 'react';
import './zoom-edit.style.scss';
import { MDBRow, MDBCol } from 'mdbreact';
import FormButton from '../../../components/form-button/form-button.component';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadPage } from '../../../redux/user/user.action';
import { updateZoom } from '../../../redux/zoom-meetings/zoom-meetings.action';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';

const ZoomEditPage = withRouter(({
	location,
	loadPage,
	isShowSideBar,
	updateZoom
}) => {
	const alert = useAlert();
	const urlHistory = useHistory();
	const [updateInfo, setUpdateInfo] = useState({
		topic: "",
		agenda: "",
		duration: ""
	})

	useEffect(() => {
			loadPage(true);
			if (location?.state?.item) {
				setUpdateInfo({
					topic: location.state.item.topic,
					agenda: location.state.item.agenda,
					duration: location.state.item.duration
				});
			}
			loadPage(false);
	}, []);

	const updateZoomDetails = async () => {
		loadPage(true);
		const result = await updateZoom(location.state.item.id,location.state.item.webinar_id, {
			topic: updateInfo.topic,
			agenda: updateInfo.agenda,
			duration: updateInfo.duration
		});
		if (result.statusCode === 200) {
			alert.success("Updated successfully");
			urlHistory.goBack();
		}
		else
			alert.error("Failed updating");
		loadPage(false);
	}

	return (
		<div className="product-edit" style={{ width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)' }}>
			<MDBRow className="headerRow">
				<MDBCol size="12" sm="12" md="8" lg="9">
					<h2 className="text-white font-weight-bold">EDIT ZOOM MEETING</h2>
				</MDBCol>
			</MDBRow>
			<MDBRow>
				<MDBCol size="12" sm="12" md="12" lg="12" className="section">
					<h4 className="text-white mb-4">Meeting Details</h4>
					<p className="label-p">Topic</p>
					<input className="custom-input mb-4" type="text" value={updateInfo.topic} onChange={(event) => setUpdateInfo({ ...updateInfo, topic: event.target.value })} />
					<p className="label-p">Description</p>
					<input className="custom-input mb-4" type="text" value={updateInfo.agenda} onChange={(event) => setUpdateInfo({ ...updateInfo, agenda: event.target.value })} />
					<p className="label-p">Duration</p>
					<input className="custom-input mb-4" type="text" value={updateInfo.duration} onChange={(event) => setUpdateInfo({ ...updateInfo, duration: event.target.value })} />
				</MDBCol>
			</MDBRow>
			<MDBRow center className="mt-4">
				<MDBCol size="6" sm="6" md="5" lg="4">
					<FormButton onClickFunc={() => updateZoomDetails()}>UPDATE</FormButton>
				</MDBCol>
				<MDBCol size="6" sm="6" md="5" lg="4">
					<FormButton greyCol={true} onClickFunc={()=> urlHistory.goBack()}>CANCEL</FormButton>
				</MDBCol>
			</MDBRow>
		</div>
	)
});
const MapStateToProps = ({ user: { isShowSideBar } }) => ({
	isShowSideBar
})
const MapDispatchToProps = dispatch => ({
	loadPage: flag => dispatch(loadPage(flag)),
	updateZoom: updateZoom(dispatch)
})
export default connect(MapStateToProps, MapDispatchToProps)(ZoomEditPage);
