import React, {useState, useEffect, Fragment} from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import './seat-refund-page.style.scss';
import { connect } from 'react-redux';
import CountPerPage from '../../components/table/count-per-page/count-per-page.component';
import SeatRefundFilterInput from './filter-search/filter-search.component';
import PageButtons from '../../components/table/pagination/pagination.component';
import SeatRefundItemRow from './item-row/item-row.component';
import { getProductItems, setLimitPerPage, seatRefund, setProductPageNum, freeTakenSeat } from '../../redux/seat-refund/seat-refund.action';
import { loadPage } from '../../redux/user/user.action';
import { useAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import FormCheckbox from '../../components/form-checkbox/form-checkbox.component';
import FormButton from '../../components/form-button/form-button.component';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';

const SeatRefundPage = withRouter(({
    filterStr, 
    product_items, 
    limitPerPage, 
    pageNum,
    getProductItems,
    seatRefund,
    freeTakenSeat,
    loadPage,
    setLimitPerPage,
    location,
    isShowSideBar,
    setProductPageNum
    }) => {

    const alert = useAlert();

    const [isLoading, setIsLoading] = useState(false);
    const [isRefundBtnClicked, setIsRefundBtnClicked] = useState(false);

    // for row counts per page
    const countSelect = [10,20,30,50,"All"];
    const [countSelectShow, setCountSelectShow] = useState(false);

    const [isAllChecked, setIsAllChecked] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        async function load(id) {
            loadPage(true);
            await getProductItems(
                id,   
                filterStr,
                limitPerPage === "All" ? "all" : limitPerPage, 
                limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1)
                );

            loadPage(false);
        }
        if (location && location.state && location.state.id)
            load(location.state.id);
    }, [pageNum, isRefundBtnClicked]);

    useEffect(() => {        
        async function load(id) {
            setProductPageNum(1);
            loadPage(true);
            await getProductItems(
                id, 
                filterStr,
                limitPerPage === "All" ? "all" : limitPerPage, 
                0
                );
            
            loadPage(false);
        }
        if (location && location.state && location.state.id)
            load(location.state.id);
    }, [limitPerPage, filterStr]);

    const [refundItemList, setRefundItemList] = useState([]);

    const addToDelList = (id) => {
        let tempList = refundItemList;
        tempList.push(id);
        setRefundItemList([...tempList]);
    }

    const removeFromDelList = (id) => {
        let tempList = refundItemList;
        let index = tempList.indexOf(id);
        if (index > -1){
            tempList.splice(index,1);
            setRefundItemList([...tempList]);
        }
    }

    useEffect(() => {
        refundItemList.length > 0 ? setTotalAmount(product_items.pricePerSeat * refundItemList.length) : setTotalAmount(0)
    }, [refundItemList]);

    const freeSeatFunc = async () => {
        if (refundItemList.length < 1) {
            alert.error("No seat selection");
            return;
        }

        if (location && location.state && location.state.id) {
            const obj = {
                webinar_id: location.state.id,
                seats: [...refundItemList],
            }
            loadPage(true);
            const result = await freeTakenSeat(obj);
            if (result === "success")
            {
                // alert.success("Refund success");
                setTotalAmount(0);
                setRefundItemList([]);
                setIsRefundBtnClicked(true);
            }
            else
                alert.error("Failed to free seat");
            loadPage(false);
        }
    }

    const refundFunc = async () => {
        if (refundItemList.length < 1) {
            alert.error("No seat selection");
            return;
        }
        
        if (location && location.state && location.state.id) {
            const obj = {
                webinar_id: location.state.id,
                seats: [...refundItemList],
                paymentMethod: "payment",
            }
            loadPage(true);
            const result = await seatRefund(obj);
            if (result === "success")
            {
                // alert.success("Refund success");
                setTotalAmount(0);
                setRefundItemList([]);
                setIsRefundBtnClicked(true);
            }
            else
                alert.error("Payment gateway policy limit or transiction failed");
            loadPage(false);
        }      
    }

    const [isMobileSize, setIsMobileSize] = useState(false);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 800)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <Fragment> 
        {
            isRefundBtnClicked ? <div className="seat-refund-dialog" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
                <div className="seat-refund-wrapper">
                    <h4 className="mb-4 text-center text-white">Confirmation Message</h4>
                    <div className="seat-refund-check-icon">
                        <MDBIcon far icon="check-circle" />
                    </div>
                    <p className="text-center mb-4 mt-4">The refund was processed successfully!</p>
                    <FormButton onClickFunc={()=>setIsRefundBtnClicked(false)}>OK</FormButton>
                </div>
            </div>
            :
            <div className="ffl-db-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}> 
                <MDBRow className="headerRow">
                    <MDBCol size="12" sm="12" md="9" lg="10">
                        <h2 className="text-white font-weight-bold">Seat Refund</h2>
                    </MDBCol>
                </MDBRow>
                <div className="section">
                    <div className="toolbar">
                        <div className="leftDiv">
                            <div>
                                <CountPerPage 
                                    options={countSelect} 
                                    showSelectBox={()=>setCountSelectShow(!countSelectShow)} 
                                    selectOption={(event)=>{
                                        setCountSelectShow(false);
                                        setLimitPerPage(event.target.id);
                                    }} 
                                    optionShow={countSelectShow} 
                                    placeholder={limitPerPage}
                                />
                                <label className="ml-1">Per Page</label>
                            </div>
                            <SeatRefundFilterInput str={filterStr}/>
                        </div>
                        <PageButtons 
                            count={ limitPerPage === "All" ? 1 : Math.ceil(product_items.count/limitPerPage) } 
                            currentIndex = {pageNum}
                            setProductPageNum = {setProductPageNum}
                        />  
                    </div>  
                    {
                    isMobileSize ? 
                        <MDBRow className="table-header">
                            <MDBCol size="1" className="text-white text-center">
                                <FormCheckbox Notif={isAllChecked} handleChange = { () => setIsAllChecked(!isAllChecked) } />
                            </MDBCol>
                            <MDBCol size="4" className="text-white text-center">
                                Seat No.
                            </MDBCol>
                            <MDBCol size="5" className="text-white text-center">
                                Name
                            </MDBCol>
                        </MDBRow>
                    :
                    <MDBRow className="table-header">
                
                        <MDBCol size="1" className="text-white">
                            <FormCheckbox Notif={isAllChecked} handleChange = { () => setIsAllChecked(!isAllChecked) } />
                        </MDBCol>
                        <MDBCol size="2" className="text-white text-center">
                            Seat No.
                        </MDBCol>                    
                        <MDBCol size="3" className="text-white text-center">
                            User Name
                        </MDBCol>         
                        <MDBCol size="3" className="text-white text-center">
                            User Email
                        </MDBCol>   
                        <MDBCol size="3" className="text-white text-center">
                            Price per seat
                        </MDBCol>           
                    </MDBRow>
                    }
                    
                    {
                        product_items && product_items && product_items.rows && product_items.rows.length > 0 ? product_items.rows.map( item => <SeatRefundItemRow 
                            key={item.id} 
                            item={item} 
                            id={item.id} 
                            price_per_seat = {product_items.pricePerSeat}
                            isAllChecked = {isAllChecked}
                            addToDelList = {(id)=>addToDelList(id)}
                            removeFromDelList = {(id)=>removeFromDelList(id)}
                            isMobile={isMobileSize}/> )
                            :
                            <div className="non-row text-center">
                                No Data
                            </div>
                    }

                    <MDBRow center className="mt-4 mb-4">

                        <MDBCol xs="12" sm="12" md="10" lg="7" xl="5" className="mb-3">
                            <div className="total-div">
                                <p>Total Ammount</p>
                                <p>${totalAmount}</p>
                            </div>
                        </MDBCol>

                        <MDBCol xs="12" sm="12" md="10" lg="5" xl="3">
                            <FormButton isLoading = {isLoading} onClickFunc={freeSeatFunc}>FREE SEAT</FormButton>
                        </MDBCol>

                        {/* <MDBCol xs="12" sm="12" md="10" lg="5" xl="4">
                            <MDBRow center>
                                <MDBCol size="6" sm="6" md="6" lg="6">
                                    <FormButton isLoading = {isLoading} onClickFunc={freeSeatFunc}>FREE SEAT</FormButton>
                                </MDBCol>
                                <MDBCol size="6" sm="6" md="6" lg="6">
                                    <FormButton isLoading = {isLoading} onClickFunc={refundFunc}>REFUND</FormButton>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol> */}
                        
                    </MDBRow>
                </div>
            </div>
        }        
        </Fragment>
    )
})

const MapStateToProps = ({ seat_refund: { filterStr, product_items, limitPerPage, pageNum }, user: { isShowSideBar }}) => ({
    filterStr,
    product_items,
    limitPerPage,
    pageNum,
    isShowSideBar
})

const MapDispatchToProps = dispatch => ({
    getProductItems: getProductItems(dispatch),
    seatRefund: seatRefund(dispatch),
    freeTakenSeat: freeTakenSeat(dispatch),
    loadPage: flag => dispatch(loadPage(flag)),
    setLimitPerPage: count => dispatch(setLimitPerPage(count)),
    setProductPageNum: num => dispatch(setProductPageNum(num))
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(SeatRefundPage));