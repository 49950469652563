import { MarkupsActionTypes } from './markups.types';

const INITIAL_SATE = {
    currentMarkups: null,
    filterString: "",
}

const MarkupsReducer = (state = INITIAL_SATE, action) => {
    switch (action.type) {
        case MarkupsActionTypes.SET_CURRENT_MARKUPS:
            return {
                ...state, currentMarkups: action.payload 
            }
        case MarkupsActionTypes.SET_FILTER_STRING: 
            return {
                ...state, filterString: action.payload
            }
        default:
            return state;
    }
}

export default MarkupsReducer;