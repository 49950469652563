import UserManagementActionTypes from './user-management.types';

const INITIAL_STATE = {
    product_items: {
        rows: [],
        count: 1,
    },
    filterStr: "",
    limitPerPage: 10,
    pageNum: 1,
    fflFilter: '',
}

const UserMangementReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserManagementActionTypes.SET_PRODUCT_LIST_ITEMS:
            return {
                ...state, 
                product_items : action.payload, 
            }
        case UserManagementActionTypes.SET_FILTER_STRING:
            return {
                ...state, filterStr: action.payload
            }
        case UserManagementActionTypes.SET_LIMIT_PER_PAGE:
            return {
                ...state, limitPerPage: action.payload
            }         
        case UserManagementActionTypes.SET_PRODUCT_LIST_PAGE_NUM:
            return {
                ...state, pageNum: action.payload
            }
        case UserManagementActionTypes.SET_FFL_FILTER:
            return {
                ...state, fflFilter: action.payload
            }
        default:
            return state;
    }
}

export default UserMangementReducer;