import React, { Fragment } from 'react';
import './product-edit.style.scss';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import FormButton from '../../../components/form-button/form-button.component';
import Switch from 'react-switch';
import { getCurrentCategories } from '../../../redux/category/category.action';
import { useState, useEffect, useRef } from 'react';
import FormSelect from '../../../components/form-select/form-select.component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	getCurrentProductItem,
	addGalleryImages,
	removeGalleryImage,
	updateProduct,
	addNewWebinar,
	getAllCompleteWebinar
} from '../../../redux/product-list/product-list.action';
import { loadPage, setIsFromDuplicate } from '../../../redux/user/user.action';
import { Storage } from 'aws-amplify';
import { useDropzone } from 'react-dropzone';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { getSiteSettings } from '../../../redux/colorChnage/colorChange.action';
import { getImageFromS3 } from '../../../services/s3';
import withPermissionChecking from '../../../utils/HOC/withPermissionCheck';

const ProductEditPage = withRouter(({
	location,
	getCurrentCategories,
	getCurrentProductItem,
	getAllCompleteWebinar,
	addGalleryImages,
	removeGalleryImage,
	updateProduct,
	addNewWebinar,
	getSiteSettings,
	loadPage,
	isShowSideBar,
	setIsFromDuplicate }) => {

	const urlHistory = useHistory();

	const alert = useAlert();

	// for converting status
	const [convertingStatus, setConvertingStatus] = useState({
		instant: false,
		schedule: false,
		queue: false,
		hold: false
	})

	const [updateInfo, setUpdateInfo] = useState({
		title: "",
		desciption: "",
		price: 0,
		seatNum: 0,
		id: "",
		prodType: "",
		boughtFor: 0,
		shippingPrice: 0,
		product_status: "",
		publish_method: "",
		UPCCode: "",
		taxable: true,
		hot_seat_number: null,
		number_duplicate: 1

	})

	// for select box of category
	const categoryList = useRef(null);
	const [categorySelect, setCategorySelect] = useState([]);
	const [categorySelectShow, setCategorySelectShow] = useState(false);
	const [currentCategorySelect, setCurrentCategorySelect] = useState("");

	// for gun photo
	const imageList = useRef(null);

	const [mainImageUrl, setMainImageUrl] = useState({ id: null, url: null });
	const [thumbnail300Url, setThumbnail300Url] = useState({ id: null, url: null });
	const [thumbnail1200Url, setThumbnail1200Url] = useState({ id: null, url: null });
	const [galleryUrls, setGalleryUrls] = useState([]);

	// for custom date picker
	const today = new Date();
	Date.shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	Date.shortWeeks = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];


	
	const [currentDate, setCurrentDate] = useState({
		year: today.getFullYear(),
		month: today.getMonth() + 1,
		shortWeek: Date.shortWeeks[today.getDay() - 1],
		day: today.getDate(),
		hour: today.getHours(),
		minute: today.getMinutes()
	});

	
	const plusDateFunc = (type) => {
		switch (type) {
			case "day":
				if (currentDate.day === 31)
					setCurrentDate({ ...currentDate, day: 1 });
				else
					setCurrentDate({ ...currentDate, day: currentDate.day + 1 });
				break;

			case "month":
				if (currentDate.month === 12)
					setCurrentDate({ ...currentDate, month: 1 });
				else
					setCurrentDate({ ...currentDate, month: currentDate.month + 1 });
				break;

			case "year":
				setCurrentDate({ ...currentDate, year: currentDate.year + 1 });
				break;

			case "hour":
				if (currentDate.hour === 23)
					setCurrentDate({ ...currentDate, hour: 0 });
				else
					setCurrentDate({ ...currentDate, hour: currentDate.hour + 1 });
				break;

			case "minute":
				if (currentDate.minute === 59)
					setCurrentDate({ ...currentDate, minute: 0 });
				else
					setCurrentDate({ ...currentDate, minute: currentDate.minute + 1 });
				break;

			default:
				break;
		}
	}

	const minusDateFunc = (type) => {
		switch (type) {
			case "day":
				if (currentDate.day === 1)
					setCurrentDate({ ...currentDate, day: 31 });
				else
					setCurrentDate({ ...currentDate, day: currentDate.day - 1 });
				break;

			case "month":
				if (currentDate.month === 1)
					setCurrentDate({ ...currentDate, month: 12 });
				else
					setCurrentDate({ ...currentDate, month: currentDate.month - 1 });
				break;

			case "year":
				if (currentDate.year === today.getFullYear())
					return;
				else
					setCurrentDate({ ...currentDate, year: currentDate.year - 1 });
				break;

			case "hour":
				if (currentDate.hour === 0)
					setCurrentDate({ ...currentDate, hour: 23 });
				else
					setCurrentDate({ ...currentDate, hour: currentDate.hour - 1 });
				break;

			case "minute":
				if (currentDate.minute === 0)
					setCurrentDate({ ...currentDate, minute: 59 });
				else
					setCurrentDate({ ...currentDate, minute: currentDate.minute - 1 });
				break;

			default:
				break;
		}
	}

	// const saveBtnClicked = () => {

	//     const today = new Date();
	//     const laterDate = new Date();
	//     laterDate.setFullYear(currentDate.year);
	//     laterDate.setMonth(currentDate.month-1);
	//     laterDate.setDate(currentDate.day);
	//     laterDate.setHours(currentDate.hour);
	//     laterDate.setMinutes(currentDate.minute);

	//     if (laterDate.getTime() < today.getTime()) {           
	//         alert.error("You selected past date");
	//         return;
	//     }
	//     else{
	//         if ( currentDate.day > new Date(currentDate.year, currentDate.month, 0).getDate()) {
	//             alert.error("Invalid date");
	//             return;
	//         }
	//         else {            
	//             alert.success("Schedule time is set");
	//         }                    
	//     }        
	// }

	// const clearBtnClicked = () => {
	//     setCurrentDate({
	//         year: today.getFullYear(),
	//         month: today.getMonth()+1,
	//         shortWeek: Date.shortWeeks[today.getDay()-1],
	//         day: today.getDate(),
	//         hour: today.getHours(),
	//         minute: today.getMinutes()
	//     })
	// }

	useEffect(() => {
		async function loadCategories() {
			loadPage(true);
			if (location?.state?.prodType && location?.state?.id) {
				const result = await getCurrentProductItem(location.state.id, location.state.prodType);

				if (result) {
					if (result.product_type === "webinar") {
						setUpdateInfo({
							title: result.name,
							desciption: result.shortDescription,
							price: result.price_per_seats,
							seatNum: result.seats,
							id: result.id,
							prodType: result.product_type,
							boughtFor: result.bought_for,
							product_status: result.product_status,
							publish_method: result.publish_method,
							taxable: result.taxable,
							UPCCode: result.upc_code ? result.upc_code : 'unassigned',
							hotSeat: result.hot_seat_number
							
						});
					}
					else {
						setUpdateInfo({
							title: result.productName,
							desciption: result.shortDescription,
							price: result.pricePerItem,
							seatNum: result.amount,
							id: result.id,
							prodType: result.product_type,
							boughtFor: result.bought_for,
							shippingPrice: result.shipping_price,
							product_status: result.product_status,
							publish_method: result.publish_method,
							taxable: result.taxable,
							UPCCode: result.upc_code ? result.upc_code : 'unassigned',
							hotSeat: result.hot_seat_number
						});
					}

					const categories = await getCurrentCategories(true);
					if (categories) {
						categoryList.current = categories;
						const webinarCategories = [];
						categories.map(category => {
							if ((category.product_type.includes(result.product_type[0].toUpperCase() + result.product_type.slice(1))) || (category.product_type.includes("Both")))
								webinarCategories.push(category.category_name);
						})
						setCategorySelect([...webinarCategories]);
					}

					const currentCat = categoryList.current.filter(category => category.id === result.category_id);
					if (currentCat.length > 0)
						setCurrentCategorySelect(currentCat[0].category_name);

					//result.main_image && !(location?.state?.duplicate) ? setMainImageUrl({
					result.main_image ? setMainImageUrl({
						id: result.main_image.id,
						url: result.main_image.image_url
					})
						:
						setMainImageUrl(null)

					result.thumbnail_300 && !(location?.state?.duplicate) ? setThumbnail300Url({
						id: result.thumbnail_300.id,
						url: result.thumbnail_300.image_url
					})
						:
						setThumbnail300Url(null)

					result.thumbnail_1200 && !(location?.state?.duplicate) ? setThumbnail1200Url({
						id: result.thumbnail_1200.id,
						url: result.thumbnail_1200.image_url
					})
						:
						setThumbnail1200Url(null)

					// if (result.pictures.length > 0 && !(location?.state?.duplicate)) {
					if (result.pictures.length > 0) {						
						let thumbnails=[result.thumbnail_1200_id,result.thumbnail_300_id];
						result.pictures=result.pictures.filter(i=>!thumbnails.includes(i.id));				
						let galleryImgs = [];
						for (let i = 0; i < result.pictures.length; i++) {
							galleryImgs.push({
								id: result.pictures[i].id,
								url: result.pictures[i].image_url
							});
						}
						
						var obj = galleryImgs.reduce( ( acc, c ) =>  Object.assign(acc, {[c.url]:c.id}) , {});
						var output = Object.keys( obj )
									.map( s => ({ url : s, id : obj[ s ] }) );

						setGalleryUrls([...output]);
					}
				}
				const settingsInfo = await getSiteSettings();
				if (settingsInfo && settingsInfo.time_zone) {
					const date = new Date(moment().tz(settingsInfo.time_zone).format('MM/DD/YY HH:mm'));
					setCurrentDate({
						year: date.getFullYear(),
						month: date.getMonth() + 1,
						shortWeek: Date.shortWeeks[date.getDay() - 1],
						day: date.getDate(),
						hour: date.getHours(),
						minute: date.getMinutes()
					});
				}
			}
			loadPage(false);
		}
		location?.state?.duplicate && setConvertingStatus({ ...convertingStatus, queue: true })
		loadCategories();

	}, []);

	const [addingImageUrls, setAddingImageUrls] = useState([]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: '.jpg, .jpeg, .png, .webp, .tiff, .tif, .gif, .svg',
		onDrop: acceptedFiles => {
			if ((acceptedFiles.length + galleryUrls.length) > 10) {
				alert.error("Count of images should be less than 10");
				return;
			}
			else{
				setAddingImageUrls(acceptedFiles.map(file => Object.assign(file, {
					preview: URL.createObjectURL(file)
				})));
			}
		}
	});

	const imagesUpload = async (files) => {
		const imagesList = [];
		for (let i = 0; i < files.length; i++) {
			const stored = await Storage.put("products-" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + "-" + files[i].name, files[i], {
				contentType: files[i].type,
				cacheControl: 'max-age=31536000'
			});

			imagesList.push(stored.key);
		}
		return imagesList;
	}

	useEffect(() => {
		async function add() {
			loadPage(true);
			const addImageList = await imagesUpload(addingImageUrls);

			if (location?.state?.duplicate) {
				const tempArray = [];
				addImageList.map(item => tempArray.push({
					image: item,
					url: item
				}))
				setGalleryUrls([...galleryUrls, ...tempArray]);
			}
			else {
				const result = await addGalleryImages({
					imageLists: addImageList,
					product_type: updateInfo.prodType,
					product_id: updateInfo.id
				});

				if (result.length > 0) {
					alert.success("Image uploaded successfully");
					imageList.current = result;
					const galleryImgs = [];
					for (let i = 0; i < result.length; i++) {
						galleryImgs.push({
							id: result[i].id,
							url: result[i].image_url
						});
					}
					setGalleryUrls([...galleryImgs]);
				}
				else
					alert.error("Image uploading failed");
			}

			loadPage(false);
		}
		addingImageUrls.length > 0 && add();
	}, [addingImageUrls]);

	const removeImage = async (image) => {
		if (location?.state?.duplicate) {
			setGalleryUrls(galleryUrls.filter(item => item.url !== image.url));
			mainImageUrl && mainImageUrl.url === image.url && setMainImageUrl(null);
		}
		else {
			const obj = {
				imageListIds: [image.id],
				product_type: updateInfo.prodType,
				product_id: updateInfo.id
			}
			loadPage(true);
			const result = await removeGalleryImage(obj);
			if (result === "success") {
				alert.success("Removed successfully");
				setGalleryUrls(galleryUrls.filter(item => item.id !== image.id));
				mainImageUrl && mainImageUrl.id === image.id && setMainImageUrl(null);
			}
			else
				alert.error("Removing failed");
			loadPage(false);
		}
	}
	
	function uuidv4() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		  var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		  return v.toString(16);
		});
	}

	const setMainImageFunc = async (image) => {	
		if (location?.state?.duplicate) {
			setMainImageUrl(image);
			// setThumbnail300Url({
			// 	id: uuidv4(),
			// 	url: image.url
			// });
			// setThumbnail1200Url({
			// 	id: uuidv4(),
			// 	url: image.url
			// });
		}
		else {
			const obj = {
				product_id: updateInfo.id,
				product_type: updateInfo.prodType.toLowerCase(),
				primary_image_id: image.id
				//thumbnail_300_id: uuidv4(), asi estaba
				// thumbnail_300_id: "",
				// thumbnail_1200_id: ""
				//thumbnail_1200_id: uuidv4()
			}
			loadPage(true);
			const result = await updateProduct(obj);
			loadPage(false);
			if (result.message)
				alert.error(result.message);
			else {
				if (result === 'success')
					alert.success("Changed main image successfully");
				else
					alert.info(result);

				setMainImageUrl({
					id: image.id,
					url: image.url
				});
				setThumbnail300Url({
					id: uuidv4(),
					url: image.url
				});
				setThumbnail1200Url({
					id: uuidv4(),
					url: image.url
				});
			}
		}
	}

	const getCurrentCategoryId = () => {
		const result = categoryList.current.filter(category => category.category_name === currentCategorySelect);
		return result[0].id;
	}

	const decimalFormat = (e, kind) => {
		const re = /^\d*\.?\d*$/;
		// if value is not blank, then test the regex

		if (e.target.value === '' || re.test(e.target.value)) {
			switch (kind) {
				case "price":
					setUpdateInfo({ ...updateInfo, price: e.target.value });
					break;
				case "bought":
					setUpdateInfo({ ...updateInfo, boughtFor: e.target.value });
					break;
				case "shipping":
					setUpdateInfo({ ...updateInfo, shippingPrice: e.target.value });
					break;
				default:
					break;
			}
		}
	}
	const numberFormat = (e, kind) => {
		const re = /^[0-9\b]+$/;
		// if value is not blank, then test the regex

		if (e.target.value === '' || re.test(e.target.value)) {
			switch (kind) {
				case "seatNum":
					setUpdateInfo({ ...updateInfo, seatNum: e.target.value });
					break;
				case "hotseat":
					setUpdateInfo({ ...updateInfo, hotSeat: e.target.value });
					break;
				default:
					break;
			}
		}
	}

	const updateFunc = async () => {
		
		if( updateInfo.number_duplicate == undefined){
			updateInfo.number_duplicate =  1;
	   }
		
		if (!mainImageUrl?.url || galleryUrls.length < 1) {
			alert.error("Please select the image");
			return;
		}

		if (currentCategorySelect === "") {
			alert.error("Please select the category");
			return;
		}

		if (updateInfo.price === 0) {
			alert.error("Price shouldn't be 0");
			return;
		}
		if (updateInfo.seatNum < 1) {
			alert.error(`${updateInfo.prodType === "webinar" ? "Seats " : "Inventory "}number shouldn't be 0`);
			return;
		}
		if (updateInfo.hotSeat !== "" && (updateInfo.hotSeat > updateInfo.seatNum || updateInfo.hotSeat == 0)) {
			alert.error('Check hot seat number');
			return;
		}
		if ((updateInfo.prodType === "webinar") && (updateInfo.seatNum > 1000)) {
			alert.error("Seats number should less than 1000");
			return;
		}
		const mainImage = mainImageUrl.url;

		const thumbnail300 = thumbnail300Url?.url || mainImageUrl.url;
		const thumbnail1200 = thumbnail1200Url?.url || mainImageUrl.url;
		const imageLists = [];
		galleryUrls.map(item => imageLists.push(item.url));
		imageLists.push(thumbnail300, thumbnail1200);

		const obj = {
			taxable: updateInfo.taxable,
			upc_code: updateInfo.UPCCode,
			number_duplicate :updateInfo.number_duplicate,
			mainImage,
			thumbnail300,
			thumbnail1200,
			imageLists
		}
		if (!location?.state?.duplicate)
			obj.product_id = updateInfo.id;
		if (updateInfo.prodType === "webinar" || location?.state?.prodType === "webinar") {
		
			obj.name = updateInfo.title;
			obj.number_duplicate = updateInfo.number_duplicate;
			obj.shortDescription = updateInfo.desciption;
			obj.price_per_seats = (Math.round(updateInfo.price * 100) / 100).toFixed(2);
			obj.seats = updateInfo.seatNum;
			obj.hot_seat_number = updateInfo.hotSeat == "" ? null : updateInfo.hotSeat;
			obj.bought_for = (Math.round(updateInfo.boughtFor * 100) / 100).toFixed(2);
			if (!location?.state?.duplicate)
				obj.product_type = updateInfo.prodType;
			else
				obj.webinar_type = "webinar";
		}
		else {
			obj.productName = updateInfo.title;
			obj.shortDescription = updateInfo.desciption;
			obj.pricePerItem = (Math.round(updateInfo.price * 100) / 100).toFixed(2);
			obj.amount = updateInfo.seatNum;
			obj.bought_for = (Math.round(updateInfo.boughtFor * 100) / 100).toFixed(2);
			obj.shipping_price = (Math.round(updateInfo.shippingPrice * 100) / 100).toFixed(2);
			if (!location?.state?.duplicate)
				obj.product_type = updateInfo.prodType;
		}

		if (convertingStatus.hold) {
			obj.product_status = "hold";
		}
		if (convertingStatus.instant) {
			if (!location?.state?.duplicate)
				obj.product_status = "active";
			obj.publish_method = "instant";
		}
		if (convertingStatus.queue) {
			obj.publish_method = "queued";
		}
		if (convertingStatus.schedule) {
			const today = new Date();
			const laterDate = new Date();
			laterDate.setFullYear(currentDate.year);
			laterDate.setMonth(currentDate.month - 1);
			laterDate.setDate(currentDate.day);
			laterDate.setHours(currentDate.hour);
			laterDate.setMinutes(currentDate.minute);

			if (laterDate.getTime() < today.getTime()) {
				alert.error("You selected past date");
				return;
			}
			else {
				if (currentDate.day > new Date(currentDate.year, currentDate.month, 0).getDate()) {
					alert.error("Invalid date");
					return;
				}
				else {
					obj.publish_method = "scheduled";
					const date = new Date(currentDate.year, currentDate.month - 1, currentDate.day, currentDate.hour, currentDate.minute);
					obj.scheduled_time = date.toISOString();
				}
			}

		}

		obj.category_id = getCurrentCategoryId();
		let result = null;
		loadPage(true);
	
		if (location?.state?.duplicate) {
	
			for (var i = 0; i < obj.number_duplicate; i++) {
				result = await addNewWebinar(location.state.prodType, obj);
			  }
		
		}
		else{
			result = await updateProduct(obj);
			}
		loadPage(false);

		if (result.message){
		alert.error(result.message);}
			
		else {
			if (result === 'success'){
				
				loadPage(true);
             	setTimeout(function(){
                	window.location.reload(true);
					
                	loadPage(false);
            	}, 3000);  
				alert.success(location?.state?.duplicate ? "Duplicated Successfully" : "Updated Successfully");

			}
			
			else 
				alert.info(result);
			setIsFromDuplicate(true);
			urlHistory.goBack();
		}

	}
		

	const updateDuplicateFunc = async (event) => {
		if(event.target.value.match("^[1-9][0-9]?$") != null){
			let n_duplicate =1;
			if(event.target.value!=undefined){
				 n_duplicate =  parseInt(event.target.value)
			}
			setUpdateInfo({ ...updateInfo, number_duplicate:n_duplicate });
					
		}
		
	}

	return (
		<div className="product-edit" style={{ width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)' }}>
			<MDBRow className="headerRow">
				<MDBCol size="12" sm="12" md="8" lg="4">
					<h2 className="text-white font-weight-bold">{location?.state?.duplicate ? 'Duplicate Product' : 'Edit Product'}</h2>
				</MDBCol>
				{location?.state?.duplicate  && (updateInfo.prodType === "webinar")  &&  <MDBCol size="6" sm="6" md="8" lg="3">
					Number of duplicates:  
					<input type="number"  min="1" max="99" value={updateInfo.number_duplicate}  onChange={(event) => updateDuplicateFunc(event)}  />
                     
				</MDBCol> }
				
				
				<MDBCol size="6" sm="6" md="4" lg="3">
					<FormButton onClickFunc={() => updateFunc()}>{location?.state?.duplicate ? 'DUPLICATE' : 'UPDATE'}</FormButton>
				</MDBCol>
			</MDBRow>
			<MDBRow>
				<MDBCol size="12" sm="12" md="12" lg="8" className="section">
					<div className="status-div mb-1">
						<div>
							<h4 className="text-white">Status</h4>
						</div>
						<div className="status-group">
							
							
							{
								((updateInfo.product_status === "active") || (updateInfo.product_status === "inactive")) && !location?.state?.duplicate && <div className="status-wrapper">
									<p>Put On Hold</p>
									<Switch onColor="#57bd7a" onChange={() => setConvertingStatus({
										hold: !convertingStatus.hold,
										instant: false,
										schedule: false,
										queue: false
									})} checked={convertingStatus.hold} />
								</div>
							}
							{
								(((updateInfo.product_status !== "active") && (updateInfo.prodType === "webinar") && ((updateInfo.product_status === "hold") || (updateInfo.publish_method === "scheduled"))) || (location?.state?.duplicate && (updateInfo.prodType === "webinar"))) && <div className="status-wrapper">
									<p>Add To Queue</p>
									<Switch onColor="#57bd7a" onChange={() => setConvertingStatus({
										queue: !convertingStatus.queue,
										instant: false,
										hold: false,
										schedule: false
									})} checked={convertingStatus.queue} />
								</div>
							}
							{
								(((updateInfo.product_status !== "active") && ((updateInfo.product_status === "hold") || (updateInfo.publish_method === "queued"))) || location?.state?.duplicate) && <div className="status-wrapper">
									<p>Scehdule Later</p>
									<Switch onColor="#57bd7a" onChange={() => setConvertingStatus({
										schedule: !convertingStatus.schedule,
										instant: false,
										hold: false,
										queue: false
									})} checked={convertingStatus.schedule} />
								</div>
							}
							{
								(((updateInfo.product_status !== "active") && ((updateInfo.product_status === "inactive") || (updateInfo.product_status === "hold"))) || location?.state?.duplicate) && <div className="status-wrapper">
									<p>Active Now</p>
									<Switch onColor="#57bd7a" onChange={() => setConvertingStatus({
										instant: !convertingStatus.instant,
										hold: false,
										schedule: false,
										queue: false
									})} checked={convertingStatus.instant} />
								</div>
							}
						</div>
					</div>
					{
						convertingStatus.schedule && <MDBRow center>
							<MDBCol size="12" sm="12" md="10" lg="8">
								<div className="custom-datepicker mb-3">
									<div className="pt-4 text-center">
										<p className="text-white mb-1">Schedule Date &amp; Time</p>
										<p>{currentDate.shortWeek}, {Date.shortMonths[currentDate.month - 1]} {`${currentDate.day < 10 ? "0" : ""}${currentDate.day}`}, {currentDate.year} <span>{`${currentDate.hour < 10 ? "0" : ""}${currentDate.hour}`}:{`${currentDate.minute < 10 ? "0" : ""}${currentDate.minute}`}</span></p>
									</div>
									<div className="date-select mt-4 pb-4">
										<div className="text-center ml-1 mr-1">
											<p className="pointer" onClick={() => plusDateFunc("day")}>+</p>
											<p>{`${currentDate.day < 10 ? "0" : ""}${currentDate.day}`}</p>
											<p className="pointer" onClick={() => minusDateFunc("day")}>-</p>
										</div>
										<div className="text-center ml-1 mr-1">
											<p className="pointer" onClick={() => plusDateFunc("month")}>+</p>
											<p>{Date.shortMonths[currentDate.month - 1]}</p>
											<p className="pointer" onClick={() => minusDateFunc("month")}>-</p>
										</div>
										<div className="text-center ml-1 mr-1">
											<p className="pointer" onClick={() => plusDateFunc("year")}>+</p>
											<p>{currentDate.year}</p>
											<p className="pointer" onClick={() => minusDateFunc("year")}>-</p>
										</div>
										<div className="text-center ml-1 mr-1">
											<p className="pointer" onClick={() => plusDateFunc("hour")}>+</p>
											<p>{`${currentDate.hour < 10 ? "0" : ""}${currentDate.hour}`}</p>
											<p className="pointer" onClick={() => minusDateFunc("hour")}>-</p>
										</div>
										<div className="text-center ml-1 mr-1">
											<p className="pointer" onClick={() => plusDateFunc("minute")}>+</p>
											<p>{`${currentDate.minute < 10 ? "0" : ""}${currentDate.minute}`}</p>
											<p className="pointer" onClick={() => minusDateFunc("minute")}>-</p>
										</div>
									</div>
									{/* <MDBRow center>
																				<MDBCol size="5">
																						<FormButton onClickFunc={()=>saveBtnClicked()}>SAVE</FormButton>
																				</MDBCol>
																				<MDBCol size="5">
																						<FormButton onClickFunc={()=>clearBtnClicked()}>CLEAR</FormButton>
																				</MDBCol>
																		</MDBRow>             */}
								</div>
							</MDBCol>
						</MDBRow>
					}
					<hr className="mb-4" />

					<h4 className="text-white mb-4">Product Details</h4>
					<p className="label-p">Product Title</p>
					<input className="custom-input mb-4" type="text" value={updateInfo.title} onChange={(event) => setUpdateInfo({ ...updateInfo, title: event.target.value })} />

					<p className="label-p">UPC Code</p>
					<input className="custom-input mb-4" type="text" value={updateInfo.UPCCode} onChange={(event) => setUpdateInfo({ ...updateInfo, UPCCode: event.target.value })} />

					<p className="label-p">Product Category</p>
					<FormSelect
						options={categorySelect}
						showSelectBox={() => setCategorySelectShow(!categorySelectShow)}
						selectOption={(event) => {
							setCategorySelectShow(false);
							setCurrentCategorySelect(event.target.id);
						}}
						optionShow={categorySelectShow}
						placeholder={currentCategorySelect} />

					<p className="label-p mt-4">Product Description</p>
					<textarea className="mb-4" rows="7" value={updateInfo.desciption} onChange={(event) => setUpdateInfo({ ...updateInfo, desciption: event.target.value })}></textarea>

					<MDBRow className="mb-4">
						<MDBCol size="6" sm="6" md="6" lg="6" className="pl-0">
							<p className="label-p">Price</p>
							<input className="custom-input" type="text" value={updateInfo.price} onChange={event => decimalFormat(event, "price")} />
						</MDBCol>
						<MDBCol size="6" sm="6" md="6" lg="6" className="pr-0">
							<p className="label-p">{`${updateInfo.prodType === "webinar" ? 'No. of Seat' : 'Inventory'}`}</p>
							<input className="custom-input" type="text" value={updateInfo.seatNum} onChange={event => numberFormat(event, "seatNum")} />
						</MDBCol>
					</MDBRow>
					<MDBRow className="mb-4">
						<MDBCol size="6" sm="6" md="6" lg="6" className="pl-0">
							<p className="label-p">Bought For</p>
							<input className="custom-input" type="text" value={updateInfo.boughtFor} onChange={event => decimalFormat(event, "bought")} />
						</MDBCol>
						{updateInfo.prodType === "webinar" ?
							<MDBCol size="6" sm="6" md="6" lg="6" className="pr-0">
								<p className="label-p">Hot Seat</p>
								<input className="custom-input" type="text" value={updateInfo.hotSeat} onChange={event => numberFormat(event, "hotseat")} />
							</MDBCol> : null
						}
						{
							updateInfo.prodType === "physical" &&
							<MDBCol size="6" sm="6" md="6" lg="6" className="pr-0">
								<p className="label-p">Shipping Price</p>
								<input className="custom-input" type="text" value={updateInfo.shippingPrice} onChange={event => decimalFormat(event, "shipping")} />
							</MDBCol>
						}
					</MDBRow>
					{
						updateInfo.prodType === "physical" && <MDBRow className="mb-4">
							<MDBCol size="6" className="pl-0">
								<div className="taxable-wrapper">
									<p>Item Not Taxable</p>
									<Switch onColor="#57bd7a" onChange={() => setUpdateInfo({
										...updateInfo, taxable: !updateInfo.taxable
									})} checked={!updateInfo.taxable} />
								</div>

							</MDBCol>
						</MDBRow>
					}
				</MDBCol>
				<MDBCol size="12" sm="10" md="8" lg="4" className="mobile-gal pr-0">
					<div className="photo-div">
						<p className="label-p text-white">Product Image</p>
						{
							mainImageUrl ? <img src={getImageFromS3(mainImageUrl.url)} className="active-img" alt={updateInfo.title} />
								:
								<div className="non-image">
									No Image Set
								</div>
						}
					</div>
					<div className="photo-div">
						<p className="label-p text-white">Product Gallery</p>
						<MDBRow>
							{
								galleryUrls && galleryUrls.length > 0 ? galleryUrls.map(
									(image, i) => <MDBCol key={i} size="4" className="gallery-item">
										<img src={getImageFromS3(image.url)} alt="active image" />
										<MDBIcon far icon="check-circle" className="checkIcon" onClick={() => setMainImageFunc(image)} />
										<MDBIcon far icon="times-circle" className="removeIcon" onClick={() => removeImage(image)} />
									</MDBCol>
								)
									:
									<div className="non-image gallery">
										No Gallery
									</div>
							}
						</MDBRow>
						<div {...getRootProps({ className: 'dropzone' })}>
							<input {...getInputProps()} className="addImageInput" />
							<label className="add-gallery"><span>Add Product Gallery Images</span> <span>(JPEG, PNG, WEBP, TIF, GIF, SVG)</span></label>
						</div>
					</div>
				</MDBCol>
			</MDBRow>
		</div>
	)
});

const MapStateToProps = ({ user: { isShowSideBar } }) => ({
	isShowSideBar
})

const MapDispatchToProps = dispatch => ({
	getCurrentCategories: getCurrentCategories(dispatch),
	getCurrentProductItem: getCurrentProductItem(dispatch),
	addGalleryImages: addGalleryImages(dispatch),
	removeGalleryImage: removeGalleryImage(dispatch),
	updateProduct: updateProduct(dispatch),
	addNewWebinar: addNewWebinar(dispatch),
	loadPage: flag => dispatch(loadPage(flag)),
	setIsFromDuplicate: flag => dispatch(setIsFromDuplicate(flag)),
	getSiteSettings: getSiteSettings(dispatch)
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(ProductEditPage));