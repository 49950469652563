import React, {useState, useEffect, Fragment} from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import './offline-sale-row.style.scss';
import Select from 'react-select';
import { connect } from 'react-redux';
import { addFFLItem } from '../../../redux/offline-sales/offline-sales.action';
import { loadPage } from '../../../redux/user/user.action';
import { Row, Col } from 'antd';
import moment from 'moment-timezone';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import FormButton from '../../../components/form-button/form-button.component';
import LongItemWithTooltip from '../../../components/tooltip/long-item-with-tooltip';

const getFflBgColor = fflItem => {
	const expDate = new Date(fflItem.expiration);
	const now = new Date(Date.now());
	const isExpired = expDate < now;
	return isExpired ? red : white;
}
const red = '#912c2c'
const white = 'white'

const OfflineSaleItemRow = ({isEdit, item, isMobile, ffl, addFFLItem, loadPage, setShowModal, alert,  showEditFFLModal, loadParent, setCurrentRow, setItemToEditFunc, setLoading,loading }) => {
    const [fflList, setFflList] = useState([]);
    const [selectedFflItem, setSelectedFflItem] = useState(null);

    const [showFFLModal, setShowFFLModal] = useState(false);
    const [showConfirmNotRequiredModal, setShowConfirmNotRequiredModal] = useState(false);
    const [showConfirmSendModal, setShowConfirmSendModal] = useState(false);
    const [validationSendToShipping, setValidationSendToShipping] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);


    const setAllStatesToFalse = () => {
        setShowFFLModal(false);
        setShowConfirmNotRequiredModal(false);
        setShowConfirmSendModal(false);
        setValidationSendToShipping(false);
    }

	const dropDownCustomStyles = {
        // container: () => ({
        //     width: '200px',
        // }),
		option: (styles, { data }) => ({
			...styles,
			backgroundColor: data.bgColor,
			color: data.bgColor === red ? white : undefined,
			borderBottom: '1px solid #c0c0c0',
		}),
	}

    useEffect(() => {
        if ( ffl && (ffl.length > 0)) {
            let tempArray = [];
            tempArray.push({
                value: "non-ffl",
                label: "FFL not required"
            });
            ffl.map(el => {
                tempArray.push({
                    value: el.id, 
                    label: el.licenseName || el.businessName,
                    bgColor: getFflBgColor(el),
                    expDate: el.expiration
                });
            });
            setFflList([...tempArray]);
        }
        if (item && item.ffl_database && item.ffl_database.id) {
            setSelectedFflItem({
                value: item.ffl_database.id,
                label: item.ffl_database.businessName || item.ffl_database.licenseName 
            })
        } else if(item && item?.assigned_ffl && item?.assigned_ffl?.id){
            setSelectedFflItem({
                value: item?.assigned_ffl?.id,
                label: item?.assigned_ffl?.businessName || item?.assigned_ffl?.licenseName
            })
        }
        if (item && item.ffl_not_required) {
            setSelectedFflItem({
                value: "non-ffl",
                label: "FFL not required"
            })
        }
    }, [item]);

    const getCustomDate = () => {
		const customDate = new Date(item.date_purchased);
		Date.shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let temo = Date.shortMonths[customDate.getMonth()] + " " + customDate.getDate() + ", " + customDate.getFullYear();
        console.log("temo", temo);
		return Date.shortMonths[customDate.getMonth()] + " " + customDate.getDate() + ", " + customDate.getFullYear();
	}

    const [isOpenTable, setIsOpenTable] = useState(false);

    const setGreen = (item) => {
        if(item?.ffl_assignment_completed) return {backgroundColor: '#53893eb3', borderBottom: '1px solid grey'}
    }

    const fixNumber = (str) => {
        let temp = str.toString();
        return temp.substring(0, 5);
    }

    const getFFLStatus = (item) => {

        if(item.ffl_not_required){
            return "FFL NOT REQUIRED"
        }

        if(item?.ffl_id && item?.ffl_database){
            return item?.ffl_database?.businessName ? item?.ffl_database?.businessName : item?.ffl_database?.licenseName
        } else if(item?.buyer?.ffl_scope_id && item?.assigned_ffl){
            return item?.assigned_ffl?.businessName ? item?.assigned_ffl?.businessName : item?.assigned_ffl?.licenseName
        }else{
            return "FFL NOT SELECTED"
        }
    }

    const handleFFLNotRequired = async ({modifiedFFL,isEdit = false,oneTimeChange,fflNotRequired}) => {
        
        let purchase_id
        let ffl_id
    
        purchase_id=modifiedFFL?.id
        oneTimeChange=null
        ffl_id=null
        
        
        const obj = { 
            purchase_id,
            ffl_id: ffl_id,
            ffl_not_required: fflNotRequired,
            ffl_one_time:oneTimeChange,
            ffl_edited:isEdit
        }
        console.log("offline sale ffl not required", obj);
        try {
            setLoading(true);
            const result = await addFFLItem(obj);
            setLoading(false);
            if (result === "success"){
                setLoading(true);
                await loadParent();
                setLoading(false);
                alert.success("FFL is assigned successfully",
                {
                    timeout: 2200,
                });
                setAllStatesToFalse();
            } else{
                setLoading(false);
                console.log("offline sale ffl not required failed", result);
                alert.error(result || "Failed assigning");
                setAllStatesToFalse();
            }
        } catch (error) {
            console.log("offline sale ffl not required failed", error);
            alert.error( "Failed assigning");
            setAllStatesToFalse();
        }
        setAllStatesToFalse();

	}

    const sendToShippingFunc = async ({modifiedFFL,isEdit = false,oneTimeChange,fflNotRequired}) => {
        setShowFFLModal(false)
        
        let purchase_id
        let ffl_id
        let ffl_one_time
        let ffl_not_required
    
        purchase_id=modifiedFFL?.id
        oneTimeChange=null
        ffl_id=null

        if(modifiedFFL.assigned_ffl){
            ffl_id = modifiedFFL?.assigned_ffl?.id
            oneTimeChange?ffl_one_time=true:ffl_one_time=false
        }

        
        const obj = { 
            purchase_id,
            ffl_id,
            ffl_not_required: fflNotRequired,
            ffl_one_time:oneTimeChange,
            ffl_edited:isEdit,
            sendToShipping: true
        }

        console.log("Offline send to shipping", obj);


        try {
            setLoading(true);
            const result = await addFFLItem(obj);
            setLoading(false);
            if (result === "success"){
                setLoading(true);
                await loadParent();
                setLoading(false);
                alert.success("FFL is assigned successfully",
                {
                    timeout: 2200,
                });
                setAllStatesToFalse();
            } else{
                alert.error(result || "Failed assigning");
                console.log("error", result);
                setAllStatesToFalse();
            }
        } catch (error) {
            console.log("error", error);
            alert.error( "Failed assigning");
            setAllStatesToFalse();
        }
        setAllStatesToFalse();
    }

    return (
        <Fragment>

{
            validationSendToShipping && (
                <ConfirmationModal
                    isLoading={loading}
                    message="Are you sure you want to proceed?"
                    closeModal={() => {
                        setShowConfirmSendModal(false)
                        setValidationSendToShipping(false)
                    }}
                    editFFL={() => {
                        setValidationSendToShipping(false)
                        setShowModal(true)
                        console.log('editFFL', item);
                    }}
                    notRequired={() => {}}
                    noResponse={() => {
                        setShowConfirmNotRequiredModal(false)
                    }}
                    style="send"
                />
            )
        }
        {
            showConfirmNotRequiredModal && (
                <ConfirmationModal
                    isLoading={loading}
                    message="Are you sure you want to proceed?"
                    closeModal={() => setShowConfirmNotRequiredModal(false)}
                    editFFL={() => {}}
                    notRequired={() => {
                        handleFFLNotRequired({
                            modifiedFFL: itemToEdit,
                            isEdit: false,
                            oneTimeChange: null,
                            fflNotRequired: true
                        })
                    }}
                    noResponse={() => {
                        setShowConfirmNotRequiredModal(false)
                    }}
                    style="basic"
                />
            )
        }
        
        {
            showConfirmSendModal && (
            <div className="removeDialogWrapper">
                <div className="removeDialog">
                    <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={() => {
                        setShowConfirmSendModal(false);
                    }} />
                    <br />
                    <h3 className="text-white text-center font-weight-bold mb-4">Are you sure you want to change the status?</h3>
                    <MDBRow center className="mb-4 mobile-row">
                        <MDBCol size="6">
                            <FormButton
                            isLoading={loading}
                            onClickFunc={()  => 
                            {
                            sendToShippingFunc({
                                modifiedFFL: itemToEdit,
                                isEdit: true,
                                oneTimeChange: null,
                                fflNotRequired: false
                            })}} type="submit">YES</FormButton>
                        </MDBCol>
                        <MDBCol size="6">
                            <FormButton greyCol={true} onClickFunc={() => {
                                setShowConfirmSendModal(false);
                            }}>NO</FormButton>
                        </MDBCol>
                    </MDBRow>
                </div>
            </div>)
        }



            { isMobile ?
                <Fragment>
                    <MDBRow className="table-row" style={ setGreen(item) }>
                        <MDBCol size="6" middle className="text-center wrapped">
                            { item && item.product_name }
                        </MDBCol>
                        <MDBCol size="6" middle className="text-center wrapped">
                            { item && item.buyer_name }
                        </MDBCol>
                        <MDBCol size="1" middle className="text-center">
                            <button className="openBtn" onClick={()=> {
                                setIsOpenTable(true);                            
                            }}><MDBIcon icon="plus"/></button>
                        </MDBCol>  
                    </MDBRow>
                    { isOpenTable &&
                        <Fragment>
                            <MDBRow className="table-row mobile-opend-table">                    
                                <MDBCol size="10" middle className="text-center text-white">Close</MDBCol>
                                <MDBCol size="2" middle className="text-center">
                                    <button className="openBtn" onClick={()=> {
                                        setIsOpenTable(false);
                                    }}>
                                        <MDBIcon icon="minus"/>
                                    </button>
                                </MDBCol>                    
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">               
                                <MDBCol size="4" middle className="text-center text-white">UPC</MDBCol> 
                                <MDBCol size="8" middle className="text-center wrapped">
                                    <LongItemWithTooltip text={ item?.upc_code ? item.upc_code : 'unassigned'} />
                                </MDBCol>               
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">               
                                <MDBCol size="4" middle className="text-center text-white">Name</MDBCol> 
                                <MDBCol size="8" middle className="text-center wrapped">
                                    <LongItemWithTooltip text={ item && item.product_name} />
                                </MDBCol>               
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">                    
                                <MDBCol size="4" middle className="text-center text-white">Buyer's Name</MDBCol> 
                                <MDBCol size="8" middle className="text-center wrapped">
                                    <LongItemWithTooltip text={ item && item.buyer_name} />
                                </MDBCol>               
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">                    
                                <MDBCol size="4" middle className="text-center text-white">Buyer's Email</MDBCol> 
                                <MDBCol size="8" middle className="text-center email-col wrapped">
                                    { item && item.buyer_email}
                                </MDBCol>               
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">                    
                                <MDBCol size="4" middle className="text-center text-white">Sold Date</MDBCol> 
                                <MDBCol size="8" middle className="text-center">
                                { item && item.date_purchased && (
                                    moment(item?.date_purchased).format('MM/DD/YYYY')
                                )}
                                </MDBCol>               
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="4" middle className="text-center text-white">Price</MDBCol>
                                <MDBCol size="8" className="text-center">
                                    { item && item.price && fixNumber(item.price) }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="4" middle className="text-center text-white">Units</MDBCol>
                                <MDBCol size="8" className="text-center">
                                    { item && item.units }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="4" middle className="text-center text-white">FFL SELECTED</MDBCol>
                                <MDBCol size="8" className="text-center">
                                    { getFFLStatus(item) }
                                </MDBCol>
                            </MDBRow>
                            {
                                !item.ffl_assignment_completed && (
                                    <>
                                        <MDBRow className="table-row mobile-opend-table">                    
                                            <MDBCol size="4" middle className="text-center text-white">Edit FFL</MDBCol> 
                                            <MDBCol size="8" middle className="text-center">
                                            <div className="fas fa-pencil-alt" onClick={() => {
                                                setShowModal(true)
                                                setItemToEdit(item)
                                                setItemToEditFunc(item)
                                            }}> </div>
                                            </MDBCol>               
                                        </MDBRow>
                                        <MDBRow className="table-row mobile-opend-table">                    
                                            <MDBCol size="4" middle className="text-center text-white">Send to Shipping</MDBCol> 
                                            <MDBCol size="8" middle className="text-center">
                                                <button 
                                                    title="You must select a valid FFL."
                                                    className="streamBtnShip" onClick={()=>{ 
                                                        setItemToEdit(item)
                                                        setItemToEditFunc(item)
                                                        console.log("item", item);
                                                        if(item?.ffl_id || item?.assigned_ffl_id || item?.ffl_not_required || item?.buyer?.ffl_scope_id){
                                                            setShowConfirmSendModal(true)
                                                        } else{
                                                            setValidationSendToShipping(true)
                                                        }
                                                    }}>Send to shipping
                                                </button>
                                            </MDBCol>               
                                        </MDBRow>
                                        <MDBRow className="table-row mobile-opend-table">                    
                                            <MDBCol size="4" middle className="text-center text-white">FFL Not Required</MDBCol> 
                                            <MDBCol size="8" middle className="text-center">
                                                <button className="streamBtnShip" onClick={()=>{ 
                                                    setShowConfirmNotRequiredModal(true)
                                                    setItemToEdit(item)
                                                }}>FFL Not Required</button>
                                            </MDBCol>               
                                        </MDBRow>
                                    </>
                                )
                            }
                        </Fragment>
                    }
                </Fragment>
            :
                <MDBRow className="table-row" style={setGreen(item)}>
                    <MDBCol size='1' className="wrapped text-center">
                        <LongItemWithTooltip text={ item?.upc_code ? item.upc_code : 'unassigned'} maxlenght={10}/>
                    </MDBCol>
                    <MDBCol size="2" className="wrapped text-center">
                        <LongItemWithTooltip text={ item && item?.product_name } />
                    </MDBCol>
                    <MDBCol size="1" className="text-center wrapped">
                        <LongItemWithTooltip text={ item && item.buyer_name } />
                    </MDBCol>
                    <MDBCol size="1" className="text-center">
                        { item && item.date_purchased && (
                            moment(item?.date_purchased).utc().format('MM/DD/YYYY')
                        )}
                    </MDBCol>  
                    <MDBCol size="1 text-center">
                        { item && item.bought_price && fixNumber(item.bought_price) }
                    </MDBCol>
                    <MDBCol size="1 text-center">
                        { item && item.sold_price && fixNumber(item.sold_price) }
                    </MDBCol>
                    <MDBCol size="1 text-center">
                        { item && item.units }
                    </MDBCol>
                    <MDBCol size="1" className="text-center">
                        { getFFLStatus(item) }
                    </MDBCol>
                    {
                        !item.ffl_assignment_completed && (
                            <>
                            <MDBCol size="1" className="text-white text-center">
                                <div className="fas fa-pencil-alt" onClick={() => {
                                    setShowModal(true)
                                    setItemToEdit(item)
                                    setItemToEditFunc(item)
                                }}> </div>
                            </MDBCol>
                            <MDBCol size="1" className="text-center">
                                <button 
                                    title="You must select a valid FFL."
                                    className="streamBtnShip" onClick={()=>{ 
                                        setItemToEdit(item)
                                        setItemToEditFunc(item)
                                        console.log("item", item);
                                        if(item?.ffl_id || item?.assigned_ffl_id || item?.ffl_not_required || item?.buyer?.ffl_scope_id){
                                            setShowConfirmSendModal(true)
                                        } else{
                                            setValidationSendToShipping(true)
                                        }
                                    }}>Send to shipping
                                </button>
                            </MDBCol>
                            <MDBCol size="1" className="text-center">
                                <button className="streamBtnShip" onClick={()=>{ 
                                    setShowConfirmNotRequiredModal(true)
                                    setItemToEdit(item)
                                }}>FFL Not Required</button>
                            </MDBCol>
                        </>
                        )
                    }
                </MDBRow>
            }
        </Fragment>
    )
}

const MapDispatchToProps = dispatch => ({
    addFFLItem: addFFLItem(dispatch),
    loadPage: flag => dispatch(loadPage(flag)) 
})

export default connect(null, MapDispatchToProps)(OfflineSaleItemRow);
