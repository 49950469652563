import { DashboardActionTypes } from './dashboard.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getCurrentRevenueGraph = dispatch => async ( startTime, endTime, unit, prod_type, graphType) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/analytics/${graphType}?startTime=${startTime}&endTime=${endTime}&unit=${unit}&product_type=${prod_type}`, { 
            headers: (await getMyTokenFunc())
        });
        // dispatch(setCurrentRevenue(result.data));
        return result.data;

    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const getCurrentMemberGraph = dispatch => async ( startTime, endTime, unit) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/analytics/activemembers?startTime=${startTime}&endTime=${endTime}&unit=${unit}`, { 
            headers: (await getMyTokenFunc())
        });
        // dispatch(setCurrentRevenue(result.data));
        return result.data;

    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const getRegisteredMemberGraph = dispatch => async ( startTime, endTime, unit) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/analytics/newmembers?startTime=${startTime}&endTime=${endTime}&unit=${unit}`, { 
            headers: (await getMyTokenFunc())
        });
        // dispatch(setCurrentRevenue(result.data));
        return result.data;

    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const getCurrentCategoryGraph = dispatch => async ( startTime, endTime, unit, category_id) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/analytics/categorysale?startTime=${startTime}&endTime=${endTime}&unit=${unit}&category_id=${category_id}`, { 
            headers: (await getMyTokenFunc())
        });
        // dispatch(setCurrentRevenue(result.data));
        return result.data;

    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

const setCurrentRevenue = list => ({
    type: DashboardActionTypes.SET_CURRENT_REVENUE,
    payload: list
});
