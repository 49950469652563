import CompletedWebinarActionTypes from './completed-webinar.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getProductItems = dispatch => async ( filterStr = null, limit = 10, offset = 0,ffl='') => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/webinar/complete?filterString=${filterStr}&limit=${limit}&offset=${offset}&ffl=${ffl}`, { 
            headers: (await getMyTokenFunc())
        });
     
        dispatch(setProductItems(result.data));
        return result.data

    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}
export const getCountCompleteWebinar =  dispatch => async () => {
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/getCompleteWebinarCount`, { 
            headers: (await getMyTokenFunc())
        });
       dispatch(setCountWebinars(result.data.count[0].count ));
        } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const getEmailList = dispatch => async (webinar_id) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/webinar_emails?webinar_id=${webinar_id}`, { 
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        return error.response.data;       
    }
}

export const getAllUsers = dispatch => async () => {

    try {
        const result = await axios.delete( RestAPI.ORIGINAL_ENDPOINT + `admin/users-management`, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const changeWebinarLink = dispatch => async ( id, link ) => {
    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/products/webinar_link?webinar_id=${id}&webinar_link=${link}`, {}, { 
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        return error.response.data;
    }
}

export const sendToShipping = dispatch => async ( obj ) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/products/webinar/complete/ffl`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);
        return error.response.data.message;
    }
}

export const updateUserFFLPermanent = dispatch => async ( obj ) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/products/webinar/complete/ffl/permanent`, obj, { 
            headers: (await getMyTokenFunc())
        });
        console.log("updateUserFFLPermanent", result);
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const addFFLItem = dispatch => async ( obj ) => {
    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/products/webinar/complete/ffl`, obj, { 
            headers: (await getMyTokenFunc())
        });
        console.log(result.data);
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const setFilterString = filterStr => ({
    type: CompletedWebinarActionTypes.SET_FILTER_STRING,
    payload: filterStr
})

export const setLimitPerPage = limit => ({
    type: CompletedWebinarActionTypes.SET_LIMIT_PER_PAGE,
    payload: limit
})

export const setProductPageNum = num => ({
    type: CompletedWebinarActionTypes.SET_PRODUCT_LIST_PAGE_NUM,
    payload: num
})
export const setCountWebinars= items => ({
    type: CompletedWebinarActionTypes.SET_PRODUCT_LIST_COMPLETE,
    payload: items
})

export const setProductItems = item => ({
    type: CompletedWebinarActionTypes.SET_PRODUCT_LIST_ITEMS,
    payload: item
})

export const setFFLFilter = check => ({
    type: CompletedWebinarActionTypes.SET_FFL_FILTER,
    payload: check
})



