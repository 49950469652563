import React, {useState, useEffect, Fragment} from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { useAlert } from 'react-alert';
import './item-row.style.scss';
import { connect } from 'react-redux';
import { loadPage } from '../../../redux/user/user.action';
import FormCheckbox from '../../../components/form-checkbox/form-checkbox.component';

const SeatRefundItemRow = ({id, item, isMobile, price_per_seat, loadPage, addToDelList, removeFromDelList, isAllChecked }) => {

    const alert = useAlert();
 
    // const getCustomDate = () => {
    //     const customDate = new Date(item.createdAt);
    //     Date.shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    //     return Date.shortMonths[customDate.getMonth()] + " " + customDate.getDate() + ", " + customDate.getFullYear();
    // }

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(isAllChecked);
    }, [isAllChecked]);

    useEffect(() => {
        if (isChecked)
            addToDelList(item.seatNo);
        else
            removeFromDelList(item.seatNo);
    }, [isChecked]);

    const [isOpenTable, setIsOpenTable] = useState(false);

    return (
        <Fragment>
        {
            isMobile ? <Fragment>
            <MDBRow className="table-row">
                <MDBCol size="1">
                    <FormCheckbox Notif={ isChecked } handleChange = { () => setIsChecked(!isChecked) } />
                </MDBCol>
                <MDBCol size="4" className="text-center">
                    { item && item.seatNo + 1}
                </MDBCol>
                <MDBCol size="5" className="text-center">
                    {item && item.user && item.user.username}
                </MDBCol>
                <MDBCol size="2" middle className="text-center">
                    <button className="openBtn" onClick={()=>setIsOpenTable(true)}><MDBIcon icon="plus"/></button>
                </MDBCol>  
            </MDBRow>
            {
                isOpenTable && <Fragment>
                    <MDBRow className="table-row mobile-opend-table">               
                        <MDBCol size="5" middle className="text-center text-white">Seat No.</MDBCol> 
                        <MDBCol size="5" middle className="text-center">
                        { item && item.seatNo + 1}
                        </MDBCol>
                        <MDBCol size="2" middle className="text-center">
                            <button className="openBtn" onClick={()=>setIsOpenTable(false)}><MDBIcon icon="minus"/></button>
                        </MDBCol>                    
                    </MDBRow>
                    <MDBRow className="table-row mobile-opend-table">               
                        <MDBCol size="5" middle className="text-center text-white">User Name</MDBCol> 
                        <MDBCol size="7" middle className="text-center">
                            {item && item.user && item.user.username}
                        </MDBCol>               
                    </MDBRow>
                    <MDBRow className="table-row mobile-opend-table">                    
                        <MDBCol size="5" middle className="text-center text-white">User Email</MDBCol> 
                        <MDBCol size="7" middle className="text-center email-col">
                            {item && item.user && item.user.email}
                        </MDBCol>               
                    </MDBRow>
                    <MDBRow className="table-row mobile-opend-table">                    
                        <MDBCol size="5" middle className="text-center text-white">Price per seat</MDBCol> 
                        <MDBCol size="7" middle className="text-center">
                            ${price_per_seat}
                        </MDBCol>               
                    </MDBRow>
                </Fragment>
            }
            </Fragment>           
            :   
            <MDBRow className="table-row">
            
                <MDBCol size="1">
                    <FormCheckbox Notif={ isChecked } handleChange = { () => setIsChecked(!isChecked) } />
                </MDBCol>
                <MDBCol size="2" className="text-center">
                    { item && item.seatNo + 1}
                </MDBCol>
                <MDBCol size="3" className="text-center">
                    {item && item.user && item.user.username}
                </MDBCol>
                <MDBCol size="3" className="text-center">
                    {item && item.user && item.user.email}
                </MDBCol>
                <MDBCol size="3" className="text-center">
                    ${price_per_seat}
                </MDBCol>
            </MDBRow>
        }
    </Fragment>
    )
}

const MapDispatchToProps = dispatch => ({
    loadPage: flag => dispatch(loadPage(flag)) 
})

export default connect(null, MapDispatchToProps)(SeatRefundItemRow);