import {combineReducers} from 'redux';
import UserReducer from './user/user.reducer';
import DashboardReducer from './dashboard/dashboard.reducer';
import CategoryReducer from './category/category.reducer';
import ColorChangeReducer from './colorChnage/colorChange.reducer';
import ProductListItemsReducer from './product-list/product-list.reducer';
import WebinarQueueListItemsReducer from './webinar-queue-list/webinar-queue-list.reducer';
import SoldOutWebinarsReducer from './sold-out-webinars/sold-out-webinars.reducer';
import AlertsReducer from './alerts/alerts.reducer';
import FFLDBListReducer from './ffl-db/ffl-db.reducer';
import LiveStreamReducer from './live-stream/live-stream.reducer';
import FaqItemsReducer from './faq-items/faq-items.reducer';
import SoldPhysicalReducer from './sold-physical/sold-physical.reducer';
import OfflineSalesReducer from './offline-sales/offline-sales.reducer';
import UserMangementReducer from './user-management/user-management.reducer';
import SeatRefundReducer from './seat-refund/seat-refund.reducer';
import ReportItemsReducer from './report/report.reducer';
import ShippingReducer from './shipping/shipping.reducer';
import CompletedWebinarReducer from './completed-webinar/completed-webinar.reducer';
import AdminRolesReducer from './admin-roles/admin-roles.reducer';
import MarkupsReducer from './markups/markups.reducer';
import ZoomMeetingsReducer from './zoom-meetings/zoom-meetings.reducer';
import SoldDistributorsProductsReducer from './sold-distributors-products/sold-distributors-products.reducer';
import FFLModificationsReducer from './ffl-modifications/ffl-modifications.reducer';

export default combineReducers({
    user: UserReducer,
    dashboard: DashboardReducer,
    category: CategoryReducer,
    productList: ProductListItemsReducer,
    webinarList: WebinarQueueListItemsReducer,
    colors: ColorChangeReducer,
    soldOutWebinarList: SoldOutWebinarsReducer,
    soldDistributorsProducts: SoldDistributorsProductsReducer,
    soldPhysical: SoldPhysicalReducer,
    offlineSales: OfflineSalesReducer,
    completeWebinar: CompletedWebinarReducer,
    FFLDBList: FFLDBListReducer,
    liveStrem: LiveStreamReducer,
    faq_items: FaqItemsReducer,
    user_management: UserMangementReducer,
    seat_refund: SeatRefundReducer,
    alerts: AlertsReducer,
    report_reducer: ReportItemsReducer,
    shipping_reducer: ShippingReducer,
    roles: AdminRolesReducer,
    markups: MarkupsReducer,
    meetings: ZoomMeetingsReducer,
    fflModification: FFLModificationsReducer
});