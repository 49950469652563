import React, { useState, useEffect, Fragment, useRef } from 'react';
import FormButton from '../../components/form-button/form-button.component';
import { MDBRow, MDBCol } from 'mdbreact';
import './ffl-database-style.scss';
import { connect, useDispatch } from 'react-redux';
import CountPerPage from '../../components/table/count-per-page/count-per-page.component';
import TableFilterInput from '../../components/table/filter-input/filter-input.component';
import PageButtons from '../../components/table/pagination/pagination.component';
import { getProductItems, setLimitPerPage, setAddNewClicked, setProductPageNum, setFilterStr } from '../../redux/ffl-db/ffl-db.action';
import { loadPage } from '../../redux/user/user.action';
import { useAlert } from 'react-alert';
import AddNewFFLDB from './add-new-ffl/add-new-ffl.component';
import FFLDBListeRow from './row-item/row-item.component';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';
import { formatDateToSearch } from '../../utils/formatDateToSearch';
import LoaderComponent from '../../components/Loader/loader.component';

const FFLDatabasePage = ({
	limitPerPage,
	pageNum,
	isAddNewClicked,
	setAddNewClicked,
	getProductItems,
	setLimitPerPage,
	isShowSideBar,
	setProductPageNum,
	filterString,
	setFilterString,
}) => {



	const alert = useAlert();

	// for row counts per page
	const countSelect = [10, 20, 30, 50];
	const [countSelectShow, setCountSelectShow] = useState(false);
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([])

	const isMounted = useRef(false);
	const isMountedAddNew = useRef(false);

	
	useEffect(() => {
		return () => {
			/* setLimitPerPage(10); */
			setFilterString("");
		}
	}, [])

	useEffect(() => {
		if(isMounted.current) {
			async function load() {
				setLoading(true)
				const result = await getProductItems(
					filterString,
					limitPerPage === "All" ? "all" : limitPerPage,
					limitPerPage === "All" ? 0 : limitPerPage * (pageNum - 1)
				);
				setProducts(result)
				setLoading(false)
			}
			load(); 
		} else {
			isMounted.current = true;
		}
	}, [pageNum]);

	useEffect(() => {
		setProductPageNum(1);
		let tempDate = formatDateToSearch(filterString)
		async function load() {
			setLoading(true)
			const result = await getProductItems(
				tempDate !== "" ? tempDate : filterString,
				limitPerPage === "All" ? "all" : limitPerPage,
				0
			);
			setProducts(result)
			setLoading(false)
		}
		load();
	}, [limitPerPage, filterString]);

	const [reloadPage, setReloadPage] = useState(false);

	useEffect(() => {
		async function load() {
			setLoading(true)
			const result = await getProductItems(
				filterString,
				limitPerPage === "All" ? "all" : limitPerPage,
				limitPerPage === "All" ? 0 : limitPerPage * (pageNum - 1)
			);
			setProducts(result)
			setLoading(false)
		}

		reloadPage && load()
	}, [reloadPage]);

	useEffect(() => {
		if(isMountedAddNew.current) {
			async function load() {
				setLoading(true)
				const result = await getProductItems(
					filterString,
					limitPerPage === "All" ? "all" : limitPerPage,
					0
				);
				setProducts(result)
				setLoading(false)
			}
			!isAddNewClicked && load();
		} else {
			isMountedAddNew.current = true;
		}
	}, [isAddNewClicked]);

	const [isMobileSize, setIsMobileSize] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 1025)
				setIsMobileSize(true);
			else
				setIsMobileSize(false);
		}
		handleResize();
		window.addEventListener('resize', handleResize);
		setIsEdit(JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'fflEdit'));
	}, []);
const loadData = async () =>{

	setLoading(true)
	const result = await getProductItems(
		filterString,
		limitPerPage === "All" ? "all" : limitPerPage,
		limitPerPage === "All" ? 0 : limitPerPage * (pageNum - 1)
	);
	setProducts(result)
	setLoading(false)
	
}
	return (
		<>
		{
				loading && (
					<LoaderComponent />
				)
			}
		<div className="ffl-db-page" style={{ width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)' }}>
			{
				isAddNewClicked ? <AddNewFFLDB />
					:
					<Fragment>
						<MDBRow className="headerRow">
						{/* 	<MDBCol size="8" sm="12" md="8" lg="8">
								<h2 className="text-white font-weight-bold">FFL Database</h2>
							</MDBCol> */}
							<MDBCol size="8" sm="12" md="8" lg="8">
								<h2 className="text-white font-weight-bold">FFL Management</h2>
							</MDBCol>
							<MDBCol size="5" sm="6" md="2" lg="2">
								{isEdit && <FormButton onClickFunc={() => setAddNewClicked(true)}>ADD NEW</FormButton>}
							</MDBCol>
							<MDBCol size="5" sm="6" md="2" lg="2">
								<h5 style={{ marginBottom: 0 }} className="text-white text-right">{`Total FFL : ${products.count}`} </h5>
							</MDBCol>
						</MDBRow>
						<div className="section">
							<div className="toolbar">
								<div className="leftDiv">
									<div>
										<CountPerPage
											options={countSelect}
											showSelectBox={() => setCountSelectShow(!countSelectShow)}
											selectOption={(event) => {
												setCountSelectShow(false);
												setLimitPerPage(event.target.id);
											}}
											optionShow={countSelectShow}
											placeholder={limitPerPage}
										/>
										<label className="ml-1">Per Page</label>
									</div>
									<TableFilterInput str={filterString} setFilterString={setFilterString} placeholder="search" />
								</div>
								<PageButtons
									count={limitPerPage === "All" ? 1 : Math.ceil(products.count / limitPerPage)}
									currentIndex={pageNum}
									setProductPageNum={setProductPageNum} />
							</div>
							{
								isMobileSize ?
									<MDBRow className="table-header">
										<MDBCol size="10" className="text-white text-center">
											FFL Name
										</MDBCol>
										{/* <MDBCol size="6" className="text-white text-center">
											FFL Number
										</MDBCol> */}
									</MDBRow>
									:
									<MDBRow className="table-header">
										<MDBCol size="1" className="text-white">
											License
										</MDBCol>
										<MDBCol size={isEdit ? '1' : '2'} className="text-white text-center">
											FFL Name
										</MDBCol>
										<MDBCol size="2" className="text-white text-center">
											Address
										</MDBCol>
										<MDBCol size="2" className="text-white text-center">
											FFL Number
										</MDBCol>

										<MDBCol size="2" className="text-white text-center">
											Expiration Date
										</MDBCol>
										<MDBCol size="1" className="text-white text-center">
											FFL Proof
										</MDBCol>
										<MDBCol size="1" className="text-white text-center">
											Contact Phone
										</MDBCol>
										{isEdit && <MDBCol size="1" className="text-white text-center">
											View/ Edit
                      					</MDBCol>}
										{isEdit && <MDBCol size="1" className="text-white text-center">
											Delete
										</MDBCol>}
									</MDBRow>
							}

							{
								products && products && products.rows && products.rows.length > 0 ? products.rows.map(item =>
									<FFLDBListeRow
										key={item.id}
										item={item}
										isMobile={isMobileSize}
										reload={setReloadPage}
										isEdit={isEdit}
										loadData={loadData}
									/>)
									:
									<div className="non-row text-center">
										No Data
									</div>
							}
						</div>
					</Fragment>
			}
		</div>
		</>
	)

}

const MapStateToProps = ({ FFLDBList: { filterString, limitPerPage, pageNum, isAddNewClicked }, user: { isShowSideBar } }) => ({
	filterString,
	limitPerPage,
	pageNum,
	isAddNewClicked,
	isShowSideBar
})

const MapDispatchToProps = dispatch => ({
	getProductItems: getProductItems(dispatch),
	loadPage: flag => dispatch(loadPage(flag)),
	setFilterString: str => dispatch(setFilterStr(str)),
	setLimitPerPage: count => dispatch(setLimitPerPage(count)),
	setAddNewClicked: flag => dispatch(setAddNewClicked(flag)),
	setProductPageNum: num => dispatch(setProductPageNum(num))
})
export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(FFLDatabasePage));