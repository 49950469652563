import { CategoryActionTypes } from './category.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getCurrentCategories = dispatch => async (getData = false, filterString="") => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/categories?filterString=${filterString}`, { 
            headers: (await getMyTokenFunc())
        });
        if (getData)
            return result.data.data;
        dispatch(setCurrentCategoris(result.data.data));

    } catch (error) {
        console.log(error?.response?.data?.message);
    }
}

export const addCategory = dispatch => async (name, type) => {

    const obj = {
        category_name: name,
        product_type: type        
    }

    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + "admin/categories", obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.data;

    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const updateCategory = dispatch => async (id, name, type) => {

    const obj = {
        category_name: name,
        product_type: type        
    }

    try {
        const result = await axios.patch( RestAPI.ORIGINAL_ENDPOINT + "admin/categories/" + id, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result;
       
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const deleteCategory = dispatch => async (list) => {

    const obj = {
        list: list
    }

    try {
        const result = await axios.delete( RestAPI.ORIGINAL_ENDPOINT + "admin/categories", { 
            data: obj,
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

const setCurrentCategoris = list => ({
    type: CategoryActionTypes.SET_CURRENT_CATEGORIES,
    payload: list
});


export const setFilterStr = filter => ({
    type: CategoryActionTypes.SET_FILTER_STRING,
    payload: filter
})