import React, { useState, useEffect, Fragment, useRef, useMemo } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import FormButton from "../../../components/form-button/form-button.component";
import { Storage } from "aws-amplify";
import { useDropzone } from "react-dropzone";
import NumberFormat from "react-number-format";
import { getImageFromS3 } from "../../../services/s3";
import { useAlert } from "react-alert";
import {
  updateFFLDate,
  deleteFFLDate,
  validateFFL,
  removeGalleryImage,
  setAsMainGalleryImage,
} from "../../../redux/ffl-db/ffl-db.action";
import LoaderComponent from "../../../components/Loader/loader.component";

import { loadPage } from "../../../redux/user/user.action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "./row-item.style.scss";
// import moment from "moment";
import moment from 'moment-timezone';
import router from "../../../router";
import Resizer from "react-image-file-resizer";
import ImageGallery from 'react-image-gallery';
import loaderComponent from "../../../components/Loader/loader.component";
import { isValidLoaExpirationDate } from "../../../utils/fflUtils";
import LongItemWithTooltip from "../../../components/tooltip/long-item-with-tooltip";

const FFLDBListeRow  = ({
  isEdit,
  item,
  isMobile,
  loadPage,
  updateFFLDate,
  validateFFL,
  deleteFFLDate,
  reload,
  removeGalleryImage,
  setAsMainGalleryImage,
  loadData
}) => {
  const alert = useAlert();
  // const [isExpired, setIsExpired] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const historyUrl = useHistory();
  const [isDeleteFFL, setIsDeleteFFL] = useState(false);
  const [galleryUrls, setGalleryUrls] = useState([]);
  const [isHead, setisHead] = useState(false);
  const [galleryItem, setGalleryItem] = useState(0);
  const [realImageUrl, setRealImageUrl] = useState(null);
  const [isShowFullSizeImage, setIsShowFullSizeImage] = useState(false);

	const hasLoaExpirationDate = useMemo(() => isValidLoaExpirationDate(item), [item])
	const expiredDate = useMemo(() => hasLoaExpirationDate? item?.loaExpirationDate : item?.expiration, [hasLoaExpirationDate, item])
	const isExpired = useMemo(() => Date.parse(expiredDate) < Date.parse(new Date()), [expiredDate])

  useEffect(() => {
    setGalleryItem(0);
  }, [isShowFullSizeImage])

  useEffect(() => {
    let galleryImgs = [];
    let galleryImg = null;
    item.ffl_image.map((urls) => {//error
      
      async function load() {
       
        if (urls.is_active){
        var imageUrl = await Storage.get(urls.ffl_url ?  checkFileType(urls.ffl_url ) : "")
        if (urls.is_head) {
          setImageUrl(imageUrl);
          setRealImageUrl(imageUrl);
        }
    
        galleryImg = {
          id: urls.id,
          original: imageUrl,
          thumbnail: imageUrl,
          is_head: urls.is_head,
          url:urls.ffl_url,
          url2: await Storage.get(urls.ffl_url),
          originalWidth: 250,
          id_fflScope: urls.id_fflScope,
          className:'custom-gallery',
        };
     
        galleryImgs.push(galleryImg);
      
      }
    }
      // if (urls.is_active == true) {
        load().then(loadImagesInGalery);
      // }
    });
    async function loadImagesInGalery() {
      
      setGalleryUrls([...galleryImgs]);

    }
    
  }, [item, loadPage]);


  function checkFileType(file) {
    var ext = file.split('.').reverse()[0]
    if(ext=='pdf')
    {
     return 'pdf.png';
    }else {return file;}     
  }
 
  const [isShowWonList, setShowWonList] = useState(false);
  const [isOpenTable, setIsOpenTable] = useState(false);
  const copyFFL = async () => {
    var copyText = item.fflNumber;
    navigator.clipboard.writeText(copyText);
  };
  const [startDate, setStartDate] = useState(
    item.expiration ? new Date(item.expiration.toString()) : new Date()
  );

  const datePickerLink = React.createRef();
  
  const updateExpirationDate = async () => {
    // moment(startDate).diff(moment(Date()), "months") < 1
    //   ? setIsExpired(true)
    //   : setIsExpired(false);
    loadPage(true);
    const result = await updateFFLDate(item.id, {
      expiration: startDate.toISOString(),
    });
    if (result === "success") {
      alert.success("Updated successfully");
      reload(true);
    } else alert.error("Failed updating");

    loadPage(false);
  };

  const closeForm = async () => {

    setIsShowFullSizeImage(false)
   
  };
  const deleteCurrentImage = async () => {
    loadPage(true);
    reload(false);
    const result = await updateFFLDate(item.id, {
      ffl_image_url: "",
      ffl_thumbnail_300: "",
      is_head: false,
    });
    if (result === "success") {
      alert.success("Deleted successfully");
      setImageUrl(null);
      setRealImageUrl(null);
      setIsShowFullSizeImage(false);
      reload(true);
    } else alert.error("Failed deleting");
    loadPage(false);
  };

  const resizeImage = (file, size) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        size,
        size,
        file.type.split("/")[1],
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
   
    accept: ".jpg, .jpeg, .png, .webp, .tiff, .tif, .gif, .svg , .pdf",
    onDrop: async (acceptedFiles) => {
      if(acceptedFiles.length>0){
      
        loadPage(true);
        reload(false);
  
        const stored = await Storage.put(
          "ffl-image-" +
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15) +
            "-" +
            acceptedFiles[0].name,
          acceptedFiles[0],
          {
            contentType: acceptedFiles[0].type,
            cacheControl: "max-age=31536000",
          }
           
        );
  
        let ffl_thumbnail_300= null
        let ffl_thumbnail_300_stored =null
        let result = null
        if(acceptedFiles[0].type!='application/pdf'){
           ffl_thumbnail_300 = await resizeImage(acceptedFiles[0], 300);
           ffl_thumbnail_300_stored = await Storage.put(
            "ffl-image-" +
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15) +
              "-" +
              ffl_thumbnail_300.name,
            ffl_thumbnail_300,
            {
              contentType: ffl_thumbnail_300.type,
              cacheControl: "max-age=31536000",
            }
          );
          result = await updateFFLDate(item.id, {
            ffl_image_url: stored.key,
            ffl_thumbnail_300: ffl_thumbnail_300_stored.key,
            is_head: isHead,
          });
        } else if(acceptedFiles[0].type=='application/pdf'){
  
          result = await updateFFLDate(item.id, {
            ffl_image_url: stored.key,
            ffl_thumbnail_300: stored.key,
            is_head: isHead,
          }); 
        }
  
        if (result === "success") {
          alert.success("Updated successfully");
          reload(true);
        } else alert.error("Failed updating");
        loadPage(false);
        setGalleryItem(0);

      }
     else {
      alert.error('Please enter a valid document')
     }
    },
  });

  const deleteFFLFunc = async (event) => {
    event.preventDefault();
    // event.preventDefault();
    loadPage(true);
    const result = await deleteFFLDate(item.id);
    if (result === "success") {
      alert.success("Deleted successfully");
      reload(true);
    } else alert.error("Failed deleting");

    loadPage(false);
    setIsDeleteFFL(false);
  };

  const [FFLNumer, setFFLNumber] = useState(item?.fflNumber);
  const [invalidFFL, setInvalidFFL] = useState(!item?.fflNumber);

  const replaceImage = async () => {
     if (galleryUrls.length>0){

       var image = galleryUrls[galleryItem];
    loadPage(true);

    const result = await setAsMainGalleryImage(image.id, image.id_fflScope);
    if (result === "success") {
      alert.success("Image setted as main successfully");
      setGalleryUrls(galleryUrls);
      await loadData();
    } else alert.error("setted as main failed");
    loadPage(false);

     }
   else{
      alert.error("You don't have any imaage")
    }
   
  };

  const removeImage = async () => {
    if (galleryUrls.length>0){
      var image = galleryUrls[galleryItem];
    loadPage(true);
    const result = await removeGalleryImage(image.id,image.url);

    
    if (result === "success") {
      alert.success("Removed successfully");
      reload(true);
      setGalleryItem(0);
      if (image.is_head){
        setImageUrl(null);
        setRealImageUrl(null);
      }

      setGalleryUrls(galleryUrls.filter((item) => item.id !== image.id));
      
      await loadData();
    } else alert.error("Removing failed");
    loadPage(false);
    }
    else{
      alert.error("You don't have any imaage")
    }
    
  };

  const changeFFLNumber = async () => {
    setInvalidFFL(false);

    if (FFLNumer.trim() === "") {
      setInvalidFFL(true);
      return;
    }

    loadPage(true);
    const fflCheckedResult = await validateFFL({ ffl_number: FFLNumer });
    if (!fflCheckedResult.isValid) {
      loadPage(false);
      return alert.error("FFL Number Not Found");
    } else if (fflCheckedResult.isExpired) {
      loadPage(false);
      return alert.error(
        "FFL License is expired! Exp. Date: " + fflCheckedResult.expirationDate
      );
    }
    setStartDate(new Date(fflCheckedResult.expirationDate));

    const result = await updateFFLDate(item.id, {
      fflNumber: FFLNumer,
      expiration: new Date(fflCheckedResult.expirationDate),
    });
    if (result === "success") {
      // setIsExpired(false);
      alert.success("Updated successfully");
      reload(true);
    } else alert.error("Failed updating");
    loadPage(false);
  };

  const handleSlide = (index) => {
    setGalleryItem(index); 
  }

  const tableRowClasses = useMemo(() => {
    let classes = 'table-row'
    if (!item.transfer_flag) {
      classes = classes.concat(' table-no-transfer')
    }
    return classes
  }, [item.transfer_flag])

  return (
    <Fragment>
      <div className="row-item" >
        <Fragment>
          {isDeleteFFL && (
            <div className="removeDialogWrapper">
              <div className="removeDialog">
                {/* <form onSubmit={deleteFFLFunc}> */}
                <form onSubmit={deleteFFLFunc}> 
                  <MDBIcon
                    className="float-right text-white closeIcon"
                    icon="times"
                    onClick={() => setIsDeleteFFL(false)}
                  />
                  <br />
                  <h3 className="text-white text-center font-weight-bold mb-4" style={{marginTop:isMobile? '5%%':''}}>
                    Are you sure to delete?
                  </h3>
                  <MDBRow center className="mb-4 mobile-row">
                    <MDBCol size="6">
                      <FormButton type="submit">YES</FormButton>
                    </MDBCol>
                    <MDBCol size="6">
                      <FormButton
                        greyCol={true}
                        onClickFunc={() => setIsDeleteFFL(false)}
                      >
                        NO
                      </FormButton>
                    </MDBCol>
                  </MDBRow>
                </form>
              </div>
            </div>
          )}
          {isShowFullSizeImage && item.fflNumber != "" && (
            <div className="full-size-img-wrapper" style={{padding:isMobile? 'unset':'inherit'}}>
              <div className="full-size-img" style={{height:isMobile? '60%':'80%'}}>
              
              
              <MDBCol className= "content-col">
          
              <div class='content-images'>
                <div class={isMobile ? 'one-mobile' : 'one'}>
                <MDBIcon
                  className="float-right closeIcon"
                  icon="times"
                  onClick={() => closeForm()}
                />
                
                <ImageGallery  onSlide={handleSlide}       showFullscreenButton={false} items={galleryUrls} onClick={(index)=>{
                  window.open(galleryUrls[galleryItem].url2, '_blank');
                  }}  showIndex={true} style={{marginBottom:isMobile? '-8%':''}} /> 
              </div>
              
                <div class='two'>
                    <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    {isMobile ? (<br /> ,<br />,<br />,<br />): ''}

                    <label  style={{marginBottom:isMobile? '-2%':''}}>
                        <span>Add New File</span> (JPEG, PNG, WEBP, TIF, GIF, SVG, PDF)
                    </label>
                  </div>
                  </div >
            
                  <div  class='three'>
                    <MDBIcon far icon="check-circle" className="checkIcon" onClick={() =>replaceImage()} />
                  </div >
                  <div  class='four'>
                  <MDBIcon far icon="times-circle" className="removeIcon" onClick={() => removeImage()} />
                  </div >
        
                </div>

                
              </MDBCol>   
            
            
            </div>
          </div>
          
          )}
          {isMobile ? (
            <Fragment>
              <MDBRow className={tableRowClasses}>
                <MDBCol size="10" middle className="text-center">
                <LongItemWithTooltip text={(item.businessName !=null && item.businessName != '') ? item.businessName : item.licenseName && item.licenseName} maxlenght={25} />
                </MDBCol>

                <MDBCol size="2" middle className="text-center">
                  <button
                    className="openBtn"
                    onClick={() => {
                      setIsOpenTable(true);
                    }}
                  >
                    <MDBIcon icon="plus" />
                  </button>
                </MDBCol>
              </MDBRow>
              {isOpenTable && (
                <Fragment>
                  <MDBRow className="table-row mobile-opend-table">
                    <MDBCol size="3" middle className="text-center text-white">
                      License
                    </MDBCol>
                    <MDBCol
                      size="1"
                      middle
                      className="text-center"
                      onClick={() => closeForm()}
                    >
                        </MDBCol>
                    <>
                                {console.log("isShowFullSizeImage."+isShowFullSizeImage)}
                                </>

                                <>
                                {console.log(" item.fflNumber ."+ item.fflNumber )}
                                </>


                    <MDBCol size="6" onClick={() => setIsShowFullSizeImage(true)}>
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt={item.name}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <div className="non-img">
                          <MDBIcon far icon="file-image" />
                        </div>
                      )}

                    </MDBCol>
          
                    <MDBCol size="2" middle className="text-center">
                      <button
                        className="openBtn"
                        onClick={() => {
                          setIsOpenTable(false);
                          setShowWonList(false);
                        }}
                      >
                        <MDBIcon icon="minus" />
                      </button>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="table-row mobile-opend-table">
                    <MDBCol size="3" middle className="text-center text-white">
                      FFL Name
                    </MDBCol>
                    <MDBCol size="9" middle className="text-center">
                      <LongItemWithTooltip text={(item.businessName !=null && item.businessName != '') ? item.businessName : item.licenseName && item.licenseName} maxlenght={30}/>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="table-row mobile-opend-table">
                    <MDBCol size="3" middle className="text-center text-white">
                      Address
                    </MDBCol>
                    <MDBCol size="9" middle className="text-center">
                      {
                        item.premiseState? 
                        `${item.premiseStreet}, ${item.premiseCity}, ${item.premiseState}, ${item.premiseZipCode}`
                        : 
                        `${item.premiseStreet}, ${item.premiseCity}, ${item.premiseZipCode}`
                      }
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="table-row mobile-opend-table">
                    <MDBCol size="4" middle className="text-center text-white">
                      FFL NUMBER
                    </MDBCol>
                    <MDBCol size="6" className="text-center email-col">
                      {item.fflNumber && item.fflNumber}
                      <MDBIcon
                        far
                        icon="fa-thin fa-copy"
                        onClick={() => copyFFL()}
                      />
                    </MDBCol>
                    
                  </MDBRow>
                  <MDBRow className="table-row mobile-opend-table">
                    <MDBCol size="3" middle className="text-center text-white">
                      Expiration Date
                    </MDBCol>
                    <MDBCol
                      className={`table-expired ${isExpired ? "expired" : ""}`}
                    >
                      {item?.expiration !== "" && item?.expiration !== null
                        ? moment(
                            item?.ffl_databases?.expiration.substring(0, 10)
                          ).format("MM/DD/YYYY")
                        : ""}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="table-row mobile-opend-table">
                    <MDBCol size="3" middle className="text-center text-white">
                      FFL Proof
                    </MDBCol>
                    <MDBCol size="9" className="text-center email-col">
                      <a
                        title="proof"
                        href={item?.fflVerificationProof}
                        target="_blank"
                      >
                        {" "}
                        <img alt="FFL Proof" />
                      </a>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="table-row mobile-opend-table">
                    <MDBCol size="3" middle className="text-center text-white">
                      Contact Phone
                    </MDBCol>
                    <MDBCol size="9" middle className="text-center">
                      {item.voiceTelephone && (
                        <span>
                        ({item.voiceTelephone.slice(0, 3)}){" "}
                        {item.voiceTelephone.slice(3, 6)}-
                        {item.voiceTelephone.slice(6)}
                      </span>
                      )}
                    </MDBCol>
                  </MDBRow>

                  {isEdit && (
                    <MDBRow className="table-row mobile-opend-table">
                      <MDBCol size="3" middle className="text-center text-white">
                        View/ Edit
                      </MDBCol>
                      <MDBCol size="9" middle className="text-center">
                        <i
                          class="fas fa-pencil-alt"
                          onClick={() =>
                            historyUrl.push(router.editFfl.path, { item: item })
                          }
                        ></i>
                      </MDBCol>
                    </MDBRow>
                  )}
                  {isEdit && (
                    <MDBRow className="table-row mobile-opend-table">
                      <MDBCol size="3" middle className="text-center text-white">
                        Delete
                      </MDBCol>
                      <MDBCol size="9" middle className="text-center">
                        <button onClick={() => setIsDeleteFFL(true)}>
                          <MDBIcon icon="fas fa-trash-alt" />
                        </button>
                      </MDBCol>
                    </MDBRow>
                  )}
                
                </Fragment>
              )}
            </Fragment>
          ) : (
            <MDBRow className={tableRowClasses}>
              <MDBCol size="1" onClick={() => setIsShowFullSizeImage(true)}>
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={item.name}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <div className="non-img">
                    <MDBIcon far icon="file-image" />
                  </div>
                )}
              </MDBCol>
              <MDBCol
                size={isEdit ? "1" : "2"}
                className="text-center"
                onClick={() => setShowWonList(!isShowWonList)}
              >
                <LongItemWithTooltip text={(item.businessName !=null && item.businessName != '') ? item.businessName : item.licenseName && item.licenseName} maxlenght={30}/>
              </MDBCol>
              <MDBCol
                size="2"
                className="text-center"
                onClick={() => setShowWonList(!isShowWonList)}
              >
                {
                  item.premiseState? 
                  `${item.premiseStreet}, ${item.premiseCity}, ${item.premiseState}, ${item.premiseZipCode}`
                  : 
                  `${item.premiseStreet}, ${item.premiseCity}, ${item.premiseZipCode}`
                }
              </MDBCol>

              <MDBCol size="2" className="text-center email-col">
                {item.fflNumber && item.fflNumber}
                <MDBIcon far icon="fa-thin fa-copy" onClick={() => copyFFL()} />
              </MDBCol>
              

              <MDBCol
                size="2"
                className={`table-expired ${isExpired ? "expired" : ""}`}
              >
                {!!expiredDate
                  ? moment(expiredDate.substring(0, 10)).format("MM/DD/YYYY")
                  : ""}
              </MDBCol>

              <MDBCol size="1" className="text-left email-col">
                <a
                  title="proof"
                  href={item?.fflVerificationProof}
                  target="_blank"
                >
                  {" "}
                  FFL Proof
                </a>
              </MDBCol>

              <MDBCol
                size="1"
                className="text-center"
                onClick={() => setShowWonList(!isShowWonList)}
              >
                {item.voiceTelephone && (
                  <span>
                    ({item.voiceTelephone.slice(0, 3)}){" "}
                    {item.voiceTelephone.slice(3, 6)}-
                    {item.voiceTelephone.slice(6)}
                  </span>
                )}
              </MDBCol>
              {isEdit && (
                <MDBCol
                  size="1"
                  className="text-center"
                  onClick={() =>
                    historyUrl.push(router.editFfl.path, { item: item })
                  }
                >
                  <i class="fas fa-pencil-alt"></i>
                </MDBCol>
              )}
              {isEdit && (
                <MDBCol
                  size="1"
                  className="text-center"
                  onClick={() => setIsDeleteFFL(true)}
                >
                  <i class="fas fa-trash-alt"></i>
                </MDBCol>
              )}
            </MDBRow>
          )}
            {
                  isShowWonList && <Fragment>
                      <MDBRow className="ffl-row">
                          <MDBCol size="2">
                              Won Item
                          </MDBCol>
                          <MDBCol size="3" className="text-center">
                              Contact Name
                          </MDBCol>
                          <MDBCol size="3" className="text-center">
                              Contact Phone
                          </MDBCol>
                          
                      </MDBRow>
                      {
                          item.winners.length > 0 ? item.winners.map(wonItem =>
                              <MDBRow className="ffl-row bottom-border">
                                  <MDBCol size="2">
                                      {wonItem.webinar.name && wonItem.webinar.name}
                                  </MDBCol>
                                  <MDBCol size="3" className="text-center">
                                      {wonItem.user_data.username && wonItem.user_data.username}
                                  </MDBCol>
                                  <MDBCol size="3" className="text-center">
                                      {wonItem.user_data.phone_number && <span>({wonItem.user_data.phone_number.slice(2, 5)}) {wonItem.user_data.phone_number.slice(5, 8)}-{wonItem.user_data.phone_number.slice(8)}</span>}
                                  </MDBCol>
                                  <MDBCol size="4" className="text-center email-col">
                                      {wonItem.user_data.email && wonItem.user_data.email}
                                  </MDBCol>
                              </MDBRow>
                          )
                              :
                              <div className="non-ffl-row text-center">
                                  No Won Items
                          </div>
                      }

                      <MDBRow className="ffl-row">
                          <MDBCol size="3">
                              Purchased Item
                      </MDBCol>
                          <MDBCol size="3" className="text-center">
                              Contact Name
                      </MDBCol>
                          <MDBCol size="3" className="text-center">
                              Contact Phone
                      </MDBCol>
                          <MDBCol size="3" className="text-center">
                              Contact Email
                      </MDBCol>
                      </MDBRow>
                      {
                          item.ffl_products.length > 0 ? item.ffl_products.map(purcahseItem =>
                              <MDBRow key={Math.random()} className="ffl-row bottom-border">
                                  <MDBCol size="3">
                                      {purcahseItem.productInfo && purcahseItem.productInfo.productName}
                                  </MDBCol>
                                  <MDBCol size="3" className="text-center">
                                      {purcahseItem.buyer && purcahseItem.buyer.username}
                                  </MDBCol>
                                  <MDBCol size="3" className="text-center">
                                      {purcahseItem.buyer && <span>({purcahseItem.buyer.phone_number.slice(2, 5)}) {purcahseItem.buyer.phone_number.slice(5, 8)}-{purcahseItem.buyer.phone_number.slice(8)}</span>}
                                  </MDBCol>
                                  <MDBCol size="3" className="text-center email-col">
                                      {purcahseItem.buyer && purcahseItem.buyer.email}
                                  </MDBCol>
                              </MDBRow>
                          )
                              :
                              <div className="non-ffl-row text-center">
                                  No Won Items
                      </div>
                      }
                  </Fragment>
              } 
        </Fragment>
      </div>
    </Fragment>
   
  );
};

const MapDispatchToProps = (dispatch) => ({
  updateFFLDate: updateFFLDate(dispatch),
  deleteFFLDate: deleteFFLDate(dispatch),
  removeGalleryImage: removeGalleryImage(dispatch),
  setAsMainGalleryImage: setAsMainGalleryImage(dispatch),
  validateFFL: validateFFL(dispatch),
  loadPage: (flag) => dispatch(loadPage(flag)),
});
export default connect(null, MapDispatchToProps)(FFLDBListeRow);
