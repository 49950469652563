import React, {useState, useEffect, Fragment} from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { Storage } from 'aws-amplify';
import { useAlert } from 'react-alert';
import './sold-distributors-products.style.scss';
import Select from 'react-select';
import { connect } from 'react-redux';
import { addFFLItem, getDistributorsProductItemsCount } from '../../../redux/sold-distributors-products/sold-distributors-products.action';
import { loadPage } from '../../../redux/user/user.action';
import { useHistory } from 'react-router-dom';
import router from '../../../router';
import FormButton from '../../../components/form-button/form-button.component';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import moment from 'moment-timezone';

const getFflBgColor = fflItem => {
	const expDate = new Date(fflItem.expiration);
	const now = new Date(Date.now());
	const isExpired = expDate < now;
	return isExpired ? red : white;
}
const red = '#912c2c'
const white = 'white'


const SoldDistributorsProductsRow = ({isEdit, item, isMobile, ffl, addFFLItem, loadPage,getDistributorsProductItemsCount, setShowModal, setLoading, setItemToEditFunc, isLoading, loadParent }) => {

    const alert = useAlert();
    const historyUrl = useHistory();

    const [fflList, setFflList] = useState([]);
    const [selectedFflItem, setSelectedFflItem] = useState(null);
    const [validationSendToShipping, setValidationSendToShipping] = useState(false);
    const [showConfirmSendModal, setShowConfirmSendModal] = useState(false);
    const [showEditOptions, setShowEditOptions] = useState(false);
    const [showConfirmNotRequiredModal, setShowConfirmNotRequiredModal] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);

    const setAllStatesToFalse = () => {
        setShowConfirmSendModal(false);
        setShowConfirmNotRequiredModal(false);
        setShowEditOptions(false);
        setValidationSendToShipping(false);
    }


	const dropDownCustomStyles = {
		option: (styles, { data }) => ({
			...styles,
			backgroundColor: data.bgColor,
			color: data.bgColor === red ? white : undefined,
			borderBottom: '1px solid #c0c0c0',
		}),
	}
    

    useEffect(() => {
        if ( ffl && (ffl.length > 0)) {
            let tempArray = [];

            item?.buyer?.ffl_scope_id && tempArray.push({
                value: item.buyer.ffl_scope_id,
                label: <p style={{margin: '0', color: 'blue', fontWeight: '700'}}>{ffl.find( el => el.id === item.buyer.ffl_scope_id)?.licenseName}</p>,
                expDate: ffl.find(el => el.id === item.buyer.ffl_scope_id)?.expiration
            });

            tempArray.push({
                value: "non-ffl",
                label: "FFL not required"
            });

            if (item?.buyer?.ffl_scope_id)
                ffl.map(el => {
                    el.id !== item.buyer.ffl_scope_id && tempArray.push({
                        value: el.id, 
                        label: el.businessName || el.licenseName,
                        bgColor: getFflBgColor(el),
						expDate: el.expiration
                    });
                });
            else
                ffl.map(el => {
                    tempArray.push({
                        value: el.id, 
                        label: el.businessName || el.licenseName,
                        bgColor: getFflBgColor(el),
						expDate: el.expiration
                    });
                });
            setFflList([...tempArray]);
        }
        if (item && item.ffl_database && item.ffl_database.id) {
            setSelectedFflItem({
                value: item.ffl_database.id,
                label: item.ffl_database.businessName || item.ffl_database.licenseName
            })
        } else if(item && item?.assigned_ffl && item?.assigned_ffl?.id){
            setSelectedFflItem({
                value: item?.assigned_ffl?.id,
                label: item?.assigned_ffl?.businessName || item?.assigned_ffl?.licenseName
            })
        }

        if (item && item.ffl_not_required) {
            setSelectedFflItem({
                value: "non-ffl",
                label: "FFL not required"
            })
        }
    }, [item]);

    
    const [imageUrl, setImageUrl] = useState(null);
    useEffect(() => {
        async function load() {
            switch (item.product_type) {
                case "lipsey":
                    setImageUrl(`https://www.lipseyscloud.com/images/${item?.productInfo?.primary_image_id}`);
                    break;
                default:
                    setImageUrl(item?.productInfo?.primary_image_id);
                    break;
            }            
        }
        load();        
    }, [item.productInfo]);
    const [isShowWonList, setShowWonList] = useState(false);

    const getCustomDate = () => {
        const customDate = new Date(item.createdAt);
        Date.shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return Date.shortMonths[customDate.getMonth()] + " " + customDate.getDate() + ", " + customDate.getFullYear();
    }

    const [isOpenTable, setIsOpenTable] = useState(false);

    
    const setGreen = (item) => {
        if(item?.ffl_assignment_completed) return {backgroundColor: '#53893eb3', borderBottom: '1px solid grey'}
    }

    const sendToShippingFunc = async ({modifiedFFL,isEdit = false,oneTimeChange,fflNotRequired}) => {
        
        let purchase_id
        let ffl_id
        let ffl_one_time
        let ffl_not_required
    
        purchase_id=modifiedFFL?.id
        oneTimeChange=null
        ffl_id=null

        if(modifiedFFL.assigned_ffl){
            ffl_id = modifiedFFL?.assigned_ffl?.id
            oneTimeChange?ffl_one_time=true:ffl_one_time=false
        }

        
        const obj = { 
            purchase_id,
            ffl_id,
            ffl_not_required: fflNotRequired,
            ffl_one_time:oneTimeChange,
            ffl_edited:isEdit
        }

        console.log("obj sendToShipping",obj);

        try {
            setLoading(true);
            const result = await addFFLItem(obj);
            setLoading(false);
            if (result === "success"){
                setLoading(true);
                await loadParent();
                setLoading(false);
                alert.success("FFL is assigned successfully",
                {
                    timeout: 2200,
                });
                setAllStatesToFalse();
            } else{
                alert.error(result || "Failed assigning");
                setAllStatesToFalse();
            }
        } catch (error) {
            alert.error( "Failed assigning");
            setAllStatesToFalse();
        }

        setAllStatesToFalse();
    }

    const handleFFLNotRequired = async ({modifiedFFL,isEdit = false,oneTimeChange,fflNotRequired}) => {
        
        let purchase_id
        let ffl_id
    
        purchase_id=modifiedFFL?.id
        oneTimeChange=null
        ffl_id=null
        
        
        const obj = { 
            purchase_id,
            ffl_id: ffl_id,
            ffl_not_required: fflNotRequired,
            ffl_one_time:oneTimeChange,
            ffl_edited:isEdit
        }
        console.log("obj handleFFLNotRequired",obj);
        try {
            setLoading(true);
            const result = await addFFLItem(obj);
            setLoading(false);
            if (result === "success"){
                setLoading(true);
                await loadParent();
                setLoading(false);
                alert.success("FFL is assigned successfully",
                {
                    timeout: 2200,
                });
                setAllStatesToFalse();
            } else{
                setAllStatesToFalse();
                alert.error(result || "Failed assigning");
            }
        } catch (error) {
            alert.error( "Failed assigning");
            setAllStatesToFalse();
        }
        setAllStatesToFalse();

	}

    const getFFLStatus = (item) => {
        if(item.ffl_not_required){
            return "FFL NOT REQUIRED"
        }

        if(item?.ffl_id && item?.ffl_database){
            return item?.ffl_database?.businessName ? item?.ffl_database?.businessName : item?.ffl_database?.licenseName
        } else if(item?.buyer?.ffl_scope_id && item?.assigned_ffl){
            return item?.assigned_ffl?.businessName ? item?.assigned_ffl?.businessName : item?.assigned_ffl?.licenseName
        }else{
            return "FFL NOT SELECTED"
        }
        
    }

    const getFFLExpirationDate = (item) => {
        let color = "white"
        let value = ""

        if(item?.ffl_database?.id){
            value = item?.ffl_database?.expiration!=='' && item?.ffl_database?.expiration!==null ?
            moment(item?.ffl_database?.expiration).utc().format('MM/DD/YYYY') : ''
            
            if(Date.parse(item?.ffl_database?.expiration ) > Date.parse(new Date())){
                color = 'white'
            } else{
                color = 'red'
            }
        } else if(item?.assigned_ffl?.id){
            value = item?.assigned_ffl?.expiration!=='' && item?.assigned_ffl?.expiration!==null ?
            moment(item?.assigned_ffl?.expiration).utc().format('MM/DD/YYYY') : ''
            console.log("item?.assigned_ffl?.expiration", {
                value,
                item: item?.assigned_ffl?.expiration
            });
            if(Date.parse(item?.assigned_ffl?.expiration) > Date.parse(new Date())){
                color = 'white'
            } else{
                color = 'red'
            }
        }
        return {
            color,
            value
        }
    }

    return (
        <Fragment>
            {
                validationSendToShipping && (
                    <ConfirmationModal
                        isLoading={isLoading}
                        message="Are you sure you want to proceed?"
                        closeModal={() => {
                            setShowConfirmSendModal(false)
                            setValidationSendToShipping(false)
                        }}
                        editFFL={() => {
                            setValidationSendToShipping(false)
                            setShowModal(true)
                            console.log('editFFL', item);
                        }}
                        notRequired={() => {}}
                        noResponse={() => {}}
                        style="send"
                    />
                )
            }
            {
                showEditOptions && (
                    <ConfirmationModal
                        isLoading={isLoading}
                        message="Are you sure you want to proceed?"
                        closeModal={() => {
                            setShowEditOptions(false)
                        }}
                        editFFL={() => {
                            /* setValidationSendToShipping(false)
                            setShowModal(true)
                            console.log('editFFL', item); */
                            setShowModal(true)
                            setShowEditOptions(false)
                        }}
                        notRequired={() => {
                            setShowConfirmNotRequiredModal(true)
                        }}
                        noResponse={() => {}}
                        style="custom"
                    />
                )
            }

            {
                showConfirmSendModal && (
                    <div className="modal-profile-form">
                    <div className="content">
                      <div className="removeDialogWrapper">
                        <div className="removeDialog">
                          <div>
                          <div className="close-icon">
                          <i onClick={() => setShowConfirmSendModal(false)} class="far fa-times-circle"></i>
                        </div>
                            <h2 className="text-white text-center ">
                              Are you sure you want to proceed?
                            </h2>
                            <p></p>
              
                            <MDBRow center className="mt-4">
                                <MDBCol size="8" sm="6" md="5" lg="6">
                                    <FormButton
										isLoading={isLoading}
										onClickFunc={()=>{
											sendToShippingFunc({
                                                modifiedFFL: itemToEdit,
                                                isEdit: true,
                                                oneTimeChange: null,
                                                fflNotRequired: false
                                            })
										}
									}>
                                    <h5>Yes</h5>
                                    </FormButton>
                                </MDBCol>
                                <MDBCol size="8" sm="6" md="5" lg="6">
                                <FormButton 
                                    greyCol={true}
                                    onClickFunc={()=>{
										setShowConfirmSendModal(false)
                                    }}> 
                                    <h5>No</h5>
                                </FormButton>
                                </MDBCol>
                            </MDBRow>
                          </div>
                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>   
                )
            }
            {
            showConfirmNotRequiredModal && (
                <ConfirmationModal
                    isLoading={isLoading}
                    message="Are you sure you want to proceed?"
                    closeModal={() => setShowConfirmNotRequiredModal(false)}
                    editFFL={() => {}}
                    notRequired={() => {
                        handleFFLNotRequired({
                            modifiedFFL: itemToEdit,
                            isEdit: false,
                            oneTimeChange: null,
                            fflNotRequired: true
                        })
                    }}
                    noResponse={() => {
                        setShowConfirmNotRequiredModal(false)
                    }}
                    style="basic"
                />
            )
        }
            {
                isMobile ? <Fragment>
                <MDBRow className="table-row" style={ setGreen(item)}>
                    <MDBCol size="4" middle className="text-center">
                    {
                        imageUrl ? <img src={imageUrl} alt={item.name}/>
                        :
                        <div className="non-img">
                            <MDBIcon far icon="file-image" />
                        </div>
                    }
                    </MDBCol>
                    <MDBCol size="6" middle className="text-center">
                    { item && item.productInfo && item.productInfo.productName && item.productInfo.productName}
                    </MDBCol>
                    <MDBCol size="2" middle className="text-center">
                        <button className="openBtn" onClick={()=> {
                            setIsOpenTable(true);                            
                        }}><MDBIcon icon="plus"/></button>
                    </MDBCol>  
                </MDBRow>
                {
                    isOpenTable &&
                    <Fragment>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">Thumbnail</MDBCol> 
                            <MDBCol size="6" middle className="text-center">
                            {
                                imageUrl ? <img src={imageUrl} alt={item.name}/>
                                :
                                <div className="non-img">
                                    <MDBIcon far icon="file-image" />
                                </div>
                            }
                            </MDBCol>
                            <MDBCol size="2" middle className="text-center">
                                <button className="openBtn" onClick={()=> {
                                    setIsOpenTable(false);
                                    setShowWonList(false);
                                }}><MDBIcon icon="minus"/></button>
                            </MDBCol>                    
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">               
                            <MDBCol size="4" middle className="text-center text-white">UPC</MDBCol> 
                            <MDBCol size="8" middle className="text-center">
                            { item?.productInfo?.upc_code ? item.productInfo.upc_code : 'unassigned'}
                            </MDBCol>               
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">               
                            <MDBCol size="4" middle className="text-center text-white">Product</MDBCol> 
                            <MDBCol size="8" middle className="text-center">
                            { item && item.productInfo && item.productInfo.productName && item.productInfo.productName}
                            </MDBCol>               
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">Buyer's Name</MDBCol> 
                            <MDBCol size="8" middle className="text-center">
                            { item && item.buyer && item.buyer.username && item.buyer.username}
                            </MDBCol>               
                        </MDBRow>
                        {/* <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">Buyer's Email</MDBCol> 
                            <MDBCol size="8" middle className="text-center email-col">
                            { item && item.buyer && item.buyer.email && item.buyer.email}
                            </MDBCol>               
                        </MDBRow> */}
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">Sold Date</MDBCol> 
                            <MDBCol size="8" middle className="text-center">
                            { item && item.createdAt && getCustomDate()}
                            </MDBCol>               
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">Distributor OrderID</MDBCol> 
                            <MDBCol size="8" middle className="text-center">
                            { item && item.distributor_order_id}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">Total Price</MDBCol> 
                            <MDBCol size="8" middle className="text-center">
                            { item?.price ? `$${item.price.toFixed(2)}` : 'unassigned'}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">Units</MDBCol> 
                            <MDBCol size="8" middle className="text-center">
                            { item?.price ? item.units : 'unassigned'}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">Shipping</MDBCol> 
                            <MDBCol size="8" middle className="text-center">
                            { item?.price ? `$${item.shipping_price.toFixed(2)}` : 'unassigned'}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">Distributor</MDBCol> 
                            <MDBCol size="8" middle className="text-center">
                            { item && item.distributor}
                            </MDBCol>
                        </MDBRow>
                        {/* {isEdit && <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">Duplicate</MDBCol> 
                            <MDBCol size="8" middle className="text-center">
                            <button onClick={() => historyUrl.push(router.editProduct.path, {
                                    prodType: 'physical', 
                                    id: item?.productID,
                                    duplicate: true
                            })}>
                                Duplicate
                            </button>
                            </MDBCol>               
                        </MDBRow>} */}
                        {/* <MDBRow className="table-row mobile-opend-table">                    
                            <MDBCol size="4" middle className="text-center text-white">FFL</MDBCol> 
                            <MDBCol size="8" middle className="text-center">
                            <Select value={selectedFflItem} isDisabled={!isEdit} onChange={selectedItem => handleFFLChange(selectedItem)} options={fflList} styles={dropDownCustomStyles} />
                            </MDBCol>               
                        </MDBRow> */}
                        <MDBRow className="table-row mobile-opend-table">    
                            <MDBCol size="4" middle className="text-center text-white">FFL expiration</MDBCol>
                            <MDBCol size="8" className={`text-white text-center text-${getFFLExpirationDate(item).color}`} >
                                {
                                    getFFLExpirationDate(item).value
                                }
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="table-row mobile-opend-table">    
                            <MDBCol size="4" middle className="text-center text-white">FFL Selected</MDBCol> 
                            <MDBCol size="8" className="text-white text-center">
                                {
                                    getFFLStatus(item)
                                }
                            </MDBCol>
                        </MDBRow>
                        {
                            !item.ffl_assignment_completed && (
                                <>
                                    <MDBRow className="table-row mobile-opend-table">    
                                        <MDBCol size="4" middle className="text-center text-white">Edit FFL</MDBCol> 
                                        <MDBCol size="8" className="text-white text-center">
                                            <div class="fas fa-pencil-alt" onClick={() => {
                                                setShowModal(true)
                                                setItemToEdit(item)
                                                setItemToEditFunc(item)
                                            }}> </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="5" middle className="text-center text-white">Confirm</MDBCol>
                                        <MDBCol size="7" className="text-center">
                                            <button 
                                                title="You must select a valid FFL."
                                                className="streamBtnShip" onClick={()=>{ 
                                                    setItemToEdit(item)
                                                    setItemToEditFunc(item)
                                                    console.log("item", item);
                                                    if(item?.ffl_id || item?.assigned_ffl_id || item?.ffl_not_required || item?.buyer?.ffl_scope_id){
                                                        setShowConfirmSendModal(true)
                                                    } else{
                                                        setValidationSendToShipping(true)
                                                    }
                                                }}>Send to shipping
                                            </button>
                                        </MDBCol>
                                    </MDBRow>	
                                    <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="5" middle className="text-center text-white">Not Required</MDBCol>
                                        <MDBCol size="7" className="text-center">
                                            <button className="streamBtnShip" onClick={()=>{ 
                                                setShowConfirmNotRequiredModal(true)
                                                setItemToEdit(item)
                                            }}>FFL Not Required</button>
                                        </MDBCol>
                                    </MDBRow>	
                                </>
                            )
                        }
                    </Fragment>
                }
            </Fragment>
            :
            <MDBRow className="table-row" style={setGreen(item)} onClick = {() => setShowWonList(!isShowWonList)}>
                <MDBCol size="1">
                    {
                        imageUrl ? <img src={imageUrl} alt={item.name}/>
                        :
                        <div className="non-img">
                            <MDBIcon far icon="file-image" />
                        </div>
                    }
                </MDBCol>
                <MDBCol size={'1'} className="text-center email-col">
                    { item?.productInfo?.upc_code ? item.productInfo.upc_code : 'unassigned'}
                </MDBCol>
                <MDBCol size="1" className="text-center">
                    { item && item.productInfo && item.productInfo.productName && item.productInfo.productName}
                </MDBCol>
                {/* <MDBCol size="1" className="text-center">
                    { item && item.buyer && item.buyer.username && item.buyer.username}
                </MDBCol> */}
                <MDBCol size="1" className="text-center">
                    { item && item.createdAt && getCustomDate()}
                </MDBCol>
                <MDBCol size="1" className="text-center">
                    { item && item.distributor_order_id}
                </MDBCol>
                <MDBCol size='1' className="text-center">
                    { item?.price ? `$${item.price.toFixed(2)}` : 'unassigned'}
                </MDBCol>
                <MDBCol size='1' className="text-center">
                    { item?.price ? item.units : 'unassigned'}
                </MDBCol>
                <MDBCol size='1' className="text-center">
                    { item?.price ? `$${item.shipping_price.toFixed(2)}` : 'unassigned'}
                </MDBCol>
                <MDBCol size='1' className="text-center">
                    { item && item.distributor}
                </MDBCol>
                <MDBCol size="1" className="text-center">
                    {getFFLStatus(item)}
                </MDBCol>
                {
                    !item.ffl_assignment_completed ? (
                        <>
                            <MDBCol size="1" className="text-center">
                                 <div 
                                    className="fas fa-pencil-alt"
                                    onClick={() => {
                                        setItemToEdit(item)
                                        setShowEditOptions(true)
                                        setItemToEditFunc(item)
                                    }}>
                                </div>
                                
                            </MDBCol>
                            <MDBCol size="1" className="text-center">
                                <button className="streamBtnShip" onClick={()=>{
                                    setItemToEdit(item)
                                    setItemToEditFunc(item)
                                    if(item?.ffl_id || item?.assigned_ffl_id || item?.ffl_not_required || item?.buyer?.ffl_scope_id){
                                        setShowConfirmSendModal(true)
                                    } else{
                                        setValidationSendToShipping(true)
                                    }
                                }}>Send to shipping
                                </button>
                                :
                                <div></div>
                            </MDBCol>
                        </>
                    )
                    :
                    (
                        <>
                            <MDBCol size="1" className="text-center"> <div></div></MDBCol>
                            <MDBCol size="1" className="text-center"><div></div></MDBCol>
                        </>
                    )
                }
                
                {/* {isEdit && <MDBCol size="1" className="text-right">
                    <button onClick={() => historyUrl.push(router.editProduct.path, {
                            prodType: 'physical', 
                            id: item?.productID,
                            duplicate: true
                    })}>
                            Duplicate
                    </button>
                </MDBCol>} */}
            </MDBRow>
            }
            {
                isShowWonList && <Fragment>
                    <MDBRow className="ffl-row">
                        <MDBCol size="2">
                            Name
                        </MDBCol>
                        <MDBCol size="4" className="text-center">
                            Address
                        </MDBCol>
                        <MDBCol size="3" className="text-center">
                            Phone
                        </MDBCol>
                        <MDBCol size="3" className="text-center">
                            Email
                        </MDBCol>
                    </MDBRow>
                
                    <MDBRow className="ffl-row bottom-border">
                        <MDBCol size="2">
                            {item && item.buyer && item.buyer.username && item.buyer.username}
                        </MDBCol>
                        <MDBCol size="4" className="text-center">
                            {item && item.buyer && item.buyer.address && item.buyer.address}
                        </MDBCol>
                        <MDBCol size="3" className="text-center">
                            {item && item.buyer && item.buyer.phone_number && <span>({item.buyer.phone_number.slice(2,5)}) {item.buyer.phone_number.slice(5,8)}-{item.buyer.phone_number.slice(8)}</span>}
                        </MDBCol>
                        <MDBCol size="3" className="text-center email-col">
                            {item && item.buyer && item.buyer.email && item.buyer.email}
                        </MDBCol>
                    </MDBRow>                           
               
                </Fragment>
            }
        </Fragment>
    )
}

const MapDispatchToProps = dispatch => ({
    addFFLItem: addFFLItem(dispatch),
    loadPage: flag => dispatch(loadPage(flag)),
    getDistributorsProductItemsCount: () => dispatch(getDistributorsProductItemsCount()),
})

export default connect(null, MapDispatchToProps)(SoldDistributorsProductsRow);