import React from 'react';
import './add-new-zoom-meeting.style.scss';
import { connect } from 'react-redux';
import { setZoomLink, getZoomTemplate } from '../../../../redux/zoom-meetings/zoom-meetings.action';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { TextField } from '@material-ui/core';
import FormInput from '../../../../components/form-input/form-input.component';
import FormButton from '../../../../components/form-button/form-button.component';
import Autocomplete from 'react-google-autocomplete';
import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import DatePicker from "react-datepicker";
import { useDropzone } from 'react-dropzone';
import { Storage } from 'aws-amplify';
import { loadPage } from '../../../../redux/user/user.action';
import { useHistory } from 'react-router-dom';
import TimePicker from 'react-time-picker';
import Select from 'react-select'
import { template } from 'lodash';
import moment from 'moment-timezone';




const AddNewMeetingZoom = ({
    setZoomLink,
    loadPage,
    getZoomTemplate
}) => {


    const alert = useAlert();
    const history = useHistory();
    const [timeDuration, setTimeDuration] = useState('00:00');
    const [zoomTemplates, setZoomTemplates] = useState([]);

    const [newZoomInfo, setnewZoomInfo] = useState({
        zoomTopic: history.location.state.name,
        zoomAgenda: ''
    })
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    function handleClick() {
        history.push("/product/sold-webinar");
    }


    const startDatePickerLink = React.createRef();
    const [startDate, setStartDate] = useState();
    const [minutes, setMinutes] = useState();


    useEffect(() => {
          getAllTemplates();
    }, []);
    

    const getAllTemplates = async() => {
        try {
          const response = await getZoomTemplate()
          let templates = [];
          response.forEach(template => {
            templates.push({
                label: template.name,
                value: template.id
            })
          });
          setZoomTemplates(templates);
        } catch(e) {
            console.log(e)
        }
    }
   

//     var today = new Date();
//    var time = today.getHours() + ":" + today.getMinutes()

   const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

    
    function convertH2M(timeDuration){
        if(timeDuration) {
            let timeParts = timeDuration.split(":");
            return Number(timeParts[0]) * 60 + Number(timeParts[1]);
        }
      }
      
      let timeInMinutes = convertH2M(timeDuration);

    const handleSubmit = async event => {
        event.preventDefault();
        
        const obj = {
            email: "Support@matastacticalsupply.com",
            topic: newZoomInfo.zoomTopic,
            start_time: moment(startDate).format('YYYY-MM-DD[T]HH:mm:ss'),
            duration: timeInMinutes,
            template_id: selectedTemplate.value
        }
        if(!selectedTemplate){
            obj["agenda"] = newZoomInfo.zoomAgenda
        }

        loadPage(true);


        const result = await setZoomLink(history.location.state.id, obj);
        loadPage(false);
        if (result.statusCode === 200) {
            alert.success("Added successfully");
            handleClick();
        }
        else
            alert.error("Adding failed");

    }
   

    return (
        <form onSubmit={handleSubmit} className="add-ffl-form">
            <h2 className="text-center text-white font-weight-bold mb-4">Create New Meeting</h2>
            <MDBRow>
                <MDBCol className="formCol" size="12" sm="6" md="6" lg="6">
                    <FormInput type="text" label="Topic" value={newZoomInfo.zoomTopic} handleChange={(event) => setnewZoomInfo({ ...newZoomInfo, zoomTopic: event.target.value })} required />
                </MDBCol>
                <MDBCol className="formCol" size="12" sm="6" md="6" lg="6">
                    <FormInput type="text" label="Description" value={newZoomInfo.zoomAgenda} handleChange={(event) => setnewZoomInfo({ ...newZoomInfo, zoomAgenda: event.target.value })} />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size="12" sm="6" md="6" lg="6">
                    <div className="background-date-wrapper">
                        <DatePicker
                            ref={startDatePickerLink}
                            selected={startDate}
                            minDate={new Date()}
                            // minTime= {moment(startDate).format('HH:mm')}
                            filterTime={filterPassedTime}
                            showTimeSelect
                            dateFormat="dd/MM/yyyy hh:mm"
                            onChange={date => {console.log("Date Formatted: YYYY-MM-DD[T]HH:mm:ss ");setStartDate(date)}}
                            placeholderText="Starting Time"
                            style={{ backgroundColor: "transparent" }}
                            timeIntervals={1}
                        />
                        <MDBIcon icon="calendar-alt" className="datePickerIcon" onClick={() => startDatePickerLink.current.setOpen(true)} />
                    </div>
                </MDBCol>
                <MDBCol className="formCol" size="12" sm="6" md="6" lg="6">
                    {/* <FormInput type="text" label="Duration" value={newZoomInfo.zoomDuration} handleChange={(event) => setnewZoomInfo({ ...newZoomInfo, zoomDuration: event.target.value })} required /> */}
                    <div className="background-date-wrapper">
                    <TimePicker
                        onChange={setTimeDuration}
                        value={timeDuration}
                        disableClock={true}
                        format="HH:mm"
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"
                        minTime="00:00"
                        maxTime="12:59"
                        clearAriaLabel
                        required 
                    />
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow className="pl-3">
            <MDBCol className="formCol background-date-wrapper" size="12" sm="6" md="6" lg="3">
                <Select  
                placeholder="Template" 
                className="selectAgenda" 
                options={zoomTemplates} 
                value={selectedTemplate}   
                onChange={selectedItem => setSelectedTemplate(selectedItem)}
                required 
                />
                </MDBCol>
            </MDBRow>
            <MDBRow center className="mt-4">
                <MDBCol size="6" sm="6" md="5" lg="4">
                    <FormButton type="submit">SAVE</FormButton>
                </MDBCol>
                <MDBCol size="6" sm="6" md="5" lg="4">
                    <FormButton type="button" greyCol={true} onClickFunc={handleClick}>CANCEL</FormButton>
                </MDBCol>
            </MDBRow>
        </form>
    )
}

const MapStateToProps = ({ FFLDBList: { isAddNewClicked } }) => ({
    isAddNewClicked
})

const MapDispatchToProps = dispatch => ({
    setZoomLink: setZoomLink(dispatch),
    loadPage: (flag) => dispatch(loadPage(flag)),
    getZoomTemplate: getZoomTemplate(dispatch)
})
export default connect(MapStateToProps, MapDispatchToProps)(AddNewMeetingZoom);
