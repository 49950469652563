import FFLDBActionTypes from './ffl-db.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';
import { serialize } from '../../utils/serialize';

export const getProductItems = dispatch => async ( filterStr = null, limit = 10, offset = 0) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/ffl-databases?filterString=${filterStr}&&limit=${limit}&offset=${offset}`, { 
            headers: (await getMyTokenFunc())
        });
        dispatch(setProductItems(result.data));
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

/* export const getFFLImage = dispatch => async ( filterStr = null, limit = 10, offset = 0) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/ffl-databases?filterString=${filterStr}&&limit=${limit}&offset=${offset}`, { 
            headers: (await getMyTokenFunc())
        });
        dispatch(setProductItems(result.data));
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
} */

export const getFFLLicences = dispatch => async (zipcode,radius) => {
    let searchInfo={
        zipcode:zipcode,
        radius:(radius==null || radius === '')?10:(radius>25?25:radius)
    }
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `consumer/ffl-database?${serialize(searchInfo)}`, {
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);
    }
}

export const getFFLLicencesFiltered = dispatch => async ({
    zipcode,
    radius,
    number='',
    name='',
    type='zipcode'
}) => {
    
    const setRoute = (type) => {
        let route = RestAPI.ORIGINAL_ENDPOINT + `consumer/ffl-database/filter?type=${type}`
        switch (type) {
            case 'zipcode':
                route = route + `&zipcode=${zipcode}&radius=${radius}`
                break;
            case 'name':
                route = route + `&name=${name}`
                break;
            case 'number':
                route = route + `&number=${number}`
                break;
            default:
                route = route + `&zipcode=${zipcode}&radius=${radius}`
                break;
        }
        return route;
    }
    try {
        const result = await axios.get(setRoute(type), {
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);
    }
}

export const saveFFLInformation = async (obj) => {
    let saveFFLData={
        fflScopeData:obj,
    }
    try {
        const result = await axios.post(RestAPI.ORIGINAL_ENDPOINT + "admin/ffl-database/saveFFL", saveFFLData,{
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        console.log(error.message);
    }
}

export const getFFLScopeInfo = dispatch => async ( user_id) => {
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + "consumer/ffl-info/"+ user_id, {
            headers: (await getMyTokenFunc())
        });
        return result.data.data;
    } catch (error) {
        console.log(error?.response?.data?.message);
    }    
}

export const addFFLItem = dispatch => async ( obj ) => {

    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + `admin/ffl-databases`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const updateFFLDate = dispatch => async ( id, obj ) => {
   
    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/ffl-databases/${id}`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}
export const updateFFL = dispatch => async ( id, obj ) => {
   
    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/ffl-databases-edit/${id}`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const validateFFL = dispatch => async (obj) => {
    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + `admin/ffl-validation`, obj, { 
            headers: (await getMyTokenFunc())
        });
        console.log(result.data)
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const validateFFLInformation = dispatch => async (obj) => {
    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + `admin/ffl-data-validation`, obj, { 
            headers: (await getMyTokenFunc())
        });
        console.log(result.data)
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const removeGalleryImage = dispatch => async ( id, url ) => {
  try {
        const result = await axios.delete( RestAPI.ORIGINAL_ENDPOINT + `admin/fflImage/remove/${id}/${url}`, { 
            data: url,
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const setAsMainGalleryImage = dispatch => async ( id,idffl ) => {

    try {
        const result = await axios.delete( RestAPI.ORIGINAL_ENDPOINT + `admin/fflImage/set-main/${id}/${idffl}`, { // Cambiar //// por url de servicio que elimina imagen de base de datos
            data: id,
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {  
        return error.response.data;                   
    }
}

export const getGalleryImage = dispatch => async ( id, url ) => {
    try {
          const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/fflImage/${id}`, { 
              headers: (await getMyTokenFunc())
          });

          return result;
          
      } catch (error) {
          console.log(error?.response?.data?.message);    
      }

  }

export const deleteFFLDate = dispatch => async ( id ) => {

    try {
        const result = await axios.delete( RestAPI.ORIGINAL_ENDPOINT + `admin/ffl-databases/${id}`, { 
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const setFilterStr = filterStr => ({
    type: FFLDBActionTypes.SET_FILTER_STRING,
    payload: filterStr
})

export const setLimitPerPage = limit => ({
    type: FFLDBActionTypes.SET_LIMIT_PER_PAGE,
    payload: limit
})

export const setProductPageNum = num => ({
    type: FFLDBActionTypes.SET_PRODUCT_LIST_PAGE_NUM,
    payload: num
})

export const setAddNewClicked = flag => ({
    type: FFLDBActionTypes.SET_ADD_NEW_CLICKED,
    payload: flag
})

const setProductItems = items => ({
    type: FFLDBActionTypes.SET_PRODUCT_LIST_ITEMS,
    payload: items
})
