import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getBackendVersion } from '../../redux/user/user.action';
import router from '../../router';
import './footer.style.scss';

const FooterComponent = ({
    getBackendVersion
}) => {

    const [backendVersion, setBackendVersion] = useState("1.0.0");

    useEffect(() => {
        async function load() {
            const result = await getBackendVersion();
            if (result) {
                setBackendVersion(result);
            }
        }
        load();
    }, [])
    return (
        <div className="footer">
            <Link to={router.dashboard.path} className='grey-text'>&copy;<span>copyright </span>{new Date().getFullYear()}matas - v{process.env.REACT_APP_VERSION} @ {backendVersion}</Link>
        </div>
    );
}


const MapStateToProps = () => ({});

const MapDispatchToProps = (dispatch) => ({
    getBackendVersion: getBackendVersion(dispatch)
});

export default connect(MapStateToProps, MapDispatchToProps)(FooterComponent);