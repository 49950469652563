import { MarkupsActionTypes } from './markups.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getCurrentMarkups = dispatch => async (getData = false, filterString="") => {
    console.log("getCurrentMarkups")
    console.log(getData)
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/markups?filterString=${filterString}`, { 
            headers: (await getMyTokenFunc())
        });
        console.log("result")
        console.log(result)
        console.log("getData")
        console.log(getData)
        if (getData)
            return result.data.data;
        dispatch(setCurrentMarkups(result.data.data));

    } catch (error) {
        console.log(error?.response?.data?.message);
    }
}

export const updateMarkup = dispatch => async (id, value,retail_value) => {

    const obj = {
        value: value,
        retail_map_markup:retail_value,
    }

    try {
        const result = await axios.patch( RestAPI.ORIGINAL_ENDPOINT + "admin/markups/" + id, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result;
       
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}


const setCurrentMarkups = list => ({
    type: MarkupsActionTypes.SET_CURRENT_MARKUPS,
    payload: list
});


export const setFilterStr = filter => ({
    type: MarkupsActionTypes.SET_FILTER_STRING,
    payload: filter
})
