import React, {useState, useEffect} from 'react';
import Select from 'react-select'
import moment from 'moment-timezone';
import { MDBRow, MDBCol } from 'mdbreact';
import { loadPage } from '../../../../redux/user/user.action';
import { updateSiteSettings } from '../../../../redux/colorChnage/colorChange.action';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';

const TimeZoneListComponent = ({isEdit, loadPage, updateSiteSettings, time_zone}) => {

    const alert = useAlert();

    //for timezone

    const tempArray = moment.tz.names();
    const timeZoneList = [];

    tempArray.map(item => {
        timeZoneList.push({
            value: item, 
            label: item
        });
    })

    const [selectedTiemZone, setSelectedTimeZone] = useState(null);

    useEffect(() => {
        async function loadTimeZone() {
            if (time_zone) {
                setSelectedTimeZone({value: time_zone, label: time_zone});
            }            
        }
        loadTimeZone();
    }, [time_zone]);

    const handleTimeZoneChange = async selectedItem => {
        setSelectedTimeZone(selectedItem);

        loadPage(true);
        const obj = {
            time_zone: selectedItem.value
        }
        const result = await updateSiteSettings(obj);
        if (result === "success")
            alert.success("TimeZone updated successfully");
        else
            alert.error("Failed updating");
        loadPage(false);
    }

    return (
        <div>
            <h4 className="text-white font-weight-bolder mt-4">Time Zone</h4>
            <MDBRow className="mt-2">
                <MDBCol middle size="12" sm="12" md="12" lg="7">
                    <p className="grey-text mb-0">The admin can change the current timezone.</p>
                </MDBCol>
                <MDBCol middle size="12" sm="12" md="12" lg="5"> 
                <Select value={selectedTiemZone} isDisabled={!isEdit} onChange={selectedItem => handleTimeZoneChange(selectedItem)} options={timeZoneList}/>
                </MDBCol>
            </MDBRow>
            <hr className="mt-4"/>
        </div>
    )
}

const MapDispatchToProps = dispatch => ({
    loadPage: flag => dispatch(loadPage(flag)),
    updateSiteSettings: updateSiteSettings(dispatch),
})

export default connect(null, MapDispatchToProps)(TimeZoneListComponent);