import {createStore, applyMiddleware, compose} from 'redux';
import {logger} from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './root-reducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'authType',
  storage: storage,
  whitelist: ['productList', 'soldDistributorsProducts' , 'webinarList', 'soldOutWebinarList', 'soldPhysical', 'offlineSales', 'completeWebinar', 'FFLDBList', 'liveStrem', 'user_management', 'seat_refund', 'shipping_reducer']
};

const pReducer = persistReducer(persistConfig, rootReducer);

const middleware = [logger];

const store = createStore(pReducer, compose(applyMiddleware(...middleware, thunk), window.__REDUX_DEVTOOLS_EXTENSION__
? window.__REDUX_DEVTOOLS_EXTENSION__()
: f => f));

const persistor = persistStore(store);

export { persistor, store };