import React, { useState, useEffect, Fragment } from 'react';
import './categories-page.style.scss';
import FormButton from '../../components/form-button/form-button.component';
import FormCheckbox from '../../components/form-checkbox/form-checkbox.component';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import CategoryTableRow from '../../components/table/category-table/row-item/row-item.component';
import TableFilterInput from '../../components/table/filter-input/filter-input.component';
import FormInput from '../../components/form-input/form-input.component';
import FormSelect from '../../components/form-select/form-select.component';
import { connect } from 'react-redux';
import { getCurrentCategories, addCategory, deleteCategory, setFilterStr } from '../../redux/category/category.action';
import { loadPage } from '../../redux/user/user.action';
import { useAlert } from 'react-alert';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';

const CategoriesPage = ({currentCategories, getCurrentCategories, addCategory, deleteCategory, loadPage, isShowSideBar, filterString, setFilterString}) => {

    const alert = useAlert();

    const [isEdit, setIsEdit] = useState(false);

    const [isAllChecked, setIsAllChecked] = useState(false);
    const [isAddNewClicked, setIsAddNewClicked] = useState(false);
    const [newName, setNewName] = useState('');
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const [isEmptyName, setIsEmptyName] = useState(false);
    const [deleteList, setDeleteList] = useState([]);

    // for select box of prod types
    const selectOptions = ["Both", "Physical", "Webinar"];
    const [optionShow, setOptionShow] = useState(false);
    const [placeholder, setPlaceholder] = useState("Both");

    useEffect(() => {
        return () => {
            setFilterString("");
        }
    }, [])


    useEffect(() => {
        async function loadCateogries () {
            loadPage(true);
            await getCurrentCategories(false, filterString);
            loadPage(false); 
        }
        loadCateogries();
    }, [filterString])

    const addFunction = async () => {
        if (isLoadingBtn)
            return
            
        setIsEmptyName(false);
        if((newName === '') || (newName.trim() === "")){
            setIsEmptyName(true);
            alert.error("Empty category name");
            return;
        }
        setIsLoadingBtn(true);
        const result = await addCategory(newName, placeholder==="Both" ? ['Both'] : placeholder==="Physical" ? ['Physical'] : ['Webinar']);
        if (result){            
            await getCurrentCategories();
            alert.success("Add new category successfully");
        }            
        else
            alert.error("Existing category name");
        setIsLoadingBtn(false);
        setNewName('');
        setIsAddNewClicked(false);
    }

    const deletCategoriesFunc = async (event) => {
        event.preventDefault();

        if (deleteList.length > 0) {
            loadPage(true);
            const result = await deleteCategory(deleteList);
            if (result === "success") {                
                await getCurrentCategories();
                alert.success("Delete successfully");
            }
            else
                alert.error("Deleting failed");
            setIsDeleteCategory(false);
            loadPage(false);
        }
    }

    const addToDelList = (id) => {
        let delList = deleteList;
        delList.push(id);
        setDeleteList([...delList]);
    }

    const removeFromDelList = (id) => {
        let delList = deleteList;
        let index = delList.indexOf(id);
        if (index > -1){
            delList.splice(index,1);
            setDeleteList([...delList]);
        }
    }

    const [isDeleteCategory, setIsDeleteCategory] = useState(false);

    return (
        <div className="categories-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
            {
                isDeleteCategory ? <div className="removeDialogWrapper">
                    <div className="removeDialog">
                    <form onSubmit={deletCategoriesFunc} >
                    <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={()=>setIsDeleteCategory(false)}/>
                    <br/>
                    <h3 className="text-white text-center font-weight-bold mb-4">Are you sure to delete?</h3>
                    <MDBRow center className="mb-4 mobile-row">                    
                        <MDBCol size="6">
                            <FormButton type="submit">YES</FormButton>
                        </MDBCol>
                        <MDBCol size="6">
                            <FormButton greyCol={true} onClickFunc={()=>setIsDeleteCategory(false)}>NO</FormButton>
                        </MDBCol>
                    </MDBRow>
                    </form>                    
                    </div>
                </div>
                :
                isAddNewClicked ? <div className="add-new">
                    <h2 className="text-white text-center font-weight-bold mb-4">Add New Category</h2>
                    <MDBRow center className="add-section">
                        <MDBCol className="mb-4" size="10" sm="10" md="5" lg="3">
                            <FormInput type="text" label = 'Category Name' value = {newName} handleChange = {(event) => setNewName(event.target.value)} changeemail={isEmptyName} required/>
                        </MDBCol>
                        <MDBCol className="mb-4" size="10" sm="10" md="5" lg="3">
                            <FormSelect options={selectOptions} label="Product type" showSelectBox={()=>setOptionShow(!optionShow)} selectOption={(event)=>{
                                setOptionShow(false);
                                setPlaceholder(event.target.id);
                            }} optionShow={optionShow} placeholder={placeholder}/>
                        </MDBCol>
                        <MDBCol className="mb-4" size="10" sm="10" md="5" lg="3">
                            <FormButton onClickFunc={ () => addFunction() } isLoading = {isLoadingBtn}>SAVE NOW</FormButton>
                        </MDBCol>                                               
                    </MDBRow>
                </div>
                :
                <Fragment>
                    <div className="title">
                        <h2 className="text-white font-weight-bold mb-0">Categories</h2>
                        {<FormButton onClickFunc={ () => setIsAddNewClicked(true)}>ADD NEW</FormButton>}
                    </div>
                    <div className="mt-3 section">
                        <div className="toolbar" >
                        {isEdit && <p>Action: <i class="fas fa-trash-alt" onClick={()=>deleteList.length > 0 && setIsDeleteCategory(true)}></i></p>}
                        <TableFilterInput str={filterString} setFilterString={setFilterString} placeholder="search by category"/>
                        </div>
                        <MDBRow className="table-header">
                            {isEdit ? <MDBCol size="1" sm="1" md="1">
                                <FormCheckbox Notif={isAllChecked} handleChange = { () => setIsAllChecked(!isAllChecked) } />
                            </MDBCol>: 
                            <MDBCol size="1" sm="1" md="1">
                                
                            </MDBCol>}
                            <MDBCol size="4" sm="4" md="5" className="text-white text-center">
                                Category Name
                            </MDBCol>
                            <MDBCol size="4" sm="4" md="3" className="text-white text-center">
                                Product Type
                            </MDBCol>
                            {isEdit ? <MDBCol size="3" sm="3" md="3" className="text-white text-center">
                                Action
                            </MDBCol>: 
                            <MDBCol size="3" sm="3" md="3" className="text-white text-center">
                                
                            </MDBCol>}
                        </MDBRow>
                        {
                            currentCategories && currentCategories.map( category => <CategoryTableRow
                                key={category.id}
                                id = {category.id}
                                name={category.category_name} 
                                prodType={category.product_type}
                                addToDelList = {(id)=>addToDelList(id)}
                                removeFromDelList = {(id)=>removeFromDelList(id)}
                                isAllChecked = {isAllChecked}/>  )
                        }
                    </div>
                </Fragment>
            }            
        </div>
    )
}

const MapStateToProps = ({category: {currentCategories, filterString}, user: {isShowSideBar}}) => ({
    filterString,
    currentCategories,
    isShowSideBar
});

const MapDispatchToProps = dispatch => ({
    getCurrentCategories: getCurrentCategories(dispatch),
    addCategory: addCategory(dispatch),
    setFilterString: str => dispatch(setFilterStr(str)),
    deleteCategory: deleteCategory(dispatch),
    loadPage: flag => dispatch(loadPage(flag))
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(CategoriesPage));