import WebinarQueuetListActionTypes from './webinar-queue-list.types';

const INITIAL_STATE = {
    product_items_count:1,
    product_items: {
        rows: [],
        count: 1,
        webinar_queue_limit: 0
    },
    filterStr: "",
    limitPerPage: 10,
    pageNum: 1,
    currentTypeFilter: "Queued"
}

const WebinarQueueListItemsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case WebinarQueuetListActionTypes.SET_PRODUCT_LIST_ITEMS:
            return {
                ...state, 
                product_items : action.payload, 
            }
        case WebinarQueuetListActionTypes.SET_PRODUCT_LIST_ITEMS_COUNT:
            return {
                ...state, 
                product_items_count : action.payload, 
            }
        case WebinarQueuetListActionTypes.SET_FILTER_STRING:
            return {
                ...state, filterStr: action.payload
            }
        case WebinarQueuetListActionTypes.SET_TYPE_FILTER:
            return {
                ...state, currentTypeFilter: action.payload
            }
        case WebinarQueuetListActionTypes.SET_LIMIT_PER_PAGE:
            return {
                ...state, limitPerPage: action.payload
            }
        case WebinarQueuetListActionTypes.SET_MAX_WEBINAR_COUNT:
            return {
                ...state, product_items: {...state.product_items, webinar_queue_limit: action.payload}
            }            
        case WebinarQueuetListActionTypes.SET_PRODUCT_LIST_PAGE_NUM:
            return {
                ...state, pageNum: action.payload
            }
        default:
            return state;
    }
}

export default WebinarQueueListItemsReducer;