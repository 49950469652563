import React, {Fragment} from 'react';
import './form-button.style.scss';
import {MDBIcon} from 'mdbreact';

const FormButton = ({children, greyCol, isLoading, disabled, onClickFunc, ...otherProps}) => {
    return(
        <Fragment>
            <button className={`${greyCol ? 'greyColor' : ''} ${disabled ? 'disable' : ''} form-button font-weight-bold`} disabled={disabled} {...otherProps} onClick = {onClickFunc}>                
                {
                    isLoading&&<MDBIcon className="loadSubIcon" icon="sync-alt" />
                }
                {children}
            </button>
        </Fragment>
    )
};

export default FormButton;