import React,{ Fragment} from 'react';
import './add-type-btn.style.scss';
import { ProductTypeEnum } from '../../productTypeEnum';

const AddNewProductTypeButton = ({prodType, active, onClickFunc}) => (
    <Fragment>
        {
            prodType === ProductTypeEnum.WEBINAR ? 
                <button type="button" className={`${active ? 'active' : ''} prodTypeBtn`} onClick={onClickFunc}>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 1000.000000 1000.000000" preserveAspectRatio="xMidYMid meet"><metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata><g transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)" fill={`${active?'#fff':'#a0a0a0'}`} stroke="none"><path d="M89 9331 l-39 -39 0 -2666 0 -2666 4945 0 4945 0 0 2666 0 2666 -39
                    39 -39 39 -4867 0 -4867 0 -39 -39z m4761 -2666 l0 -2345 -2150 0 -2150 0 0
                    2345 0 2345 2150 0 2150 0 0 -2345z m4570 0 l0 -2345 -2150 0 -2150 0 0 2345
                    0 2345 2150 0 2150 0 0 -2345z"></path><path d="M2391 8180 c-275 -72 -491 -290 -561 -565 -27 -108 -27 -282 0 -390
                    71 -277 288 -494 565 -565 108 -27 282 -27 390 0 277 71 494 288 565 565 14
                    56 20 111 20 195 0 137 -21 233 -75 345 -97 203 -289 358 -510 415 -106 27
                    -290 27 -394 0z"></path><path d="M2365 6390 c-387 -82 -723 -348 -975 -773 -89 -149 -220 -433 -220
                    -477 0 -7 447 -10 1425 -10 1262 0 1425 2 1425 15 0 29 -83 241 -140 360 -230
                    473 -603 787 -1053 884 -128 28 -332 28 -462 1z"></path><path d="M6342 8310 c-71 -36 -125 -92 -159 -166 -36 -78 -35 -191 2 -271 64
                    -141 205 -216 359 -192 106 17 184 74 237 171 32 59 34 69 34 158 0 89 -2 99
                    -34 158 -63 116 -161 172 -300 172 -67 0 -86 -4 -139 -30z"></path><path d="M7892 8310 c-71 -36 -125 -92 -159 -166 -36 -78 -35 -191 2 -271 64
                    -141 205 -216 359 -192 106 17 184 74 237 171 32 59 34 69 34 158 0 89 -2 99
                    -34 158 -63 116 -161 172 -300 172 -67 0 -86 -4 -139 -30z"></path><path d="M6401 7579 c-141 -25 -301 -144 -401 -300 -47 -74 -110 -204 -110
                    -228 0 -8 186 -11 601 -11 l601 0 -7 28 c-54 219 -252 437 -451 497 -65 19
                    -168 26 -233 14z"></path><path d="M7951 7579 c-141 -25 -301 -144 -401 -300 -47 -74 -110 -204 -110
                    -228 0 -8 186 -11 601 -11 l601 0 -7 28 c-54 219 -252 437 -451 497 -65 19
                    -168 26 -233 14z"></path><path d="M6342 6280 c-71 -36 -125 -92 -159 -166 -36 -78 -35 -191 2 -271 64
                    -141 205 -216 359 -192 106 17 184 74 237 171 32 59 34 69 34 158 0 89 -2 99
                    -34 158 -63 116 -161 172 -300 172 -67 0 -86 -4 -139 -30z"></path><path d="M7892 6280 c-71 -36 -125 -92 -159 -166 -36 -78 -35 -191 2 -271 64
                    -141 205 -216 359 -192 106 17 184 74 237 171 32 59 34 69 34 158 0 89 -2 99
                    -34 158 -63 116 -161 172 -300 172 -67 0 -86 -4 -139 -30z"></path><path d="M6401 5549 c-141 -25 -301 -144 -401 -300 -47 -74 -110 -204 -110
                    -228 0 -8 186 -11 601 -11 l601 0 -7 28 c-54 219 -252 437 -451 497 -65 19
                    -168 26 -233 14z"></path><path d="M7951 5549 c-141 -25 -301 -144 -401 -300 -47 -74 -110 -204 -110
                    -228 0 -8 186 -11 601 -11 l601 0 -7 28 c-54 219 -252 437 -451 497 -65 19
                    -168 26 -233 14z"></path><path d="M50 2949 l0 -651 39 -39 39 -39 1895 0 c1797 0 1896 -1 1901 -17 31
                    -105 32 -376 2 -488 -23 -87 -88 -212 -141 -272 -60 -68 -165 -137 -264 -173
                    -135 -50 -218 -59 -546 -64 -334 -4 -393 -13 -496 -67 -118 -63 -138 -112
                    -139 -346 l0 -173 2650 0 2650 0 0 173 c-1 234 -21 283 -139 346 -103 54 -162
                    63 -496 67 -210 3 -325 9 -384 20 -179 33 -333 111 -427 218 -52 59 -117 184
                    -140 271 -30 112 -29 383 2 488 5 16 104 17 1906 17 l1900 0 39 39 39 39 0
                    651 0 651 -4945 0 -4945 0 0 -651z"></path></g></svg>
                    <span>Add New Webinar</span></button>
            : prodType === ProductTypeEnum.PRODUCT ?
                <button type="button" className={`${active ? 'active' : ''} prodTypeBtn`} onClick={onClickFunc}>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 1000.000000 1000.000000" preserveAspectRatio="xMidYMid meet"><metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata><g transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)" fill={`${active?'#fff':'#a0a0a0'}`} stroke="none"><path d="M9329 8527 c-77 -52 -139 -180 -139 -288 l0 -49 -3883 0 c-3515 0
                    -3888 -2 -3937 -16 -94 -28 -181 -98 -222 -179 -34 -65 -247 -401 -263 -415
                    -14 -11 -24 -6 -68 37 -129 126 -234 149 -323 71 -106 -93 -80 -186 101 -356
                    l93 -87 -179 -280 c-233 -364 -389 -623 -447 -743 -77 -159 -64 -215 62 -277
                    85 -41 204 -66 446 -94 283 -32 421 -84 541 -201 173 -169 208 -402 114 -765
                    -50 -194 -137 -417 -288 -740 -225 -482 -370 -939 -452 -1428 -74 -446 -96
                    -1012 -45 -1150 21 -57 69 -111 110 -124 14 -4 617 -6 1340 -3 l1315 5 45 25
                    c35 19 51 38 73 82 27 53 29 63 28 185 -5 805 167 1613 526 2466 25 59 48 107
                    52 107 4 0 50 -9 102 -20 448 -93 1084 -113 1784 -54 379 31 762 78 814 100
                    55 23 109 81 132 144 19 51 21 79 23 365 3 356 10 408 78 545 68 141 190 244
                    355 301 137 47 182 49 1119 49 632 0 891 3 915 11 91 31 139 102 147 216 l5
                    72 212 3 212 3 49 30 c30 19 60 49 79 79 l30 49 3 875 c3 968 5 937 -61 1024
                    -48 63 -103 88 -199 88 l-78 0 0 49 c0 68 -34 172 -73 223 -51 66 -90 88 -157
                    88 -43 0 -65 -6 -91 -23z m-5009 -1066 l0 -338 26 -27 27 -26 1072 0 1072 0
                    27 26 26 27 0 338 0 339 1505 0 1505 0 0 -669 0 -668 -122 -6 c-292 -14 -458
                    -116 -458 -282 l0 -55 -2595 0 c-2060 1 -2603 -2 -2637 -12 -57 -17 -113 -72
                    -127 -126 -24 -87 10 -152 101 -193 49 -22 68 -24 256 -27 199 -3 202 -4 203
                    -25 2 -39 -2 -71 -13 -106 -12 -40 -518 -988 -558 -1046 -193 -282 -373 -794
                    -495 -1407 -81 -411 -165 -1103 -165 -1365 l0 -63 -1079 0 -1079 0 -12 102
                    c-18 142 -8 447 19 603 62 352 158 664 461 1495 180 492 296 835 342 1010 40
                    151 50 360 24 490 -65 317 -310 557 -707 690 -86 29 -275 74 -341 82 -28 3
                    -23 12 336 648 199 355 394 697 432 760 79 132 100 147 217 160 40 4 672 8
                    1405 9 l1332 1 0 -339z m1880 159 l0 -180 -755 0 -755 0 0 180 0 180 755 0
                    755 0 0 -180z m-1299 -1886 l125 -7 47 -166 c26 -91 56 -209 68 -261 11 -52
                    31 -114 45 -137 44 -75 127 -123 212 -123 63 0 95 24 116 87 28 81 21 162 -34
                    387 -27 114 -50 211 -50 216 0 6 209 10 580 10 l580 0 -35 -49 c-48 -66 -99
                    -180 -124 -273 -37 -142 -46 -235 -46 -489 l0 -246 -130 -22 c-341 -57 -657
                    -76 -1140 -68 -390 6 -922 41 -943 62 -6 6 43 126 146 360 128 291 255 534
                    355 678 38 54 43 58 71 53 17 -3 88 -8 157 -12z"></path><path d="M1592 6993 c-18 -9 -45 -32 -60 -51 -24 -31 -27 -44 -27 -112 0 -68
                    3 -81 27 -112 55 -72 -8 -68 1018 -68 1026 0 963 -4 1018 68 24 31 27 44 27
                    112 0 68 -3 81 -27 112 -55 72 8 68 -1020 68 -834 0 -926 -2 -956 -17z"></path><path d="M7342 6993 c-18 -9 -45 -32 -60 -51 -24 -31 -27 -44 -27 -112 0 -68
                    3 -81 27 -112 54 -70 29 -68 688 -68 659 0 634 -2 688 68 24 31 27 44 27 112
                    0 68 -3 81 -27 112 -54 70 -29 68 -690 68 -529 0 -597 -2 -626 -17z"></path><path d="M2620 5507 c-25 -13 -58 -43 -75 -66 -25 -36 -30 -54 -33 -114 -3
                    -61 0 -79 21 -117 13 -25 43 -58 66 -75 36 -25 54 -30 114 -33 61 -3 79 0 117
                    21 25 13 58 43 75 66 25 36 30 54 33 114 3 61 0 79 -21 117 -13 25 -43 58 -66
                    75 -36 25 -54 30 -114 33 -61 3 -79 0 -117 -21z"></path><path d="M1830 2617 c-25 -13 -58 -43 -75 -66 -25 -36 -30 -54 -33 -114 -3
                    -61 0 -79 21 -117 13 -25 43 -58 66 -75 36 -25 54 -30 114 -33 61 -3 79 0 117
                    21 25 13 58 43 75 66 25 36 30 54 33 114 3 61 0 79 -21 117 -13 25 -43 58 -66
                    75 -36 25 -54 30 -114 33 -61 3 -79 0 -117 -21z"></path></g></svg>
                    <span>Add New Product</span></button>
            : prodType === ProductTypeEnum.OFFLINE?
            <button type="button" className={`${active ? 'active' : ''} prodTypeBtn`} onClick={onClickFunc}>
                <span>Add New Offline Product</span>
            </button>:
             <button type="button" className={`${active ? 'active' : ''} prodTypeBtn`} onClick={onClickFunc}>
             <span>Free Webinar</span>
         </button>
        }
    </Fragment>
)

export default AddNewProductTypeButton;