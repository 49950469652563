import SeatRefundActionTypes from './seat-refund.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getProductItems = dispatch => async (webinarID, filterStr, limit, offset) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/webinar/${webinarID}/users?filterString=${filterStr}&limit=${limit}&offset=${offset}`, { 
            headers: (await getMyTokenFunc())
        });
        console.log(result);
        dispatch(setProductItems({
            rows: [...result.data.users.rows],
            count: result.data.users.count,
            pricePerSeat: result.data.won_item[0].webinar.price_per_seats

        }));
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const freeTakenSeat = dispatch => async (obj) => {
    debugger
    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + `admin/checkout/free-taken-seat`, obj, {
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const seatRefund = dispatch => async (obj) => {

    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + `admin/checkout/refundmatas`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const setFilterString = filterStr => ({
    type: SeatRefundActionTypes.SET_FILTER_STRING,
    payload: filterStr
})

export const setLimitPerPage = limit => ({
    type: SeatRefundActionTypes.SET_LIMIT_PER_PAGE,
    payload: limit
})

export const setProductPageNum = num => ({
    type: SeatRefundActionTypes.SET_PRODUCT_LIST_PAGE_NUM,
    payload: num
})

const setProductItems = items => ({
    type: SeatRefundActionTypes.SET_PRODUCT_LIST_ITEMS,
    payload: items
})
