import React, {useState, Fragment, useEffect, useRef} from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import CountPerPage from '../../components/table/count-per-page/count-per-page.component';
import TableFilterInput from '../../components/table/filter-input/filter-input.component';
import PageButtons from '../../components/table/pagination/pagination.component';
import { connect, useDispatch } from 'react-redux';
import FormSelect from '../../components/form-select/form-select.component';
import { 
    getProductItems,
    getProductItemsCount,
    setLimitPerPage, 
    updateQueueLimit, 
    setFilterString, 
    setCurrentTypeFilter,
    setProductPageNum } from '../../redux/webinar-queue-list/webinar-queue-list.action';
import { setIsFromDuplicate } from '../../redux/user/user.action';
import './webinar-queues-page.style.scss';
import WebinarQueueListeRow from '../../components/table/webinar-queue-row/webinar-queue-row.component';
import {useAlert} from 'react-alert';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';
import LoaderComponent from '../../components/Loader/loader.component';

const WebinarQueuesPage = ({
    filterStr, 
    product_items_count,
    limitPerPage, 
    pageNum,
    getProductItems,
    getProductItemsCount,
    setLimitPerPage,
    updateQueueLimit,
    isShowSideBar,
    setFilterString,
    currentTypeFilter,
    setCurrentTypeFilter,
    setProductPageNum,
    setIsFromDuplicate,
    isFromDuplicate
    }) => {

    const alert = useAlert();
    console.log("🚀 ~ file: webinar-queues-page.component.jsx:45 ~ product_items_count", product_items_count)

    // for select box of list types
    const typeFilter = ["Queued", "Scheduled"];
    const [typeFilterShow, setTypeFilterShow] = useState(false);

    // for row counts per page
    const countSelect = [10,20,30,50];
    const [countSelectShow, setCountSelectShow] = useState(false);
    const [loading, setLoading] = useState(false );
    const [products, setProducts] = useState([]);

    let sum;
    sum = products.count || 0;
    // for max no of list webinars
    const [maxWebinarCount, setMaxWebinarCount] = useState(products?.webinar_queue_limit);

    const isMounted = useRef(false);

    useEffect(() => {
        return () => {
            /* setCurrentTypeFilter("Queued")
            setLimitPerPage(10) */
            setFilterString("")
        }
    }, [])
    

    useEffect(() => {
        if(isMounted.current) {
            async function load() {
                setLoading(true);
                await getProductItemsCount(
                    currentTypeFilter.toLowerCase(),
                    );
                const result = await getProductItems(
                    filterStr,   
                    currentTypeFilter.toLowerCase(),
                    limitPerPage === "All" ? "all" : limitPerPage, 
                    limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1)
                    );
    
                setProducts(result);
                setLoading(false);
            }
            load();
        } else {
            isMounted.current = true;
        }
    }, [pageNum]);

    const load = async (currentTypeFilter, filterStr, limitPerPage) => {
        //console.log("🚀 ~ file: webinar-queues-page.component.jsx ~ useEffect ~AAAAAAA load AAAAAAAA~ currentTypeFilter", currentTypeFilter)
        setLoading(true);
        let result;
        const getProductItemC = await getProductItemsCount(
            currentTypeFilter.toLowerCase(""),
            );
        if (products.count !== undefined){
            //console.log("SIIII se sabe la cantidad de lineas AAAAAAAAA");
            if(products.count < limitPerPage*(pageNum-1)){
                console.log("el ofset esta fuera de rango BBBBBBBB");

                result = await getProductItems(
                    filterStr,   
                    currentTypeFilter.toLowerCase(),
                    limitPerPage === "All" ? "all" : limitPerPage, 
                    limitPerPage === "All" ? 0 : 0
                    );
                    setProductPageNum(1);   
            }else{
            //console.log("si se sabe y el offset NO esta fuera de rango BBBBBBBBCCCC");
            result = await getProductItems(
                filterStr,   
                currentTypeFilter.toLowerCase(),
                limitPerPage === "All" ? "all" : limitPerPage, 
                limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1)
                );

            }
        } else {
            //console.log("NO se sabe la cantidad de lineas CCCCCCCCCCC");
            result = await getProductItems(
                filterStr,   
                currentTypeFilter.toLowerCase(),
                limitPerPage === "All" ? "all" : limitPerPage, 
                limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1)
            );
            if(result.count < limitPerPage*(pageNum-1)){
                //console.log("el ofset esta fuera de rango DDDDDDDDDDD");
                result = await getProductItems(
                    filterStr,   
                    currentTypeFilter.toLowerCase(),
                    limitPerPage === "All" ? "all" : limitPerPage, 
                    limitPerPage === "All" ? 0 : 0
                    );
                    setProductPageNum(1); 
            }
        }
    setProducts(result);
    setLoading(false);
    }

    useEffect(() => {             
        !isFromDuplicate && load( currentTypeFilter, filterStr, limitPerPage);
    }, [currentTypeFilter, limitPerPage, filterStr]);

    useEffect(() => {
        setMaxWebinarCount(products?.webinar_queue_limit);
    }, [products?.webinar_queue_limit]);

    const numberFormat = (e) => {        
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex

        if (e.target.value === '' || re.test(e.target.value)) {
            setMaxWebinarCount(e.target.value);    
        }
    }

    const updateMaxWebinarCount = async () => {
        setLoading(true);
        const result = await updateQueueLimit({
            queued_webinar_limit: parseInt(maxWebinarCount)
        })
        setLoading(false);
        if (result === "success"){
            alert.success("Changed queue limit successfully");
            setLoading(true);
            await load( currentTypeFilter, filterStr, limitPerPage);
            setLoading(false);
        }
        else
            alert.error("Changing failed");
    }

    const [isMobileSize, setIsMobileSize] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setIsFromDuplicate(false);
        function handleResize() {
            if (window.innerWidth < 900)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        setIsEdit(JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'productEdit' || item === 'webinarQueueEdit'));
    }, []);

    return (
       <>
       {
        loading && (
            <LoaderComponent />
        )
       }
        <div className="webinar-queues-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
            <MDBRow className="headerRow">
            <MDBCol size="7" className="text-white text-left">
            <h2 className="text-white font-weight-bold">Webinar Queues</h2>
            </MDBCol>
            <MDBCol size="5" className="text-white text-center">
            <h3 className="text-white font-weight-bold">Total {currentTypeFilter}: {sum}</h3>
            </MDBCol>
        </MDBRow>
            <div className="section">
                <MDBRow>
                    <MDBCol middle size="12" sm="12" md="12" lg="5" className="filterIndex mt-2">
                        <label className="mobile-label">List type:</label>                        
                        <FormSelect options={typeFilter} showSelectBox={()=>setTypeFilterShow(!typeFilterShow)} selectOption={(event)=>{
                            setTypeFilterShow(false);
                            setCurrentTypeFilter(event.target.id);
                        }} optionShow={typeFilterShow} placeholder={currentTypeFilter}/>
            
                    </MDBCol>
                    <MDBCol middle size="12" sm="12" md="12" lg="7" className="text-right mt-2">
                            <label className="text-white mobile-hidden">Max no. of listed webinars:</label>
                            <span className="mobile-show text-white mr-3">Limit no.</span>
                            <input className="max-no-webinars" type="text" value={maxWebinarCount} onChange={event => numberFormat(event)}/>
                            <button className="max-no-btn" onClick={()=>updateMaxWebinarCount()}>Update</button>
                    </MDBCol>
                </MDBRow>
                <div className="toolbar">
                    <div className="leftDiv">
                        <div>
                            <CountPerPage 
                                options={countSelect} 
                                showSelectBox={()=>setCountSelectShow(!countSelectShow)} 
                                selectOption={(event)=>{
                                    setCountSelectShow(false);
                                    setLimitPerPage(event.target.id);
                                }} 
                                optionShow={countSelectShow} 
                                placeholder={limitPerPage}
                            />
                            <label className="ml-1">Per Page</label>
                        </div>
                        <TableFilterInput str={filterStr} setFilterString={setFilterString}/>
                    </div>
                    <PageButtons 
                        count={ limitPerPage === "All" ? 1 : Math.ceil(products.count/limitPerPage) } 
                        currentIndex = {pageNum}
                        setProductPageNum={setProductPageNum}
                    />  
                </div>
                {/* <p className="deleteAction">Action: <i class="fas fa-trash-alt" onClick={()=>deletSelectedRows()}></i></p> */}
                
                {
                    isMobileSize ? 
                        <MDBRow className="table-header">
                            <MDBCol size="3" className="text-white text-center">
                                Thumbnail
                            </MDBCol>
                            <MDBCol size="7" className="text-white text-center">
                                Name
                            </MDBCol>
                        </MDBRow>
                    :
                    currentTypeFilter === "Queued" ?                     
                        <MDBRow className="table-header">
                        {/* <MDBCol size="1">
                            <FormCheckbox Notif={isAllChecked} handleChange = { () => setIsAllChecked(!isAllChecked) } />
                        </MDBCol> */}
                            <MDBCol size="1" className="text-white">
                                Thumbnail
                            </MDBCol>
                            <MDBCol size={isEdit ? '1' : '2'} className="text-white text-center">
                                UPC
                            </MDBCol>
                            <MDBCol size={isEdit ? '2' : '3'} className="text-white text-center">
                                Name
                            </MDBCol>
                            <MDBCol size={isEdit ? '2' : '3'} className="text-white text-center">
                                Description
                            </MDBCol>
                            
                            <MDBCol size="1" className="text-white text-center">
                                Price
                            </MDBCol>
                            <MDBCol size="2" className="text-white text-center">
                                No. of Seats
                            </MDBCol>         
                            {isEdit && <MDBCol size="2" className="text-white text-center">
                                Remove from queue
                            </MDBCol>}
                            {isEdit && <MDBCol size="1" className="text-white text-center">
                                Duplicate
                            </MDBCol>}

                        </MDBRow>
                        :
                        <MDBRow className="table-header">                    
                            <MDBCol size="1" className="text-white">
                                Thumbnail
                            </MDBCol>
                            <MDBCol size={isEdit ? '1' : '2'} className="text-white text-center">
                                UPC
                            </MDBCol>
                            <MDBCol size={isEdit ? '1' : '2'} className="text-white text-center">
                                Name
                            </MDBCol>
                            <MDBCol size="2" className="text-white text-center">
                                Description
                            </MDBCol>      
                            <MDBCol size="2" className="text-white text-center">
                                Date
                            </MDBCol> 
                            <MDBCol size="1" className="text-white text-center">
                                Time
                            </MDBCol>            
                            <MDBCol size="1" className="text-white text-center">
                                Price
                            </MDBCol>
                            <MDBCol size="1" className="text-white text-center">
                                No. of Seats
                            </MDBCol>         
                            {isEdit && <MDBCol size="1" className="text-white text-center">
                                Add to queue
                            </MDBCol>} 
                            {isEdit && <MDBCol size="1" className="text-white text-center">
                                Duplicate
                            </MDBCol>}       
                        </MDBRow>
                }
                
                {
                    products && products.rows && products.rows.length > 0 ? products.rows.map( item => <WebinarQueueListeRow
                        key = {item.id}
                        item = {item}
                        filterStr = {filterStr}
                        type = {currentTypeFilter.toLowerCase()}
                        pageLimit = {limitPerPage === "All" ? "all" : limitPerPage}
                        offset = {limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1)}
                        isMobile={isMobileSize}
                        isEdit={isEdit}
                        loadParentData = {async () => {
                            setLoading(true);
                            await load( currentTypeFilter, filterStr, limitPerPage);
                            setLoading(false);
                        }}
                        />  )
                        :
                        <div className="non-row text-center">
                            No Data
                        </div>
                }
            </div>
            
        </div>

       </>
    )
}

const MapStateToProps = ({ webinarList: { filterStr, product_items, product_items_count,  limitPerPage, pageNum, currentTypeFilter }, user: {isShowSideBar, isFromDuplicate}}) => ({
    filterStr,
    product_items,
    product_items_count,
    limitPerPage,
    pageNum,
    isShowSideBar,
    currentTypeFilter,
    isFromDuplicate
})

const MapDispatchToProps = dispatch => ({
    getProductItems: getProductItems(dispatch),
    getProductItemsCount: getProductItemsCount(dispatch),
    setLimitPerPage: count => dispatch(setLimitPerPage(count)),
    updateQueueLimit: updateQueueLimit(dispatch),
    setFilterString: str => dispatch(setFilterString(str)),
    setCurrentTypeFilter: str => dispatch(setCurrentTypeFilter(str)),
    setProductPageNum: num => dispatch(setProductPageNum(num)),
    setIsFromDuplicate: flag => dispatch(setIsFromDuplicate(flag))
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(WebinarQueuesPage));