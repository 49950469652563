import React, {useState, useEffect} from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import FormSelect from '../../../components/form-select/form-select.component';
import { connect } from 'react-redux';
import { getCurrentMemberGraph, getRegisteredMemberGraph } from '../../../redux/dashboard/dashboard.action';

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import SpecificPeriodComponent from '../specific-period/specific-period.component';
const MemeberGraph = ({ isRegister, getCurrentMemberGraph, getRegisteredMemberGraph }) => {

    const [member_data, setMemberData] = useState([]);
    const [totalValue, setTotalValue] = useState();
    
    //for selectbox of member period
    const memberPeriod = ["Day", "Week", "Month", "Year", "Specific Period"];
    const [memberPeriodsShow, setMemberPeriodsShow] = useState(false);
    const [selectedMemberPeriod, setSelectedMemberPeriod] = useState("Day");

    const tooltipstyle = {
        backgroundColor: 'lightgrey',
        border: '2px solid white'
    }

    const drawMemberGraph = async () => {
        let startTime = new Date();
        let endTime = new Date();
        let periodUnit = "hour";

        switch (selectedMemberPeriod) {
            case "Day":              
                periodUnit = "hour";
                break;

            case "Week":
                let day = startTime.getDay();
                let diff = startTime.getDate() - day + (day === 0 ? -6 : 1 );
                startTime.setDate(diff);
                periodUnit = "day";
                break;

            case "Month":
                startTime = new Date(startTime.getFullYear(), startTime.getMonth(), 1);
                periodUnit = "day";
                break;

            case "Year":
                startTime = new Date(startTime.getFullYear(), 0, 1);
                periodUnit = "month";
                break;
        
            default:
                break;
        }

        startTime.setHours(0);
        startTime.setMinutes(0);
        let result = null;
        if (isRegister)
            result = await getRegisteredMemberGraph(startTime.toISOString(), endTime.toISOString(), periodUnit);
        else
            result = await getCurrentMemberGraph(startTime.toISOString(), endTime.toISOString(), periodUnit);

        if (result) {
            let tempArray = [];
            let tempTotalVal = 0;
            result.map( data => {
                const tempDate = new Date(data.date.toString());
                tempTotalVal += data.amount;
                switch (selectedMemberPeriod) {
                    case "Day":
                        tempArray.push({name: tempDate.getHours(), value: parseInt(data.amount)});
                        break;
                    case "Week":
                        const weekArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                        tempArray.push({name: weekArray[tempDate.getDay()-1], value: parseInt(data.amount)});
                        break;
                    case "Month":
                        tempArray.push({name: tempDate.getDate(), value: parseInt(data.amount)});
                        break;
                    case "Year":
                        tempArray.push({name: tempDate.getMonth()+1, value: parseInt(data.amount)});
                    default:
                        break;
                }                
            });
            setMemberData([...tempArray]);
            tempTotalVal = tempTotalVal.toString();
            if (tempTotalVal.indexOf(".") > -1)
                tempTotalVal = tempTotalVal.slice(0, (tempTotalVal.indexOf("."))+3);
            setTotalValue(Number(tempTotalVal));
        }
    }

    const [isShowCustDate, setIsShowCustDate] = useState(false);
    useEffect(() => {
        if (selectedMemberPeriod !== 'Selected period') {
            async function loadMember() {
                await drawMemberGraph();
            }
            selectedMemberPeriod === 'Specific Period' ? setIsShowCustDate(true) : loadMember()
        }
    }, [selectedMemberPeriod]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const saveSpecificPeriod = async () => {
        setIsShowCustDate(false);
        setSelectedMemberPeriod('Selected period');
        const periodUnit = "day";
        startDate.setHours(0);
        startDate.setMinutes(0);

        let result = null;
        if (isRegister)
            result = await getRegisteredMemberGraph(startDate.toISOString(), endDate.toISOString(), periodUnit);
        else
            result = await getCurrentMemberGraph(startDate.toISOString(), endDate.toISOString(), periodUnit);

        if (result) {
            let tempArray = [];
            let tempTotalVal = 0;
            result.map( data => {
                const tempDate = new Date(data.date.toString());
                tempTotalVal += data.amount;            
                tempArray.push({name: tempDate.getDate(), value: parseInt(data.amount)});                         
            });
            setMemberData([...tempArray]);
            tempTotalVal = tempTotalVal.toString();
            if (tempTotalVal.indexOf(".") > -1)
                tempTotalVal = tempTotalVal.slice(0, (tempTotalVal.indexOf("."))+3);
            setTotalValue(Number(tempTotalVal));
        }
    }

    return (
   
        <div className="graph-wrapper">
            { 
                isShowCustDate && <SpecificPeriodComponent
                    startDate={startDate} 
                    setStartDate={setStartDate} 
                    endDate={endDate} 
                    setEndDate={setEndDate} 
                    saveSpecificPeriod={saveSpecificPeriod} 
                    setIsShowCustDate={setIsShowCustDate}
                    setSelectedRevenuePeriod={setSelectedMemberPeriod}
                />
            }
            <MDBRow className="graph-header" between>
                <MDBCol size="12" sm="6" md="6">
                    <p className="text-white mb-2">{ isRegister ? 'Registered users' : 'Member Counter'}</p>
                </MDBCol>
                <MDBCol size="12" sm="6" md="6" className="mb-2">                   
                <FormSelect options={memberPeriod} showSelectBox={()=>setMemberPeriodsShow(!memberPeriodsShow)} selectOption={(event)=>{
                    setMemberPeriodsShow(false);
                    setSelectedMemberPeriod(event.target.id);
                }} optionShow={memberPeriodsShow} placeholder={selectedMemberPeriod}/>
                </MDBCol>                  
            </MDBRow>
            <div style={{ width: '100%', height: 240 }}>
                <ResponsiveContainer>
                <AreaChart
                    data={member_data}
                    margin={{
                    top: 10, right: 20, left: -10, bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id="colorMember" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="15%" stopColor="grey" stopOpacity={0.9}/>
                        <stop offset="85%" stopColor="grey" stopOpacity={0}/>
                        </linearGradient>
                        
                    </defs>
                    <CartesianGrid horizontal={false} stroke="grey"/>
                    <XAxis dataKey="name" stroke="white" tick={{fontSize: 11}} axisLine={{stroke: "grey"}} tickLine={{stroke: "grey"}} tickMargin={5}/>
                    <YAxis type="number" stroke="white" tickLine={false} axisLine={false} tick={{fontSize: 11}} tickMargin={5} allowDecimals={false}/>
                    <Tooltip contentStyle={tooltipstyle}/>
                    <Area type="monotone" dataKey="value" stroke="#e43c59" strokeWidth="2" dot={{ fill: '#e43c59', strokeWidth: 2 }} fillOpacity={1} fill="url(#colorMember)" />
                </AreaChart>
                </ResponsiveContainer>
            </div>
            <p className="text-center text-white totalVal">Total Member: {totalValue}</p>
        </div>

    )
}

const MapDispatchToProps = dispatch => ({
    getCurrentMemberGraph: getCurrentMemberGraph(dispatch),
    getRegisteredMemberGraph: getRegisteredMemberGraph(dispatch)
})

export default connect(null, MapDispatchToProps)(MemeberGraph);