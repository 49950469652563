import React, {useState, Fragment, useEffect, useRef} from 'react';
import './product-listing-page.style.scss';
import FormButton from '../../components/form-button/form-button.component';
import { MDBRow, MDBCol } from 'mdbreact';
import CountPerPage from '../../components/table/count-per-page/count-per-page.component';
import TableFilterInput from '../../components/table/filter-input/filter-input.component';
import PageButtons from '../../components/table/pagination/pagination.component';
import { connect } from 'react-redux';
import FormSelect from '../../components/form-select/form-select.component';
import ProductListeRow from '../../components/table/prduct-list-row/product-list-row.component';
import AddNewProductForm from '../../components/product-management/add-new/add-new.component';
import { 
    setAddNewClicked, 
    getProductItems,
    getProductItemsCount, 
    setLimitPerPage, 
    setCurrentTypeFilter, 
    setCurrentStateFilter,
    setFilterString,
    setProductPageNum } from '../../redux/product-list/product-list.action';
import { setIsFromDuplicate } from '../../redux/user/user.action';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';
import { getSiteSettings } from '../../redux/colorChnage/colorChange.action';
import { formatDateToSearchProductListing } from '../../utils/formatDateToSearch';
import LoaderComponent from '../../components/Loader/loader.component';

const ProductListingPage = ({
    filterStr, 
    product_items_count,
    limitPerPage, 
    pageNum,
    isAddNewClicked,
    setAddNewClicked,
    getProductItems,
    getProductItemsCount,
    setLimitPerPage,
    isShowSideBar,
    currentTypeFilter,
    currentStateFilter,
    setCurrentTypeFilter,
    setCurrentStateFilter,
    setFilterString,
    setProductPageNum,
    setIsFromDuplicate,
    isFromDuplicate,
    getSiteSettings
    }) => {

    const sum = product_items_count;

    // for select box of prod types
    const typeFilter = ["All", "Physical", "Webinar"];
    const [typeFilterShow, setTypeFilterShow] = useState(false);

    // for select box of prod types
    const stateFilter = ["Active", "Inactive", "Hold"];
    const [stateFilterShow, setStateFilterShow] = useState(false);
    
    // for row counts per page
    const countSelect = [10,20,30,50];
    const [countSelectShow, setCountSelectShow] = useState(false);
    // const [currentCount, setCurrentCount] = useState(10);
    const [timezone, setTimezone] = useState('');

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([])

    const isMounted = useRef(false);
    const isMountedPageNum = useRef(false);
    
    useEffect(() => {
        return () => {
            /* setCurrentTypeFilter("All");
            setCurrentStateFilter("Active");
            setLimitPerPage(10); */
            setProductPageNum(0);
            setFilterString("");
        }
    }, [])
    

    useEffect(() => {
        async function load() {
            const settings = await getSiteSettings();
            setTimezone(settings?.time_zone);
        }
        load();
    }, []);
   
    useEffect(() => {
        if(isMounted.current) {
            async function load() {
                setLoading(true);
                await getProductItemsCount(
                    currentStateFilter.toLowerCase(), 
                );
                const result = await getProductItems(
                    "", 
                    currentStateFilter.toLowerCase(), 
                    currentTypeFilter === "All" ? "both" : currentTypeFilter.toLowerCase(),
                    10, 
                    0
                    );
                setProducts(result);
                setLoading(false);
            }
            !isAddNewClicked && load();
        } else {
            isMounted.current = true;
        }
    }, [isAddNewClicked]);

    useEffect(() => {
        if(isMountedPageNum.current) {
            let tempDate = formatDateToSearchProductListing(filterStr)
            async function load() {
                setLoading(true);
                await getProductItemsCount(
                    currentStateFilter.toLowerCase(), 
                );
                const results = await getProductItems(
                    tempDate !== "" ? tempDate : filterStr,
                    currentStateFilter.toLowerCase(), 
                    currentTypeFilter === "All" ? "both" : currentTypeFilter.toLowerCase(),
                    limitPerPage === "All" ? "all" : limitPerPage, 
                    limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1)
                    );
                setProducts(results);
                setLoading(false);
            }
            load();
        } else {
            isMountedPageNum.current = true;
        }
    }, [pageNum]);

    useEffect(() => {
        let tempDate = formatDateToSearchProductListing(filterStr)
        async function load() {
            setProductPageNum(1);   
            setLoading(true);
            await getProductItemsCount(
                currentStateFilter.toLowerCase(), 
            );
            const results = await getProductItems(
                tempDate !== "" ? tempDate : filterStr,
                currentStateFilter.toLowerCase(), 
                currentTypeFilter === "All" ? "both" : currentTypeFilter.toLowerCase(),
                limitPerPage === "All" ? "all" : limitPerPage, 
                0
                );
            setProducts(results);
            setLoading(false);
        }
        !isFromDuplicate && load();
    }, [currentTypeFilter, currentStateFilter, limitPerPage, filterStr]);

    useEffect(() => {
        if(isFromDuplicate) {
            let tempDate = formatDateToSearchProductListing(filterStr)
            async function load() {
                setProductPageNum(1);   
                setLoading(true);
                await getProductItemsCount(
                    currentStateFilter.toLowerCase(), 
                );
                const results = await getProductItems(
                    tempDate !== "" ? tempDate : filterStr,
                    currentStateFilter.toLowerCase(), 
                    currentTypeFilter === "All" ? "both" : currentTypeFilter.toLowerCase(),
                    limitPerPage === "All" ? "all" : limitPerPage, 
                    0
                    );
                setProducts(results);
                setLoading(false);
            }
            load()
        }
    }, []);

    const [isMobileSize, setIsMobileSize] = useState(false);

    const [isEdit, setIsEdit] = useState({
        edit: false,
        seatRefund: false
    });

    useEffect(() => {
        setIsFromDuplicate(false);
        function handleResize() {
            if (window.innerWidth < 900)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        setIsEdit({
            edit: JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'productEdit'),
            seatRefund: JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'seatsRefundView')
        });
    }, []);

    const renderAddNewPage = () => (
        <AddNewProductForm/>
    )

    const renderListPage = () => (
        <Fragment>
            <MDBRow className="headerRow">
                <MDBCol size="12" sm="12" md="9" lg="9">
                    <h2 className="text-white font-weight-bold">Product Listing</h2>
                </MDBCol>
                <MDBCol size="6" sm="6" md="3" lg="3">
                    {
                        products && products.totalProductListing && (
                            <h2 className="text-white font-weight-bold">Total {currentStateFilter}: {products?.totalProductListing}</h2>
                        )
                    }
                </MDBCol>
                <MDBCol size="6" sm="6" md="3" lg="2">
                    {isEdit.edit && <FormButton onClickFunc={()=>setAddNewClicked(true)}>ADD NEW</FormButton>}
                </MDBCol>
            </MDBRow>
            <div className="section">
                <MDBRow>
                    <MDBCol size="12" sm="12" md="12" lg="7" className="filterIndex">
                        <label className="mobile-hidden">Filter by </label>
                        <label className="filterLabel">Type:</label>
                        <FormSelect options={typeFilter} showSelectBox={()=>setTypeFilterShow(!typeFilterShow)} selectOption={(event)=>{
                            setTypeFilterShow(false);
                            setCurrentTypeFilter(event.target.id);
                        }} optionShow={typeFilterShow} placeholder={currentTypeFilter}/>
                        <label className="filterLabel">State:</label>
                        <FormSelect options={stateFilter} showSelectBox={()=>setStateFilterShow(!stateFilterShow)} selectOption={(event)=>{
                            setStateFilterShow(false);
                            setCurrentStateFilter(event.target.id);
                        }} optionShow={stateFilterShow} placeholder={currentStateFilter}/>
                    </MDBCol>
                    <MDBCol middle size="12" sm="12" md="12" lg="5" className="text-right mt-2">
                            <label className="text-white">Total live webinars: </label>
                            <label className="text-white">{products?.total_live_count}</label>
                    </MDBCol>
                </MDBRow>
                <div className="toolbar">
                    <div className="leftDiv">
                        <div>
                            <CountPerPage 
                                options={countSelect} 
                                showSelectBox={()=>setCountSelectShow(!countSelectShow)} 
                                selectOption={(event)=>{
                                    setCountSelectShow(false);            
                                    setLimitPerPage(event.target.id);
                                }} 
                                optionShow={countSelectShow} 
                                placeholder={limitPerPage}
                            />
                            <label className="ml-1">Per Page</label>
                        </div>
                        <TableFilterInput str={filterStr} setFilterString={setFilterString}/>
                    </div>
                    <PageButtons 
                        count={ limitPerPage === "All" ? 1 : Math.ceil(products?.count/limitPerPage) } 
                        currentIndex = {pageNum} 
                        setProductPageNum={setProductPageNum}
                    />  
                </div>
                {/* <p className="deleteAction">Action: <i class="fas fa-trash-alt" onClick={()=>deletSelectedRows()}></i></p> */}
                {
                    isMobileSize ? 
                        <MDBRow className="table-header">
                            <MDBCol size="3" className="text-white text-center">
                                Thumbnail
                            </MDBCol>
                            <MDBCol size="7" className="text-white text-center">
                                Name
                            </MDBCol>
                        </MDBRow>
                    :
                    <MDBRow className="table-header">
                        {/* <MDBCol size="1">
                            <FormCheckbox Notif={isAllChecked} handleChange = { () => setIsAllChecked(!isAllChecked) } />
                        </MDBCol> */}
                        <MDBCol size="1" className="text-white thumb">
                            Thumbnail
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            UPC
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Name
                        </MDBCol>
                        <MDBCol size="2" className="text-white text-center">
                            Description
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Type
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Price
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Quantity
                        </MDBCol>
                        <MDBCol size={isEdit?.edit ? '1' : '3'} className="text-white text-center">
                            Live Date
                        </MDBCol>
                        {isEdit?.edit && <MDBCol size="1" className="text-white text-center">
                          Edit / Delete
                        </MDBCol>}
                        <MDBCol size="1" className="text-white text-center">
                            Active/ Inactive
                        </MDBCol>
                        {isEdit?.edit && <MDBCol size="1" className="text-white text-center">
                            Duplicate
                        </MDBCol>}
                    </MDBRow>   
                }
                {
                    products && products.rows && products.rows.length > 0 ? products.rows.map( item => <ProductListeRow
                        key={item.id}
                        item={item}
                        typeFilter = {currentTypeFilter}
                        statusFilter = {currentStateFilter}
                        isMobile={isMobileSize}
                        isEdit={isEdit}
                        // time_zone={timezone}
                        />  )
                    :
                    <div className="non-row text-center">
                        No Data
                    </div>
                }
            </div>
        </Fragment>
    )
    
    return (
        <>
        {
            loading && (
                <LoaderComponent />
            )
        }
        <div className="product-listing-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
        {
            isAddNewClicked ? renderAddNewPage()
            :
            renderListPage()
        }
        </div>
        </>
    )
}

const MapStateToProps = ({ 
    productList: { 
        filterStr, product_items,product_items_count, limitPerPage, pageNum, isAddNewClicked, currentTypeFilter, currentStateFilter
    }, 
    user: {
        isShowSideBar,
        isFromDuplicate
    }
}) => ({
    filterStr,
    product_items,
    product_items_count,
    limitPerPage,
    pageNum,
    isAddNewClicked,
    isShowSideBar,
    currentTypeFilter,
    currentStateFilter,
    isFromDuplicate
})

const MapDispatchToProps = dispatch => ({
    setAddNewClicked: flag => dispatch(setAddNewClicked(flag)),
    getProductItems: getProductItems(dispatch),
    getProductItemsCount: getProductItemsCount(dispatch),
    setLimitPerPage: count => dispatch(setLimitPerPage(count)),
    setCurrentTypeFilter: str => dispatch(setCurrentTypeFilter(str)),
    setCurrentStateFilter: str => dispatch(setCurrentStateFilter(str)),
    setFilterString: str => dispatch(setFilterString(str)),
    setProductPageNum: num => dispatch(setProductPageNum(num)),
    setIsFromDuplicate: flag => dispatch(setIsFromDuplicate(flag)),
    getSiteSettings: getSiteSettings(dispatch)
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(ProductListingPage));
