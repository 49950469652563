import React, {useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../redux/user/user.action';
import './signin-page.style.scss';
import FormInput from '../../components/form-input/form-input.component';
import FormButton from '../../components/form-button/form-button.component';
import { useAlert } from 'react-alert';
import router from '../../router';

const SignInPage = ({getCurrentUser}) => {

    const alert = useAlert();

    const [credential, setCredential] = useState({ email:'', pass:''});
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    // for new created admin
    const [newUser, setNewUser] = useState(null);
    const [newUserPass, setNewUserPass] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
     
        if(isLoading)
            return;
        setIsLoading(true);

        try {
            const result = await Auth.signIn(credential.email.toLowerCase(), credential.pass);
            setIsLoading(false);
            if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
                return setNewUser(result);
            }            
            const res = await getCurrentUser(result);
            if (res)
                history.push(router.dashboard.path);
            else
                alert.error(res);            
        } catch (error) {
            console.log(error);      
            alert.error("The username and/or password combination is incorrect.");
            setIsLoading(false);
        }
      
    }

    const newUserSubmit = async (event) => {
        event.preventDefault();
     
        if(isLoading)
            return;

        setIsLoading(true);
        newUser.completeNewPasswordChallenge(newUserPass, {}, {
            onSuccess: async () => {
                const userData = await Auth.signIn(credential.email.toLowerCase(), newUserPass);                        
                const res = await getCurrentUser(userData);
                if (res)
                    history.push(router.dashboard.path);
                else
                    alert.error(res);
            },
            onFailure: (err) => {
                setIsLoading(false);
                alert.error(err.message);
            }
        });        
    }

    return(        
        <div className = 'sign-in-page'>                        
            <div className='login-form'>
                {
                    newUser ?
                    <form onSubmit={newUserSubmit}>
                        <h2 className='login-title text-center font-weight-bold'>New password</h2>                        
                        <FormInput type="password" name = "new_pass" label = "New Password" value = {newUserPass} handleChange = {(event) => setNewUserPass(event.target.value)} required/>
                        <FormButton type="submit" isLoading={isLoading}>CONFIRM</FormButton>              
                    </form> 
                    :
                    <form onSubmit={handleSubmit}>
                        <h2 className='login-title text-center font-weight-bold'>Login to Admin</h2>
                        <FormInput type="email" name = "email" label = "E-mail" value = {credential.email} handleChange = {(event) => setCredential({ ...credential, email: event.target.value})} required/>
                        <FormInput type="password" name = "pass" label = "Password" value = {credential.pass} handleChange = {(event) => setCredential({...credential, pass: event.target.value})} required/>
                        <FormButton type="submit" isLoading={isLoading}>LOG IN</FormButton>
                        <p className='mt-3'><Link to={router.forgotPassword.path} className='white-text text-center ft-400'>Forgot Password ?</Link></p>
                    </form> 
                }            
            </div>                      
        </div>
    );
};

const MapDispatchToProps = dispatch => ({
    getCurrentUser: getCurrentUser(dispatch)
})

export default connect(null, MapDispatchToProps)(SignInPage);