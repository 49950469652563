const ProductListActionTypes = {
    SET_PRODUCT_LIST_ITEMS: "SET_PRODUCT_LIST_ITEMS",
    SET_PRODUCT_LIST_ITEMS_COUNT: "SET_PRODUCT_LIST_ITEMS_COUNT",
    SET_FILTER_STRING: "SET_PROD_LIST_FILTER_STRING",
    SET_LIMIT_PER_PAGE: "SET_PRODUCT_LIST_LIMIT_PER_PAGE",
    SET_PRODUCT_LIST_PAGE_NUM: "SET_PRODUCT_LIST_PAGE_NUM",
    SET_ADD_NEW_CLICKED: "SET_ADD_NEW_CLICKED",
    SET_TYPE_FILTER: "SET_PROD_LIST_TYPE_FILTER",
    SET_STATE_FILTER: "SET_PROD_LIST_STATE_FILTER",
};

export default ProductListActionTypes;