const SoldPhysicalActionTypes = {
    SET_PRODUCT_LIST_ITEMS: "SET_SOLD_PHYSICAL_LIST_ITEMS",
    SET_PRODUCT_LIST_ITEMS_COUNT: "SET_SOLD_LIST_ITEMS_COUNT",
    SET_FILTER_STRING: "SET_SOLD_PHYSICAL_FILTER_STRING",
    SET_LIMIT_PER_PAGE: "SET_SOLD_PHYSICAL_LIMIT_PER_PAGE",
    SET_PRODUCT_LIST_PAGE_NUM: "SET_SOLD_PHYSICAL_PAGE_NUM",
    SET_PRODUCT_COUNT: "SET_PRODUCT_COUNT",
    SET_FFL_FILTER: "SET_SOLD_PHYSICAL_FFL_FILTER",
};

export default SoldPhysicalActionTypes;