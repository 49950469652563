export const formatDateToSearch = filterString => {
  let tempDate = ''
  if (filterString.includes('%2F')) {
    let tem = filterString.replace(/%2F/g, '/')
    tem = tem.replace(/\//g, '-')
    tem = tem.split('-')
    tem = tem[2] + '-' + tem[0] + '-' + tem[1]

    tempDate = tem
    return tempDate
  } else {
    return ""
  }
}

export const formatDateToSearchProductListing = filterString => {
  let tempDate = ''
  if (filterString.includes('%2F')) {
    let tem = decodeURIComponent(filterString);
    tem = tem.substring(0, 10)
    tem = tem.replace(/\//g, '-')
    tem = tem.split('-')
    tem = tem[2] + '-' + tem[0] + '-' + tem[1]

    tempDate = tem
    
    return tempDate
  } else {
    return ""
  }
}


