import React, { useState, Fragment, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import { MDBRow, MDBCol } from 'mdbreact';
import { updateSiteSettings } from '../../../../redux/colorChnage/colorChange.action';
import { loadPage } from '../../../../redux/user/user.action';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import FormButton from '../../../../components/form-button/form-button.component';

const FooterColorChange = ({isEdit, updateSiteSettings, footer_background_color, loadPage }) => {

  const alert = useAlert();

  const [colorPicker, setColorPicker] = useState({
    show: false,
    color: {
      r: 255,
      g: 255,
      b: 255,
      a: 1
    }
  });

  useEffect(() => {
    async function loadColors() {
        if (footer_background_color) {
          setColorPicker({...colorPicker, color: JSON.parse(footer_background_color)});
        }
    }    
    loadColors();
      
  }, [footer_background_color]);

  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${colorPicker.color.r}, ${colorPicker.color.g}, ${colorPicker.color.b}, ${colorPicker.color.a})`
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'none',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        marginTop: '-370px',
        marginLeft: '20px',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  const changeFooterColor = async () => {
 
    const color = {
      footer_background_color: JSON.stringify(colorPicker.color),
    }
    loadPage(true);
    const result = await updateSiteSettings(color);

    if (result === "success")
        alert.success("Changed successfully");
    else
        alert.error("Failed changing");

    loadPage(false);

}

  return (
    <Fragment>
      <h4 className="text-white font-weight-bolder mt-4">Change Footer Color</h4>
      <p className="grey-text mb-4">The admin can change the footer color of consumer site.</p>
      {/* <button className="color-btn c1 actived"></button>
            <button className="color-btn c2"></button>
            <button className="color-btn c3"></button>
            <button className="color-btn c4"></button>
            <button className="color-btn c5"></button> */}
      <MDBRow>
        <MDBCol size="3" sm="3" md="3" lg="1">
          <button className="color-btn" style={
            { background: `rgba(${colorPicker.color.r}, ${colorPicker.color.g}, ${colorPicker.color.b}, ${colorPicker.color.a})` }
          } onClick={() => {
            console.log(colorPicker.color);
            setColorPicker({ ...colorPicker, show: !colorPicker.show });
          }}></button>
          <div>
            <div style={styles.swatch} onClick={() => setColorPicker({ ...colorPicker, show: !colorPicker.show })}>
              <div style={styles.color} />
            </div>
            {colorPicker.show ? <div style={styles.popover}>
              <div style={styles.cover} onClick={() => setColorPicker({ ...colorPicker, show: false })} />
              <SketchPicker color={colorPicker.color} onChange={col => setColorPicker({ ...colorPicker, color: col.rgb })} />
            </div> : null}

          </div>
        </MDBCol>

        {isEdit && <MDBCol size="9" sm="5" md="5" lg="3">
          <FormButton onClickFunc={()=>changeFooterColor()}>CHANGE COLOR</FormButton>
        </MDBCol>}
      </MDBRow>
    </Fragment>
  )
}

const MapDispatchToProps = dispatch => ({
  updateSiteSettings: updateSiteSettings(dispatch),
  loadPage: flag => dispatch(loadPage(flag))
})

export default connect(null, MapDispatchToProps)(FooterColorChange);