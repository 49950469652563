import React, {useState, useEffect, Fragment, useRef} from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import './completed-webinars.style.scss';
import { connect } from 'react-redux';
import CountPerPage from '../../components/table/count-per-page/count-per-page.component';
import TableFilterInput from '../../components/table/filter-input/filter-input.component';
import PageButtons from '../../components/table/pagination/pagination.component';
import LoaderComponent from '../../components/Loader/loader.component';
import CompletedWebinarItemRow from './item-row/item-row.component';
import { sendToShipping, getProductItems, setLimitPerPage, setFilterString, setProductPageNum, setCountWebinars,getCountCompleteWebinar, setProductItems, setFFLFilter} from '../../redux/completed-webinar/completed-webinar.action';
import { loadPage } from '../../redux/user/user.action';
import { setIsFromDuplicate } from '../../redux/user/user.action';
import { useAlert } from 'react-alert';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';
import FFLModal from '../../components/ffl-modal/ffl-modal.component';
import { getFFLLicences,getFFLLicencesFiltered } from '../../redux/ffl-db/ffl-db.action';

const CompletedWebinarPage = ({
    filterStr, 
    limitPerPage, 
    pageNum,
    getProductItems,
    setLimitPerPage,
    isShowSideBar,
    setFilterString,
    setProductPageNum,
    product_complete,
    getCountCompleteWebinar,
    sendToShipping,
    setIsFromDuplicate,
    isFromDuplicate,
    getFFLLicencesFiltered,
    setFFLFilter,
    fflFilter,
    }) => {

    const alert = useAlert();
    let sum;
    sum   = product_complete || 0;
    // for row counts per page
    const countSelect = [10,20,30,50];
    const [countSelectShow, setCountSelectShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [currentRow, setCurrentRow] = useState();
    const [errorWithFFL, setErrorWithFFL] = useState(false);
    const [childrenNeedUpdate, setChildrenNeedUpdate] = useState(false);

    const isMounted = useRef(false);
    
    useEffect(() => {
        return () => {
            /* setLimitPerPage(10); */
            setFilterString("");
        }
    }, [])

    useEffect(() => {
        if(isMounted.current) {
            async function load() {
                setLoading(true);
                await getCountCompleteWebinar();
                const result = await getProductItems(
                    filterStr,   
                    limitPerPage === "All" ? "all" : limitPerPage, 
                    limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1)
                );
                setProducts(result);
                setLoading(false);
            }
            load();
            
        } else {
            isMounted.current = true;
        }
    }, [pageNum]);

    useEffect(() => {        
        async function load() {
            setProductPageNum(1);
            setLoading(true);
            await getCountCompleteWebinar();
            const result = await getProductItems(
                filterStr,   
                limitPerPage === "All" ? "all" : limitPerPage, 
                0,
                fflFilter
            );
            setProducts(result);
            setLoading(false);
        }
        !isFromDuplicate && load();
    }, [limitPerPage, filterStr]);

    const load = async (filterStr, limitPerPage, fflFilter) => {
        setLoading(true);
        await getCountCompleteWebinar();
        const result = await getProductItems(
            filterStr,   
            limitPerPage === "All" ? "all" : limitPerPage, 
            0,
            fflFilter
        );
        setLoading(false);
        if(result ){
            setProducts(result);
        }
    }

    const [isMobileSize, setIsMobileSize] = useState(false);

    // const getItemsWithLinkFilter = (filter) => {
    //     async function load() {
    //         setProductPageNum(1);

    //         await getProductItems(
    //             filterStr,   
    //             limitPerPage === "All" ? "all" : limitPerPage, 
    //             0,
    //             // filter
    //         );
            

    //     }
    //     load()
    // }

    const getItemsFromRow = async () => {
        setLoading(true);
        await getCountCompleteWebinar();
        const result = await getProductItems(
            filterStr,   
            limitPerPage === "All" ? "all" : limitPerPage, 
            limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1),
            fflFilter
        );
        setProducts(result);
        setLoading(false);
    }
   
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setIsFromDuplicate(false);
        getCountCompleteWebinar();

        function handleResize() {
            if (window.innerWidth < 850)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();
      
        
        window.addEventListener('resize', handleResize);
        setIsEdit(JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'completedWebinarsEdit'));
    }, []);

    const handleFFLChange = async (modifiedFFL,isEdit,oneTimeChange,fflNotRequired) => {
        // if(new Date(modifiedFFL.expDate) < new Date()) {
        // 	return alert.error("Expired License");
        // }
        // setSelectedFflItem(modifiedFFL);
        let winner_id
        let ffl_id
        let ffl_one_time
        let ffl_not_required
        if(isEdit){
            winner_id=currentRow.id
            ffl_id=modifiedFFL.id
            oneTimeChange?ffl_one_time=true:ffl_one_time=false
        } else {
            winner_id=modifiedFFL.id
            oneTimeChange=null
            fflNotRequired? ffl_id=null : ffl_id=modifiedFFL.ffl_databases.id
        }
        const obj = {
            winner_id: winner_id,
            ffl_id: ffl_id,//modifiedFFL.ffl_one_time? modifiedFFL.ffl_id:modifiedFFL.ffl_databases.id,
            ffl_not_required: fflNotRequired,
            ffl_one_time:oneTimeChange,
            ffl_edited:isEdit
        }
        console.log(obj)
        let result;
        try {
            setLoading(true);
            result = await sendToShipping(obj);
            setLoading(false);
            console.log("result1", result);

            if (result === "success"){
                setErrorWithFFL(false);
                alert.success("FFL is assigned successfully");
                setLoading(true);
                await load(filterStr, limitPerPage, fflFilter);
                setLoading(false);
                setShowModal(false);
                // await getProductItemsCount()}
            } else{
                alert.error(result || "Failed assigning");
                setErrorWithFFL(true);
                setShowModal(false);
            }
        } catch (error) {
            alert.error(result || "Failed assigning");
            setLoading(false);
        }
        //window.location.replace('');
	}
    
    return (
        <>
            {
                loading && (
                    <LoaderComponent />
                )
            }
            {
                showModal && (
                    <FFLModal errorWithFFL={errorWithFFL} setErrorWithFFL={setErrorWithFFL} changeFFL={handleFFLChange} alert={alert} getFFLLicencesFiltered={getFFLLicencesFiltered} closeModal={() => setShowModal(false)} loading={loading} setLoading={setLoading}/>
                )
            }
            <div className="ffl-db-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
                <MDBRow className="headerRow">
                    <MDBCol size="12" sm="12" md="9" lg="5">
                        <h2 className="text-white font-weight-bold">Completed Webinars</h2>
                    </MDBCol>
                    <MDBCol size="6" className="text-white text-center">
                        <h3 className="text-white font-weight-bold">Total: {sum}</h3>
                    </MDBCol>
                </MDBRow>
                <div className="section">
                    <div className="toolbar">
                        <div className="leftDiv">
                            <div>
                                <CountPerPage 
                                    options={countSelect} 
                                    showSelectBox={()=>setCountSelectShow(!countSelectShow)} 
                                    selectOption={(event)=>{
                                        setCountSelectShow(false);
                                        setLimitPerPage(event.target.id);
                                    }} 
                                    optionShow={countSelectShow} 
                                    placeholder={limitPerPage}
                                />
                                <label className="ml-1">Per Page</label>
                            </div>
                            <TableFilterInput str={filterStr} setFilterString={setFilterString}/>
                            <div className='check-ffl'>
                                <label>Filter by FFL</label>
                                <input checked={fflFilter === 'ffl' ? true : false} type="checkbox" onChange={() => {
                                    setFFLFilter(fflFilter === '' ? 'ffl' : '');
                                }} />
                            </div>
                            {/* <div className="table-filter-input">
                                <select name="opts" className='select-filter-opts' onChange={(e) => {
                                    setLinkFilter(e.target.value);
                                    getItemsWithLinkFilter(e.target.value);
                                }} >
                                    <option value="all">All</option>
                                    <option value="ffl">Only FFL</option>
                                    <option value="non-ffl">Only Non-FFL</option>
                                    <option value="link">Only with link</option>
                                    <option value="non-link">Only without link</option>
                                </select>
                            </div> */}
                        </div>
                        {
                            products && products.count && (
                                <PageButtons 
                                    count={ limitPerPage === "All" ? 1 : Math.ceil(products?.count/limitPerPage) } 
                                    currentIndex = {pageNum}
                                    setProductPageNum={setProductPageNum}
                                />  
                            )
                        }
                    </div>  
                    {
                        isMobileSize ? 
                            <MDBRow className="table-header">
                                <MDBCol size="5" className="text-white text-center">
                                    Thumbnail
                                </MDBCol>
                                <MDBCol size="5" className="text-white text-center">
                                    Webinar Name
                                </MDBCol>
                            </MDBRow>
                        :
                        <MDBRow className="table-header">
                            <MDBCol size="1" className="text-white">
                                Photo
                            </MDBCol>
                            <MDBCol size="1" className="text-white text-center">
                                UPC
                            </MDBCol>
                            <MDBCol size="2" className="text-white text-center">
                                Webinar Name
                            </MDBCol>
                            <MDBCol size="2" className="text-white text-center">
                                Winner
                            </MDBCol>
                            {/* {isEdit && 
                            <MDBCol size="1" className="text-white text-center">
                                Remove / Add Link
                            </MDBCol> } */}
                            <MDBCol size="1" className="text-white text-center">
                                FFL Selected
                            </MDBCol>
                            <MDBCol size="1"  className="text-white text-center">
                                FFL  Expiration Date
                            </MDBCol>
                            <MDBCol size="1" className="text-white text-center">
                                FFL Number
                            </MDBCol>
                            <MDBCol size="1" className="text-white text-center">
                                Edit FFL
                            </MDBCol>
                            {/* <MDBCol size={isEdit ? '1' : '2'} className="text-white text-center">
                                FFL
                            </MDBCol> */}
                            <MDBCol size="1" className="text-white text-center">
                                Send to Shipping
                            </MDBCol>
                            <MDBCol size="1" className="text-white text-center">
                                Not Required
                            </MDBCol>
                        </MDBRow>
                    }
                    {
                        products && products.rows && products.rows.length > 0 ? products.rows.map( item => 
                            <CompletedWebinarItemRow 
                                key={item.id} 
                                item={item} 
                                ffl={products.ffl_data} 
                                isMobile = {isMobileSize}
                                getItemsAgain={getItemsFromRow}
                                isEdit={isEdit}
                                showModal={setShowModal}
                                currentRow={setCurrentRow}
                                handleFFLChange={handleFFLChange}
                                setErrorWithFFL={setErrorWithFFL}
                            />
                            )
                            :
                            <div className="non-row text-center">
                                No Data
                            </div>
                    }
                </div>
            </div>
        </>
    )
}

const MapStateToProps = ({ completeWebinar: { filterStr, product_items, product_complete , limitPerPage, pageNum, fflFilter }, user: {isShowSideBar, isFromDuplicate}}) => ({
    filterStr,
    product_items,
    product_complete,
    limitPerPage,
    pageNum,
    isShowSideBar,
    isFromDuplicate,
    fflFilter
})

const MapDispatchToProps = dispatch => ({
    getProductItems: getProductItems(dispatch),
    getCountCompleteWebinar: getCountCompleteWebinar(dispatch),
    sendToShipping: sendToShipping(dispatch),
    setLimitPerPage: count => dispatch(setLimitPerPage(count)),
    setFilterString: str => dispatch(setFilterString(str)),
    setProductItems: items => dispatch(setProductItems(items)),
    setProductPageNum: num => dispatch(setProductPageNum(num)),
    setCountWebinars: num => dispatch(setCountWebinars(num)),
    setIsFromDuplicate: flag => dispatch(setIsFromDuplicate(flag)),
    setCountWebinars: num => dispatch(setCountWebinars(num)),
    getFFLLicences: getFFLLicences(dispatch),
    getFFLLicencesFiltered: getFFLLicencesFiltered(dispatch),
    setFFLFilter: str => dispatch(setFFLFilter(str)),
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(CompletedWebinarPage));
