import React, {useState, useEffect, Fragment, useRef} from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import './sold-physical.style.scss';
import { connect, useDispatch } from 'react-redux';
import CountPerPage from '../../components/table/count-per-page/count-per-page.component';
import TableFilterInput from '../../components/table/filter-input/filter-input.component';
import PageButtons from '../../components/table/pagination/pagination.component';
import SoldPhysicalItemRow from './sold-physical-row/sold-physical-row.component';
import { getProductItems, setLimitPerPage, setFilterString, setProductPageNum, getCountProductItems, getProductItemsCount, setFFLFilter, addFFLItem } from '../../redux/sold-physical/sold-physical.action';
import { loadPage, setIsFromDuplicate } from '../../redux/user/user.action';
import { useAlert } from 'react-alert';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';
import { formatDateToSearch } from '../../utils/formatDateToSearch';
import LoaderComponent from '../../components/Loader/loader.component';
import FFLModal from '../../components/ffl-modal/ffl-modal.component';
import { getFFLLicencesFiltered } from '../../redux/ffl-db/ffl-db.action';

const SoldPhysicalPage = ({
    filterStr, 
    product_items_count,
    limitPerPage, 
    pageNum,
    getProductItems,
    getProductItemsCount,
    setLimitPerPage,
    isShowSideBar,
    setFilterString,
    setProductPageNum,
    isFromDuplicate, 
    setIsFromDuplicate,
    getCountProductItems,
    fflFilter,
    setFflFilter,
    getFFLLicencesFiltered,
    addFFLItem
    }) => {

    const alert = useAlert();
    let sum = 0;
    sum= product_items_count?.count || 0;
    // for row counts per page
    const countSelect = [10,20,30,50];
    
    const [countSelectShow, setCountSelectShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    
    const [errorWithFFL, setErrorWithFFL] = useState(false);

    const [showFFLModal, setShowFFLModal] = useState(false)
    const [childNeedToBeUpdated, setChildNeedToBeUpdated] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);


    const isMounted = useRef(false);
    const dispatch = useDispatch();

    const setAllStatesToFalse = () => {
        setShowModal(false);
        setShowFFLModal(false);
    }

    useEffect(() => {
        
        return () => {
            /* setLimitPerPage(10); */
            setFilterString("");
            setProductPageNum(1);
        }
    }, [])

    useEffect(() => {
        if(isMounted.current) {
            async function load() {
                setLoading(true);
                const results = await getProductItems(
                    filterStr,   
                    limitPerPage === "All" ? "all" : limitPerPage, 
                    limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1),
                    fflFilter
                    );
    
                setProducts(results);
                setLoading(false);
            }
            load();
        } else {
            isMounted.current = true;
        }
    }, [pageNum]);

    
    useEffect(() => {    
        let tempDate = formatDateToSearch(filterStr)
        async function load() {
            setProductPageNum(1);
            setLoading(true);
            const result = await getProductItems(
                tempDate !== "" ? tempDate : filterStr,
                limitPerPage === "All" ? "all" : limitPerPage, 
                0,
                fflFilter
                );
            
            setProducts(result);
            setLoading(false);
        }
        !isFromDuplicate && load();
    }, [limitPerPage, filterStr, fflFilter]);

    const [isMobileSize, setIsMobileSize] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setIsFromDuplicate(false);
        getProductItemsCount();
        function handleResize() {
            if (window.innerWidth < 850)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        setIsEdit(JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'soldOutPhysicalEdit'));
    }, []);

    const load = async (filterStr, limitPerPage, pageNum, fflFilter) =>  {
        setLoading(true);
        let tempDate = formatDateToSearch(filterStr)
        const result = await getProductItems(
            tempDate !== "" ? tempDate : filterStr,
            limitPerPage === "All" ? "all" : limitPerPage, 
            0,
            fflFilter
            );
        await getCountProductItems();
        setProducts(result);
        setLoading(false);
    }

    const getItemsWithFflFilter = (filter) => {
        async function load() {
            setProductPageNum(1);
            setLoading(true);
            const result = await getProductItems(
                filterStr, 
                limitPerPage === "All" ? "all" : limitPerPage, 
                0,
                filter
                );
            
            setProducts(result);
            setLoading(false);
        }
        load();
    }


    const handleFFLChange = async ( modifiedFFL,isEdit,oneTimeChange,fflNotRequired) => {
        setShowFFLModal(false)
        
        let purchase_id = itemToEdit?.id
        let ffl_id = modifiedFFL?.id
        let ffl_one_time

        if(isEdit){
            oneTimeChange?ffl_one_time=true:ffl_one_time=false
        } else {
            oneTimeChange=null
            fflNotRequired? ffl_id=null : ffl_id=modifiedFFL?.ffl_databases?.id
        }
        
        
        const obj = { 
            purchase_id,
            ffl_id,
            ffl_not_required: fflNotRequired,
            ffl_one_time:oneTimeChange,
            ffl_edited:isEdit,
            sendToShipping: false
        }

        try {
            setLoading(true);
            const result = await addFFLItem(obj);
            setLoading(false);

            if (result === "success"){        
                setLoading(true);
                await load( filterStr, limitPerPage, pageNum, fflFilter);
                setLoading(false);
                alert.success("FFL is assigned successfully and will be sent to shipping",
                {
                    delay: 1000,
                    timeout: 3200,
                });
                setAllStatesToFalse();
            } else{
                alert.error(result || "Failed assigning");
                setAllStatesToFalse();
            }
        } catch (error) {
            alert.error( "Failed assigning");
            setAllStatesToFalse();
        }
        setAllStatesToFalse();
    }

    return (
       <>
       {
        loading && (
            <LoaderComponent />
        )
       }
       {
            showModal && (
                <FFLModal setErrorWithFFL={setErrorWithFFL} errorWithFFL={errorWithFFL} changeFFL={handleFFLChange} alert={alert} getFFLLicencesFiltered={getFFLLicencesFiltered} closeModal={() => setShowModal(false)} loading={loading} setLoading={setLoading}/>
            )
        }
        <div className="ffl-db-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>         
            <MDBRow className="headerRow">
                <MDBCol size="12" sm="12" md="9" lg="6">
                    <h2 className="text-white font-weight-bold">Sold Physical Products</h2>
                </MDBCol>
                <MDBCol size="12" sm="12" md="9" lg="2">
                    <h2 className="text-white font-weight-bold">Total: {sum}</h2>
                </MDBCol>
            </MDBRow>
            <div className="section">
                <div className="toolbar">
                    <div className="leftDiv">
                        <div>
                            <CountPerPage 
                                options={countSelect} 
                                showSelectBox={()=>setCountSelectShow(!countSelectShow)} 
                                selectOption={(event)=>{
                                    setCountSelectShow(false);
                                    setLimitPerPage(event.target.id);
                                }} 
                                optionShow={countSelectShow} 
                                placeholder={limitPerPage}
                            />
                            <label className="ml-1">Per Page</label>
                        </div>
                        <TableFilterInput str={filterStr} setFilterString={setFilterString}/>
                        <div className="table-filter-input">
                        <select 
                        value={fflFilter}
                        name="opts" className='select-filter-opts' onChange={(e) => {
                                setFflFilter(e.target.value);
                                getItemsWithFflFilter(e.target.value);
                            }} >
                                <option value="all">All</option>
                                <option value="ffl">Already Sent to shipping</option>
                                <option value="non-ffl">Not Sent to shipping</option>
                            </select>
                        </div>
                    </div>
                    <PageButtons 
                        count={ limitPerPage === "All" ? 1 : Math.ceil(products?.count/limitPerPage) } 
                        currentIndex = {pageNum}
                        setProductPageNum = {setProductPageNum}
                    />  
                </div>
                <div className="total-count-div mb-4">
                    <span>{products?.count} results shown</span>
                </div>    
                {
                    isMobileSize ? 
                        <MDBRow className="table-header">
                            <MDBCol size="4" className="text-white text-center">
                                Thumbnail
                            </MDBCol>
                            <MDBCol size="6" className="text-white text-center">
                                Name
                            </MDBCol>
                        </MDBRow>
                    :        
                    <MDBRow className="table-header">
                        <MDBCol size="1" className="text-white">
                            Thumbnail
                        </MDBCol>
                        <MDBCol size={isEdit ? '1' : '2'} className="text-white text-center">
                            UPC
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Name
                        </MDBCol>
                        <MDBCol size="2" className="text-white text-center">
                            Buyer's Name
                        </MDBCol>                    
                        <MDBCol size="1" className="text-white text-center">
                            Sold Date
                        </MDBCol>  
                        <MDBCol size="1" className="text-white text-center">
                            FFL Expiration
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            FFL Selected
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Edit FFL
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Send to Shipping
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            FFL Not Required
                        </MDBCol>
                        {isEdit && <MDBCol size="1" className="text-white text-center">
                            Duplicate
                        </MDBCol> }          
                    </MDBRow>
                }
                {
                    products && products.rows && products.rows.length > 0 ? products.rows.map( item => 
                        <SoldPhysicalItemRow 
                            key={item.id} 
                            item={item} 
                            ffl={products.ffl_data} 
                            isMobile={isMobileSize}
                            isEdit={isEdit}
                            setLoading={setLoading}
                            loading={loading}
                            setShowModal={setShowModal}
                            showEditFFLModal={()=> {
                                setShowFFLModal(true)
                            }}
                            setCurrentRow={setCurrentRow}
                            setChildNeedToBeUpdated={setChildNeedToBeUpdated}
                            loadParent={() =>  {
                                setLoading(true);
                                load( filterStr, limitPerPage, pageNum, fflFilter)
                                setLoading(false);
                            }
                            }

                            loadDataOnSuccess={() => {
                                setLoading(true);
                                load( filterStr, limitPerPage, pageNum, fflFilter);
                                setLoading(false);
                            }}

                            setItemToEditFunc={setItemToEdit}
                        /> )
                        :
                        <div className="non-row text-center">
                            No Data
                        </div>
                }
            </div>
        </div>
       </>
    )
}

const MapStateToProps = ({ soldPhysical: { filterStr, product_items, product_items_count, limitPerPage, pageNum, fflFilter }, user: {isShowSideBar, isFromDuplicate}}) => ({
    filterStr,
    product_items,
    product_items_count,
    limitPerPage,
    pageNum,
    isShowSideBar,
    isFromDuplicate,
    fflFilter
})

const MapDispatchToProps = dispatch => ({
    getProductItems: getProductItems(dispatch),
    getProductItemsCount: getProductItemsCount(dispatch),
    loadPage: flag => dispatch(loadPage(flag)),
    setLimitPerPage: count => dispatch(setLimitPerPage(count)),
    setFilterString: str => dispatch(setFilterString(str)),
    setProductPageNum: num => dispatch(setProductPageNum(num)),
    setIsFromDuplicate: flag => dispatch(setIsFromDuplicate(flag)),
    getCountProductItems: getCountProductItems(dispatch),
    setFflFilter: ffl => dispatch(setFFLFilter(ffl)),
    getFFLLicencesFiltered: getFFLLicencesFiltered(dispatch),
    addFFLItem: addFFLItem(dispatch),

})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(SoldPhysicalPage));