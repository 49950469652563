import React, { useState, Fragment, useEffect } from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import CountPerPage from '../../../components/table/count-per-page/count-per-page.component';
import PageButtons from '../../../components/table/pagination/pagination.component';
import { connect } from 'react-redux';
import { getProductItems, setLimitPerPage, selectWinner, setProductPageNum, clearWinnerID, getHotSeatWinner, getHotSeatEmail } from '../../../redux/live-stream/live-stream.action';
import { validateFFLInformation} from '../../../redux/ffl-db/ffl-db.action.js';
import { loadPage } from '../../../redux/user/user.action';
import './live-stream.style.scss';
import { useAlert } from 'react-alert';
import { withRouter, useHistory } from 'react-router-dom';
import LiveStreamRow from './live-stream-row/live-stream-row.component';
import router from '../../../router';
import { getProductItemsCount } from '../../../redux/sold-out-webinars/sold-out-webinars.action';
import moment from 'moment-timezone';

const LiveStreamModePage = withRouter(({
    match,
    location,
    filterStr,
    product_items,
    limitPerPage,
    pageNum,
    getProductItems,
    getHotSeatWinner,
    getHotSeatEmail,
    loadPage,
    setLimitPerPage,
    winnerID,
    clearWinnerID,
    selectWinner,
    validateFFLInformation,
    isShowSideBar,
    setProductPageNum,
    getProductItemsCount
}) => {

    const alert = useAlert();
    const historyURL = useHistory();

    const webinarID = match.params.webinarID;
    const [fflExpirationDate, setFflExpirationDate] = useState('');
    const [fflLoaExpirationDate, setFflLoaExpirationDate] = useState('');
    const [fflBusinessName, setFFLBusinessName] = useState('');
    const [fflLicenseName, setFFLLicenseName] = useState('');
    const [fflPhoneNumber, setFFLPhoneNumber] = useState('');
    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [isValidatingFFL, setIsValidatingFFL] = useState(false);
    const [isValidFFL, setIsValidFFL] = useState(false);
    const [isCongrate, setIsCongrate] = useState(false);
    const [isHotSeatWinner, setIsHotSeatWinner] = useState(false);
    const [isDisplay, setIsDisplay] = useState(true);
    const [hotSeatCheck, setHotSeatCheck] = useState(false);

    // for row counts per page
    const countSelect = [10, 20, 30, 50, "All"];
    const [countSelectShow, setCountSelectShow] = useState(false);

    const [webinarName, setWebinarName] = useState();
    const [webinarImg, setWebinarImg] = useState();
    const [hotSeatWinner, setHotSeatWinner] = useState();
    const [ishotSeatUser, setIsHotSeatUser] = useState(false);
    const [winnerButton, setWinnerButton] = useState(false);

    useEffect(() => {
        async function outputHotSeatWinner() {
            loadPage(true);
            const winnerUser = await getHotSeatWinner(
                webinarID,
                limitPerPage = "all",
                0
            );
            if (typeof winnerUser !== 'undefined') {
                setHotSeatWinner(winnerUser[0].hot_seat_winner?.username);
                setIsHotSeatUser(true);
            }
            loadPage(false);
        }
        outputHotSeatWinner();
    }, [limitPerPage, filterStr]);

    const clearWinnerIDFunc = async () => {
        await clearWinnerID();
    }

    useEffect(() => {
        clearWinnerIDFunc()
    }, []);

    useEffect(() => {
        location && location.state && location.state.webinarName && setWebinarName(location.state.webinarName)
        location && location.state && location.state.imageUrl && setWebinarImg(location.state.imageUrl)
    }, [location]);

    useEffect(() => {
        async function load() {
            loadPage(true);
            await getProductItems(
                webinarID,
                limitPerPage === "All" ? "all" : limitPerPage,
                limitPerPage === "All" ? 0 : limitPerPage * (pageNum - 1)
            );

            loadPage(false);
        }
        load();
    }, [pageNum]);

    useEffect(() => {
        setProductPageNum(1);
        async function load() {
            loadPage(true);
            await getProductItems(
                webinarID,
                limitPerPage === "All" ? "all" : limitPerPage,
                0
            );

            loadPage(false);
        }
        load();
    }, [limitPerPage, filterStr]);

    const validateFFLWinner = async () => {
        loadPage(true);
        if ((winnerID.seatID === "") || (winnerID.userID === "")) {
            alert.error("Please choose a number to complete winner selection");
            return;
        }
        const fflValidationResult= await validateFFLInformation({user_id: winnerID.userID, ffl_number:null})
        console.log("fflValidationResult!!",fflValidationResult)
        setIsValidatingFFL(true);
        if(fflValidationResult.isValid && fflValidationResult.userHasFFL && fflValidationResult.fflFound){
            setIsValidFFL(true)
        } else {
            setIsValidFFL(false)
            if(!fflValidationResult.isValid) alert.error("Selected user has an invalid FFL!");
            if(!fflValidationResult.userHasFFL) alert.error("Selected user has not an FFL assigned!");
            if(fflValidationResult.userHasFFL && !fflValidationResult.fflFound) alert.error("Selected user's FFL is invalid!");
        }
        setFflExpirationDate(fflValidationResult.expirationDate)
        setFflLoaExpirationDate(fflValidationResult.loaExpirationDate)
        setFFLBusinessName(fflValidationResult.fflName)
        setFFLLicenseName(fflValidationResult.fflLicenseName)
        setUserPhoneNumber(fflValidationResult.userPhoneNumber)
        setFFLPhoneNumber(fflValidationResult.fflVoiceTelephone)
        loadPage(false);
    }

    const selectWinnerFunc = async () => {
        if(hotSeatCheck === false && isHotSeatWinner === true){
            alert.error("Please display the hot seat winner first");
        } else
        if ((winnerID.seatID === "") || (winnerID.userID === "")) {
            alert.error("Please choose a number to complete winner selection");
            return;
        }
        else {
            loadPage(true);
            const obj = {
                webinar_type: "webinar",
                gifts: [{
                    user_id: winnerID.userID,
                    id: webinarID,
                    seatNo: winnerID.seatNum
                }],
                webinar_id: webinarID
            }
            const result = await selectWinner(obj);
            await getProductItemsCount()
            if (result === "success") {
                setIsCongrate(true);
            }
            else
                alert.error("Please choose a number to complete winner selection");
            loadPage(false);
        }
    }    

    const selectHotSeatFunc = async () => {

        getHotSeatEmail(webinarID);
        setIsHotSeatWinner(true);
        setIsHotSeatUser(false);
        setWinnerButton(true);

    }
  
    return (
        <div className="sold-webinar-page" style={{ width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)' }}>
            {
                isValidatingFFL && <div className="removeDialogWrapper">
                    <div className="removeDialog">
                        <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={() => {
                            setIsValidatingFFL(false);
                            setFflExpirationDate('')
                            setFFLBusinessName('')
                            setFFLLicenseName('')
                            setFFLPhoneNumber('')
                            setUserPhoneNumber('')
                            historyURL.push(router.soldOutWebinars.path);
                        }} />
                        {
                            isValidFFL?
                            <div>
                                <h2 className="text-white text-center ft43 font-weight-bold mb-4"><span style={{ fontFamily: 'emoji' }}><i>{winnerID.userName}</i></span></h2>
                                <h2 className="text-white text-center ft35 font-weight-bold mb-4">FFL Business Name:</h2>
                                <h3 className="text-white text-center ft26 font-weight-bold mb-3">
                                {
                                    (fflBusinessName!=''&& fflBusinessName!=null)?fflBusinessName:fflLicenseName
                                }
                                </h3>
                                {
                                    fflLoaExpirationDate && <>
                                        <h2 className="text-white text-center ft35 font-weight-bold mb-4">FFL loa expiration date:</h2>
                                        <h3 className="text-white text-center ft26 font-weight-bold mb-3">{moment(fflLoaExpirationDate.substring(0,10)).format('MM/DD/YYYY')}</h3>  
                                    </>
                                }
                                <h2 className="text-white text-center ft35 font-weight-bold mb-4">FFL expiration date:</h2>
                                <h3 className="text-white text-center ft26 font-weight-bold mb-3">{moment(fflExpirationDate.substring(0,10)).format('MM/DD/YYYY')}</h3>            
                                {
                                    (fflPhoneNumber!=''&& fflPhoneNumber!=null) &&
                                    <>
                                        <h2 className="text-white text-center ft35 font-weight-bold mb-4">FFL Contact number:</h2>
                                        <h3 className="text-white text-center ft26 font-weight-bold mb-3"> ({fflPhoneNumber.slice(2, 5)}) {fflPhoneNumber.slice(5, 8)}-{fflPhoneNumber.slice(8)} </h3>
                                    </>
                                }
                                <h2 className="text-white text-center ft35 font-weight-bold mb-4">User Contact number:</h2>
                                <h3 className="text-white text-center ft26 font-weight-bold mb-3">({userPhoneNumber.slice(2, 5)}) {userPhoneNumber.slice(5, 8)}-{userPhoneNumber.slice(8)}</h3>
                            </div>
                            :
                            <div>
                                <h2 className="text-white text-center ft43 font-weight-bold mb-4"><span style={{ fontFamily: 'emoji' }}><i>{winnerID.userName}</i></span></h2>
                                <h2 className="text-white text-center ft35 font-weight-bold mb-4">Has not assigned a preferred FFL yet.
                                </h2>
                            </div>
                        }
                    </div>
                </div>
            }
            {
                isCongrate && <div className="removeDialogWrapper">
                    <div className="removeDialog">
                        <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={() => {
                            validateFFLWinner()
                            // historyURL.push(router.soldOutWebinars.path);
                            setIsCongrate(false);
                        }} />
                        <br />
                        <h1 className="text-white text-center ft43 font-weight-bold mb-4">Congratulations to <span style={{ fontFamily: 'emoji' }}><i>{winnerID.userName}</i></span></h1>
                        <h2 className="text-white text-center ft35 font-weight-bold mb-4">You've been chosen for <span style={{ fontFamily: 'emoji' }}><i>{location && location.state && location.state.webinarName && location.state.webinarName}</i></span></h2>
                    </div>
                </div>
            }
            {
                isHotSeatWinner && <div className="removeDialogWrapper">
                    <div className="removeDialog">
                        <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={() => {
                            setIsHotSeatWinner(false);
                            setIsDisplay(false);
                            setHotSeatCheck(true);
                        }} />
                        <br />
                        <h1 className="text-white text-center ft43 font-weight-bold mb-4">Congratulations to <span style={{ fontFamily: 'emoji' }}><i>{hotSeatWinner}</i></span></h1>
                        <h2 className="text-white text-center ft35 font-weight-bold mb-4">You've been chosen for <span style={{ fontFamily: 'emoji' }}><i>The Hot Seat</i></span></h2>
                    </div>
                </div>
            }
            <div className="headerRow">
                <h2 className="text-white font-weight-bold">Live Stream Mode</h2>
                <h5 className="text-white font-weight-bold">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1000.000000 1000.000000" preserveAspectRatio="xMidYMid meet"><metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata><g transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)" fill='#fff' stroke="none"><path d="M9329 8527 c-77 -52 -139 -180 -139 -288 l0 -49 -3883 0 c-3515 0
                    -3888 -2 -3937 -16 -94 -28 -181 -98 -222 -179 -34 -65 -247 -401 -263 -415
                    -14 -11 -24 -6 -68 37 -129 126 -234 149 -323 71 -106 -93 -80 -186 101 -356
                    l93 -87 -179 -280 c-233 -364 -389 -623 -447 -743 -77 -159 -64 -215 62 -277
                    85 -41 204 -66 446 -94 283 -32 421 -84 541 -201 173 -169 208 -402 114 -765
                    -50 -194 -137 -417 -288 -740 -225 -482 -370 -939 -452 -1428 -74 -446 -96
                    -1012 -45 -1150 21 -57 69 -111 110 -124 14 -4 617 -6 1340 -3 l1315 5 45 25
                    c35 19 51 38 73 82 27 53 29 63 28 185 -5 805 167 1613 526 2466 25 59 48 107
                    52 107 4 0 50 -9 102 -20 448 -93 1084 -113 1784 -54 379 31 762 78 814 100
                    55 23 109 81 132 144 19 51 21 79 23 365 3 356 10 408 78 545 68 141 190 244
                    355 301 137 47 182 49 1119 49 632 0 891 3 915 11 91 31 139 102 147 216 l5
                    72 212 3 212 3 49 30 c30 19 60 49 79 79 l30 49 3 875 c3 968 5 937 -61 1024
                    -48 63 -103 88 -199 88 l-78 0 0 49 c0 68 -34 172 -73 223 -51 66 -90 88 -157
                    88 -43 0 -65 -6 -91 -23z m-5009 -1066 l0 -338 26 -27 27 -26 1072 0 1072 0
                    27 26 26 27 0 338 0 339 1505 0 1505 0 0 -669 0 -668 -122 -6 c-292 -14 -458
                    -116 -458 -282 l0 -55 -2595 0 c-2060 1 -2603 -2 -2637 -12 -57 -17 -113 -72
                    -127 -126 -24 -87 10 -152 101 -193 49 -22 68 -24 256 -27 199 -3 202 -4 203
                    -25 2 -39 -2 -71 -13 -106 -12 -40 -518 -988 -558 -1046 -193 -282 -373 -794
                    -495 -1407 -81 -411 -165 -1103 -165 -1365 l0 -63 -1079 0 -1079 0 -12 102
                    c-18 142 -8 447 19 603 62 352 158 664 461 1495 180 492 296 835 342 1010 40
                    151 50 360 24 490 -65 317 -310 557 -707 690 -86 29 -275 74 -341 82 -28 3
                    -23 12 336 648 199 355 394 697 432 760 79 132 100 147 217 160 40 4 672 8
                    1405 9 l1332 1 0 -339z m1880 159 l0 -180 -755 0 -755 0 0 180 0 180 755 0
                    755 0 0 -180z m-1299 -1886 l125 -7 47 -166 c26 -91 56 -209 68 -261 11 -52
                    31 -114 45 -137 44 -75 127 -123 212 -123 63 0 95 24 116 87 28 81 21 162 -34
                    387 -27 114 -50 211 -50 216 0 6 209 10 580 10 l580 0 -35 -49 c-48 -66 -99
                    -180 -124 -273 -37 -142 -46 -235 -46 -489 l0 -246 -130 -22 c-341 -57 -657
                    -76 -1140 -68 -390 6 -922 41 -943 62 -6 6 43 126 146 360 128 291 255 534
                    355 678 38 54 43 58 71 53 17 -3 88 -8 157 -12z"></path><path d="M1592 6993 c-18 -9 -45 -32 -60 -51 -24 -31 -27 -44 -27 -112 0 -68
                    3 -81 27 -112 55 -72 -8 -68 1018 -68 1026 0 963 -4 1018 68 24 31 27 44 27
                    112 0 68 -3 81 -27 112 -55 72 8 68 -1020 68 -834 0 -926 -2 -956 -17z"></path><path d="M7342 6993 c-18 -9 -45 -32 -60 -51 -24 -31 -27 -44 -27 -112 0 -68
                    3 -81 27 -112 54 -70 29 -68 688 -68 659 0 634 -2 688 68 24 31 27 44 27 112
                    0 68 -3 81 -27 112 -54 70 -29 68 -690 68 -529 0 -597 -2 -626 -17z"></path><path d="M2620 5507 c-25 -13 -58 -43 -75 -66 -25 -36 -30 -54 -33 -114 -3
                    -61 0 -79 21 -117 13 -25 43 -58 66 -75 36 -25 54 -30 114 -33 61 -3 79 0 117
                    21 25 13 58 43 75 66 25 36 30 54 33 114 3 61 0 79 -21 117 -13 25 -43 58 -66
                    75 -36 25 -54 30 -114 33 -61 3 -79 0 -117 -21z"></path><path d="M1830 2617 c-25 -13 -58 -43 -75 -66 -25 -36 -30 -54 -33 -114 -3
                    -61 0 -79 21 -117 13 -25 43 -58 66 -75 36 -25 54 -30 114 -33 61 -3 79 0 117
                    21 25 13 58 43 75 66 25 36 30 54 33 114 3 61 0 79 -21 117 -13 25 -43 58 -66
                    75 -36 25 -54 30 -114 33 -61 3 -79 0 -117 -21z"></path></g></svg>
                    {webinarName && <span>{webinarName}</span>}</h5>
            </div>
            <div className="section">
                <div className="toolbar">
                    <div className="leftDiv">
                        <div>
                            <CountPerPage
                                options={countSelect}
                                showSelectBox={() => setCountSelectShow(!countSelectShow)}
                                selectOption={(event) => {
                                    setCountSelectShow(false);
                                    setLimitPerPage(event.target.id);
                                }}
                                optionShow={countSelectShow}
                                placeholder={limitPerPage}
                            />
                            <label className="ml-1">Per Page</label>
                        </div>
                        <img className="webinarImg" src={webinarImg} alt={webinarName} />
                    </div>
                    <PageButtons
                        count={limitPerPage === "All" ? 1 : Math.ceil(product_items.count / limitPerPage)}
                        currentIndex={pageNum}
                        setProductPageNum={setProductPageNum}
                    />
                </div>

                <MDBRow className="table-header">
                    <MDBCol size="1" className="text-white">

                    </MDBCol>
                    <MDBCol size="4" className="text-white text-center ft19">
                        <span className="ft16">Seat No.</span>
                    </MDBCol>
                    <MDBCol size="6" sm="7" md="7" lg="7" className="text-white text-center ft19">
                        <span className="ft16">User Name</span>
                    </MDBCol>
                </MDBRow>

                {
                    product_items && product_items.rows && product_items.rows.length > 0 ? product_items.rows.map(item => <LiveStreamRow
                        key={item.id}
                        item={item}
                    />)
                        :
                        <div className="non-row text-center">
                            No Data
                        </div>
                }

                <MDBRow center className="mt-4">
                    {
                        <MDBCol size="10" sm="8" md="4" lg="4" className="mt-auto mb-auto">
                        <button className="winnerBtn mt-auto" onClick={() => selectWinnerFunc()}><MDBIcon icon="trophy" /><span className="ml-2">SELECT A SEAT</span></button>
                        </MDBCol>
                        
                    }
                    {
                        hotSeatWinner && isDisplay ?
                            <MDBCol size="10" sm="8" md="4" lg="4" className="mt-auto mb-auto">
                                <button className="hotSeatBtn mt-auto" onClick={() => selectHotSeatFunc()}><MDBIcon icon="trophy" /><span className="ml-2">REVEAL HOT SEAT</span></button>
                            </MDBCol>
                            :
                            ""
                    }
                </MDBRow>
            </div>

        </div>
    )

})

const MapStateToProps = ({ liveStrem: { filterStr, product_items, limitPerPage, pageNum, winnerID }, user: { isShowSideBar } }) => ({
    filterStr,
    product_items,
    limitPerPage,
    pageNum,
    winnerID,
    isShowSideBar
})

const MapDispatchToProps = dispatch => ({
    clearWinnerID: () => dispatch(clearWinnerID()),
    getProductItems: getProductItems(dispatch),
    getHotSeatWinner: getHotSeatWinner(dispatch),
    getHotSeatEmail: getHotSeatEmail(dispatch),
    loadPage: flag => dispatch(loadPage(flag)),
    setLimitPerPage: count => dispatch(setLimitPerPage(count)),
    selectWinner: selectWinner(dispatch),
    validateFFLInformation: validateFFLInformation(dispatch),
    setProductPageNum: num => dispatch(setProductPageNum(num)),
    getProductItemsCount: getProductItemsCount(dispatch),
})

export default connect(MapStateToProps, MapDispatchToProps)(LiveStreamModePage);
