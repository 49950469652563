import React from 'react';
import './add-new-ffl.style.scss';
import { connect } from 'react-redux';
import { setAddNewClicked } from '../../../redux/ffl-db/ffl-db.action';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import DatePicker from "react-datepicker"; 
import FormInput from '../../../components/form-input/form-input.component';
import FormButton from '../../../components/form-button/form-button.component';
import Input from 'react-phone-number-input/input';
import Autocomplete from 'react-google-autocomplete';
import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useDropzone } from 'react-dropzone';
import { Storage } from 'aws-amplify';
import { addFFLItem } from '../../../redux/ffl-db/ffl-db.action';
import { loadPage } from '../../../redux/user/user.action';
import Resizer from "react-image-file-resizer";

const AddNewFFLDB = ({
    isAddNewClicked,
    setAddNewClicked,
    addFFLItem,
    loadPage
}) => {

    const alert = useAlert();

    const [newFFLInfo, setNewFFLInfo] = useState({
        fflName: '',
        fflAddress: '',
        fflNumber: '',
        contactName: '',
        contactEmail: ''
    })

    const [phone, setPhone] = useState(null);
    const [isValidPhone, setIsValidPhone] = useState(false);

    // for image pick with drag and drop file

    const [FFLImage, setFFLImage] = useState(null);

    const {getRootProps, getInputProps} = useDropzone({
        accept: '.jpg, .jpeg, .png, .webp, .tiff, .tif, .gif, .svg',
        multiple : false,
        onDrop: acceptedFiles => {            
                    setFFLImage(Object.assign(acceptedFiles[0], {
                        preview: URL.createObjectURL(acceptedFiles[0])
                    }))
                }
    });

    const FFLImageUpload = async file => {
        const stored = await Storage.put("ffl-image-"+Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)+"-"+file.name, file, {
            contentType: file.type,
            cacheControl: 'max-age=31536000'
        });
        return stored.key;
    }

    // for phone validataion
    const validatePhoneNumber = (num) => {
        var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        return phoneNumberPattern.test(num);
    }

    const [isEmailValid, setIsEmailValid] = useState(false);
    // for email validation
    const validateEmail = (email) => {
        var emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return emailPattern.test(email); 
    }

    const startDatePickerLink = React.createRef();
    const [startDate, setStartDate] = useState();

    useEffect(() => {
        console.log(startDate);
    }, [startDate]);

    const resizeImage = (file, size) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              size,
              size,
              file.type.split('/')[1],
              100,
              0,
              (uri) => {
                resolve(uri);
              },
              "file"
            );
          });
    }

    const handleSubmit = async event => {
        event.preventDefault();

        setIsValidPhone(false);
        setIsEmailValid(false);

        // if(!validatePhoneNumber(phone.slice(2))) {
        //     setIsValidPhone(true);
        //     return;
        // }

        // if(!validateEmail(newFFLInfo.contactEmail)) {
        //     setIsEmailValid(true);
        //     return;
        // }

        if (!startDate) {
            alert.error("Please select the expiration date");
            return;
        }

        const fflObj = {
            ffl_name: newFFLInfo.fflName,
            location: newFFLInfo.fflAddress,
            ffl_no: newFFLInfo.fflNumber,
            expiration_date: startDate.toISOString(),
            contact_phone: phone,
            contact_email: newFFLInfo.contactEmail
        }     

        loadPage(true);
        if (FFLImage) {
            const fflLicenceImage = await FFLImageUpload(FFLImage);
            fflObj.ffl_image_url = fflLicenceImage;

            const thumbnail300 = await resizeImage(FFLImage, 300);
            const thumbnail300Url = await FFLImageUpload(thumbnail300);
            fflObj.ffl_thumbnail_300 = thumbnail300Url;
        }
              
        const result = await addFFLItem(fflObj);
        loadPage(false);
        if ( result && result.data){
            alert.success("Added successfully");
            setAddNewClicked(false);
        }
        else
            alert.error("Adding failed");
        

    }
    return (
        <form onSubmit={handleSubmit} className="add-ffl-form">
            <h2 className="text-center text-white font-weight-bold mb-4">Add New FFL</h2>
            <MDBRow>
                <MDBCol className="formCol" size="12" sm="6" md="6" lg="6">
                    <FormInput type="text" label = "FFL Name" value = {newFFLInfo.fflName} handleChange = {(event) => setNewFFLInfo({...newFFLInfo, fflName: event.target.value})} required/>
                </MDBCol>
                <MDBCol className="formCol" size="12" sm="6" md="6" lg="6">
                    <FormInput type="text" label = "FFL Number" value = {newFFLInfo.fflNumber} handleChange = {(event) => setNewFFLInfo({...newFFLInfo, fflNumber: event.target.value})} required/>
                </MDBCol>
                
            </MDBRow>
            <MDBRow>
                <MDBCol className="formCol" size="12" sm="6" md="6" lg="6">
                   <div className="phone-group">
                        <Autocomplete
                            className="phone-input"
                            placeholder=""
                            value = {newFFLInfo.fflAddress}
                            onChange = {(e)=> setNewFFLInfo({...newFFLInfo, fflAddress: e.target.value})}
                            onPlaceSelected={(place) => {
                                setNewFFLInfo({...newFFLInfo, fflAddress: place.formatted_address})
                            }}
                            types={['address']}
                            componentRestrictions={{country: "us"}}
                            required
                        />
                        <label className = {`${newFFLInfo.fflAddress ? 'shrink' : ''} phone-input-label`}>FFL Address</label>
                    </div>
                </MDBCol>
                <MDBCol size="12" sm="6" md="6" lg="6">
                    <div className="background-date-wrapper">
                    <DatePicker
                        ref={startDatePickerLink} 
                        selected={startDate} 
                        minDate={new Date()} 
                        onChange={date => setStartDate(date)}
                        placeholderText="Expiration Date"
                        style={{backgroundColor: "transparent"}}
                    />
                    <MDBIcon icon="calendar-alt" className="datePickerIcon" onClick={()=>startDatePickerLink.current.setOpen(true)}/> 
                    {/* <FormInput type="text" label = "Contact Name" value = {newFFLInfo.contactName} handleChange = {(event) => setNewFFLInfo({...newFFLInfo, contactName: event.target.value})} required/> */}
                
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol className="formCol" size="12" sm="6" md="6" lg="6">
                <div className='phone-group'>
                    <Input
                        className={`${isValidPhone ? 'red-outline' : ''} phone-input`}
                        country="US"
                        value={phone}
                        onChange={setPhone}
                        autocomplete="tel"
                       />
                    <label className={`${phone ? 'shrink' : ''} ${isValidPhone ? 'red-label' : ''} phone-input-label`}>Contact Phone</label>
                </div>
                </MDBCol>
                <MDBCol className="formCol" size="12" sm="6" md="6" lg="6">
                    <FormInput type="email" label = "Contact Email" value = {newFFLInfo.contactEmail} handleChange = {(event) => setNewFFLInfo({...newFFLInfo, contactEmail: event.target.value})} changeemail={isEmailValid}/>
                </MDBCol>
            </MDBRow>
            <MDBRow center className="mt-4">
                <MDBCol size="12" sm="12" md="10" lg="8">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()}/>
                    <div className="dragBorder">
                        <div className="dragWrapper">
                            <p className="text-center">DROP OR CHOOSE <span className="text-white">LICENCE PICTURE</span></p>
                        </div>
                        {
                            FFLImage && <div className="activeImg">
                                    <img
                                        src={FFLImage.preview}                                    
                                    />
                                    <label>Active</label>
                                </div>
                        }
                    </div>
                </div>
                </MDBCol>
            </MDBRow>
            <MDBRow center className="mt-4">
                <MDBCol size="6" sm="6" md="5" lg="4">
                    <FormButton type="submit">SAVE</FormButton>
                </MDBCol>
                <MDBCol size="6" sm="6" md="5" lg="4">
                    <FormButton greyCol={true} onClickFunc={()=>setAddNewClicked(false)}>CANCEL</FormButton>
                </MDBCol>
            </MDBRow>
        </form>
    )
}

const MapStateToProps = ({FFLDBList: { isAddNewClicked }}) => ({
    isAddNewClicked
})

const MapDispatchToProps = dispatch => ({
    setAddNewClicked: () => dispatch(setAddNewClicked()),
    addFFLItem: addFFLItem(dispatch),
    loadPage: (flag) => dispatch(loadPage(flag)) 
})
export default connect(MapStateToProps, MapDispatchToProps)(AddNewFFLDB);
