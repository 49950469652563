import SoldOutWebinarsActionTypes from './sold-out-webinars.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getProductItems = dispatch => async ( filterStr = null, limit = 10, offset = 0, is_sort_asc = '',selectedWinner= '') => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/product/webinar/soldout?filterString=${filterStr}&&limit=${limit}&offset=${offset}&is_sort_asc=${is_sort_asc}&selectedWinner=${selectedWinner}`, { 
            headers: (await getMyTokenFunc())
        });
        dispatch(setProductItems(result.data));
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const getProductItemsCount = dispatch => async ( ) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/product/webinar/soldout/counter`, { 
            headers: (await getMyTokenFunc())
        });
        dispatch(setProductItemsCount(result?.data));
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const setWebinarStartLink = dispatch => async (id,obj) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/products/product/webinar/start?product_id=${id}`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
        // dispatch(setProductItems(result.data));
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const getCountSoldOutWebinar =  dispatch => async () => {
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/getCompleteSoldOutCount`, { 
            headers: (await getMyTokenFunc())
        });
       dispatch(setCountSoldOutWebinars(result.data.count[0].count ));
        } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}


export const setFilterString = filterStr => ({
    type: SoldOutWebinarsActionTypes.SET_FILTER_STRING,
    payload: filterStr
})

export const setLimitPerPage = limit => ({
    type: SoldOutWebinarsActionTypes.SET_LIMIT_PER_PAGE,
    payload: limit
})

export const setProductPageNum = num => ({
    type: SoldOutWebinarsActionTypes.SET_PRODUCT_LIST_PAGE_NUM,
    payload: num
})

export const hideOtherGoLives = id => ({
    type: SoldOutWebinarsActionTypes.HIDE_OTHER_GO_LIVES,
    payload: id
})

const setProductItems = items => ({
    type: SoldOutWebinarsActionTypes.SET_PRODUCT_LIST_ITEMS,
    payload: items
})

const setProductItemsCount = items => ({
    type: SoldOutWebinarsActionTypes.SET_PRODUCT_LIST_ITEMS_COUNT,
    payload: items
})

export const setCountSoldOutWebinars = num => ({
    type: SoldOutWebinarsActionTypes.SET_PRODUCT_LIST_COMPLETE,
    payload: num
})

export const setLinkFilter = type => ({
    type: SoldOutWebinarsActionTypes.SET_LINK_FILTER,
    payload: type
})
