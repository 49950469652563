import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBIcon } from 'mdbreact';
import './App.scss';
import HeaderComponent from './components/header/header.component';
import FooterComponent from './components/footer/footer.component';
import SideBar from './components/sidebar/sidebar.component';
import { getNotifications } from './redux/alerts/alerts.action';
import router from './router';
import { useHistory } from 'react-router-dom';
// import RouteWithAuth from './utils/authRoute';
import 'antd/dist/antd.dark.css';
import "react-image-gallery/styles/css/image-gallery.css";
import "react-image-gallery/styles/scss/image-gallery.scss";

const App = ({ currentUser, isLoadPage, getNotifications }) => {
  
  const [userData, setUserData] = useState( JSON.parse(localStorage.getItem('userData') || null ));

  const urlHistory = useHistory();

  useEffect(() => {
    if(currentUser){ 
      if(currentUser === "noInfo") {
        localStorage.setItem("userData", JSON.stringify(null));
        localStorage.setItem("userPermissions", JSON.stringify(null));
        urlHistory.push(router.logIn.path);
      }        
      else{
        localStorage.setItem("userData", JSON.stringify(currentUser.userInfo));
        localStorage.setItem("userPermissions", JSON.stringify(currentUser.userPermission));    
      }        
      setUserData(currentUser.userInfo);      
    }    
  }, [currentUser]);

  useEffect(() => {
    async function loadData() {
      await getNotifications();
    }
    if(userData)
      loadData()
  }, [userData]);

  return (
    <div className="App">
      <HeaderComponent />
      <div className="main-section">
        {
          userData && <SideBar />
        }
        <Switch>         
          <Route exact path = {router.dashboard.path} component={router.dashboard.component} />
          <Route exact path = {router.lipseyDashboard.path} component={router.lipseyDashboard.component} />
          <Route exact path = {router.lipseyProducts.path} component={router.lipseyProducts.component} />
          <Route exact path = {router.lipseyMarkup.path} component={router.lipseyMarkup.component} />
          <Route exact path = {router.lipseySoldProducts.path} component={router.lipseySoldProducts.component} />
          <Route exact path = {router.lipseyShipping.path} component={router.lipseyShipping.component} />
          <Route exact path = {router.emailNewProduct.path} component={router.emailNewProduct.component} />
          <Route exact path = {router.emailWinner.path} component={router.emailWinner.component} />
          <Route exact path = {router.categories.path} component={router.categories.component} />
          <Route exact path = {router.productListing.path} component={router.productListing.component} />
          <Route exact path = {router.editProduct.path} component={router.editProduct.component} />
          <Route exact path = {router.editFfl.path} component={router.editFfl.component} />
          <Route exact path = {router.webinarQueues.path} component={router.webinarQueues.component} />
          <Route exact path = {router.soldOutWebinars.path} component={router.soldOutWebinars.component} />
          <Route exact path = {router.liveStreamMode.path} component={router.liveStreamMode.component} />
          <Route exact path = {router.fflDb.path} component={router.fflDb.component} />
          <Route exact path = {router.completedWebinars.path} component={router.completedWebinars.component} />
          <Route exact path = {router.soldPhysicalProducts.path} component={router.soldPhysicalProducts.component} />         
          <Route exact path = {router.offlineSales.path} component={router.offlineSales.component} />
          <Route exact path = {router.shipping.path} component={router.shipping.component} />
          <Route exact path = {router.userManagement.path} component={router.userManagement.component} />
          <Route exact path = {router.userEdit.path} component={router.userEdit.component} />
          <Route exact path = {router.seatRefund.path} component={router.seatRefund.component} />
          <Route exact path = {router.faq.path} component={router.faq.component} />
          <Route exact path = {router.editFaq.path} component={router.editFaq.component} />
          <Route exact path = {router.setting.path} component={router.setting.component} />
          <Route exact path = {router.roles.path} component={router.roles.component} />
          <Route exact path = {router.alertPage.path} component={router.alertPage.component} />
          <Route exact path = {router.report.path} component={userData ? router.report.component : router.logIn.component} />
          <Route exact path = {router.ffl.path} component={userData ? router.ffl.component : router.logIn.component} />
          <Route exact path = {router.forgotPassword.path} component={userData ? router.dashboard.component : router.forgotPassword.component} />
          <Route exact path = {router.resetPassword.path} component={userData ? router.dashboard.component : router.resetPassword.component} />
          <Route exact path = {router.logIn.path} component={userData ? router.dashboard.component : router.logIn.component} />     
          <Route exact path = {router.zoomMeetings.path} component={router.zoomMeetings.component} />
          <Route exact path = {router.editZoom.path} component={router.editZoom.component} />
          <Route exact path = {router.createZoom.path} component={router.createZoom.component} />
          <Route render = { () => (<h1 className="text-white text-center">Page not found...</h1>)} />         
        </Switch>
      </div>
      <FooterComponent />
      {
        isLoadPage && <div className="wait-loading">
            <MDBIcon className="loadIcon" icon="sync-alt" />
        </div>       
      }
    </div>
  );
}

const MapStateToProps = ({ user: {currentUser, isLoadPage}}) => ({
  currentUser,
  isLoadPage
})

const MapDispatchToProps = dispatch => ({
  getNotifications: getNotifications(dispatch)
})

export default connect(MapStateToProps, MapDispatchToProps)(App);
