import React, { useState, useEffect, Fragment } from 'react';
import './zoom-list-row.style.scss';
import FormButton from '../../../components/form-button/form-button.component'
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import DatePicker from "react-datepicker";
import { useHistory } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { loadPage } from '../../../redux/user/user.action';
import { updateZoom, deleteZoomMeeting } from '../../../redux/zoom-meetings/zoom-meetings.action';
import { setWebinarStartLink } from '../../../redux/sold-out-webinars/sold-out-webinars.action';
import { getSiteSettings } from '../../../redux/colorChnage/colorChange.action';
import moment from 'moment-timezone';
import { useAlert } from 'react-alert';
import { Storage } from 'aws-amplify';
import router from '../../../router';



const ZoomListRow = ({ isEdit, loadPage, isMobile, updateZoom, item, deleteZoomMeeting, statusFilter, type, getSiteSettings, setWebinarStartLink }) => {


     const alert = useAlert();
     const historyUrl = useHistory();

     const [startDate, setStartDate] = useState();



     useEffect(() => {
          async function load() {
               if (type === "Upcoming") {
                    const settingsInfo = await getSiteSettings();
                    if (settingsInfo && settingsInfo.time_zone) {

                         const calculatedDate = new Date(moment(item.scheduled_time).tz(settingsInfo.time_zone).format('MM/DD/YY HH:mm'));
                         setStartDate(calculatedDate);
                    }
               }
               else {
                    const date = new Date(item.scheduled_time.toString());
                    setStartDate(date);
               }
          }
          item && item.scheduled_time && load()
     }, []);


     const [isDeleteZoom, setIsDeleteZoom] = useState(false);




     const deleteZoomFunc = async (event) => {
          event.preventDefault();
          loadPage(true);
          const result = await deleteZoomMeeting(item.id, item.webinar_id);
          if (result.statusCode === 200) {
               alert.success("Deleted successfully");
               window.location.reload();
          }
          else
               alert.error("Failed deleting");
          loadPage(false);
          setIsDeleteZoom(false);
     }


     const LaunchZoom = async () => {

          const obj = {
               webinar_link: item.join_url
          }
          loadPage(true);
          const result = await setWebinarStartLink(item.webinar_id, obj);
          loadPage(false);
          if (result === "success") {
              // historyUrl.push(`/product/sold-webinar/live-stream/${item.webinar_id}`);
               window.open(item.start_url, "_blank")
          }
          else
               alert.error("Failed");

     }





     const DownloadZoom = () => {
          window.open(item?.download_url, "_blank")
     }



     const [isOpenTable, setIsOpenTable] = useState(false);

     return (
          <Fragment>
               {
                    isMobile &&
                         type === "Previous" ? <Fragment>
                         <MDBRow className="table-row">
                              <MDBCol size="4" middle className="text-left" >
                                   {item?.topic && item?.topic}
                              </MDBCol>
                              <MDBCol size="5" className="text-center ">

                                   {moment(item?.start_time).format('MM/DD/YYYY hh:mm')}
                              </MDBCol>
                              <MDBCol size="3" middle className="text-center">
                                   <button className="openBtn" onClick={() => setIsOpenTable(!isOpenTable)}><MDBIcon icon="plus" /></button>
                              </MDBCol>

                         </MDBRow>
                         {
                              isOpenTable && <Fragment>
                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Topic</MDBCol>
                                        <MDBCol size="9" middle className="text-center">
                                             {item?.topic && item?.topic}
                                        </MDBCol>
                                   </MDBRow>
                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Description</MDBCol>
                                        <MDBCol size="9" middle className="text-center">
                                             {item.agenda && item.agenda}
                                        </MDBCol>
                                   </MDBRow>

                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Created at</MDBCol>
                                        <MDBCol size="7" className="text-right pl-5">
                                             {moment(item?.created_at).format('MM/DD/YYYY hh:mm')}
                                        </MDBCol>
                                   </MDBRow>
                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Duration</MDBCol>
                                        <MDBCol size="9" middle className="text-center">
                                             {item.duration && item.duration}
                                        </MDBCol>
                                   </MDBRow>
                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Download Video</MDBCol>
                                        <MDBCol size="5" className="text-right pl-4">
                                             <i class="fas fa-download" onClick={() => DownloadZoom()}></i>
                                        </MDBCol>
                                   </MDBRow>
                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Edit</MDBCol>
                                        <MDBCol size="5" className="text-right">
                                             <i class="fas fa-pencil-alt" onClick={() => historyUrl.push(router.editZoom.path, { item: item })}></i>
                                        </MDBCol>
                                   </MDBRow>
                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Delete</MDBCol>
                                        <MDBCol size="5" className="text-right">
                                             <button onClick={() => setIsDeleteZoom(true)}><MDBIcon icon="trash-alt" /></button>
                                        </MDBCol>
                                   </MDBRow>
                              </Fragment>

                         }
                         <MDBRow className="table-row">
                              <MDBCol size="4" middle className="text-left" >
                                   {item?.topic && item?.topic}
                              </MDBCol>
                              <MDBCol size="5" className="text-center ">

                                   {moment(item?.start_time).format('MM/DD/YYYY hh:mm')}
                              </MDBCol>
                              <MDBCol size="3" middle className="text-center">
                                   <button className="openBtn" onClick={() => setIsOpenTable(!isOpenTable)}><MDBIcon icon="plus" /></button>
                              </MDBCol>
                         </MDBRow>
                         {
                              isOpenTable && <Fragment>
                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Topic</MDBCol>
                                        <MDBCol size="9" middle className="text-center">
                                             {item?.topic && item?.topic}
                                        </MDBCol>
                                   </MDBRow>
                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Description</MDBCol>
                                        <MDBCol size="9" middle className="text-center">
                                             {item.agenda && item.agenda}
                                        </MDBCol>
                                   </MDBRow>

                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Created at</MDBCol>
                                        <MDBCol size="7" className="text-right pl-5">
                                             {moment(item?.created_at).format('MM/DD/YYYY hh:mm')}
                                        </MDBCol>
                                   </MDBRow>
                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Duration</MDBCol>
                                        <MDBCol size="9" middle className="text-center">
                                             {item.duration && item.duration}
                                        </MDBCol>
                                   </MDBRow>
                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Launch</MDBCol>
                                        <MDBCol size="8" className="text-center pl-4">
                                             <i class="fas fa fa-rocket" onClick={() => LaunchZoom()}></i>
                                        </MDBCol>
                                   </MDBRow>
                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Edit</MDBCol>
                                        <MDBCol size="5" className="text-right">
                                             <i class="fas fa-pencil-alt" onClick={() => historyUrl.push(router.editZoom.path, { item: item })}></i>
                                        </MDBCol>
                                   </MDBRow>
                                   <MDBRow className="table-row mobile-opend-table">
                                        <MDBCol size="3" middle className="text-center text-white">Delete</MDBCol>
                                        <MDBCol size="5" className="text-right">
                                             <button onClick={() => setIsDeleteZoom(true)}><MDBIcon icon="trash-alt" /></button>
                                        </MDBCol>
                                   </MDBRow>
                              </Fragment>

                         }



                    </Fragment>
                         :
                         type === "Previous" ?
                              <MDBRow className="table-row">
                                   <MDBCol size="2" className="text-left email-col" >
                                        {item?.topic && item?.topic}
                                   </MDBCol>
                                   <MDBCol size="3" className="text-center" >
                                        {item?.agenda && item?.agenda}
                                   </MDBCol>

                                   <MDBCol size="2" className="text-center ">

                                        {moment(item?.created_at).format('MM/DD/YYYY hh:mm')}
                                   </MDBCol>

                                   <MDBCol size="1" className="text-center">
                                        {item?.duration && item?.duration}
                                   </MDBCol>
                                   <MDBCol size="2" className="text-center pl-4">
                                        <i class="fas fa-download" onClick={() => DownloadZoom()}></i>
                                   </MDBCol>
                                   <MDBCol size="1" className="text-center">
                                        <i class="fas fa-pencil-alt" onClick={() => historyUrl.push(router.editZoom.path, { item: item })}></i>
                                   </MDBCol>
                                   <MDBCol size="1" className="text-center">
                                        <button onClick={() => setIsDeleteZoom(true)}><MDBIcon icon="trash-alt" /></button>
                                   </MDBCol>


                                   {
                                        isDeleteZoom && <div className="removeDialogWrapper">
                                             <div className="removeDialog">
                                                  <form onSubmit={deleteZoomFunc} >
                                                       <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={() => setIsDeleteZoom(false)} />
                                                       <br />
                                                       <h3 className="text-white text-center font-weight-bold mb-4">Are you sure to delete?</h3>
                                                       <MDBRow center className="mb-4 mobile-row">
                                                            <MDBCol size="6">
                                                                 <FormButton type="submit">YES</FormButton>
                                                            </MDBCol>
                                                            <MDBCol size="6">
                                                                 <FormButton greyCol={true} onClickFunc={() => setIsDeleteZoom(false)}>NO</FormButton>
                                                            </MDBCol>
                                                       </MDBRow>
                                                  </form>
                                             </div>
                                        </div>
                                   }
                              </MDBRow>
                              :
                              <MDBRow className="table-row">
                                   <MDBCol size="1" className="text-left email-col">
                                        {item?.topic && item?.topic}
                                   </MDBCol>
                                   <MDBCol size="3" className="text-left" >
                                        {item?.agenda && item?.agenda}
                                   </MDBCol>

                                   <MDBCol size="2" className="text-left ">

                                        {moment(item?.start_time).format('MM/DD/YYYY hh:mm')}
                                   </MDBCol>
                                   <MDBCol size="2" className="text-left ">

                                        {moment(item?.created_at).format('MM/DD/YYYY hh:mm')}
                                   </MDBCol>

                                   <MDBCol size="1" className="text-center">
                                        {item?.duration && item?.duration}
                                   </MDBCol>
                                   <MDBCol size="1" className="text-center pl-4">
                                        <i class="fas fa fa-rocket" onClick={() => LaunchZoom()}></i>
                                   </MDBCol>
                                   <MDBCol size="1" className="text-right">
                                        <i class="fas fa-pencil-alt" onClick={() => historyUrl.push(router.editZoom.path, { item: item })}></i>
                                   </MDBCol>
                                   <MDBCol size="1" className="text-right">
                                        <button onClick={() => setIsDeleteZoom(true)}><MDBIcon icon="trash-alt" /></button>
                                   </MDBCol>


                                   {
                                        isDeleteZoom && <div className="removeDialogWrapper">
                                             <div className="removeDialog">
                                                  <form onSubmit={deleteZoomFunc} >
                                                       <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={() => setIsDeleteZoom(false)} />
                                                       <br />
                                                       <h3 className="text-white text-center font-weight-bold mb-4">Are you sure to delete?</h3>
                                                       <MDBRow center className="mb-4 mobile-row">
                                                            <MDBCol size="6">
                                                                 <FormButton type="submit">YES</FormButton>
                                                            </MDBCol>
                                                            <MDBCol size="6">
                                                                 <FormButton greyCol={true} onClickFunc={() => setIsDeleteZoom(false)}>NO</FormButton>
                                                            </MDBCol>
                                                       </MDBRow>
                                                  </form>
                                             </div>
                                        </div>
                                   }
                              </MDBRow>
               }


          </Fragment>
     )
}

const MapDispatchToProps = dispatch => ({
     loadPage: flag => dispatch(loadPage(flag)),
     updateZoom: updateZoom(dispatch),
     getSiteSettings: getSiteSettings(dispatch),
     setWebinarStartLink: setWebinarStartLink(dispatch),
     deleteZoomMeeting: deleteZoomMeeting(dispatch)
})

export default connect(null, MapDispatchToProps)(ZoomListRow);