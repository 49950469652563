import React, {useState, useEffect } from 'react';
import './distributors-shipping-page.style.scss';
import { MDBRow, MDBCol } from 'mdbreact';
import CountPerPage from '../../components/table/count-per-page/count-per-page.component';
import ShippingPageButtons from './pagination/distributors-pagination.component';
import { connect } from 'react-redux';
import FormSelect from '../../components/form-select/form-select.component';
import TableFilterInput from '../../components/table/filter-input/filter-input.component';
import { getShippingItems, setLimitPerPage, setCurrentStateFilter, setProductPageNum, setFilterStr, setOrder, getShippingItemsCustom } from '../../redux/shipping/shipping.action';
import { loadPage } from '../../redux/user/user.action';
import ShippingRowItem from './row-item/distributors-row-item.component';
import LoaderComponent from '../../components/Loader/loader.component';

const DistributorsShippingPage = ({ 
    product_items, 
    limitPerPage, 
    pageNum,    
    getShippingItems,
    loadPage,
    setLimitPerPage,
    isShowSideBar,
    currentStateFilter,
    setCurrentStateFilter,
    setProductPageNum,
    filterString,
    isDistributor,
    setFilterString,
    order,
    setOrder,
    getShippingItemsCustom
}) => {

    // for select box of convert status
    const stateFilter = ["All","Not Printed", "Printed", "Shipped"];
    const [stateFilterShow, setStateFilterShow] = useState(false);
    
    // for row counts per page
    const countSelect = [10,20,30,50];
    const [countSelectShow, setCountSelectShow] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return () => {
            setFilterString("");
            setProductPageNum(1);
        }
    }, []);

    useEffect(() => {
        setProductPageNum(1)
      }, [])

    useEffect(() => {
        if(currentStateFilter === null) {
            setCurrentStateFilter("All")
        }
    }, [currentStateFilter])

    useEffect(() => {
        async function load() {
            setLoading(true);
            await getShippingItems(
                currentStateFilter === "Not Printed" ? "not_printed" : currentStateFilter === "Printed" ? "printed" : currentStateFilter === "Shipped" ? "shipped" : "all",
                limitPerPage === "All" ? "all" : limitPerPage, 
                limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1),
                filterString,
                isDistributor='true',
                order
                );
            setLoading(false);
        }
        if( currentStateFilter !== null){
            load();
        }
    }, [currentStateFilter, limitPerPage, pageNum, filterString]);
        /* 
        TEMPORAL CHANGE TO ADD CUSTOM ITEM
    */

        const [customItems, setCustomItems] = useState(null)

        useEffect(() => {
            async function load() {
                setLoading(true);
                const result = await getShippingItemsCustom(
                    currentStateFilter === "Not Printed" ? "not_printed" : currentStateFilter === "Printed" ? "printed" : currentStateFilter === "Shipped" ? "shipped" : "all",
                    limitPerPage === "All" ? "all" : limitPerPage,
                    limitPerPage === "All" ? 0 : limitPerPage * (pageNum - 1),
                    filterString,
                    isDistributor = true,
                    order
                )
                setLoading(false);
                if (result?.rows && result?.rows?.length > 0) {
                    let item = result?.rows[0]
                    if(item){
                        let temp = {...product_items}
                        temp?.rows?.unshift(item)
                        setCustomItems({...temp})
                    }
                }
            }
            load();
        }, [product_items])
    
        /* 
            TEMPORAL CHANGE TO ADD CUSTOM ITEM
        */

    const [isMobileSize, setIsMobileSize] = useState(false);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1025)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
      console.log("customItems",customItems);
    }, [customItems])
    
    
    return (
        <>
        {
            loading && (
                <LoaderComponent />
            )
        }
        <div className="product-listing-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
            <MDBRow className="headerRow">
                <MDBCol size="12" sm="12" md="9" lg="10">
                    <h2 className="text-white font-weight-bold">Distributors Shipping Page</h2>
                </MDBCol>
            </MDBRow>
            <div className="section">
                <div className="toolbar pt-1">
                    <div className="leftDiv flexTop">
                        <div>
                            <CountPerPage 
                                options={countSelect} 
                                showSelectBox={()=>setCountSelectShow(!countSelectShow)} 
                                selectOption={(event)=>{
                                    setCountSelectShow(false);
                                    setLimitPerPage(event.target.id);
                                }} 
                                optionShow={countSelectShow} 
                                placeholder={limitPerPage}
                            />
                            <label className="ml-1">Per Page</label>
                        </div>
                        <div className="filterIndex shipping">
                        <FormSelect options={stateFilter} showSelectBox={()=>setStateFilterShow(!stateFilterShow)} selectOption={(event)=>{
                            setStateFilterShow(false);
                            setCurrentStateFilter(event.target.id);
                        }} optionShow={stateFilterShow} placeholder={currentStateFilter}/>
                        </div>
                        <TableFilterInput  placeholder="search shipping" str = {filterString} setFilterString = {setFilterString}/>
                    </div>
                    <ShippingPageButtons
                    setProductPageNum={setProductPageNum}
                    count={ limitPerPage === "All" ? 1 : Math.ceil(product_items.count/limitPerPage) } currentIndex = {pageNum}/>  
                </div>
                {
                    isMobileSize ? 
                
                        <MDBRow className="table-header">
                            <MDBCol size="6" className="text-white text-center">
                                Shipping Address
                            </MDBCol>
                            <MDBCol size="4" className="text-white text-center">
                                FFL Name
                            </MDBCol>
                        </MDBRow>
                    :                 
                    <MDBRow className="table-header">
                        <MDBCol size="7" className="text-white thumb">
                            Shipping Address
                        </MDBCol>
                        <MDBCol size="2" className="text-white text-center">
                            Item Count
                        </MDBCol>                        
                        <MDBCol size="3" className="text-white text-center">
                            Convert Status
                        </MDBCol>
                    
                    </MDBRow>               
                }
                {
                    /* TEMPORAL CHANGE TO ADD CUSTOM ITEM */
                    customItems && customItems?.rows && customItems?.rows?.length > 0 ? customItems?.rows?.map( (item, index) => <ShippingRowItem
                    key={item.id}
                    item={item}
                    index={index}
                    statusFilter = {currentStateFilter}
                    pageLimit = { limitPerPage === 'All' ? 'all' : limitPerPage}
                    pageOffset = { limitPerPage === 'All' ? 0 : limitPerPage*(pageNum-1)}
                    // trackingNumber = {item.tracking_number}
                    isMobile={isMobileSize}
                    />  )
                    /* TEMPORAL CHANGE TO ADD CUSTOM ITEM */
                    :(
                        product_items && product_items.rows && product_items.rows.length > 0 ? product_items.rows.map( (item, index) => <ShippingRowItem
                        key={item.id}
                        item={item}
                        index={index}
                        statusFilter = {currentStateFilter}
                        pageLimit = { limitPerPage === 'All' ? 'all' : limitPerPage}                        
                        pageOffset = { limitPerPage === 'All' ? 0 : limitPerPage*(pageNum-1)}
                        // trackingNumber = {item.tracking_number}
                        isMobile={isMobileSize}
                        />  )
                    :
                    <div className="non-row text-center">
                        No Data
                    </div>
                    )
                }
            </div>
        </div>
        </>
    )
}

const MapStateToProps = ({ shipping_reducer: { product_items, limitPerPage, pageNum, currentStateFilter, filterString, order }, user: {isShowSideBar}}) => ({
    product_items,
    limitPerPage,
    filterString,
    pageNum,
    isShowSideBar,
    currentStateFilter,
    order
})

const MapDispatchToProps = dispatch => ({
    getShippingItems: getShippingItems(dispatch),
    loadPage: flag => dispatch(loadPage(flag)),
    setFilterString: str => dispatch(setFilterStr(str)),
    setLimitPerPage: count => dispatch(setLimitPerPage(count)),
    setCurrentStateFilter: str => dispatch(setCurrentStateFilter(str)),
    setProductPageNum: num => dispatch(setProductPageNum(num)),
    setOrder: (str) => dispatch(setOrder(str)),
    getShippingItemsCustom: getShippingItemsCustom(dispatch)
})

export default connect(MapStateToProps, MapDispatchToProps)(DistributorsShippingPage);