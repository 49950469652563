import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { removeReadItem, readNotifications, alertsShow } from '../../redux/alerts/alerts.action';
import { loadPage } from '../../redux/user/user.action';
import { Link } from 'react-router-dom';
import './alerts.style.scss';
import EachAlertItem from './alert-item/alert-item.component';
import router from '../../router';

const NotificationAlerts = ({alertItems, alertsShow, removeReadItem, readNotifications, loadPage}) => {
   
    const handleChange = async (id) => {
                
        loadPage(true);
        const isSuccess = await readNotifications(id);
        if(isSuccess === "Success"){
            removeReadItem(id);
            loadPage(false);                
        }
        else{
            loadPage(false);
            return;
        }          
       
    }

    return (
        <div className="alerts-div-wrapper">
            <div className="triangleDiv"></div>
            <div className="alerts-popup-div">
                <div className="font-weight-bolder text-center top-div">
                    {
                        alertItems.length > 0 ? <Fragment>
                            <span>Notifications</span>
                            <button className='alerts-count-btn'>{alertItems.length}</button>
                        </Fragment>
                        :
                            <span>No Notifications</span>
                    }                    
                </div>
                <div className="notification-items-container">
                    {
                        alertItems.length > 0 &&
                            alertItems.map(alertItem=>           
                                (
                                    <EachAlertItem key={alertItem.id} alertItem = { alertItem } clickFunc = {() =>handleChange(alertItem.id)}/>                        
                                )
                                
                            )
                    }
                </div>
                <Link to={router.alertPage.path}>
                    <div className="close-notification" onClick={() => alertsShow()}>
                        <p className="text-center">View All Notifications</p>
                    </div>
                </Link>
            </div> 
        </div>
    )
};

const MapDispatchToProps = dispatch => ({
    removeReadItem: (id) => dispatch(removeReadItem(id)),
    readNotifications: readNotifications(dispatch),
    loadPage: flag => dispatch(loadPage(flag)),
    alertsShow: () => dispatch(alertsShow())
})

export default connect(null, MapDispatchToProps)(NotificationAlerts);