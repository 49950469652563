import AlertsActionTypes from './alerts.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const alertsShow = () => ({
    type: AlertsActionTypes.ALERTS_SHOW
});

export const hideAlerts = () => ({
    type: AlertsActionTypes.HIDE_ALERTS
});

export const removeReadItem = id => ({
    type: AlertsActionTypes.REMOVE_READ_ITEM,
    payload: id
});

export const getNotifications = dispatch => async () => {
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + "admin/notification?product_type=webinar", {
            headers: (await getMyTokenFunc())
        });
        dispatch(setNotifications(result.data))
    } catch (error) {
        console.log(error?.response?.data);
        // if (error?.response?.data)
        //    ((error.response.data.message === "Token Expired") || (error.response.data.message === "Unauthorized")) && logOutFunc(store.dispatch)()
    }
};

export const readNotifications = dispatch => async (notificationID) => {
    try {
        const result = await axios.patch( RestAPI.ORIGINAL_ENDPOINT + "admin/notification/"+notificationID+"?product_type=webinar", null, { 
            headers: (await getMyTokenFunc())
        });
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);
    }
};

const setNotifications = notifications => ({
    type: AlertsActionTypes.SET_NOTIFICATIONS,
    payload: notifications
})

