import ReportActionTypes from './report.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getReportItems = dispatch => async (startDate, endDate) => {
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/analytics/reports?startDate=${startDate}&endDate=${endDate}`, { 
            headers: (await getMyTokenFunc())
        });
        console.log("Report Data", result);
        return result.data;
        // dispatch(setProductItems(result));
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

const setProductItems = items => ({
    type: ReportActionTypes.SET_REPORT_ITEMS,
    payload: items
})
