import React from 'react'
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import FormButton from '../form-button/form-button.component';
import './confirmation-modal.style.scss'

const CustomConfirmationModal = ({
  isLoading,
  message,
  closeModal,
  editFFL,
  notRequired,
}) => {
  return (
    <div className="modal-profile-form">
      <div className="content">
        <div className="removeDialogWrapper">
          <div className="removeDialog">
            <div>
              <div className="close-icon">
                <i onClick={() => {
                  closeModal()
                }} className="far fa-times-circle"></i>
              </div>
              <h2 className="text-white text-center ">
                {message}
              </h2>
              <p></p>

              <MDBRow center className="mt-4">
                <MDBCol size="8" sm="6" md="5" lg="6">
                  <FormButton
                    className="int-buttons success"
                    greyCol={true}
                    isLoading={isLoading}
                    onClickFunc={() => {
                      editFFL()
                    }}>
                    <h5>Edit FFL</h5>
                  </FormButton>
                </MDBCol>
                <MDBCol size="8" sm="6" md="5" lg="6">
                  <FormButton
                    className="int-buttons grey"
                    isLoading={isLoading}
                    onClickFunc={() => {
                      notRequired()
                    }
                    }>
                    <h5>FFL not required</h5>
                  </FormButton>
                </MDBCol>
              </MDBRow>
              <MDBRow center>
                <MDBCol size="8" sm="6" md="5" lg="6">
                  <FormButton
                    className="int-buttons cancel"
                    onClickFunc={() => {
                      closeModal()
                      /* setIsLoading(true)
                      handleFFLChange(item, false, null, FFLNotRequired)
                      setShowConfirmationModal(false) */
                    }
                    }>
                    <h5>Cancel</h5>
                  </FormButton>
                </MDBCol>
              </MDBRow>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  )
}
const SendToShippingModal = ({
  isLoading,
  message,
  closeModal,
  editFFL,
  notRequired,
}) => {
  return (
    <div className="modal-profile-form">
      <div className="content">
        <div className="removeDialogWrapper">
          <div className="removeDialog">
            <div>
              <div className="close-icon">
                <i onClick={() => {
                  closeModal()
                }} className="far fa-times-circle"></i>
              </div>
              <h2 className="text-white text-center ">
                You must have a valid FFL to send this item.
              </h2>
              <p></p>

              <MDBRow center className="mt-4">
                <MDBCol size="8" sm="6" md="5" lg="6">
                  <FormButton
                    className="int-buttons success"
                    greyCol={true}
                    isLoading={isLoading}
                    onClickFunc={() => {
                      editFFL()
                    }}>
                    <h5>Edit FFL</h5>
                  </FormButton>
                </MDBCol>
              </MDBRow>
              <MDBRow center>
                <MDBCol size="8" sm="6" md="5" lg="6">
                  <FormButton
                    className="int-buttons cancel"
                    onClickFunc={() => {
                      closeModal()
                      /* setIsLoading(true)
                      handleFFLChange(item, false, null, FFLNotRequired)
                      setShowConfirmationModal(false) */
                    }
                    }>
                    <h5>Cancel</h5>
                  </FormButton>
                </MDBCol>
              </MDBRow>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  )
}

const CustomConfirmationModalWithDelete = ({
  isLoading,
  message,
  closeModal,
  editFFL,
  notRequired,
  deleteModal,
}) => {
  return (
    <div className="modal-profile-form">
      <div className="content">
        <div className="removeDialogWrapper">
          <div className="removeDialog">
            <div>
              <div className="close-icon">
                <i onClick={() => {
                  closeModal()
                }} className="far fa-times-circle"></i>
              </div>
              <h2 className="text-white text-center ">
                {message}
              </h2>
              <p></p>

              <MDBRow center className="mt-4">
                <MDBCol size="8" sm="6" md="5" lg="6">
                  <FormButton
                    className="int-buttons success"
                    greyCol={true}
                    isLoading={isLoading}
                    onClickFunc={() => {
                      editFFL()
                    }}>
                    <h5>Edit FFL</h5>
                  </FormButton>
                </MDBCol>
              </MDBRow>
              <MDBRow center>
                {/* <MDBCol size="8" sm="6" md="5" lg="6">
                  <FormButton
                    className="int-buttons remove"
                    onClickFunc={() => {
                      deleteModal()
                      setIsLoading(true)
                      handleFFLChange(item, false, null, FFLNotRequired)
                      setShowConfirmationModal(false)
                    }
                    }>
                    <h5>Remove FFL</h5>
                  </FormButton>
                </MDBCol> */}
                <MDBCol size="8" sm="6" md="5" lg="6">
                  <FormButton
                    className="int-buttons cancel"
                    onClickFunc={() => {
                      closeModal()
                      /* setIsLoading(true)
                      handleFFLChange(item, false, null, FFLNotRequired)
                      setShowConfirmationModal(false) */
                    }
                    }>
                    <h5>Cancel</h5>
                  </FormButton>
                </MDBCol>
              </MDBRow>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  )
}

const ConfirmationModal = ({
  isLoading,
  message,
  closeModal,
  editFFL,
  notRequired,
  noResponse,
}) => {
  return (
    <div className="modal-profile-form">
      <div className="content">
        <div className="removeDialogWrapper">
          <div className="removeDialog">
            <div>
              <div className="close-icon">
                <i onClick={() => {
                  closeModal()
                }} className="far fa-times-circle"></i>
              </div>
              <h2 className="text-white text-center ">
                {message}
              </h2>
              <p></p>

              <MDBRow center className="mt-4">
                <MDBCol size="8" sm="6" md="5" lg="6">
                  <FormButton
                    greyCol={true}
                    isLoading={isLoading}
                    onClickFunc={() => {
                      notRequired()
                    }}>
                    <h5>Yes</h5>
                  </FormButton>
                </MDBCol>
                <MDBCol size="8" sm="6" md="5" lg="6">
                  <FormButton
                    isLoading={isLoading}
                    onClickFunc={() => {
                      noResponse()
                      /* setIsLoading(true)
                      handleFFLChange(item, false, null, FFLNotRequired)
                      setShowConfirmationModal(false) */
                    }
                    }>
                    <h5>No</h5>
                  </FormButton>
                </MDBCol>
              </MDBRow>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  )
}

const returnSelectedModal = (props) => {
  const { style, ...rest } = props
  switch (style) {
    case "basic":
      return <ConfirmationModal {...rest} />
    case "custom":
      return <CustomConfirmationModal {...rest} />
    case "customWithDelete":
      return <CustomConfirmationModalWithDelete {...rest} />
    case "send":
      return <SendToShippingModal {...rest} />
    default:
      return <ConfirmationModal {...rest} />
  }
}


const Modal = ({
  isLoading,
  message,
  closeModal,
  editFFL,
  style = "basic",
  notRequired,
  noResponse,
  deleteModal
}) => {
  return (
    <>
      {
        returnSelectedModal({
          isLoading,
          message,
          closeModal,
          editFFL,
          style,
          notRequired,
          noResponse,
          deleteModal
        })
      }
    </>
  )
}

export default Modal