const SoldOutWebinarsActionTypes = {
    SET_PRODUCT_LIST_ITEMS: "SET_SOLD_WEBINAR_LIST_ITEMS",
    SET_PRODUCT_LIST_ITEMS_COUNT: "SET_SOLD_WEBINAR_LIST_ITEMS_COUNT",
    SET_FILTER_STRING: "SET_SOLD_WEBINAR_FILTER_STRING",
    SET_LIMIT_PER_PAGE: "SET_SOLD_WEBINAR_LIMIT_PER_PAGE",
    SET_PRODUCT_LIST_PAGE_NUM: "SET_SOLD_WEBINAR_PAGE_NUM",
    HIDE_OTHER_GO_LIVES: "HIDE_OTHER_GO_LIVES",
    SET_PRODUCT_LIST_COMPLETE: "SET_SOLD_WEBINAR_LIST_COMPLETE",
    SET_LINK_FILTER: "SET_SOLD_WEBINAR_LINK_FILTER",
};

export default SoldOutWebinarsActionTypes;