import React from 'react';
import './add-new-meeting.style.scss';
import { connect } from 'react-redux';
import { setZoomLink } from '../../../redux/zoom-meetings/zoom-meetings.action';
import { MDBRow, MDBCol } from 'mdbreact';
import { TextField } from '@material-ui/core';
import FormInput from '../../../components/form-input/form-input.component';
import FormButton from '../../../components/form-button/form-button.component';
import Autocomplete from 'react-google-autocomplete';
import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useDropzone } from 'react-dropzone';
import { Storage } from 'aws-amplify';
import { loadPage } from '../../../redux/user/user.action';
import { useHistory } from 'react-router-dom';

const AddNewMeetingZoom = ({
    setZoomLink,
    loadPage,
    onClose
}) => {
    const historyUrl = useHistory();


    const alert = useAlert();

    const [newZoomInfo, setnewZoomInfo] = useState({
        zoomTopic: '',
        zoomDescription: '',
        zoomDuration: '',
        zoomSchedule: '',
    })
  

    const handleSubmit = async event => {
        event.preventDefault();
        const obj = {
            email: "mateohoxha26@gmail.com",
            topic: newZoomInfo.zoomTopic,
            agenda: newZoomInfo.zoomDescription,
            start_time: "2021-07-29T13:30:00Z",
            duration: newZoomInfo.zoomDuration
        }

        loadPage(true);
    
        const result = await setZoomLink( "03133a6b-432b-4e6a-b0cb-a21f875f2096",obj);
        loadPage(false);
        if (result.statusCode === 200) {
            alert.success("Added successfully");
        }
        else
            alert.error("Adding failed");


    }
    return (
        <form onSubmit={handleSubmit} className="add-ffl-form">
            <h2 className="text-center text-white font-weight-bold mb-4">Create New Meeting</h2>
            <MDBRow>
                <MDBCol className="formCol" size="12" sm="6" md="6" lg="6">
                    <FormInput type="text" label="Topic" value={newZoomInfo.zoomTopic} handleChange={(event) => setnewZoomInfo({ ...newZoomInfo, zoomTopic: event.target.value })} required />
                </MDBCol>
               
                <MDBCol className="formCol" size="12" sm="6" md="6" lg="6">
                    <FormInput type="text" label="Description" value={newZoomInfo.zoomDescription} handleChange={(event) => setnewZoomInfo({ ...newZoomInfo, zoomDescription: event.target.value })} required />
                </MDBCol>

            </MDBRow>
            <MDBRow>

                <MDBCol size="12" sm="6" md="6" lg="6">
                    <div className="background-date-wrapper">
                        <TextField
                            id="datetime-local"
                            type="datetime-local"
                            minDate={new Date()}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            
                        />

                        {/* <DateTimePicker
                           minDate={new Date()}
                           placeholderText="Start Date"
                           className="datePicker"
                        /> */}
                        {/* <DatePicker
                            ref={startDatePickerLink}
                            selected={startDate}
                            minDate={new Date()}
                            onChange={date => setStartDate(date)}
                            placeholderText="Start Date"
                            style={{ backgroundColor: "transparent" }}
                        /> */}
                        {/* <MDBIcon icon="calendar-alt" className="datePickerIcon" onClick={() => startDatePickerLink.current.setOpen(true)} /> */}

                    </div>
                </MDBCol>
                <MDBCol className="formCol" size="12" sm="6" md="6" lg="6">
                    <FormInput type="text" label="Duration" value={newZoomInfo.zoomDuration} handleChange={(event) => setnewZoomInfo({ ...newZoomInfo, zoomDuration: event.target.value })} required />
                </MDBCol>
            </MDBRow>
            <MDBRow>
            </MDBRow>
            <MDBRow center className="mt-4">

            </MDBRow>
            <MDBRow center className="mt-4">
                <MDBCol size="6" sm="6" md="5" lg="4">
                    <FormButton type="submit">SAVE</FormButton>
                </MDBCol>
                <MDBCol size="6" sm="6" md="5" lg="4">
                    <FormButton type="button" greyCol={true} onClickFunc={onClose}>CANCEL</FormButton>
                </MDBCol>
            </MDBRow>
        </form>
    )
}

const MapStateToProps = ({ FFLDBList: { isAddNewClicked } }) => ({
    isAddNewClicked
})

const MapDispatchToProps = dispatch => ({
    setZoomLink: setZoomLink(dispatch),
    loadPage: (flag) => dispatch(loadPage(flag))
})
export default connect(MapStateToProps, MapDispatchToProps)(AddNewMeetingZoom);
