import React, {useState, useEffect} from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import FormSelect from '../../../components/form-select/form-select.component';
import { connect } from 'react-redux';
import { getCurrentRevenueGraph } from '../../../redux/dashboard/dashboard.action';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import DatePicker from 'react-datepicker';
import SpecificPeriodComponent from '../specific-period/specific-period.component';

const RevenueGraph = ({ getCurrentRevenueGraph, isProfit, isLipsey }) => {
    const datePickerLink = React.createRef();
    const [revenue_data, setRevenueData] = useState([]);
    const [totalValue, setTotalValue] = useState();
    const [totalFormatedValue, setTotalFormatedValue] = useState();


    //for selectbox of revenue types
    const revenueTypes = isLipsey? ["Lipsey"]:["All", "Webinars", "Products"];
    const [revenueOptionShow, setRevenueOptionShow] = useState(false);
    const [selectedRevenue, setSelectedRevenue] = useState("All");

    //for selectbox of revenue period
    const revenuePeriod = ["Day", "Week", "Month", "Year", "Specific Period"];
    const [revenuePeriodsShow, setRevenuePeriodsShow] = useState(false);
    const [selectedRevenuePeriod, setSelectedRevenuePeriod] = useState("Day");

    const tooltipstyle = {
        backgroundColor: 'lightgrey',
        border: '2px solid white'
    }

    const drawRevenueGraph = async () => {
        if (isLipsey) setSelectedRevenue("Lipsey");
        let startTime = new Date();
        let endTime = new Date();
        let periodUnit = "hour";
        let prodType = "both";

        switch (selectedRevenuePeriod) {
            case "Day":
                periodUnit = "hour";
                break;

            case "Week":
                let day = startTime.getDay();
                let diff = startTime.getDate() - day + (day === 0 ? -6 : 1 );
                startTime.setDate(diff);
                periodUnit = "day";
                break;

            case "Month":
                startTime = new Date(startTime.getFullYear(), startTime.getMonth(), 1);
                // endTime.setDate(endTime.getDate()-1);
                periodUnit = "day";
                break;

            case "Year":
                startTime = new Date(startTime.getFullYear(), 0, 1);
                periodUnit = "month";
                break;
        
            default:
                break;
        }

        switch (selectedRevenue) {
            case "All":
                prodType = "both";
                break;

            case "Webinars":
                prodType = "webinar";
                break;
            
            case "Products":
                prodType = "physical";
                break;

            case "Lipsey":
                prodType = "lipsey";
                break;                
            
            default:
                break;
        }

        startTime.setHours(0);
        startTime.setMinutes(0);

        let result = null;
        isProfit ?
            result = await getCurrentRevenueGraph(startTime.toISOString(), endTime.toISOString(), periodUnit, prodType, 'profit')
            :
            result = await getCurrentRevenueGraph(startTime.toISOString(), endTime.toISOString(), periodUnit, prodType, 'revenue')

        if (result) {
            let tempArray = [];
            let tempTotalVal = 0;
            result.map( data => {
                const tempDate = new Date(data.date.toString());
                tempTotalVal += data.amount;
                switch (selectedRevenuePeriod) {
                    case "Day":
                        tempArray.push({name: tempDate.getHours(), value: parseInt(data.amount)});
                        break;
                    case "Week":
                        const weekArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                        tempArray.push({name: weekArray[tempDate.getDay()-1], value: parseInt(data.amount)});
                        break;
                    case "Month":
                        tempArray.push({name: tempDate.getDate(), value: parseInt(data.amount)});
                        break;
                    case "Year":
                        tempArray.push({name: tempDate.getMonth()+1, value: parseInt(data.amount)});
                    default:
                        break;
                }                
            });
            setRevenueData([...tempArray]);
            tempTotalVal = tempTotalVal.toString();
            if (tempTotalVal.indexOf(".") > -1)
                tempTotalVal = tempTotalVal.slice(0, (tempTotalVal.indexOf("."))+3);
            setTotalValue(Number(tempTotalVal));
            setTotalFormatedValue(new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(tempTotalVal));
        }
    }
    const [isShowCustDate, setIsShowCustDate] = useState(false);
    useEffect(() => {
        if (selectedRevenuePeriod !== 'Selected period') {
            async function loadRevenue() {
                await drawRevenueGraph();
            }
            selectedRevenuePeriod === 'Specific Period' ? setIsShowCustDate(true) : loadRevenue()
        }
    }, [selectedRevenuePeriod, selectedRevenue, isShowCustDate]);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    
    const saveSpecificPeriod = async () => {
        setIsShowCustDate(false);
        setSelectedRevenuePeriod('Selected period');
        console.log(startDate, endDate);
        const periodUnit = "day";
        let prodType = "both";
        switch (selectedRevenue) {
            case "All":
                prodType = "both";
                break;

            case "Webinars":
                prodType = "webinar";
                break;
            
            case "Products":
                prodType = "physical";
                break;

            case "Lipsey":
                prodType = "lipsey";
                break;                
            
            default:
                break;
        }
        startDate.setHours(0);
        startDate.setMinutes(0);

        let result = null;
        isProfit ?
            result = await getCurrentRevenueGraph(startDate.toISOString(), endDate.toISOString(), periodUnit, prodType, 'profit')
            :
            result = await getCurrentRevenueGraph(startDate.toISOString(), endDate.toISOString(), periodUnit, prodType, 'revenue')

        if (result) {
            let tempArray = [];
            let tempTotalVal = 0;
            result.map( data => {
                const tempDate = new Date(data.date.toString());
                tempTotalVal += data.amount;            
                tempArray.push({name: tempDate.getDate(), value: parseInt(data.amount)});                         
            });
            setRevenueData([...tempArray]);
            tempTotalVal = tempTotalVal.toString();
            if (tempTotalVal.indexOf(".") > -1)
                tempTotalVal = tempTotalVal.slice(0, (tempTotalVal.indexOf("."))+3);
            setTotalValue(Number(tempTotalVal));
            setTotalFormatedValue(new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(tempTotalVal));
        }
    }

    return (        
            <div className="graph-wrapper">
                { 
                    isShowCustDate &&
                    <SpecificPeriodComponent
                        startDate={startDate} 
                        setStartDate={setStartDate} 
                        endDate={endDate} 
                        setEndDate={setEndDate} 
                        saveSpecificPeriod={saveSpecificPeriod} 
                        setIsShowCustDate={setIsShowCustDate}
                        setSelectedRevenuePeriod={setSelectedRevenuePeriod}
                    />
                }
                <MDBRow className="graph-header">
                    <MDBCol size="12" sm="12" md="12" lg="12" xl="5">
                        <p className="text-white mb-2">{ isProfit ? 'Profit Counter' : 'Revenue Counter'}</p>
                    </MDBCol>
                    <MDBCol size="12" sm="12" md="12" lg="12" xl="7">
                        <MDBRow end>
                            <MDBCol size="12" sm="12" md="6" lg="6" className="mb-2 pl-2">
                            <FormSelect options={revenueTypes} showSelectBox={()=>setRevenueOptionShow(!revenueOptionShow)} selectOption={(event)=>{
                                setRevenueOptionShow(false);
                                setSelectedRevenue(event.target.id);
                            }} optionShow={revenueOptionShow} placeholder={selectedRevenue}/>
                            </MDBCol>
                            <MDBCol size="12" sm="12" md="6" lg="6" className="mb-2 pl-2">
                            <FormSelect options={revenuePeriod} showSelectBox={()=>setRevenuePeriodsShow(!revenuePeriodsShow)} selectOption={(event)=>{
                                setRevenuePeriodsShow(false);
                                setSelectedRevenuePeriod(event.target.id);
                            }} optionShow={revenuePeriodsShow} placeholder={selectedRevenuePeriod}/>
                            </MDBCol>
                        </MDBRow>    
                    </MDBCol>                     
                </MDBRow>
                <div style={{ width: '100%', height: 240 }}>
                    <ResponsiveContainer>
                    <AreaChart
                        data={revenue_data}
                        margin={{
                        top: 10, right: 20, left: 10, bottom: 0,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="15%" stopColor="grey" stopOpacity={0.9}/>
                            <stop offset="85%" stopColor="grey" stopOpacity={0}/>
                            </linearGradient>
                            
                        </defs>
                        <CartesianGrid stroke="grey" horizontal={false}/>
                        <XAxis dataKey="name" stroke="white" tick={{fontSize: 11}} tickMargin={5} axisLine={{stroke: "grey"}} tickLine={{stroke: "grey"}}/>
                        <YAxis stroke="white" tickLine={false} axisLine={false} tick={{fontSize: 11}} tickMargin={5} tickFormatter={(value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(value)}/>
                        <Tooltip contentStyle={tooltipstyle} formatter={(value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(value)}/>
                        <Area type="monotone" dataKey="value" stroke="#e43c59" strokeWidth="2" dot={{ fill: '#e43c59', strokeWidth: 2 }} fillOpacity={1} fill="url(#colorRevenue)"/>
                    </AreaChart>
                    </ResponsiveContainer>
                </div>
                <p className="text-center text-white totalVal">Total Revenue: {totalFormatedValue}</p>
            </div>                    
  
    )
}

const MapDispatchToProps = dispatch => ({
    getCurrentRevenueGraph: getCurrentRevenueGraph(dispatch)
})

export default connect(null, MapDispatchToProps)(RevenueGraph);