import React, { useState, useEffect, useCallback } from 'react';
import './ffl-modifications-page.style.scss';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import FormButton from '../../components/form-button/form-button.component';
import FormInput from '../../components/form-input/form-input.component';


import { clearSyncing, createFFL, syncFFLs } from '../../redux/ffl-modifications/ffl-modifications.action';



import DatePicker from "react-datepicker"; 
import ReactExport from "react-export-excel";
import { useAlert } from 'react-alert';
import { connect } from 'react-redux';
import { getReportItems } from '../../redux/report/report.action';
import { getLipseysProducts } from '../../redux/product-list/product-list.action';
// import withPermissionChecking from '../../utils/HOC/withPermissionCheck';

const FFLModificationsPage = ({ isShowSideBar, isSyncing, syncingSuccess, syncingError, syncFFLs, clearSyncing }) => {
    const alert = useAlert();
    const [ffl, setFFL] = useState(false);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const [fflid, setFFLid] = useState('');
    
    const sendData=async ()=>{
        setIsLoadingBtn(true)
        const result = await createFFL(ffl);
        console.log("RESULT", result)
        if(result.created) {
            alert.success("Added successfully");
            setFFLid(result.id)
        }
        setIsLoadingBtn(false)
    }

    const handleSyncFFLs = useCallback(
        () => {
            syncFFLs()
        },
        [syncFFLs],
    )

    useEffect(() => {
        if (syncingError) {
            alert.error('Syncing FFLs failed')
            clearSyncing()
        }
    }, [syncingError, clearSyncing])

    useEffect(() => {
        if (syncingSuccess) {
            alert.success('Syncing FFLs successfully')
            clearSyncing()
        }
    }, [syncingSuccess, clearSyncing])

    return (
        <div className="report-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
            <div className="report-wrapper">
                <h1 className="text-white text-center font-weight-bold">FFL Modifications Page. IT Team</h1>
                <MDBRow center className='mb-4'>
                    <MDBCol size="6">
                        <FormButton isLoading={isSyncing} disabled={isSyncing} onClickFunc={handleSyncFFLs}>SYNC FFLs</FormButton>
                    </MDBCol>
                </MDBRow>
                <h5 className="text-center">Type FFL</h5>
                <MDBRow center className="mb-4">
                    <FormInput name='ffl' handleChange={(event) => setFFL(event.target.value)} required />
                </MDBRow>
                <h5 className="text-center">FFL ID</h5>
                <MDBRow center className="mb-4">
                    <FormInput name='ffl' value={fflid}/>
                </MDBRow>
                <MDBRow center>
                    <MDBCol size="6">
                        <FormButton isLoading={isLoadingBtn} onClickFunc={()=>sendData()}>SEARCH</FormButton>
                    </MDBCol>
                </MDBRow>
            </div>            
        </div>
    )
}

const MapStateToProps = ({user: {isShowSideBar}, fflModification: {isSyncing, syncingSuccess, syncingError}}) => ({
    isShowSideBar,
    isSyncing,
    syncingError,
    syncingSuccess
})

const MapDispatchToProps = dispatch => ({
    syncFFLs: syncFFLs(dispatch),
    clearSyncing: clearSyncing(dispatch)
})

export default connect(MapStateToProps, MapDispatchToProps)(FFLModificationsPage);