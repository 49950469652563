import React, { useState, useEffect, Fragment} from 'react';
import { MDBIcon } from 'mdbreact';
import { setBookNumberAction, getShippingItems, setNotesAction } from '../../../redux/shipping/shipping.action';
import { connect } from 'react-redux';
import { loadPage } from '../../../redux/user/user.action';
import { useAlert } from 'react-alert';

const BookNumberComponent = ({id, bookNum, status, convertState, limit, offset, isNotFFL, setBookNumberAction, setNotesAction, getShippingItems, loadPage}) => {
  
    const alert = useAlert();

    const [bookNumber, setBookNumber] = useState("");

    useEffect(() => {
        bookNum ? setBookNumber(bookNum) : setBookNumber("");
    }, [bookNum]);

    const setBookNumberFunc = async () => {
        const obj = {
            id: id,
            book_number: bookNumber
        }
        loadPage(true);
        const result = await setBookNumberAction(obj);
        if (result === "success"){
            alert.success("Set the book number successfully");
            await getShippingItems(
                status === "Not Printed" ? "not_printed" : status === "Printed" ? "printed" : status === "Shipped" ? "shipped" : "all",                
                limit, 
                offset
            );
        }
        else
            alert.error("Setting failed");
        loadPage(false);
    }
    return (
        <div className="trackingWrapper">
            <input disabled={convertState !== "not_printed" || isNotFFL ? true : false} type="text" value={ isNotFFL ? "N/A" : bookNumber } onChange={event => setBookNumber(event.target.value)}/>
            <button disabled={convertState !== "not_printed" || isNotFFL ? true : false} onClick={()=>setBookNumberFunc()}><MDBIcon icon="check" /></button>
        </div>
    )
}

const MapDispatchToProps = dispatch => ({
    setBookNumberAction: setBookNumberAction(dispatch),
    getShippingItems: getShippingItems(dispatch),
    loadPage: flag => dispatch(loadPage(flag))
})
export default connect(null, MapDispatchToProps)(BookNumberComponent);