import SoldPhysicalActionTypes from './sold-physical.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getProductItems = dispatch => async ( filterStr = null, limit = 10, offset = 0, fflFilter = '') => {
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/physical/soldout?filterString=${filterStr}&&limit=${limit}&offset=${offset}&productType=product&onlyFFl=${fflFilter}`, { 
            headers: (await getMyTokenFunc())
        });
       dispatch(setProductItems(result.data));
       return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}


export const getProductItemsCount = dispatch => async ( filterStr = null, limit = 10, offset = 0) => {
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/physical/soldout?filterString=&&limit=${limit}&offset=${offset}&productType=product`, { 
            headers: (await getMyTokenFunc())
        });
        dispatch(setProductItemsCount(result.data));
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const getCountProductItems = dispatch => async ( ) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/products/physical/soldout/count?productType=product`, { 
            headers: (await getMyTokenFunc())
        })        
        dispatch(setCountProductItems(result?.data));
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const addFFLItem = dispatch => async ( obj ) => {
    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/products/physical/soldout/ffl`, obj, { 
            headers: (await getMyTokenFunc())
        });
        console.log(result)
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
        return error?.response?.data?.message;
    }
}

export const setFilterString = filterStr => ({
    type: SoldPhysicalActionTypes.SET_FILTER_STRING,
    payload: filterStr
})

export const setLimitPerPage = limit => ({
    type: SoldPhysicalActionTypes.SET_LIMIT_PER_PAGE,
    payload: limit
})

export const setProductPageNum = num => ({
    type: SoldPhysicalActionTypes.SET_PRODUCT_LIST_PAGE_NUM,
    payload: num
})

const setProductItems = items => ({
    type: SoldPhysicalActionTypes.SET_PRODUCT_LIST_ITEMS,
    payload: items
})
const setProductItemsCount = items => ({
    type: SoldPhysicalActionTypes.SET_PRODUCT_LIST_ITEMS_COUNT,
    payload: items
})

const setCountProductItems = items => ({
    type: SoldPhysicalActionTypes.SET_PRODUCT_COUNT,
    payload: items
})

export const setFFLFilter = filter => ({
    type: SoldPhysicalActionTypes.SET_FFL_FILTER,
    payload: filter
})


