import React, { useState, useEffect, Fragment, useRef } from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { useAlert } from 'react-alert';
import FormButton from '../../../components/form-button/form-button.component';
import FormInput from '../../../components/form-input/form-input.component';
import FormSelect from '../../../components/form-select/form-select.component';
import { useHistory } from 'react-router-dom';
import { groupingItem, convertStatus, getShippingItems } from '../../../redux/shipping/shipping.action';
import { connect } from 'react-redux';
import { loadPage } from '../../../redux/user/user.action';
import './row-item.style.scss';
import BookNumberComponent from './book-number.component';
import NotesComponent from './notes.component';
import { Storage } from 'aws-amplify';
import moment from 'moment-timezone';
import ConfirmationModal from '../../../components/confirmation-modal/ConfirmationModal';
import { sendToShipping } from '../../../redux/completed-webinar/completed-webinar.action';
import ImageGallery from 'react-image-gallery';
import { getGalleryImage } from '../../../redux/ffl-db/ffl-db.action';
import LongItemWithTooltip from "../../../components/tooltip/long-item-with-tooltip";
import Switch from 'react-switch';




const ShippingRowItem = ({ id, item, index, statusFilter, pageLimit, pageOffset, isMobile, groupingItem, convertStatus, getShippingItems, loadParent, sendToShipping, setCurrentRow, setLoading, loading,showEditFFLModal ,getGalleryImage,filterString,
	setFilterString,}) => {
    const historyURL = useHistory();
    const alert = useAlert();
    const [imageUrl, setImageUrl] = useState(null);
    const [isShowWonList, setIsShowWonList] = useState();
    const [itemSelected, setItemSelected] = useState(null);
    const [isAllShow, setIsAllShow] = useState(false);
    const [isOpenTable, setIsOpenTable] = useState(false);
    const [trackingNum, setTrackingNum] = useState("");
    const [galleryItem, setGalleryItem] = useState(0);
    const [galleryUrls, setGalleryUrls] = useState([]);
    // for select box of convert status
    const stateFilter = ["Not Printed", "Printed", "Shipped"];
    const [stateFilterShow, setStateFilterShow] = useState(false);
    const [currentStateFilter, setCurrentStateFilter] = useState("Not Printed");

    const [isConvertDropdownOpened, setIsConvertDropdownOpened] = useState(false);

    const [changingStatusDlg, setChanginStatusDlg] = useState(false);

    // display ffl image
    const [realImageUrl, setRealImageUrl] = useState(null);
    const [isShowFullSizeImage, setIsShowFullSizeImage] = useState(false);

    const [showFFLModal, setShowFFLModal] = useState(false);
    const [showConfirmNotRequiredModal, setShowConfirmNotRequiredModal] = useState(false);
    const [showNotRequiredModal, setShowNotRequiredModal] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);

    const copyFFL = async () => {
		var copyText = item.children[0].webinar_shipping.ffl_database.fflNumber;
		navigator.clipboard.writeText(copyText);
	}

    useEffect(() => {
        if (item && item.shipping_status) {
            let statusStr = "";
            statusStr = item.shipping_status === "not_printed" ? "Not Printed" : item.shipping_status === "printed" ? "Printed" : "Shipped";
            setCurrentStateFilter(statusStr);
        }
        setIsConvertDropdownOpened(false);
    }, [item, loadPage]);

    useEffect(() => {
        function load() {
            const compareStr = item.shipping_status === "not_printed" ? "Not Printed" : item.shipping_status === "printed" ? "Printed" : "Shipped";

            if (compareStr !== currentStateFilter) {

                if (item.children.length === 1) {
                    if (!item.children[0].book_number && !item.ffl_not_required) {
                        alert.info("Please enter book number");
                        setCurrentStateFilter(compareStr);
                        return;
                    }
                }
                else {
                    for (let index = 0; index < item.children.length; index++) {
                        if (!item.children[index].book_number && !item.ffl_not_required) {
                            alert.info("Please enter all book numbers of current group");
                            setCurrentStateFilter(compareStr);
                            return;
                        }
                    }
                }
                setChanginStatusDlg(true);
            }
        }
        item && item.children && item.children.length > 0 && isConvertDropdownOpened && load()
    }, [currentStateFilter]);


    const addToGroupFunc = async (id) => {
        setLoading(true)
        const result = await groupingItem(id, true);
        setLoading(false)
        if (result === "success") {
            alert.success("Added into group successfully");
            loadParent()
        }
        else{
            alert.error("Adding failed");
        }
    }

    const removeFromGroupFunc = async (id) => {
        setLoading(true)
        const result = await groupingItem(id, false);
        if (result === "success") {
            alert.success("Removed from group successfully");
            loadParent()
        }
        else {
            alert.error("Removing failed");
        }
        setLoading(false)
    }

    // const [changingStatus, setChangingStatus] = useState();

    const setAllStatesToFalse = () => {
        setShowNotRequiredModal(false);
        setShowConfirmNotRequiredModal(false);
        setShowFFLModal(false)
    }

    const changeStatusFunc = async (event) => {
        event.preventDefault();
        const obj = {
            id: [],
            shipping_status: currentStateFilter === "Not Printed" ? "not_printed" : currentStateFilter === "Printed" ? "printed" : currentStateFilter === "Shipped" ? "shipped" : "all"
        }
        item.children.map(item => obj.id.push(item.id));

        setLoading(true)
        const result = await convertStatus(obj);
        setLoading(false)
        if (result === "success") {
            setLoading(true)
            await loadParent()
            setLoading(false)
            setChanginStatusDlg(false);
            alert.success("Convert status successfully",{
                delay: 2000,
                timeout: 2400
            });
            setAllStatesToFalse();
        } else {
            alert.error("Converting failed");
            setAllStatesToFalse();
        }
    }

    const showFullImageFunc = async () => {
        //console.log("item en boton",item);
        setLoading(true);
        item?.ffl_image_url && setRealImageUrl(await Storage.get(item.ffl_image_url))
        let galleryImgs = [];
        let galleryImg = null;

        const result = await getGalleryImage(item.real_ffl_id);    
        result.data.map((urls) => {//error
            async function load() {
                if (urls.is_active){
                    var imageUrl = await Storage.get(urls.ffl_url ?  checkFileType(urls.ffl_url ) : "")
                    galleryImg = {
                        id: urls.id,
                        original: imageUrl,
                        thumbnail: imageUrl,
                        is_head: urls.is_head,
                        url:urls.ffl_url,
                        url2: await Storage.get(urls.ffl_url),
                        originalWidth: 250,
                        id_fflScope: urls.id_fflScope,
                        className:'custom-gallery',
                    };
                    galleryImgs.push(galleryImg);
                }
            }
            load().then(loadImagesInGalery);
        }); 
        async function loadImagesInGalery() {
            setGalleryUrls([...galleryImgs]);
        }
          
        // setGalleryItem(0);
        setLoading(false);
        setIsShowFullSizeImage(true);
    }
    function checkFileType(file) {
        var ext = file.split('.').reverse()[0]
        if(ext=='pdf')
        {
         return 'pdf.png';
        }else {
            return file;
        }     
    }

    const setBorderColorByStatus = (thin=false) => {
        let temp = item?.shipping_status;
        let border = `button-state-style  ${thin && 'button-state-style-thin'} button-state-style-`;
        let tempColor = temp?.toLowerCase() === "not_printed" ? `${border}white` : temp?.toLowerCase() === "printed" ? `${border}yellow` : `${border}green`;
        return tempColor;
    }

    const setBorderColorForSelectedRow = (text) => {
        let tempColor = text.toLowerCase() === "not_printed" ? "white" : text.toLowerCase() === "printed" ? "yellow" : "green";
        return tempColor
    }

    const setBorderColorInnerData = (text,extra) => {
        let tempColor = text.toLowerCase() === "not_printed" ? "white" : text.toLowerCase() === "printed" ? "yellow" : "green";
        let border = `container-inner-data container-inner-data-left-${tempColor} container-inner-data-${extra}-${tempColor}`;
        return border;
    }

    useEffect(() => {
        if(isAllShow === false){
            setItemSelected(null)
        }
    }, [isAllShow])

    const handleFFLNotRequired = async ({modifiedFFL,isEdit = false,oneTimeChange,fflNotRequired}) => {
        
        let winner_id
        let ffl_id
    
        winner_id=modifiedFFL?.webinar_shipping?.id
        oneTimeChange=null
        ffl_id=null
        
        
        const obj = {
            winner_id: winner_id,
            ffl_id: ffl_id,
            ffl_not_required: fflNotRequired,
            ffl_one_time:oneTimeChange,
            ffl_edited:isEdit,
            purchase_or_winner_id: item.winner_id,
        }
        
        try {
            setLoading(true);
            const result = await sendToShipping(obj);
            setLoading(false);
        
            if (result === "success"){
                setLoading(true);
                await loadParent();
                setLoading(false);
                alert.success("FFL is assigned successfully",
                {
                    timeout: 2200,
                });

                setAllStatesToFalse();
            } else{
                alert.error("Failed assigning");
                setAllStatesToFalse();
            }
        } catch (error) {
            alert.error("Failed assigning");
        }

	}

    const FflDataItem = ({
        title,
        type,
        value,
    }) => {
        return (
            <div 
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: "100%",
                    margin: "5px 0",
                }}
            >
                <MDBRow>
                    <MDBCol size='12'>
                        <span style={{ color:"rgba(255,255,255,0.4)", fontSize: "16px" }}>
                        {title}:
                        </span>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size='12' >
                        { type === 'toggle' ?
                            <Switch onColor="#57bd7a" className='mb-1 pb-0 mt-1 toggle'
                                checked={value === 'true'}
                                disabled={true}
                                onChange={() => {}}
                            /> :
                            <p
                            style={{ fontSize: "18px" }}
                            className="mb-1 pb-0"
                            >
                            {
                                <LongItemWithTooltip text={value} maxlenght={40} />
                            }
                            </p>
                        }
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
    
    const getFFLInfo =  (item) => {
        
        let data = null;
        if( item?.children[0]?.webinar_shipping?.id){
            data = item?.children[0]?.webinar_shipping?.ffl_database
        } 
        // else if( item?.children[0]?.offline_shipping?.id){
        //     data = item?.children[0]?.offline_shipping?.ffl_database
        // } else if( item?.children[0]?.physical_shipping?.id){
        //     data = item?.children[0]?.physical_shipping?.ffl_database
        // }
     
        let address = data.premiseStreet;
        address = address.concat(data.premiseCity ? ', ' +  data.premiseCity : "");
        address = address.concat(data.premiseState ? ', ' +  data.premiseState : "");
        let fflInfo = {
            licenseName: data?.licenseName || "",
            businessName: data?.businessName || "",
            address: address,
            telephone:  data?.voiceTelephone ? (`(${data?.voiceTelephone.slice(0, 3)}) ${data?.voiceTelephone.slice(3, 6)}-${data?.voiceTelephone.slice(6)}`) : "",
            fflNumber: data?.fflNumber || "",
            expiration: moment(data?.expiration).format("MM/DD/YYYY") || "",
            loaExpirationDate: data?.loaExpirationDate ? moment(data.loaExpirationDate).format("MM/DD/YYYY") : "",
            note:item?.children[0]?.real_notes  || "",
            real_secondary_phone_number: item?.children[0]?.real_secondary_phone_number ? (`(${item?.children[0]?.real_secondary_phone_number.slice(2, 5)}) ${item?.children[0]?.real_secondary_phone_number.slice(5, 8)}-${item?.children[0]?.real_secondary_phone_number.slice(8)}`) : "",
            real_nfa_flag: ((item?.children[0]?.real_nfa_flag )=== 1)  ?   "true" : ((item?.children[0]?.real_nfa_flag )=== 0) ? "false" : '',
            transfer_flag: ((item?.children[0]?.real_transfer_flag ) === 0)  ?   "true" : ((item?.children[0]?.real_transfer_flag )=== 1) ? "false" : '',
            real_billing_address:item?.children[0]?.real_billing_address  || "", 
        }
        return fflInfo; 
    }

    const RenderFFLInfo = ({item}) => {
      
        let fflInfo = getFFLInfo(item)
        return (
            <>
                {fflInfo.fflNumber && (
                    <FflDataItem
                        title="FFL Number"
                        value={fflInfo.fflNumber}
                    />
                )}
                
                {fflInfo.licenseName && (
                    <FflDataItem
                        title="License Name"
                        value={fflInfo.licenseName}
                    />
                )}

                {fflInfo.businessName && (
                    <FflDataItem
                    title="Business Name"
                    value={fflInfo.businessName}
                />
                )}
                {fflInfo.address && !fflInfo.address.includes(null) && (
                    <FflDataItem
                        title="Address"
                        value={fflInfo.address}
                    />
                )}

                {fflInfo.telephone && (
                    <FflDataItem
                    title="Telephone"
                    value={fflInfo.telephone}
                />
                )}
                
                {fflInfo.expiration && fflInfo.expiration !== "Invalid date" && (
                    <FflDataItem
                    title="Expiration"
                    value={fflInfo.expiration}
                />
                )}
                <hr style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "rgba(255,255,255,0.2)",
                }} />
            </>
        )
    }
    const  RenderFFLInfo2 =   ({item}) => {
        //console.log("item",item);
        let fflInfo =   getFFLInfo(item)   
        return (
            <>
                {fflInfo.fflNumber && (
                    <FflDataItem
                        title="FFL Number"
                        value={fflInfo.fflNumber}
                    />
                )}
                
                {fflInfo.licenseName && (
                    <FflDataItem
                        title="License Name"
                        value={fflInfo.licenseName}
                    />
                )}

           
                {fflInfo.address && !fflInfo.address.includes(null) && (
                    <FflDataItem
                        title="Address"
                        value={fflInfo.address}
                    />
                )}

                {fflInfo.telephone && (
                    <FflDataItem
                    title="Telephone"
                    value={fflInfo.telephone}
                />
                )}
                
                {fflInfo.expiration && fflInfo.expiration !== "Invalid date" && (
                    <FflDataItem
                    title="Expiration"
                    value={fflInfo.expiration}
                />
                )}
                {fflInfo.loaExpirationDate && (
                    <FflDataItem
                    title="Loa Expiration Date"
                    value={fflInfo.loaExpirationDate}
                />
                )}
                 {fflInfo.real_billing_address &&(
                    <FflDataItem
                    title="Billing Address"
                    value={fflInfo.real_billing_address}
                />
                )}
                 {fflInfo.real_secondary_phone_number &&  (
                    <FflDataItem
                    title="Secondary Phone Number"
                    value={fflInfo.real_secondary_phone_number}
                />
                )}
                { fflInfo.real_nfa_flag &&  (
                    <FflDataItem
                        title="NFA Flag"
                        type="toggle"
                        value={fflInfo.real_nfa_flag}
                    />
                )}  
                { fflInfo.transfer_flag &&  (
                    <FflDataItem
                        title="No Transfer"
                        type="toggle"
                        value={fflInfo.transfer_flag}
                    />
                )}  
                 {fflInfo.note && (
                    <FflDataItem
                    title="Notes"
                    value={fflInfo.note}
                />
                )}  
              
                <hr style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "rgba(255,255,255,0.2)",
                }} />
            </>
        )
    }
    const handleSlide = (index) => {
        setGalleryItem(index);
    }
    const renderTable = () => 
    <Fragment>
        {
            changingStatusDlg && <div className="modal-shipping">
                <div className="content">
                    <h2 className='title'>FFL Information</h2>
                    {
                        item?.ffl_not_required ? (
                            <div style={{
                                fontSize: "18px",
                            }}>
                                <p>FFL is not required</p>
                            </div>
                        )
                        :
                        item?.real_businessName === null && item?.real_licenseName === null  ? (
                            <div>
                                <p>FFL information is not available</p>
                            </div>
                        )
                        :
                        (
                            <RenderFFLInfo item={item} />
                        )
                    }
                    <div className='buttons'>
                        <hr />
                        <h2 className="title text-white text-center">Are you sure you want to change the status?</h2>
                        <div className='btns'>
                            <FormButton  isLoading={loading} onClickFunc={changeStatusFunc} >
                                Confirm
                            </FormButton>
                            <span style={{maring: "0 5px", width: "7px"}}></span>
                            <FormButton greyCol={true} onClickFunc={() => {
                                setChanginStatusDlg(false);
                                setCurrentStateFilter(item.shipping_status === "not_printed" ? "Not Printed" : item.shipping_status === "printed" ? "Printed" : "Shipped");
                            }}> Cancel </FormButton>
                        </div>
                    </div>
                    {/* <form onSubmit={changeStatusFunc} >
                        <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={() => {
                            setChanginStatusDlg(false);
                            setCurrentStateFilter(item.shipping_status === "not_printed" ? "Not Printed" : item.shipping_status === "printed" ? "Printed" : "Shipped");
                        }} />
                        <br />
                        <h3 className="text-white text-center font-weight-bold mb-4">Are you sure you want to change the status?</h3>
                        <MDBRow center className="mb-4 mobile-row">
                            <MDBCol size="6">
                                <FormButton type="submit">YES</FormButton>
                            </MDBCol>
                            <MDBCol size="6">
                                <FormButton greyCol={true} onClickFunc={() => {
                                    setChanginStatusDlg(false);
                                    setCurrentStateFilter(item.shipping_status === "not_printed" ? "Not Printed" : item.shipping_status === "printed" ? "Printed" : "Shipped");
                                }}>NO</FormButton>
                            </MDBCol>
                        </MDBRow>
                    </form> */}
                </div>
            </div>
        }
        {
            showConfirmNotRequiredModal && (
                <ConfirmationModal
                    isLoading={loading}
                    message="Are you sure you want to proceed?"
                    closeModal={() => setShowConfirmNotRequiredModal(false)}
                    editFFL={() => {
                        setShowConfirmNotRequiredModal(false)
                        showEditFFLModal(itemToEdit)

                    }}
                    notRequired={() => {
                        setShowNotRequiredModal(true)
                    }}
                    noResponse={() => {
                        setShowConfirmNotRequiredModal(false)
                        setShowNotRequiredModal(false)
                    }}
                    style="custom"
                />
            )
        }
        {
            showNotRequiredModal && (
                <ConfirmationModal
                    isLoading={loading}
                    message="Are you sure you want to proceed?"
                    closeModal={() => setShowNotRequiredModal(false)}
                    editFFL={() => {}}
                    notRequired={() => {
                        handleFFLNotRequired({
                            modifiedFFL: itemToEdit,
                            isEdit: false,
                            oneTimeChange: null,
                            fflNotRequired: true
                        })
                    }}
                    noResponse={() => {
                        setShowNotRequiredModal(false)
                        setShowConfirmNotRequiredModal(false)
                        
                    }}
                    style="basic"
                />
            )
        }
        {
            isMobile ?
                <Fragment>
                    <MDBRow className="table-row" style={{
                        backgroundColor: "rgb(142,142,142)",
                        color: "#FFF", 
                    }}>
                        <MDBCol size="5" middle className="text-center">
                            {
                                item.real_shipping_address
                            }
                        </MDBCol>
                        <MDBCol size="0.5" middle className="text-center">
                            <div className="non-img">
                                <MDBIcon far icon="fa-thin fa-copy"  onClick={()=>copyFFL()}/>
                            </div>
                            </MDBCol>
                        <MDBCol size="3" middle className="text-center fflNameLabel mobile email-col trunc" onClick={showFullImageFunc}>
                            {
                                item.ffl_not_required ? "Non-FFL" : item?.real_licenseName || item?.real_businessName
                            }
                        </MDBCol>
                        <MDBCol size="2" middle className="text-center">
                            <button className="openBtn" onClick={() => {
                                setIsOpenTable(true);
                            }}><MDBIcon icon="plus" /></button>
                        </MDBCol>
                    </MDBRow>
                    {
                        isOpenTable && <Fragment>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Shipping Address</MDBCol>
                                <MDBCol size="5" middle className="text-center">
                                    {
                                        item.real_shipping_address
                                    }
                                </MDBCol>
                                <MDBCol size="2" middle className="text-center">
                                    <button className="openBtn custom-button" onClick={() => {
                                        setIsOpenTable(false);
                                        setIsAllShow(false);
                                    }}><MDBIcon icon="minus" /></button>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">FFL Name</MDBCol>
                                <MDBCol size="7" middle className="text-center trunc">
                                    {
                                        item?.ffl_not_required ? "Non-FFL" : item?.real_licenseName || item?.real_businessName
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Item Count</MDBCol>
                                <MDBCol size="7" middle className="text-center">
                                    {
                                        item.children.length
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="table-row mobile-opend-table">
                                <MDBCol size="5" middle className="text-center text-white">Convert Status</MDBCol>
                                <MDBCol size="7" middle className="text-center" onClick={() => setIsConvertDropdownOpened(true)}>
                                    {
                                        <FormSelect options={stateFilter} showSelectBox={() => setStateFilterShow(!stateFilterShow)} selectOption={(event) => {
                                            setStateFilterShow(false);
                                            setCurrentStateFilter(event.target.id);
                                        }} optionShow={stateFilterShow} placeholder={currentStateFilter} />
                                    }
                                </MDBCol>
                            </MDBRow>
                            {
                                item.children && item.children.length > 0 && item.children.map((each, i) => <div className="mobile-child-wrapper" key={i}>
                                    <Fragment key={i}>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle className="text-center text-white">Buyer/Winner Name</MDBCol>
                                            <MDBCol size="7" middle className="text-center trunc" onClick={() => {
                                                setIsAllShow(!isAllShow);
                                                setIsShowWonList((index + 1) * (i + 1));
                                                setItemSelected(each)
                                            }}>
                                                {
                                                    showBuyerName(each)
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle className="text-center text-white">Product Name</MDBCol>
                                            <MDBCol size="7" middle className="text-center trunc">
                                                {
                                                    showProductName(each)
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle  className="text-center text-white">Edit FFL</MDBCol>
                                            <MDBCol size="7" middle className="text-center">
                                                {
                                                    each?.shipping_status === "not_printed" ? (
                                                        <div class="fas fa-pencil-alt edit" onClick={() => {
                                                            //setShowFFLModal(true)
                                                            setShowConfirmNotRequiredModal(true)
                                                            setItemToEdit(each)
                                                            setCurrentRow(each)
                                                        }}> </div>
                                                    ) :
                                                    (
                                                        <div></div>
                                                    )
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle className="text-center text-white">Book Number</MDBCol>
                                            <MDBCol size="7" middle className="text-center">
                                                <BookNumberComponent setIsLoading={setLoading} loadParent={loadParent} key={i} id={each.id} bookNum={each.book_number} status={statusFilter} convertState={item.shipping_status} isNotFFL={each.ffl_not_required} limit={pageLimit} offset={pageOffset} />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle className="text-center text-white">Product Type</MDBCol>
                                            <MDBCol size="7" middle className="text-center">
                                                {
                                                    showProductType(each)
                                                   }
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle className="text-center text-white">Notes</MDBCol>
                                            <MDBCol size="7" middle className="text-center">
                                                <NotesComponent setIsLoading={setLoading} loadParent={loadParent} key={i} id={each.id} notesString={each.notes} status={statusFilter} convertState={item.shipping_status} isNotFFL={each.ffl_not_required} limit={pageLimit} offset={pageOffset} />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="table-row ">
                                            <MDBCol size="5" middle className="text-center text-white">Group/Ungroup</MDBCol>
                                            <MDBCol size="7" middle className="text-center">
                                                {
                                                    each.is_grouped ? <button className="ungroupBtn" disabled={each.shipping_status === "not_printed" ? false : true} onClick={() => removeFromGroupFunc(each.id)}>Ungroup</button>
                                                        :
                                                        <button className="ungroupBtn" onClick={() => addToGroupFunc(each.id)} disabled={each.shipping_status === "not_printed" ? false : true}>Group</button>
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                        {
                                            isAllShow && isShowWonList && isShowWonList === (index + 1) * (i + 1) && <Fragment>
                                                <MDBRow className="ffl-row">
                                                    <MDBCol size="3">
                                                        Name
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center">
                                                        Phone
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center">
                                                        Email
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="ffl-row bottom-border">
                                                    <MDBCol size="3" className="email-col">
                                                        {showBuyerName(each)}
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center">
                                                        {
                                                            showPhone(each)
                                                        }
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center email-col">
                                                        {showEmail(each)}
                                                    </MDBCol>
                                                </MDBRow>

                                            </Fragment>
                                        }
                                    </Fragment>
                                </div>)
                            }

                        </Fragment>
                    }
                </Fragment>
                :
                <div className={`${isAllShow && `container-initial-row-item container-initial-row-item-${setBorderColorForSelectedRow(item.shipping_status)}`}`}>
                    <MDBRow className={`table-row ${setBorderColorByStatus()}
                     ${ itemSelected !== null && item?.indexParent === itemSelected?.indexParent && ` selected-row selected-row-${setBorderColorForSelectedRow(item.shipping_status)}`}
                    `} style={{
                        backgroundColor: "rgb(142,142,142)",
                        color: "#FFF", 
                        fontWeight: "bold",
                        marginTop: '30px',
                        }}>
                        <MDBCol size="4">

                            {
                                item?.ffl_not_required ?
                                item.children[0]?.webinar_shipping?.user_data.address
                                : item.real_shipping_address
                            }
                        </MDBCol>

                        <MDBCol size="1" middle className="text-center">
                            <div className="non-img">
                                <MDBIcon far icon="fa-thin fa-copy"  onClick={()=>copyFFL()}/>
                            </div>
                            </MDBCol>

                        <MDBCol size="3">
                            <div className="fflNameLabel text-center"  onClick={showFullImageFunc}>
                                {
                                    item?.ffl_not_required ? "Non-FFL" : (
                                        (item?.real_businessName!=='' && item?.real_businessName!==null) ? item?.real_businessName : item?.real_licenseName
                                    )
                                }
                            </div>
                        </MDBCol>
                        <MDBCol size="2" className="text-center">
                            {
                                item.children && item.children.length
                            }
                        </MDBCol>
                        <MDBCol size="2" className="text-center" onClick={() => setIsConvertDropdownOpened(true)}>
                            <FormSelect options={stateFilter} showSelectBox={() => setStateFilterShow(!stateFilterShow)} selectOption={(event) => {
                                setStateFilterShow(false);
                                setCurrentStateFilter(event.target.id);
                            }} optionShow={stateFilterShow} placeholder={currentStateFilter} />
                        </MDBCol>
                    </MDBRow>

                    <Fragment>
                        <MDBRow className={`ffl-row table-names custom
                         ${ itemSelected !== null && item?.indexParent === itemSelected?.indexParent && ` selected-row-int selected-row-int-${setBorderColorForSelectedRow(item.shipping_status)}` }
                         ${setBorderColorByStatus()}
                        `}>
                            <MDBCol size="2">
                                Buyer/Winner Name
                            </MDBCol>
                            <MDBCol size="2" >
                                Product Name
                            </MDBCol>
                            <MDBCol size="1" className='color'>
                                Edit FFL
                            </MDBCol>
                            <MDBCol size="2" >
                                Book Number
                            </MDBCol>
                            <MDBCol size="1" >
                                Product Type
                            </MDBCol>
                            <MDBCol size="2" >
                                Notes
                            </MDBCol>
                            <MDBCol size="1" >
                                Group/Ungroup
                            </MDBCol>
                        </MDBRow>
                        {
                            item.children && item.children.length > 0 ? item.children.map((each, i) =>
                                <Fragment key={i}>
                                    <MDBRow className={`ffl-row bottom-border custom table-elements ${setBorderColorByStatus()}
                                    ${ itemSelected !== null && item?.indexParent === itemSelected?.indexParent && ` selected-row-int selected-row-int-${setBorderColorForSelectedRow(item.shipping_status)}`}
                                    `} key={i}>
                                        <MDBCol size="2" onClick={() => {
                                            setIsAllShow(!isAllShow);
                                            setIsShowWonList((index + 1) * (i + 1));
                                            setItemSelected(each);
                                        }} className='trunc'  style={{ cursor: "pointer" }}>
                                            {showBuyerName(each)}
                                        </MDBCol>
                                        <MDBCol size="2" className='trunc'>
                                            {showProductName(each)}
                                        </MDBCol>
                                        <MDBCol size="1" className='text-center'>
                                            {
                                                each?.shipping_status === "not_printed" ?
                                                (
                                                    <div class="fas fa-pencil-alt edit" onClick={() => {
                                                        //setShowFFLModal(true)
                                                        setShowConfirmNotRequiredModal(true)
                                                        setItemToEdit(each)
                                                        setCurrentRow(each)
                                                    }}> </div>
                                                ):
                                                (
                                                    <div></div>
                                                )
                                            }
                                        </MDBCol>
                                        <MDBCol size="2">
                                            <BookNumberComponent loadParent={loadParent} setIsLoading={setLoading}  key={i} id={each.id} bookNum={each.book_number} status={statusFilter} convertState={item.shipping_status} isNotFFL={each.ffl_not_required} limit={pageLimit} offset={pageOffset} />
                                        </MDBCol>
                                        <MDBCol className='text-center' size="1">
                                            {showProductType(each) || "Webinar"}
                                        </MDBCol>
                                        <MDBCol size="2" >
                                            <NotesComponent loadParent={loadParent} setIsLoading={setLoading} key={i} id={each.id} notesString={each.notes} status={statusFilter} convertState={item.shipping_status} isNotFFL={each.ffl_not_required} limit={pageLimit} offset={pageOffset} />
                                        </MDBCol>
                                        <MDBCol className='text-center' size="1">
                                            {
                                                each.is_grouped ? <button className="ungroupBtn" disabled={each.shipping_status === "not_printed" ? false : true} onClick={() => removeFromGroupFunc(each.id)}>Ungroup</button>
                                                    :
                                                    <button className="ungroupBtn" onClick={() => addToGroupFunc(each.id)} disabled={each.shipping_status === "not_printed" ? false : true}>Group</button>
                                            }

                                        </MDBCol>
                                    </MDBRow>
                                    {
                                        isAllShow && isShowWonList && isShowWonList === (index + 1) * (i + 1) && <Fragment>
                                            <div 
                                            className={`${setBorderColorInnerData(item.shipping_status,'top')}`}>
                                                {/* INNER DATA 1 */}
                                                <MDBRow className={`complete-webinar-row complete-webinar-row-${setBorderColorForSelectedRow(item.shipping_status)} `} >
                                                    <MDBCol size="2">
                                                        Name
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center">
                                                        Phone
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center">
                                                        Address
                                                    </MDBCol>
                                                    <MDBCol size="3" className="text-center">
                                                        Email
                                                    </MDBCol>
                                                </MDBRow>
                                            </div>

                                            <div 
                                            className={`${setBorderColorInnerData(item.shipping_status,'footer')}
                                            `}>
                                                {/* INNER DATA 2 */}
                                            <MDBRow className={`complete-webinar-row  bottom-border`}>
                                                <MDBCol size="2" className='trunc'>
                                                    { showBuyerName(each) }
                                                </MDBCol>
                                                <MDBCol size="3" className="text-center trunc">
                                                    { showPhone(each) }
                                                </MDBCol>
                                                <MDBCol size="3" className="text-center trunc">
                                                    { //aca es lA DIRECCION DE ABAJO
                                                    showAddress(each) }
                                                </MDBCol>
                                                <MDBCol size="3" className="text-center email-col trunc">
                                                    { showEmail(each) }
                                                </MDBCol>
                                            </MDBRow>
                                            </div>

                                        </Fragment>
                                    }
                                </Fragment>
                            )
                                :
                                <div className="non-ffl-row text-center">
                                    No Won Items
                                </div>
                        }
                    </Fragment>
                </div>
        }
    </Fragment>
    const showBuyerName = (each) => {
        if (each.product_type === "webinar") {
            return <LongItemWithTooltip text={each.webinar_shipping?.user_data.username} />;
        }
        // else if (each.product_type === "physical") {
        //     return each.physical_shipping?.buyer?.username
        // }
        // else if (each.product_type === "physical") {
        //     return each.physical_shipping.buyer.username
        // }
        // else {
        //     return each.offline_shipping?.buyer_name
        // }
    }
    const showProductName = (each) => {
        let textResponse = "";
        if (each.product_type === "webinar") {
          textResponse = each.webinar_shipping?.webinar.name;
        } else if (each.product_type === "physical") {
          textResponse = each.physical_shipping?.productInfo.productName;
        } else if (each.product_type === "physical") {
          textResponse = each.physical_shipping.productInfo.productName;
        } else {
          textResponse = each.offline_shipping?.product_name;
        }
        return <LongItemWithTooltip text={textResponse} />
    };

    const showProductType = (each) => {
        return capitalizeFirstLetter(each.product_type);
    }
    const showAddress = (each) => {
        if (each.product_type === "webinar") {
            return <LongItemWithTooltip text={each.webinar_shipping?.user_data?.address} maxlenght={40}/>;
        }
        // else if (each.product_type === "physical") {
        //     return each.physical_shipping?.buyer?.address
        // }
        // else if (each.product_type === "physical") {
        //     return each.physical_shipping.buyer.address
        // }
        // else {
        //     return each.offline_shipping?.shipping_address
        // }
    }
    const showPhone = (each) => {
        if (each?.product_type === "webinar") {
            return <span>({each?.webinar_shipping?.user_data?.phone_number?.slice(2, 5)}) {each?.webinar_shipping?.user_data?.phone_number?.slice(5, 8)}-{each?.webinar_shipping?.user_data?.phone_number?.slice(8)}</span>
        }
        // else if (each.product_type === "physical") {
        //     return <span>({each.physical_shipping.buyer.phone_number.slice(2, 5)}) {each.physical_shipping.buyer.phone_number.slice(5, 8)}-{each.physical_shipping.buyer.phone_number.slice(8)}</span>
        // }
        // else {
        //     return <span>({each?.offline_shipping?.buyer_phone?.slice(2, 5)}) {each?.offline_shipping?.buyer_phone?.slice(5, 8)}-{each?.offline_shipping?.buyer_phone?.slice(8)}</span>
        // }
    }

    const showEmail = (each) => {
        if (each.product_type === "webinar") {
            return each.webinar_shipping.user_data.email
        }
        // else if (each.product_type === "physical") {
        //     return each.physical_shipping.buyer.email
        // }
        // else {
        //     return each.offline_shipping.buyer_email
        // }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return (
        isMobile ?
        <> {
            !item.ffl_not_required &&
            isShowFullSizeImage && 
            <div className="modal-profile-form">
                <div className='content-ffl-data'>
                    {galleryUrls.length>0 &&
                            <p>
                                <ImageGallery                                
                                 showFullscreenButton={false}
                                 showPlayButton={false}
                                 onSlide={handleSlide} 
                                items={galleryUrls} onClick={(index)=>{
                                window.open(galleryUrls[galleryItem].url2, '_blank');
                            }}  showIndex={true} style={{marginBottom:isMobile? '-8%':''}} /> 
                                
                            </p>
                        }
                    <MDBIcon className="float-right closeIcon" icon="times" onClick={() => setIsShowFullSizeImage(false)} />
                        <p>
                            <RenderFFLInfo2 item={item} />
                        </p>
                      
                </div>
            </div>
        }
           {item && renderTable()}
        </>
        :
        <Fragment>

            {
                !item.ffl_not_required &&
                isShowFullSizeImage && <div className="full-size-img-wrapper">
                    <div className="full-size-img">
                        <MDBIcon className="float-right closeIcon" icon="times" onClick={() => setIsShowFullSizeImage(false)} />
                      
                            {galleryUrls.length>0 &&
                           
                           
                                <MDBCol size="4" >
                                  {
                                        <ImageGallery  
                                        showFullscreenButton={false} onSlide={handleSlide} 
                                        showPlayButton={false}
                                         items={galleryUrls} onClick={(index)=>{
                                          window.open(galleryUrls[galleryItem].url2, '_blank');
                                         }}  showIndex={true} style={{marginBottom:isMobile? '-8%':''}} /> 
                                    
                                         
                                  }
                                  </MDBCol>
                            }
                          
                            <MDBCol size="4" >{
    
                            }
                              
                                <RenderFFLInfo2 item={item} />
                                                     
                            </MDBCol>
                                   
                    </div>
                    
                    
                    
                </div>
                
            }
            
            {item && renderTable()}

        </Fragment>   
    )
}

const MapDispatchToProps = dispatch => ({
    loadPage: flag => dispatch(loadPage(flag)),
    groupingItem: groupingItem(dispatch),
    convertStatus: convertStatus(dispatch),
    getShippingItems: getShippingItems(dispatch),
    sendToShipping: sendToShipping(dispatch),
    getGalleryImage: getGalleryImage(dispatch),
})

export default connect(null, MapDispatchToProps)(ShippingRowItem);