import React, { useState, useEffect, Fragment } from 'react';
import './sidebar.style.scss';
import { MDBIcon } from 'mdbreact';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadPage, showSideBar } from '../../redux/user/user.action';
import router from '../../router';
import { checkPermission } from '../../utils/checkPermissions';
import Icon from '../../assets/bullet-icon.svg';
import { getCountProductItems } from '../../redux/sold-physical/sold-physical.action';
import { getProductItemsCount } from '../../redux/sold-out-webinars/sold-out-webinars.action';
import { getDistributorsProductItemsCount } from '../../redux/sold-distributors-products/sold-distributors-products.action';

const SideBar = ({ isShowSideBar, showSideBar, product_items,soldPhysical,soldOutWebinarList,soldDistributorsProducts, getCountProductItems,getProductItemsCount,getDistributorsProductItemsCount}) => {
 
	const urlHistory = useHistory();

	const permissions = JSON.parse(localStorage.getItem('userPermissions'));
	const [subMenuShow, setSubMenuShow] = useState(false);
	const [lipseyMenuShow, setlipseyMenuShow] = useState(false);
	const [emailMenuShow, setEmailMenuShow] = useState(false);
	const [subProdShow, setSubProdShow] = useState(false);
	const [menuActive, setMenuActive] = useState({ dashboard: true, lipseys:false, product: false, user: false, faq: false, setting: false, roles: false, email: false, ffl: false });
	const [subMenuActive, setSubMenuActive] = useState({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false,zoom_meetings:false, report: false, shipping: false });
	const [subLipseyMenuActive, setSubLipseyMenuActive] = useState({ dashboard: false, products: false, markup: false, soldProducts:false, shipping: false});
	const [subEmailMenuActive, setSubEmailMenuActive] = useState({ newProduct: true, winner: false});
	const [subProdActive, setSubProdActive] = useState({ prod: false, webinar: false });

	useEffect(() => {
		async function loadCount () {
			loadPage(true);
			await getCountProductItems();
			await getProductItemsCount();
			await getDistributorsProductItemsCount();
			loadPage(false); 
		}
		loadCount();
	}, [])

	useEffect(() => {
		if (urlHistory.location.pathname.indexOf("/product/") > -1 || urlHistory.location.pathname.indexOf("/lipseys/") > -1 || urlHistory.location.pathname.indexOf("/email/") > -1) {
			if (urlHistory.location.pathname.indexOf("/product/") > -1) {
				setMenuActive({ dashboard: false, lipseys:false, product: true, user: false, faq: false, setting: false, roles: false, email: false });
				setSubMenuShow(true);
				setlipseyMenuShow(false);
				setEmailMenuShow(false);
				if (urlHistory.location.pathname.indexOf("/product/product") > -1) {
					setSubMenuActive({ categories: false, products: true, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false, report: false, shipping: false });
					setSubProdShow(true);
					if (urlHistory.location.pathname === router.productListing.path)
						setSubProdActive({ prod: true, webinar: false });
					if (urlHistory.location.pathname === router.webinarQueues.path)
						setSubProdActive({ prod: false, webinar: true });
				}
				else {
					setSubProdShow(false);
					if (urlHistory.location.pathname.indexOf("/category") > -1)
						setSubMenuActive({ categories: true, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false, report: false, shipping: false });
					if (urlHistory.location.pathname.indexOf("/sold-webinar") > -1)
						setSubMenuActive({ categories: false, products: false, sold_webinars: true, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false, report: false, shipping: false });
					if (urlHistory.location.pathname.indexOf("/ffl-db") > -1)
						setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: true, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false, report: false, shipping: false });
					if (urlHistory.location.pathname.indexOf("/completed-webinar") > -1)
						setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: true, sold_physical: false, offline_sales: false, zoom_meetings:false, report: false, shipping: false });
					if (urlHistory.location.pathname.indexOf("/sold-physical") > -1)
						setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: true, offline_sales: false, zoom_meetings:false, report: false, shipping: false });
					if (urlHistory.location.pathname.indexOf("/offline-sales") > -1)
						setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: true, zoom_meetings:false, report: false, shipping: false });
					if (urlHistory.location.pathname.indexOf("/zoom-meetings") > -1)
						setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:true, report: false, shipping: false });
					if (urlHistory.location.pathname.indexOf("/report") > -1)
						setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false, report: true, shipping: false });
					if (urlHistory.location.pathname.indexOf("/shipping") > -1)
						setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false, report: false, shipping: true });
				}
			}
			if (urlHistory.location.pathname.indexOf("/lipseys/") > -1) {
				setMenuActive({ dashboard: false, lipseys: true, product: false, user: false, faq: false, setting: false, roles: false, email: false });
				setSubMenuShow(false);
				setlipseyMenuShow(true);
				setEmailMenuShow(false);
				if (urlHistory.location.pathname.indexOf("/dashboard") > -1)
					setSubLipseyMenuActive({dashboard: true, products: false, markup: false, soldProducts: false, shipping: false});
				if (urlHistory.location.pathname.indexOf("/products") > -1)
					setSubLipseyMenuActive({dashboard: false, products: true, markup: false, soldProducts: false, shipping: false});
				if (urlHistory.location.pathname.indexOf("/markup") > -1)
					setSubLipseyMenuActive({dashboard: false, products: false, markup: true, soldProducts: false, shipping: false});
				if (urlHistory.location.pathname.indexOf("/sold-distributors-products") > -1)
					setSubLipseyMenuActive({dashboard: false, products: false, markup: false, soldProducts: true, shipping: false});
				if (urlHistory.location.pathname.indexOf("/shipping") > -1)
					setSubLipseyMenuActive({dashboard: false, products: false, markup: false, soldProducts: false, shipping: true});
			}
			if (urlHistory.location.pathname.indexOf("/email/") > -1) {
				setMenuActive({ dashboard: false, lipseys: false, product: false, user: false, faq: false, setting: false, roles: false, email: true});
				setSubMenuShow(false);
				setlipseyMenuShow(false);
				setEmailMenuShow(true);
				if (urlHistory.location.pathname.indexOf("/new-product") > -1)
					setSubEmailMenuActive({newProduct: true, winner: false});
				if (urlHistory.location.pathname.indexOf("/winner") > -1)
					setSubEmailMenuActive({newProduct: false, winner: true});
			}
		}
		else {
			setSubMenuShow(false);
			setlipseyMenuShow(false);
			setEmailMenuShow(false);
			switch (urlHistory.location.pathname) {
				case router.dashboard.path:
					setMenuActive({ dashboard: true, lipseys:false, product: false, user: false, faq: false, setting: false, roles: false, email: false });
					break;
				case router.userManagement.path:
					setMenuActive({ dashboard: false, lipseys:false, product: false, user: true, faq: false, setting: false, roles: false, email: false });
					break;
				case router.roles.path:
					setMenuActive({ dashboard: false, lipseys:false, product: false, user: false, faq: false, setting: false, roles: true, email: false });
					break;
				case router.faq.path:
					setMenuActive({ dashboard: false, lipseys:false, product: false, user: false, faq: true, setting: false, roles: false, email: false });
					break;
				case router.setting.path:
					setMenuActive({ dashboard: false, lipseys:false, product: false, user: false, faq: false, setting: true, roles: false, email: false });
					break;
				default:
					break;
			}
		}



		// if (urlHistory.location.pathname.indexOf("/faqs") > -1)
		//     setActiveItem({ product: false, myaccount: false, faqs: true });
		// else if (urlHistory.location.pathname.indexOf("/myaccount") > -1)
		//     setActiveItem({ product: false, myaccount: true, faqs: false });
		// else
		//     setActiveItem({ product: true, myaccount: false, faqs: false });
	}, [urlHistory.location.pathname]);

	const [isMobileSize, setIsMobileSize] = useState(false);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 768)
				setIsMobileSize(true);
			else
				setIsMobileSize(false);
		}
		handleResize();

		window.addEventListener('resize', handleResize);
	}, []);

	return (
		<Fragment>
			{
				!isMobileSize && !isShowSideBar && <div className="showSideWrapper" onClick={() => showSideBar(true)}>
					<MDBIcon icon="angle-double-right" className="showSideBarIcon" />
				</div>
			}
			{
				!isMobileSize && isShowSideBar && permissions && 
				<div className="side-bar">
					<div className="fixed-position">
						<div className="hideSideWrapper" onClick={() => showSideBar(false)}>
							<MDBIcon icon="angle-double-left" className="hideSideBarIcon" />
						</div>
						{ checkPermission(router.dashboard.path, permissions) && <div className={`${menuActive.dashboard ? 'active' : ''} parent-menu`} onClick={
							() => {
								setMenuActive({ dashboard: true, lipseys:false, product: false, user: false, faq: false, setting: false, roles: false, email: false });
								setSubMenuShow(false);
								setlipseyMenuShow(false);
								setEmailMenuShow(false);
								urlHistory.push(router.dashboard.path);
							}
						}>Dashboard</div>}
						<div className={`${menuActive.lipseys ? 'active' : ''} parent-menu with-sub-menu extra-counter`} onClick={
							() => {
								setlipseyMenuShow(!lipseyMenuShow);
								setSubMenuShow(false);
								setEmailMenuShow(false);
								setMenuActive({ dashboard: false, lipseys:true, product: false, user: false, faq: false, setting: false, roles: false, email: false });
							}
						}>
							<div className='extra-counter-link'>
								<p>Distributors Management</p>
								<button>{lipseyMenuShow ? <MDBIcon icon="minus" /> : <MDBIcon icon="plus" />}</button>
							</div>
							<div className='extra-counter-counter'><img className='bullet' width={25} src={Icon} alt="Bullet Icon" />   Pending: { soldDistributorsProducts || 0 }</div>
						</div>
						{
							lipseyMenuShow && <div className="sub-wrapper">
								{ <div className={`${subLipseyMenuActive.dashboard ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubLipseyMenuActive({dashboard: true, products: false, markup: false, soldProducts: false, shipping:false});
										urlHistory.push(router.lipseyDashboard.path)
									}
								}><i class="fas fa-chevron-right"></i>Dashboard</div>}

								{/* { <div className={`${subLipseyMenuActive.products ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubLipseyMenuActive({dashboard: false, products: true, markup: false});
										urlHistory.push(router.lipseyProducts.path)
									}
								}><i class="fas fa-chevron-right"></i>Lipseys Products</div>} */}

								{ <div className={`${subLipseyMenuActive.markup ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubLipseyMenuActive({dashboard: false, products: false, markup: true, soldProducts: false, shipping:false});
										urlHistory.push(router.lipseyMarkup.path);
									}
								}><i class="fas fa-chevron-right"></i>Markups</div>}
								{ <div className={`with-extra-icon ${subLipseyMenuActive.soldProducts ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubLipseyMenuActive({dashboard: false, products: false, markup: false, soldProducts: true, shipping:false});
										urlHistory.push(router.lipseySoldProducts.path);
									}
								}><span><i class="fas fa-chevron-right"></i>Sold Products</span>
								<span className='bullet-icon'><img className='bullet' width={25} src={Icon} alt="Bullet Icon" /> <div>{ soldDistributorsProducts || 0}</div></span>
								</div>}
								{ <div className={`${subLipseyMenuActive.shipping ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubLipseyMenuActive({dashboard: false, products: false, markup: false, soldProducts: false, shipping:true});
										urlHistory.push(router.lipseyShipping.path);
									}
								}><i class="fas fa-chevron-right"></i>Distributor products shipping</div>}								
							</div>
						}
						<div className={`${menuActive.product ? 'active' : ''} parent-menu with-sub-menu extra-counter`} 
						onClick={
							() => {
								setSubMenuShow(!subMenuShow);
								setlipseyMenuShow(false);
								setEmailMenuShow(false);
								setMenuActive({ dashboard: false, lipseys:false, product: true, user: false, faq: false, setting: false, roles: false, email: false });
							}
						}>
							<div className='extra-counter-link'>
								<p>Product Management</p>
								<button>{subMenuShow ? <MDBIcon icon="minus" /> : <MDBIcon icon="plus" />}</button>
							</div>
							<div className='extra-counter-counter'> <img className='bullet' width={25} src={Icon} alt="Bullet Icon" /> Pending: {(soldPhysical + soldOutWebinarList) || 0 }</div>
						</div>
						{
							subMenuShow && <div className="sub-wrapper">
								{ checkPermission(router.categories.path, permissions) && <div className={`${subMenuActive.categories ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubMenuActive({ categories: true, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false, report: false, shipping: false });
										setSubProdShow(false);
										urlHistory.push(router.categories.path)
									}
								}><i class="fas fa-chevron-right"></i>Categories</div>}

								{(permissions?.includes('productView') || permissions?.includes('webinarQueueView')) && <div className={`${subMenuActive.products ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubMenuActive({ categories: false, products: true, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false, report: false, shipping: false });
										setSubProdShow(!subProdShow);
									}
								}><i class="fas fa-chevron-right"></i>Products</div>}

								{
									subProdShow && <div>
										{ checkPermission(router.productListing.path, permissions) && <div className={`${subProdActive.prod ? 'active' : ''} sub-menu sub-prod`} onClick={
											() => {
												setSubProdActive({ prod: true, webinar: false });
												urlHistory.push(router.productListing.path);
											}
										}><i class="fas fa-genderless"></i>Product listing</div>}

										{ checkPermission(router.webinarQueues.path, permissions) && <div className={`${subProdActive.webinar ? 'active' : ''} sub-menu sub-prod`} onClick={
											() => {
												setSubProdActive({ prod: false, webinar: true });
												urlHistory.push(router.webinarQueues.path);
											}
										}><i class="fas fa-genderless"></i>Webinar queues</div>}
									</div>
								}

								{checkPermission(router.soldOutWebinars.path, permissions) && <div className={` with-extra-icon ${subMenuActive.sold_webinars ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubMenuActive({ categories: false, products: false, sold_webinars: true, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false, report: false, shipping: false });
										setSubProdShow(false);
										urlHistory.push(router.soldOutWebinars.path);
									}
								}><span><i class="fas fa-chevron-right"></i>Sold out webinars </span>
									<span className='bullet-icon'><img className='bullet' width={25} src={Icon} alt="Bullet Icon" /> <div>{soldOutWebinarList || 0}</div></span></div>}

								{checkPermission(router.fflDb.path, permissions) && <div className={`${subMenuActive.ffl_db ? 'active' : ''} sub-menu`} onClick={() => {
									setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: true, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false, report: false, shipping: false });
									setSubProdShow(false);
									urlHistory.push(router.fflDb.path);
								}
								}><i class="fas fa-chevron-right"></i>FFL Management</div>}

								{checkPermission(router.completedWebinars.path, permissions) && <div className={`${subMenuActive.completed_webinars ? 'active' : ''} sub-menu`} onClick={() => {
									setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: true, sold_physical: false, offline_sales: false, zoom_meetings:false, report: false, shipping: false });
									setSubProdShow(false);
									urlHistory.push(router.completedWebinars.path);
								}
								}><i class="fas fa-chevron-right"></i>Completed webinars</div>}

								{checkPermission(router.soldPhysicalProducts.path, permissions) && <div className={` with-extra-icon ${subMenuActive.sold_physical ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: true, offline_sales: false, zoom_meetings:false, report: false, shipping: false });
										setSubProdShow(false);
										urlHistory.push(router.soldPhysicalProducts.path);
									}
								}><span><i class="fas fa-chevron-right"></i>Sold physical products </span>
								<span className='bullet-icon'><img className='bullet' width={25} src={Icon} alt="Bullet Icon" /> <div>{soldPhysical || 0}</div></span>
								</div>}

								{<div className={`${subMenuActive.offline_sales ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: true, zoom_meetings:false, report: false, shipping: false });
										setSubProdShow(false);
										urlHistory.push(router.offlineSales.path);
									}
								}><i class="fas fa-chevron-right"></i>Offline sales</div>}

								{<div className={`${subMenuActive.zoom_meetings ? 'active' : ''} sub-menu`} onClick={() => {
									setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:true, report: false, shipping: false });
									setSubProdShow(false);
									urlHistory.push(router.zoomMeetings.path);
								}
								}><i class="fas fa-chevron-right"></i>Zoom Webinars</div>}
								<div className={`${subMenuActive.shipping ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false, report: false, shipping: true });
										setSubProdShow(false);
										urlHistory.push(router.shipping.path);
									}
								}><i class="fas fa-chevron-right"></i>Shipping</div>
								<div className={`${subMenuActive.report ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubMenuActive({ categories: false, products: false, sold_webinars: false, ffl_db: false, completed_webinars: false, sold_physical: false, offline_sales: false, zoom_meetings:false,report: true, shipping: false });
										setSubProdShow(false);
										urlHistory.push(router.report.path);
									}
								}><i class="fas fa-chevron-right"></i>Report</div>
							</div>
						}
						{checkPermission(router.userManagement.path, permissions) && <div className={`${menuActive.user ? 'active' : ''} parent-menu`} onClick={
							() => {
								setMenuActive({ dashboard: false, lipseys:false, product: false, user: true, faq: false, setting: false, roles: false, email: false });
								setSubMenuShow(false);
								setlipseyMenuShow(false);
								setEmailMenuShow(false);
								urlHistory.push(router.userManagement.path);
							}
						}>User Management</div>}
						{checkPermission(router.roles.path, permissions) && <div className={`${menuActive.roles ? 'active' : ''} parent-menu`} onClick={
							() => {
								setMenuActive({ dashboard: false, lipseys:false, product: false, user: false, faq: false, setting: false, roles: true, email: false });
								setSubMenuShow(false);
								setlipseyMenuShow(false);
								setEmailMenuShow(false);
								urlHistory.push(router.roles.path);
							}
						}>Roles of Admin</div>}
						{checkPermission(router.faq.path, permissions) && <div className={`${menuActive.faq ? 'active' : ''} parent-menu`} onClick={
							() => {
								setMenuActive({ dashboard: false, lipseys:false, product: false, user: false, faq: true, setting: false, roles: false, email: false });
								setSubMenuShow(false);
								setlipseyMenuShow(false);
								setEmailMenuShow(false);
								urlHistory.push(router.faq.path);
							}
						}>FAQ</div>}
						{checkPermission(router.setting.path, permissions) && <div className={`${menuActive.setting ? 'active' : ''} parent-menu`} onClick={
							() => {
								setMenuActive({ dashboard: false, lipseys:false, product: false, user: false, faq: false, setting: true, roles: false, email: false });
								setSubMenuShow(false);
								setlipseyMenuShow(false);
								setEmailMenuShow(false);
								urlHistory.push(router.setting.path);
							}
						}>Settings</div>}
						{checkPermission(router.setting.path, permissions) && <div className={`${menuActive.ffl ? 'active' : ''} parent-menu`} onClick={
							() => {
								setMenuActive({ dashboard: false, lipseys:false, product: false, user: false, faq: false, setting: false, roles: false, email: false, ffl: true, });
								setSubMenuShow(false);
								setlipseyMenuShow(false);
								setEmailMenuShow(false);
								urlHistory.push(router.ffl.path);
							}
						}>FFL Modifications</div>}
						<div className={`${menuActive.email ? 'active' : ''} parent-menu with-sub-menu`} onClick={
							() => {
								setEmailMenuShow(!emailMenuShow);
								setMenuActive({ dashboard: false, lipseys:false, product: false, user: false, faq: false, setting: false, roles: false, email: true });
							}
						}>
							<p>Email Settings</p>
							<button>{emailMenuShow ? <MDBIcon icon="minus" /> : <MDBIcon icon="plus" />}</button>
						</div>
						{
							emailMenuShow && <div className="sub-wrapper">
								{ <div className={`${subEmailMenuActive.newProduct ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubEmailMenuActive({newProduct: true, winner: false});
										urlHistory.push(router.emailNewProduct.path)
									}
								}><i class="fas fa-chevron-right"></i>Templates</div>}
								{/* { <div className={`${subEmailMenuActive.winner ? 'active' : ''} sub-menu`} onClick={
									() => {
										setSubEmailMenuActive({newProduct: true, winner: false});
										urlHistory.push(router.emailWinner.path);
									}
								}><i class="fas fa-chevron-right"></i>Winners</div>} */}
							</div>
						}
					</div>
				</div>
			}
		</Fragment>
	)
}

const MapStateToProps = ({ user: { isShowSideBar }, soldOutWebinarList:{product_items,rowsToCounter}, soldDistributorsProducts, soldPhysical}) => ({
	isShowSideBar,
	product_items,
	soldPhysical: soldPhysical.rowsToCounter,
	soldDistributorsProducts: soldDistributorsProducts.rowsToCounter,
	soldOutWebinarList: rowsToCounter
})

const MapDispatchToProps = dispatch => ({
	showSideBar: flag => dispatch(showSideBar(flag)),
	getCountProductItems: () => dispatch(getCountProductItems(dispatch)),
	getProductItemsCount: () => dispatch(getProductItemsCount(dispatch)),
	getDistributorsProductItemsCount: () => dispatch(getDistributorsProductItemsCount(dispatch)),

})

export default connect(MapStateToProps, MapDispatchToProps)(SideBar);