import React, {useState, useEffect, Fragment} from 'react';
import './settings.style.scss';
import FormButton from '../../components/form-button/form-button.component';
import FormInput from '../../components/form-input/form-input.component';
import Switch from 'react-switch';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import HeaderColorChange from './components/headerColor/headerColor.component';
import FooterColorChange from './components/footerColor/footerColor.component';
import LandingImageChange from './components/landingImage/landingImage.component';
import FontColorChange from './components/fontColor/fontColor.component';
import { connect } from 'react-redux';
import { 
    updateSiteSettings, 
    getSiteSettings, 
    addBackgroundImage, 
    getBackgroundImages, 
    deleteBackgroundImage, 
    editBackgroundImage,
    getBannerSettings ,
    setBannerSettings } from '../../redux/colorChnage/colorChange.action';
import { loadPage } from '../../redux/user/user.action';
import { useAlert } from 'react-alert';
import DatePicker from "react-datepicker";
import { Storage } from 'aws-amplify'; 
import { useDropzone } from 'react-dropzone';

import TimeZoneListComponent from './components/timeZone/timeZone.component';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';

const SettingsPage = ({
    updateSiteSettings, 
    getSiteSettings, 
    addBackgroundImage, 
    getBackgroundImages, 
    deleteBackgroundImage, 
    editBackgroundImage, 
    loadPage, 
    isShowSideBar, 
    getBannerSettings,
    setBannerSettings }) => {

    const alert = useAlert();

    const [switchSetting, setSwitchSetting] = useState({disableComment: false, hideComment: false, background: false});
    const [socialLinkUrl, setSocialLinkUrl] = useState({facebook: '', instagram: ''});
    const [contactEmail, setContactEmail] = useState('');
    const [contactText, setContactText] = useState('');
    const [temrmsAndConditions, setTermsAndConditions] = useState('');
    const [webinarPopUp, setWebinarPopUp] = useState('');
    const [tempIssue, setTempIssue] = useState({
        isShown: false,
        isInvalid: false,
        content: "We are sorry but there is a temporary issue on the website. We'll be back soon!"
    });
    const [videoLink, setVideoLink] = useState({
        link: '',
        isInvalid: false
    })

    const [isLoading, setIsLoading] = useState({
        fontColor: false,
        termsConditions: false,
        webiPopUp: false,
        videoLink: false
    });

    const [invalidInput, setInvalidInput] = useState({
        facebookLink: false,
        instagramLink: false,
        contactText: false,
        contactEmail: false
    });

    // font color picker
    const [menuFont, setMenuFont] = useState({
        show: false, 
        color: {
          r: 63,
          g: 114,
          b: 155,
          a: 1
        }
    });
    const [footerFont, setFooterFont] = useState({
        show: false, 
        color: {
          r: 158,
          g: 158,
          b: 158,
          a: 1
        }
    });
    const [header1Font, setHeader1Font] = useState({
        show: false, 
        color: {
          r: 255,
          g: 255,
          b: 255,
          a: 1
        }
    });
    const [header2Font, setHeader2Font] = useState({
        show: false, 
        color: {
          r: 255,
          g: 255,
          b: 255,
          a: 1
        }
    });
    const [tableHeaderFont, setTableHeaderFont] = useState({
        show: false, 
        color: {
          r: 163,
          g: 163,
          b: 163,
          a: 1
        }
    });
    const [tableContentFont, setTableContentFont] = useState({
        show: false, 
        color: {
          r: 255,
          g: 255,
          b: 255,
          a: 1
        }
    });
    const [formFont, setFormFont] = useState({
        show: false, 
        color: {
          r: 163,
          g: 163,
          b: 163,
          a: 1
        }
    });
    const [paragraphFont, setParagraphFont] = useState({
        show: false, 
        color: {
          r: 163,
          g: 163,
          b: 163,
          a: 1
        }
    });
    const [specialFont, setSpecialFont] = useState({
        show: false, 
        color: {
            r: 220,
            g: 47,
            b: 70,
            a: 1
        }
    }); 

    const startDatePickerLink = React.createRef();
    const [startDate, setStartDate] = useState();

    const endDatePickerLink = React.createRef();
    const [endDate, setEndDate] = useState();

    // for background image pick with drag and drop file

    const [backgroundImage, setBackgroundImage] = useState(null);

    const [backgroundImageList, setBackgroundImageList] = useState([]);

    const [selectedBackImg, setSelectedBackImg] = useState(null);
    const [activeIndex, setActiveIndex] = useState();
    const [timezone, setTimezone] = useState('');
    const [header_background_color, set_header_background_color] = useState('');
    const [footer_background_color, set_footer_background_color] = useState('');

    const {getRootProps, getInputProps} = useDropzone({
        accept: '.jpg, .jpeg, .png, .webp, .tiff, .tif, .gif, .svg',
        multiple : false,
        onDrop: acceptedFiles => {
                setActiveIndex(null);
                setSelectedBackImg(null);
                setStartDate(null);
                setEndDate(null);      
                setBackgroundImage(Object.assign(acceptedFiles[0], {
                        preview: URL.createObjectURL(acceptedFiles[0])
                    }));
                }
    });

    const backgoundImageUpload = async file => {
        const stored = await Storage.put("background-image-"+Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)+"-"+file.name, file, {
            contentType: file.type,
            cacheControl: 'max-age=31536000'
        });
        return stored.key;
    }

    const [isMobileSize, setIsMobileSize] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        
        setIsEdit(JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'settingsEdit'));

        function handleResize() {
            if (window.innerWidth < 767)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        async function loadColors() {
            loadPage(true);
            const settingsInfo = await getSiteSettings();
            console.log(settingsInfo);            
            if (settingsInfo) {
                settingsInfo.menu_color && setMenuFont({...menuFont, color: JSON.parse(settingsInfo.menu_color)});
                settingsInfo.footer_color && setFooterFont({...footerFont, color: JSON.parse(settingsInfo.footer_color)});
                settingsInfo.header1_color && setHeader1Font({...header1Font, color: JSON.parse(settingsInfo.header1_color)});
                settingsInfo.header2_color && setHeader2Font({...header2Font, color: JSON.parse(settingsInfo.header2_color)});
                settingsInfo.table_header_color && setTableHeaderFont({...tableHeaderFont, color: JSON.parse(settingsInfo.table_header_color)});
                settingsInfo.table_content_color && setTableContentFont({...tableContentFont, color: JSON.parse(settingsInfo.table_content_color)});
                settingsInfo.form_color && setFormFont({...formFont, color: JSON.parse(settingsInfo.form_color)});
                settingsInfo.paragraph_color && setParagraphFont({...paragraphFont, color: JSON.parse(settingsInfo.paragraph_color)});
                settingsInfo.special_color && setSpecialFont({...specialFont, color: JSON.parse(settingsInfo.special_color)});
                settingsInfo.post_video_link && setVideoLink({...videoLink, link: settingsInfo.post_video_link})
                setSocialLinkUrl({facebook: settingsInfo.facebook_media_links ? settingsInfo.facebook_media_links : '' , instagram: settingsInfo.instagram_media_links ? settingsInfo.instagram_media_links :'' });
                setContactEmail(settingsInfo.contact_us_email_address ? settingsInfo.contact_us_email_address : '');
                setContactText(settingsInfo.contact_us_page_info ? settingsInfo.contact_us_page_info : '');
                setSwitchSetting({disableComment: settingsInfo.commentsOption, hideComment: settingsInfo.hide_comments});
                setTermsAndConditions(settingsInfo.terms ? settingsInfo.terms : '');
                setWebinarPopUp(settingsInfo.webinarPopUpInfo ? settingsInfo.webinarPopUpInfo : '');
                setTimezone(settingsInfo.time_zone);
                set_header_background_color(settingsInfo.header_background_color);
                set_footer_background_color(settingsInfo.footer_background_color);
            }
            const backImgList = await getBackgroundImages();
            if ( backImgList && backImgList.length > 0) {
                console.log("BackImageList", backImgList);
                const galleryImgs = [];
                for (let i=0; i<backImgList.length; i++) {
                    galleryImgs.push({
                        id: backImgList[i].id,
                        origin_url: backImgList[i].image_url,
                        url: await Storage.get(backImgList[i].image_url),
                        start: backImgList[i].start_from && backImgList[i].start_from.toString(),
                        end: backImgList[i].end_to && backImgList[i].end_to.toString()
                    });
                }
                setBackgroundImageList([...galleryImgs]);
            }

            const bannerSetting = await getBannerSettings();
            bannerSetting?.banner_option && setTempIssue({
                ...tempIssue, 
                isShown: bannerSetting.banner_option, 
                content: bannerSetting?.banner_text ? bannerSetting?.banner_text : tempIssue.content})

            loadPage(false);     
            
        }
        
        loadColors();
        
    }, []);

    const addBackgroundImgFunc = async () => {

        if(!startDate || !endDate || (startDate.getTime() > endDate.getTime())) {
            alert.error("Invalid Date Range");
            return;
        }

        if(!backgroundImage) {
            alert.error("Please select the file");
            return;
        }

        startDate.setHours(0);
        startDate.setMinutes(0);
        endDate.setHours(23);
        endDate.setMinutes(59);
        
        const obj ={
            image_url: backgroundImage,
            start_from: startDate.toISOString(),
            end_to: endDate.toISOString()
        }
        loadPage(true);
        if (backgroundImage){
            const imgUrl = await backgoundImageUpload(backgroundImage);
            obj.image_url = imgUrl;
        }
        const result = await addBackgroundImage(obj);

        if (result === "success") {
            alert.success("Added successfully");
            const backImgList = await getBackgroundImages();
            console.log(backImgList);   
            if (backImgList.length > 0) {
                const galleryImgs = [];
                for (let i=0; i<backImgList.length; i++) {
                    galleryImgs.push({
                        id: backImgList[i].id,
                        url: await Storage.get(backImgList[i].image_url),
                        start: backImgList[i].start_from.toString(),
                        end: backImgList[i].end_to.toString()
                    });
                }
                setBackgroundImageList([...galleryImgs]);
            }
            else
                setBackgroundImageList([]);
        }
        else
            alert.error(result);
        loadPage(false);
    }

    const deleteBackImgFunc = async () => {

        loadPage(true);
        const result = await deleteBackgroundImage(selectedBackImg.id);
        if (result === "success") {
            alert.success("Removed successfully");
            const backImgList = await getBackgroundImages();
            if (backImgList.length > 0) {
                const galleryImgs = [];
                for (let i=0; i<backImgList.length; i++) {
                    galleryImgs.push({
                        id: backImgList[i].id,
                        url: await Storage.get(backImgList[i].image_url),
                        start: backImgList[i].start_from.toString(),
                        end: backImgList[i].end_to.toString()
                    });
                }
                setBackgroundImageList([...galleryImgs]);
            }
            else
                setBackgroundImageList([]);
            setSelectedBackImg(null);
        }
        else
            alert.error(result);
        loadPage(false);
    }

    const editBackImgFunc = async () => {

        startDate.setHours(0);
        startDate.setMinutes(0);
        endDate.setHours(23);
        endDate.setMinutes(59);

        if(!startDate || !endDate || (startDate.getTime() > endDate.getTime())) {
            alert.error("Invalid Date Range");
            return;
        }

        const obj = {
            start_from: startDate.toISOString(),
            end_to: endDate.toISOString()
        }

        console.log(obj);

        loadPage(true);
        const result = await editBackgroundImage(selectedBackImg.id, obj);
        if (result === "success") {
            alert.success("Edited successfully");
            const backImgList = await getBackgroundImages();
            if (backImgList.length > 0) {
                const galleryImgs = [];
                for (let i=0; i<backImgList.length; i++) {
                    galleryImgs.push({
                        id: backImgList[i].id,
                        url: await Storage.get(backImgList[i].image_url),
                        start: backImgList[i].start_from.toString(),
                        end: backImgList[i].end_to.toString()
                    });
                }
                setBackgroundImageList([...galleryImgs]);
            }
        }
        else
            alert.error(result);
        loadPage(false);
    }

    const validateEmail = (email) => {
        var emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return emailPattern.test(email); 
    }

    const changeFontColors = async () => {

        if (!menuFont.color || !footerFont.color || !header1Font.color || !header2Font.color || !tableHeaderFont.color || !tableContentFont.color || !formFont.color || !paragraphFont.color || !specialFont.color )
            return;

        if (isLoading.fontColor)
            return;
        
        const colors = {
            menu_color: JSON.stringify(menuFont.color),
            footer_color: JSON.stringify(footerFont.color),
            header1_color: JSON.stringify(header1Font.color),
            header2_color: JSON.stringify(header2Font.color),
            table_header_color: JSON.stringify(tableHeaderFont.color),
            table_content_color: JSON.stringify(tableContentFont.color),
            form_color: JSON.stringify(formFont.color),
            paragraph_color: JSON.stringify(paragraphFont.color),
            special_color: JSON.stringify(specialFont.color)
        }

        setIsLoading({...isLoading, fontColor: true});
        const result = await updateSiteSettings(colors);

        if (result === "success")
            alert.success("Changed successfully");
        else
            alert.error("Failed changing");

        setIsLoading({...isLoading, fontColor: false});

    }

    const changeSocialLinks = async () => {
        if (socialLinkUrl.facebook.trim() === ""){
            setInvalidInput({
                facebookLink: true,
                instagramLink: false,
                contactText: false,
                contactEmail: false
            });
            return;
        }
        if (socialLinkUrl.instagram.trim() === ""){
            setInvalidInput({
                facebookLink: false,
                instagramLink: true,
                contactText: false,
                contactEmail: false
            });
            return;
        }
        const socialLinks = {
            facebook_media_links: socialLinkUrl.facebook,
            instagram_media_links: socialLinkUrl.instagram
        }
        loadPage(true);
        const result = await updateSiteSettings(socialLinks);

        if (result === "success")
            alert.success("Changed successfully");
        else
            alert.error("Failed changing");

        setInvalidInput({
            facebookLink: false,
            instagramLink: false,
            contactText: false,
            contactEmail: false
        });
        loadPage(false);
    }

    const changeContactInfos = async () => {
        if ((contactEmail.trim() === "") || !validateEmail(contactEmail)){
            setInvalidInput({
                facebookLink: false,
                instagramLink: false,
                contactText: false,
                contactEmail: true
            });
            return;
        }
        if (contactText.trim() === ""){
            setInvalidInput({
                facebookLink: false,
                instagramLink: false,
                contactText: true,
                contactEmail: false
            });
            return;
        }
        const contactInfos = {
            contact_us_page_info: contactText,
            contact_us_email_address: contactEmail
        }
        loadPage(true);
        const result = await updateSiteSettings(contactInfos);

        if (result === "success")
            alert.success("Changed successfully");
        else
            alert.error("Failed changing");

        setInvalidInput({
            facebookLink: false,
            instagramLink: false,
            contactText: false,
            contactEmail: false
        });
        loadPage(false);
    }

    //  disable comments
    const disableCommentFunc = async () => {
  
        loadPage(true);
        const commentObj = {
            commentsOption: !switchSetting.disableComment
        }
        const result = await updateSiteSettings(commentObj);
        if (result === "success"){
            alert.success("Disabled comments successfully");
            setSwitchSetting({...switchSetting, disableComment: !switchSetting.disableComment}) 
        }            
        else
            alert.error("Failed disabling");
        loadPage(false); 
    };

    //  hide comments
    const hideCommentFunc = async () => {
        loadPage(true);
        const commentObj = {
            hide_comments: !switchSetting.hideComment
        }
        const result = await updateSiteSettings(commentObj);
        if (result === "success"){
            alert.success("Hide comments successfully");
            setSwitchSetting({...switchSetting, hideComment: !switchSetting.hideComment});
        }            
        else
            alert.error("Failed hiding");
        loadPage(false); 
        
    }

    //  switch temporary warning message
    const switchTempWarningFunc = async () => {
        loadPage(true);
        const setting = {
            banner_option: !tempIssue.isShown
        }
        const result = await setBannerSettings(setting);
        if (result === "success"){
            alert.success("Success");
            setTempIssue({...tempIssue, isShown: !tempIssue.isShown}); 
        }            
        else
            alert.error("Failed");            
        loadPage(false);           
    }

    const handleTempContent = (val) => {
        if (val.length > 150) {
            setTempIssue({...tempIssue, isInvalid: true});
            return;
        }
        setTempIssue({...tempIssue, isInvalid: false, content: val});
    }

    const handleVideoLink = (val) => {
        // if (val.trim() === '') {
        //     setVideoLink({...videoLink, isInvalid: true});
        //     return;
        // }
        setVideoLink({
            link: val,
            isInvalid: false
        })
    }

    const updateWebinarPopUp = async () => {
        if (isLoading.webiPopUp)
            return;
        
        const newWeb = {
            webinarPopUpInfo: webinarPopUp
        }

        setIsLoading({...isLoading, webiPopUp: true});
        const result = await updateSiteSettings(newWeb);

        if (result === "success")
            alert.success("Updated Webinar Pop Up successfully");
        else
            alert.error("Failed Updating");

        setIsLoading({...isLoading, webiPopUp: false}); 
    }
    const changeTempIssueFunc = async () => {
        if (tempIssue.isInvalid) {
            return;
        }

        loadPage(true);
        const setting = {
            banner_text: tempIssue.content
        }
        const result = await setBannerSettings(setting);
        if (result === "success"){
            alert.success("Success"); 
        }            
        else
            alert.error("Failed"); 
        loadPage(false);
    }

    const updateTermsAndConditions = async () => {
        if (isLoading.termsConditions)
            return;
        
        const newTerms = {
            terms: temrmsAndConditions
        }

        setIsLoading({...isLoading, termsConditions: true});
        const result = await updateSiteSettings(newTerms);

        if (result === "success")
            alert.success("Updated Terms and Conditions successfully");
        else
            alert.error("Failed Updating");

        setIsLoading({...isLoading, termsConditions: false}); 
    }
   

    const updateVideoLink = async () => {
        if (isLoading.videoLink || videoLink.isInvalid)
            return;
    
        if (videoLink.link.trim() !== '' && videoLink.link.substring(0,8) !== 'https://') {
            setVideoLink({...videoLink, isInvalid: true});
            return;
        }
        
        const link = {
            post_video_link: videoLink.link
        }

        setIsLoading({...isLoading, videoLink: true});
        const result = await updateSiteSettings(link);

        if (result === "success")
            alert.success("Updated Video Link successfully");
        else
            alert.error("Failed Updating");

        setIsLoading({...isLoading, videoLink: false}); 
    }

    return (
        <div className="settings-page" style={{width: isShowSideBar && !isMobileSize && 'calc(100% - 300px)'}}>
            <div className="general-setting mb-4">
                <h2 className="text-white font-weight-bold mb-4">General Settings</h2>
                <div className="disable-comment">
                    <div className="content-div mt-4">
                        <h4 className="text-white font-weight-bolder">Disable User Comments</h4>
                        <p className="mb-4 grey-text">The admin can turn off user responses to comments and only allow the admin to respond.</p>
                    </div>
                    <div className="switch-div mt-4">
                        <Switch disabled={!isEdit} onColor="#57bd7a" onChange={()=>disableCommentFunc()} checked={switchSetting.disableComment} />
                    </div>
                </div>
                <div className="disable-comment">
                    <div className="content-div mt-4">
                        <h4 className="text-white font-weight-bolder">Hide Comments Completely</h4>
                        <p className="mb-4 grey-text">The admin can turn off the comment system entirely site-wide.</p>
                    </div>
                    <div className="switch-div mt-4">
                        <Switch disabled={!isEdit} onColor="#57bd7a" onChange={()=>hideCommentFunc()} checked={switchSetting.hideComment} />
                    </div>
                </div>

                <TimeZoneListComponent isEdit={isEdit} time_zone={timezone} />                   

                <h4 className="text-white font-weight-bolder mt-4">Terms and Conditions Management</h4>
                <p className="grey-text mb-4">The admin can update the terms and conditions here by pasting or typing into a text box the terms and conditions.</p>
                <textarea className="mb-4" name="terms_conditions" rows="7" value={temrmsAndConditions} onChange={(event)=>setTermsAndConditions(event.target.value)} placeholder="Type here..."></textarea>
                {isEdit && <div className="btn-200">
                    <FormButton isLoading={isLoading.termsConditions} onClickFunc={()=>updateTermsAndConditions()}>UPDATE</FormButton>
                </div>}
                <hr className="mt-4"/>

                <h4 className="text-white font-weight-bolder mt-4">Webinar Pop Up</h4>
                <p className="grey-text mb-4">The admin can update the webinar pop up here by pasting or typing into a text box the new webinar alert.</p>
                <textarea className="mb-4" name="webinar_pop_up" rows="2" value={webinarPopUp} onChange={(event)=>setWebinarPopUp(event.target.value)} placeholder="Type here..."></textarea>
                {isEdit && <div className="btn-200">
                    <FormButton isLoading={isLoading.webiPopUp} onClickFunc={()=>updateWebinarPopUp()}>UPDATE</FormButton>
                </div>}

                <hr className="mt-4"/>
                <div className="flexDiv mb-2">
                    <div className="content-div mt-4">
                        <h4 className="text-white font-weight-bolder">Temporary Issue Banner</h4>
                        <p className="mb-4 grey-text">The admin can switch on and off and also edit the temporary warning message(letters should be less than 150).</p>
                    </div>
                    <div className="switch-div mt-4">
                        <Switch disabled={!isEdit} onColor="#57bd7a" onChange={()=>switchTempWarningFunc()} checked={tempIssue.isShown} />
                    </div>
                </div>
                <MDBRow>
                    <MDBCol size="12" sm="12" md="12" lg="9" xl="10" className="mb-4">
                        <FormInput type="text" name = "temp_warning" label="Temporary Warning" changeemail = {tempIssue.isInvalid} value = {tempIssue.content} handleChange = {(event) => handleTempContent(event.target.value)} />
                    </MDBCol>
                    {isEdit && <MDBCol size="6" sm="6" md="4" lg="3" xl="2">
                        <FormButton onClickFunc={()=>changeTempIssueFunc()}>SAVE</FormButton>
                    </MDBCol>}
                </MDBRow>
            </div>

            <div className="general-setting">
                <h2 className="text-white font-weight-bold mb-4">Advance Settings</h2>
                <hr/>
                <h4 className="text-white font-weight-bolder mt-4">Hyperlink to Video</h4>
                <p className="grey-text mb-4">The admin should be able to define hyperlink to the video which is streamed on the Consumer page.</p>
                <MDBRow>
                    <MDBCol size="12" sm="12" md="12" lg="9" xl="10" className="mb-4">
                        <FormInput type="text" name = "video_link" label="Hyperlink To Video" changeemail = {videoLink.isInvalid} value = {videoLink.link} handleChange = {(event) => handleVideoLink(event.target.value)} />
                    </MDBCol>
                    {isEdit && <MDBCol size="6" sm="6" md="4" lg="3" xl="2">
                        <FormButton isLoading={isLoading.videoLink} onClickFunc={()=>updateVideoLink()}>SAVE</FormButton>
                    </MDBCol>}
                </MDBRow>
                <hr/>
                <h4 className="text-white font-weight-bolder mt-4">Social Media Links</h4>
                <p className="grey-text mb-4">The admin should be able to set a Facebook and an Instagram link for the social media icons in the footer of the customer facing site.</p>
                <MDBRow>
                    <MDBCol size="12" sm="6" md="6" lg="5" className="mb-4">
                        <FormInput type="text" name = "facebook_link" label="Add Facebook Link" changeemail = {invalidInput.facebookLink} value = {socialLinkUrl.facebook} handleChange = {(event) => setSocialLinkUrl({...socialLinkUrl, facebook: event.target.value})} />
                    </MDBCol>
                    <MDBCol size="12" sm="6" md="6" lg="5" className="mb-4">
                        <FormInput type="text" name = "instagram_link" label="Add Instagram Link" changeemail = {invalidInput.instagramLink} value = {socialLinkUrl.instagram} handleChange = {(event) => setSocialLinkUrl({...socialLinkUrl, instagram: event.target.value})} />
                    </MDBCol>
                    {isEdit && <MDBCol size="12" sm="6" md="4" lg="2">
                        <FormButton onClickFunc={()=>changeSocialLinks()}>SAVE</FormButton>
                    </MDBCol>}
                </MDBRow>

                <hr className="mt-4"/>

                <h4 className="text-white font-weight-bolder mt-4">Contact Us Page</h4>
                <p className="grey-text mb-4">The admin can change the text on the contact us page, and specify what emails the contact form goes to.</p>
                <textarea className={`${invalidInput.contactText ? 'invalid' : ''} mb-4 no-back`} name="contact_us" placeholder="Type here..." value={contactText} onChange={(event)=>setContactText(event.target.value)} rows="5"></textarea>
                <MDBRow>
                    <MDBCol size="12" sm="6" md="10" lg="6">
                        <FormInput type="email" name = "contact_address" label="Email Address going to" changeemail = {invalidInput.contactEmail} value = {contactEmail} handleChange = {(event) => setContactEmail(event.target.value)} />
                    </MDBCol>
                </MDBRow>
                {isEdit && <MDBRow className="mt-4">
                    <MDBCol size="12" sm="6" md="4" lg="2">
                        <FormButton onClickFunc={()=>changeContactInfos()}>SAVE</FormButton>
                    </MDBCol>
                </MDBRow>}

                <hr className="mt-4"/>
                <h4 className="text-white font-weight-bolder mt-4">Change Website Background</h4>
                <p className="grey-text mb-4">The admin can change the background image with a temporary background and put a start and end date.</p>
                <div className="select-back-img-wrapper mb-4">
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()}/>
                        <div className="select-upload-img">
                            <div>
                                {
                                    backgroundImage ? 
                                        <Fragment>
                                            <label><MDBIcon far icon="check-circle" style={{color: '#99ffee'}}/></label>
                                            <label className="text-center">{backgroundImage.name}</label>
                                        </Fragment>
                                    :    
                                        <Fragment>
                                            <label>+</label>
                                            <label className="text-center">Select File</label>
                                        </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        backgroundImageList && backgroundImageList.length > 0 ? backgroundImageList.map(
                            (image,i) => <div className={`${activeIndex === i+1 ? "active" : ""} pre-back-img`} key={i} onClick={()=> {
                                setActiveIndex(i+1);
                                setSelectedBackImg({
                                        id: image.id,
                                        origin_url: image.origin_url,
                                        url: image.url,
                                        start: image.start,
                                        end: image.end
                                    });
                                setStartDate(new Date(image.start));
                                setEndDate(new Date(image.end));
                                }}>
                                    <img src={image.url} alt="background image"/>
                                </div>
                        )
                        :
                        null
                    }
                </div>
                <MDBRow>
                    <MDBCol size="8" sm="8" md="4" lg="2" className="background-date-wrapper ml-3">
                        <DatePicker
                            ref={startDatePickerLink} 
                            minDate={new Date()} 
                            selected={startDate} 
                            onChange={date => setStartDate(date)}
                            placeholderText="Start Date"
                            style={{backgroundColor: "transparent"}}
                        />
                        <MDBIcon icon="calendar-alt" className="datePickerIcon" onClick={()=>startDatePickerLink.current.setOpen(true)}/>  
                    </MDBCol>
                    <MDBCol size="8" sm="8" md="4" lg="2" className="background-date-wrapper ml-3">
                        <DatePicker
                            ref={endDatePickerLink} 
                            minDate={new Date()} 
                            selected={endDate} 
                            onChange={date => setEndDate(date)}
                            placeholderText="End Date"
                            style={{backgroundColor: "transparent"}}
                        />
                        <MDBIcon icon="calendar-alt" className="datePickerIcon" onClick={()=>endDatePickerLink.current.setOpen(true)}/>  
                    </MDBCol>
                    {
                        selectedBackImg ? <Fragment>
                            {isEdit && <MDBCol size="8" sm="6" md="4" lg="2">
                                <FormButton onClickFunc={()=>editBackImgFunc()}>EDIT</FormButton>
                            </MDBCol>}
                            {isEdit && <MDBCol size="8" sm="6" md="4" lg="2">
                                <FormButton onClickFunc={()=>deleteBackImgFunc()}>DELETE</FormButton>
                            </MDBCol>}
                        </Fragment>
                        :
                        <MDBCol size="8" sm="6" md="4" lg="2">
                            {isEdit && <FormButton onClickFunc={()=>addBackgroundImgFunc()}>ADD</FormButton>}
                        </MDBCol>
                    }
                    
                </MDBRow>

                <hr className="mt-4"/>
                <LandingImageChange isEdit={isEdit}/>

                <hr className="mt-4"/>
                <HeaderColorChange isEdit={isEdit} header_background_color={header_background_color} />

                <hr className="mt-4"/>
                <FooterColorChange isEdit={isEdit} footer_background_color={footer_background_color} />

                <hr className="mt-4"/>
                <h4 className="text-white font-weight-bolder mt-4">Change Font Color</h4>
                <p className="grey-text mb-4">The admin can change the font color of titles, paragraphs and other texts.</p>

                <div className="fontColorWrapper">
                    <FontColorChange purpose="Menu" colorPicker={menuFont} setColorPicker={setMenuFont}/>
                    <FontColorChange purpose="Footer" colorPicker={footerFont} setColorPicker={setFooterFont}/>
                    <FontColorChange purpose="Header1" colorPicker={header1Font} setColorPicker={setHeader1Font}/>
                    <FontColorChange purpose="Header2" colorPicker={header2Font} setColorPicker={setHeader2Font}/>
                    <FontColorChange purpose="Table Header" colorPicker={tableHeaderFont} setColorPicker={setTableHeaderFont}/>
                    <FontColorChange purpose="Table Content" colorPicker={tableContentFont} setColorPicker={setTableContentFont}/>
                    <FontColorChange purpose="Form" colorPicker={formFont} setColorPicker={setFormFont}/>
                    <FontColorChange purpose="Paragraph" colorPicker={paragraphFont} setColorPicker={setParagraphFont}/>
                    <FontColorChange purpose="Special" colorPicker={specialFont} setColorPicker={setSpecialFont}/>
                </div>
                {isEdit && <div className="fontColorChangeBtn mt-3">
                    <FormButton onClickFunc={()=>changeFontColors()} isLoading={isLoading.fontColor}>CHANGE COLOR</FormButton>
                </div>}
                
            </div>
        </div>
    )
}

const MapStateToProps = ({ user: {isShowSideBar}}) => ({
    isShowSideBar
})

const MapDispatchToProps = dispatch => ({
    updateSiteSettings: updateSiteSettings(dispatch),
    getSiteSettings: getSiteSettings(dispatch),
    addBackgroundImage: addBackgroundImage(dispatch),
    getBackgroundImages: getBackgroundImages(dispatch),
    deleteBackgroundImage: deleteBackgroundImage(dispatch),
    editBackgroundImage: editBackgroundImage(dispatch),
    loadPage: flag => dispatch(loadPage(flag)),
    getBannerSettings: getBannerSettings(dispatch),
    setBannerSettings: setBannerSettings(dispatch)
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(SettingsPage));