import React, {useState, useEffect, Fragment, useRef} from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import './sold-distributors-products.style.scss';
import { connect, useDispatch } from 'react-redux';
import CountPerPage from '../../components/table/count-per-page/count-per-page.component';
import TableFilterInput from '../../components/table/filter-input/filter-input.component';
import PageButtons from '../../components/table/pagination/pagination.component';
import SoldDistributorsProductsRow from './sold-distributors-products-row/sold-distributors-products-row.component';
import { getDistributorsProductItems, setLimitPerPage, setFilterString, setProductPageNum, addFFLItem } from '../../redux/sold-distributors-products/sold-distributors-products.action';
import { loadPage, setIsFromDuplicate } from '../../redux/user/user.action';
import { useAlert } from 'react-alert';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';
import LoaderComponent from '../../components/Loader/loader.component';
import FFLModal from '../../components/ffl-modal/ffl-modal.component';
import { getFFLLicencesFiltered } from '../../redux/ffl-db/ffl-db.action';

const SoldDistributorsProductsPage = ({
    filterStr, 
    limitPerPage, 
    pageNum,
    getDistributorsProductItems,
    setLimitPerPage,
    isShowSideBar,
    setFilterString,
    setProductPageNum,
    isFromDuplicate,
    setIsFromDuplicate,
    addFFLItem,
    getFFLLicencesFiltered
    }) => {

    const alert = useAlert();

    // for row counts per page
    const countSelect = [10,20,30,50];
    const [countSelectShow, setCountSelectShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    
    const [errorWithFFL, setErrorWithFFL] = useState(false);

    const isMounted = useRef(false);

    const setAllStatesToFalse = () => {
        setShowModal(false);
    }

    useEffect(() => {
        return () => {
            //setLimitPerPage(10);
            setFilterString("");
            setProductPageNum(1);
        }
    }, [])
    

    useEffect(() => {
        if(isMounted.current) {
            async function load() {
                setLoading(true);
                const result = await getDistributorsProductItems(
                    filterStr,   
                    limitPerPage === "All" ? "all" : limitPerPage, 
                    limitPerPage === "All" ? 0 : limitPerPage*(pageNum-1)
                    );
                setProducts(result);
                setLoading(false);
            }
             load();
        } else {
            isMounted.current = true;
        }
    }, [pageNum]);

    const load = async (filterStr, limitPerPage) => {
        setProductPageNum(1);
        setLoading(true);
        const result = await getDistributorsProductItems(
            filterStr, 
            limitPerPage === "All" ? "all" : limitPerPage, 
            0
            );
        setProducts(result);
        setLoading(false);
    }

    useEffect(() => {    
        !isFromDuplicate && load( filterStr, limitPerPage);
    }, [limitPerPage, filterStr]);

    const [isMobileSize, setIsMobileSize] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

      useEffect(() => {
        setIsFromDuplicate(false);
        function handleResize() {
            if (window.innerWidth < 850)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        setIsEdit(JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'soldOutPhysicalEdit'));
    }, []);

    const handleFFLChange = async ( modifiedFFL,isEdit,oneTimeChange,fflNotRequired) => {
        console.log("modifiedFFL",{modifiedFFL, itemToEdit});

        //setShowFFLModal(false)
        
        let purchase_id = itemToEdit?.id
        let ffl_id = modifiedFFL?.id
        let ffl_one_time

        if(isEdit){
            oneTimeChange?ffl_one_time=true:ffl_one_time=false
        } else {
            oneTimeChange=null
            fflNotRequired? ffl_id=null : ffl_id=modifiedFFL?.ffl_databases?.id
        }
        
        
        const obj = { 
            purchase_id,
            ffl_id,
            ffl_not_required: fflNotRequired,
            ffl_one_time:oneTimeChange,
            ffl_edited:isEdit,
            sendToShipping: false
        }

        console.log("🚀 ~ file: sold-physical-row.component.jsx:103 ~ load ~ modifiedFFL 0", obj);
        try {
            setLoading(true);
            const result = await addFFLItem(obj);
            setLoading(false);

            if (result === "success"){        
                setLoading(true);
                await load( filterStr, limitPerPage);
                setLoading(false);
                alert.success("FFL is assigned successfully",
                {
                    timeout: 2200,
                });
                setAllStatesToFalse()
            } else{
                alert.error(result || "Failed assigning");
                setAllStatesToFalse()
            }
        } catch (error) {
            alert.error( "Failed assigning");
        }
        setAllStatesToFalse()
    }

    return (
      <>
        {
            loading && (
                <LoaderComponent />
            )
        }

        {
            showModal && (
                <FFLModal setErrorWithFFL={setErrorWithFFL} errorWithFFL={errorWithFFL} changeFFL={handleFFLChange} alert={alert} getFFLLicencesFiltered={getFFLLicencesFiltered} closeModal={() => setShowModal(false)} loading={loading} setLoading={setLoading}/>
            )
        }

        <div className="ffl-db-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>         
            <MDBRow className="headerRow">
                <MDBCol size="12" sm="12" md="9" lg="10">
                    <h2 className="text-white font-weight-bold">Sold Physical Products</h2>
                </MDBCol>
            </MDBRow>
            <div className="section">
                <div className="toolbar">
                    <div className="leftDiv">
                        <div>
                            <CountPerPage 
                                options={countSelect} 
                                showSelectBox={()=>setCountSelectShow(!countSelectShow)} 
                                selectOption={(event)=>{
                                    setCountSelectShow(false);
                                    setLimitPerPage(event.target.id);
                                }} 
                                optionShow={countSelectShow} 
                                placeholder={limitPerPage}
                            />
                            <label className="ml-1">Per Page</label>
                        </div>
                        <TableFilterInput str={filterStr} setFilterString={setFilterString}/>
                    </div>
                    <PageButtons 
                        count={ limitPerPage === "All" ? 1 : Math.ceil(products?.count/limitPerPage) } 
                        currentIndex = {pageNum}
                        setProductPageNum = {setProductPageNum}
                    />  
                </div>
                <div className="total-count-div mb-4">
                    <span>{products?.count} results shown</span>
                </div>    
                {
                    isMobileSize ? 
                        <MDBRow className="table-header">
                            <MDBCol size="4" className="text-white text-center">
                                Thumbnail
                            </MDBCol>
                            <MDBCol size="6" className="text-white text-center">
                                Name
                            </MDBCol>
                        </MDBRow>
                    :        
                    <MDBRow className="table-header">
                        <MDBCol size="1" className="text-white">
                            Thumbnail
                        </MDBCol>
                        <MDBCol size={'1'} className="text-white text-center">
                            UPC
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Product
                        </MDBCol>
                        {/* <MDBCol size="1" className="text-white text-center">
                            Buyer's Name
                        </MDBCol> */}
                        {/* <MDBCol size="2" className="text-white text-center">
                            Buyer's Email
                        </MDBCol> */}
                        <MDBCol size="1" className="text-white text-center">
                            Sold Date
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Distributor OrderID
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Total Price
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Units
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Shipping
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Distributor OrderID
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            FFL Selected
                        </MDBCol>   
                        <MDBCol size="1" className="text-white text-center">
                            Edit FFL
                        </MDBCol>
                        <MDBCol size="1" className="text-white text-center">
                            Send to Shipping
                        </MDBCol>   
                        {/* {isEdit && <MDBCol size="1" className="text-white text-center">
                            Duplicate
                        </MDBCol> }           */}
                    </MDBRow>
                }
                {
                    products && products.rows && products.rows.length > 0 ? products.rows.map( item => 
                        <SoldDistributorsProductsRow 
                            key={item.id} 
                            item={item} 
                            ffl={products.ffl_data} 
                            isMobile={isMobileSize}
                            isEdit={isEdit}
                            setShowModal={setShowModal}
                            setLoading={setLoading}
                            setItemToEditFunc={setItemToEdit}
                            isLoading={loading}
                            loadParent={() => {
                                setLoading(true);
                                load( filterStr, limitPerPage);
                                setLoading(false);
                            }}
                        /> )
                        :
                        <div className="non-row text-center">
                            No Data
                        </div>
                }
            </div>
        </div>
      </>
    )
}

const MapStateToProps = ({ soldDistributorsProducts: { filterStr, product_items:distributor, limitPerPage, pageNum }, user: {isShowSideBar, isFromDuplicate}}) => ({
    filterStr,
    limitPerPage,
    pageNum,
    isShowSideBar,
    isFromDuplicate,
    distributor,
})

const MapDispatchToProps = dispatch => ({
    getDistributorsProductItems: getDistributorsProductItems(dispatch),
    loadPage: flag => dispatch(loadPage(flag)),
    setLimitPerPage: count => dispatch(setLimitPerPage(count)),
    setFilterString: str => dispatch(setFilterString(str)),
    setProductPageNum: num => dispatch(setProductPageNum(num)),
    setIsFromDuplicate: flag => dispatch(setIsFromDuplicate(flag)),
    getFFLLicencesFiltered: getFFLLicencesFiltered(dispatch),
    addFFLItem: addFFLItem(dispatch),
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(SoldDistributorsProductsPage));