import { UserActionTypes } from './user.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const sendForgotPassword = dispatch => async (email, isAdmin=false) => {
    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + "general/users/forgotpassword", { email: email, is_admin: isAdmin});        
        return result.data.message;        
    } catch (error) {
        return error.response.data;
    }
}

export const sendResetPassword = dispatch => async (userID, code, password) => {
    const obj = {
        id: userID,
        forgot_link: code,
        password: password
    }
    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + "general/users/resetpassword", obj);        
        return result.data.message;        
    } catch (error) {
        console.log(error.message);
    }
}

export const verfiyResetLink = dispatch => async (userID, code) => {
    const obj = {
        id: userID,
        forgot_link: code
    }
    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + "general/users/forgetlink/verify", obj);        
        return result.data.message;        
    } catch (error) {
        console.log(error.message);
    }
}

export const setCurrentUser = dispatch => async (user) => {

    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + "general/users", user);        
        return result.data.message;
        
    } catch (error) {
        console.log(error.message);
    }
}

export const setVerifiedUser = dispatch => async (user, isEmailVerified = false, isPhoneVerified = false ) => {
    try {
        const userData = await axios.post( RestAPI.ORIGINAL_ENDPOINT + "consumer/users/login", user);
        let userInfo = userData.data;
        if(!isEmailVerified && !isPhoneVerified)
            userInfo.is_verified = true;
        else{
            if(isEmailVerified && isPhoneVerified){
                userInfo.is_email_verified = true;
                userInfo.is_phone_verified = true;
                userInfo.is_verified = true;
            }                
            else{
                userInfo.is_email_verified = isEmailVerified;
                userInfo.is_phone_verified = isPhoneVerified;
                userInfo.is_verified = false;
            }
        }
        await axios.put( RestAPI.ORIGINAL_ENDPOINT + "consumer/users/profile", userInfo); // must add token

    } catch (error) {
        console.log(error.message);
    }
}

export const getCurrentUser = dispatch => async (payload) => {

    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + "consumer/users/login", null, { 
            headers: (await getMyTokenFunc())
        });        
        if (result.data.user_role === "admin") {            
            if(payload?.attributes['custom:permission'])
                dispatch(addCurrentUser({
                    userInfo: result.data,
                    userPermission: payload.attributes['custom:permission'].split(',')
                }));
            else
                dispatch(addCurrentUser({
                    userInfo: result.data,
                    userPermission: null
                }));
            return result.data;
        }               
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
    
}

// export const updateCurrentUser = dispatch => async user => {

//     try {
//         const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + "consumer/users/profile", user, { 
//             headers: (await getMyTokenFunc())
//         });
//         dispatch(addCurrentUser(result.data.message));

//     } catch (error) {
//         console.log(error?.response?.data?.message);    
//     }
// }


/* FUNCTIONS RELATED TO BACKEND SERVICES */
export const getBackendVersion = dispatch => async () => {
    try {
        const result = await axios.get(RestAPI.ORIGINAL_ENDPOINT + "general/backend-version");
        return result.data;
    } catch (error) {
        console.log(error.message);
    }
}
/* FUNCTIONS RELATED TO BACKEND SERVICES */

export const delCurrentUser = () => ({
    type: UserActionTypes.DEL_CURRENT_USER
})

export const loadPage = flag => ({
    type: UserActionTypes.LOAD_PAGE,
    payload: flag
})

export const showSideBar = flag => ({
    type: UserActionTypes.IS_SHOW_SIDEBAR,
    payload: flag
})

const addCurrentUser = user => ({
    type: UserActionTypes.ADD_CURRENT_USER,
    payload: user
});

export const setIsFromDuplicate = flag => ({
    type: UserActionTypes.SET_IS_FROM_DUPLICATE,
    payload: flag
})

