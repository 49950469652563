import FFLDBActionTypes from './ffl-db.types';

const INITIAL_STATE = {
    product_items: {
        rows: [],
        count: 1,
    },
    isAddNewClicked: false,
    filterString: "",
    limitPerPage: 10,
    pageNum: 1
}

const FFLDBListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FFLDBActionTypes.SET_PRODUCT_LIST_ITEMS:
            return {
                ...state, 
                product_items : action.payload, 
            }
        case FFLDBActionTypes.SET_FILTER_STRING:
            return {
                ...state, filterString: action.payload
            }
        case FFLDBActionTypes.SET_LIMIT_PER_PAGE:
            return {
                ...state, limitPerPage: action.payload
            }         
        case FFLDBActionTypes.SET_PRODUCT_LIST_PAGE_NUM:
            return {
                ...state, pageNum: action.payload
            }
        case FFLDBActionTypes.SET_ADD_NEW_CLICKED:
            return {
                ...state, isAddNewClicked: action.payload
            }
        default:
            return state;
    }
}

export default FFLDBListReducer;