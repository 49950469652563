import { ColorChangeActionTypes } from './colorChange.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getSiteSettings = dispatch => async () => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + "general/support/sitesettings");
        // dispatch(setCurrentFontColors(result.data.data));
        return result.data.data;
    } catch (error) {
        console.log(error.message);
    }
}

export const getColors = dispatch => async () => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + "general/support/sitesettings");
        return result.data.data;
    } catch (error) {
        console.log(error.message);
    }
}

export const getBannerSettings = dispatch => async () => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + "general/support/sitesettings/banner");
        return result.data.message;
    } catch (error) {
        console.log(error.message);
    }
}

export const setBannerSettings = dispatch => async (settings) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + "admin/support/banner_settings", settings, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
        
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const updateSiteSettings = dispatch => async (colors) => {
    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + "admin/support/sitesettings", colors, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
        
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const addBackgroundImage = dispatch => async (obj, type = 'background') => {

    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + `admin/${type}`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
        
    } catch (error) {
        // if (error?.response?.data) {
        //     if ((error.response.data.message === "Token Expired") || (error.response.data.message === "Unauthorized"))
        //         logOutFunc(store.dispatch)()
        //     else
        //         return error.response.data.message;
        // }
        return error?.response?.data?.message;            
    }
}

export const editBackgroundImage = dispatch => async (id, obj, type = 'background') => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/${type}/${id}`, obj, { 
            headers: (await getMyTokenFunc())
        });
        console.log(result.data);
        return result.data.message;
        
    } catch (error) {      
        return error.response.data.message;                     
    }
}

export const deleteBackgroundImage = dispatch => async (id, type = 'background') => {

    try {
        const result = await axios.delete( RestAPI.ORIGINAL_ENDPOINT + `admin/${type}`, { 
            data: {
                id: id
                },
            headers: (await getMyTokenFunc())
        });
        console.log(result.data);
        return result.data.message;
        
    } catch (error) {
        return error.response.data.message;                      
    }
}

export const getBackgroundImages = dispatch => async (type = 'background') => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/${type}`, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.data;
        
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

const setCurrentFontColors = colors => ({
    type: ColorChangeActionTypes.SET_CURRENT_FONT_COLORS,
    payload: colors
});