import React, { useState, useEffect, Fragment, useRef } from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import './user-management.style.scss';
import { connect, useDispatch } from 'react-redux';
import CountPerPage from '../../components/table/count-per-page/count-per-page.component';
import FormButton from '../../components/form-button/form-button.component';
import FormInput from '../../components/form-input/form-input.component';
import TableFilterInput from '../../components/table/filter-input/filter-input.component';
import PageButtons from '../../components/table/pagination/pagination.component';
import { getProductItems, setLimitPerPage, setFilterString, setProductPageNum, createNewUser, setFFLFilter } from '../../redux/user-management/user-management.action';
import { getCurrentRoles } from '../../redux/admin-roles/admin-roles.action';
import { loadPage } from '../../redux/user/user.action';
import { useAlert } from 'react-alert';
import UserItemRow from './item-row/item-row.component';
import { Select } from 'antd';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';
import LoaderComponent from '../../components/Loader/loader.component';
import FFLModal from '../../components/ffl-modal/ffl-modal.component';
import { getFFLLicencesFiltered } from '../../redux/ffl-db/ffl-db.action';
import { updateUserFFLPermanent } from '../../redux/completed-webinar/completed-webinar.action';

const { Option } = Select;

const UserManagementPage = ({
	filterStr,
	limitPerPage,
	pageNum,
	getProductItems,
	setLimitPerPage,
	isShowSideBar,
	setFilterString,
	setProductPageNum,
	getCurrentRoles,
	currentRoles,
	createNewUser,
	getFFLLicencesFiltered,
	updateUserFFLPermanent,
	setFFLFilter,
	fflFilter,
}) => {

	const alert = useAlert();

	// for row counts per page
	const countSelect = [10, 20, 30, 50];
	const [countSelectShow, setCountSelectShow] = useState(false);

	// for creating new admin
	const [isAddNewClicked, setIsAddNewClicked] = useState(false);
	const [isLoadingBtn, setIsLoadingBtn] = useState(false);
	const [newAdminInfo, setNewAdminInfo] = useState({
		username: '',
		email: '',
		password: '',
		role: null
	})

	const [isInvalid, setIsInvalid] = useState({
		name: false,
		email: false,
		password: false
	})

	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [isMobileSize, setIsMobileSize] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [isCreateAdmin, setIsCreateAdmin] = useState(false);
	const [errorWithFFL, setErrorWithFFL] = useState(false);
	const isMounted = useRef(false);
	
	useEffect(() => {
		return () => {
			/* setLimitPerPage(10); */
			setProductPageNum(1);
			setFilterString("");
		}
}, [])

	const validateEmail = (email) => {
		var emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
		return emailPattern.test(email);
	}

	const validatePassword = (pass) => {
		var passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?"!@#%&/,><':;|_~`^\]\[\)\(]).{8,}/;
		return passPattern.test(pass);
	}

	useEffect(() => {
		if(isMounted.current) {
			async function load() {
				setLoading(true);
				const result = await getProductItems(
					filterStr,
					limitPerPage === "All" ? "all" : limitPerPage,
					limitPerPage === "All" ? 0 : limitPerPage * (pageNum - 1),
					fflFilter
				);
				setProducts(result);
				setLoading(false);
			}
			load();
		} else {
			isMounted.current = true;
		}
	}, [pageNum]);

	const loadData =async (filterStr, limitPerPage, pageNum, fflFilter) => {
		setLoading(true);
		const result = await getProductItems(
			filterStr,
			limitPerPage === "All" ? "all" : limitPerPage,
			limitPerPage === "All" ? 0 : limitPerPage * (pageNum - 1),
			fflFilter
		);
		setProducts(result);
		setLoading(false);
	}

	useEffect(() => {
		setProductPageNum(1);
		async function load() {
			setLoading(true);
			const result = await getProductItems(
				filterStr,
				limitPerPage === "All" ? "all" : limitPerPage,
				0,
				fflFilter
			);
			setProducts(result);
			setLoading(false);
		}
		load();
	}, [limitPerPage, filterStr]);

	useEffect(() => {
		getCurrentRoles();
		function handleResize() {
			if (window.innerWidth < 900)
				setIsMobileSize(true);
			else
				setIsMobileSize(false);
		}
		handleResize();
		window.addEventListener('resize', handleResize);
		const permissions = JSON.parse(localStorage.getItem('userPermissions'));
		setIsEdit(permissions?.some((item) => item === 'usersEdit'));
    // setIsDeletePermission(permissions?.some((item) => item === 'usersDelete'));
    setIsCreateAdmin(permissions?.some((item) => item === 'userCreate'));
	}, []);

	const addFunction = async () => {
		if (isLoadingBtn)
			return

		if (newAdminInfo.username.trim() === '') {
			setIsInvalid({
				name: true,
				email: false,
				password: false
			})
			return;
		}
		if (newAdminInfo.email.trim() === '' || !validateEmail(newAdminInfo.email)) {
			setIsInvalid({
				email: true,
				name: false,
				password: false
			})
			return;
		}
		if (!validatePassword(newAdminInfo.password)) {
			setIsInvalid({
				name: false,
				email: false,
				password: true
			})
			alert.error('Minimum 8 characters. Numeric characters(0-9). Special characters. Uppercase characters. Lowercase characters.');
			return;
		}
		if (!newAdminInfo.role) {
			setIsInvalid({
				name: false,
				email: false,
				password: false
			})
			alert.error("You have to select the role");
			return;
		}
		setIsLoadingBtn(true);
		const result = await createNewUser(newAdminInfo);
		if (result === 'success') {
			alert.success("Added successfully");
			setIsLoadingBtn(false);
			await getProductItems(
				"",
				limitPerPage === "All" ? "all" : limitPerPage,
				0,
				fflFilter
			);
			setNewAdminInfo({
				username: '',
				email: '',
				password: '',
				role: null
			});
			setIsAddNewClicked(false);
		}
	}
 
	const handleFFLChange = async (modifiedFFL, isEdit, oneTimeChange) => {
		setLoading(true);
		const user = selectedUser;
		const obj = {
			ffl_id: modifiedFFL?.id,
			user_id: user?.id,
		}
		const result = await updateUserFFLPermanent(obj);
		if (result?.message === "success"){
			setLoading(true);
			const result = await getProductItems(
				filterStr,
				limitPerPage === "All" ? "all" : limitPerPage,
				limitPerPage === "All" ? 0 : limitPerPage * (pageNum - 1),
				fflFilter
			);
			setProducts(result);
			setLoading(false);
			setShowModal(false);
			setErrorWithFFL(false);
			alert.success("FFL is assigned successfully",{
				delay: 1000,
				containerStyle:{
					zIndex: 9999000
				}
			});
		} else{
				alert.error("Failed assigning",{
					delay: 1000,
					containerStyle:{
						zIndex: 9999000
					}
				});
				setErrorWithFFL(true);
		}
		setLoading(false);
}

	return (
		<>
		{
			loading && (
				<LoaderComponent />
			)
		}

		{
				showModal && (
						<FFLModal setErrorWithFFL={setErrorWithFFL} errorWithFFL={errorWithFFL} onlyPermanent={true} setLoading={setLoading} loading={loading} selectedUser={selectedUser} changeFFL={handleFFLChange} alert={alert} getFFLLicencesFiltered={getFFLLicencesFiltered} closeModal={() => setShowModal(false)} />
				)
		}

<div className="ffl-db-page" style={{ width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)' }}>
			{
				isAddNewClicked ? <div className="add-new">
					<MDBIcon className="closeIcon" icon="times" onClick={()=>{setIsAddNewClicked(false)}}/>
					<h2 className="text-white text-center font-weight-bold mb-4">Create New Admin</h2>
					<MDBRow center className="add-section">
						<MDBCol className="mb-4" size="10" md="6">
							<FormInput
								type="text"
								label='Name*'
								value={newAdminInfo.username}
								handleChange={(e) => setNewAdminInfo({
									...newAdminInfo,
									username: e.target.value
								})}
								changeemail={isInvalid.name}
								required />
						</MDBCol>
						<MDBCol className="mb-4" size="10" md="6">
							<FormInput
								type="email"
								label='Email*'
								value={newAdminInfo.email}
								handleChange={(e) => setNewAdminInfo({
									...newAdminInfo,
									email: e.target.value
								})}
								changeemail={isInvalid.email}
								required />
						</MDBCol>
						<MDBCol className="mb-4" size="10" md="6">
							<FormInput
								type="password"
								label='Password*'
								value={newAdminInfo.password}
								handleChange={(e) => setNewAdminInfo({
									...newAdminInfo,
									password: e.target.value
								})}
								changeemail={isInvalid.password}
								required />
						</MDBCol>
						<MDBCol className="mb-4" size="10" md="6">
							<Select
								className="antdCustomSingleSel"
								bordered={false}								
								placeholder="Select role"
								onChange={(id) => setNewAdminInfo({
									...newAdminInfo,
									role: id
								})}
								showArrow
							>
								{currentRoles?.length > 0 &&
									currentRoles.map((item) => (
										<Option key={item.id} value={item.id}>
											{item.name}
										</Option>
									))}
							</Select>
						</MDBCol>
						<MDBCol className="mb-4" size="8" md="6" lg="3">
							<FormButton onClickFunc={() => addFunction()} isLoading={isLoadingBtn}>SAVE NOW</FormButton>
						</MDBCol>
					</MDBRow>
				</div>
					:
					<>
						<MDBRow className="headerRow">
							<MDBCol size="12" sm="12" md="8" lg="7">
								<h2 className="text-white font-weight-bold">User Management</h2>
							</MDBCol>
							<MDBCol size="8" lg="3">
								{isCreateAdmin && <FormButton onClickFunc={() => setIsAddNewClicked(true)}>CREATE ADMIN</FormButton>}
							</MDBCol>
							<MDBCol size="4" lg="2">
								<h5 className="text-white text-right">{products?.count} Users</h5>
							</MDBCol>
						</MDBRow>
						<div className="section">
							<div className="toolbar">
								<div className="leftDiv">
									<div>
										<CountPerPage
											options={countSelect}
											showSelectBox={() => setCountSelectShow(!countSelectShow)}
											selectOption={(event) => {
												setCountSelectShow(false);
												setLimitPerPage(event.target.id);
											}}
											optionShow={countSelectShow}
											placeholder={limitPerPage}
										/>
										<label className="ml-1">Per Page</label>
									</div>
									<TableFilterInput str={filterStr} setFilterString={setFilterString} placeholder="Search by column" />
									<div className='check-ffl'>
											<label>Filter by FFL</label>
											<input checked={fflFilter === 'ffl' ? true : false} type="checkbox" onChange={() => {
													setFFLFilter(fflFilter === '' ? 'ffl' : '');
											}} />
									</div>
								</div>
								<PageButtons
									count={limitPerPage === "All" ? 1 : Math.ceil(products?.count / limitPerPage)}
									currentIndex={pageNum}
									setProductPageNum={setProductPageNum}
								/>
							</div>
							{
								isMobileSize ?
									<MDBRow className="table-header">
										<MDBCol size="4" className="text-white text-center">
											Name
										</MDBCol>
										<MDBCol size="6" className="text-white text-center">
											Email
										</MDBCol>
									</MDBRow>
									:
									<MDBRow className="table-header">
										<MDBCol size={'1'} className="text-white">
											Name
										</MDBCol>
										<MDBCol size={'2'} className="text-white text-center">
											Address
										</MDBCol>
										<MDBCol size="1" className="text-white text-center">
											Phone
										</MDBCol>
										<MDBCol size="2" className="text-white text-center">
											Email
										</MDBCol>
										<MDBCol size="1" className="text-white text-center">
											Change FFL
										</MDBCol>
										<MDBCol size="1" className="text-white text-center">
											Selected FFL
										</MDBCol>
										<MDBCol size="1" className="text-white text-center">
											Remove FFL
										</MDBCol>
										{/* <MDBCol size="2" className="text-white text-center">
											FFL
										</MDBCol> */}
										<MDBCol size="1" className="text-white text-center">
											Role
										</MDBCol>
										{isEdit && <MDBCol size="1" className="text-white text-center">
											Edit
										</MDBCol>}
										{isEdit && <MDBCol size="1" className="text-white text-center">
											Disable/ Enable
										</MDBCol>}
										{/* <MDBCol size="1" className="text-white text-center">
																Delete
														</MDBCol> */}
									</MDBRow>
							}
							{
								!loading && products && products.rows && products.rows.length > 0 ? products.rows.map(item => 
									<UserItemRow 
										key={item.id}
										item={item} 
										isEdit={isEdit} 
										ffl={products.ffl_data} 
										isMobile={isMobileSize} 
										loading={loading}
										setLoading={setLoading}
										loadData={() => loadData( filterStr, limitPerPage, pageNum, fflFilter)}
										showModal={(item) => {
											setShowModal(true)
											setSelectedUser(item)
										}}
									/>)
									: loading ? 
									<div className="non-row text-center">
										Loding...
									</div>
									:
									<div className="non-row text-center">
										No Data
								</div>
							}
						</div>
					</>
			}
		</div>
		</>
	)
}

const MapStateToProps = ({ user_management: { filterStr, product_items, limitPerPage, pageNum, fflFilter }, user: { isShowSideBar }, roles: { currentRoles } }) => ({
	filterStr,
	product_items,
	limitPerPage,
	pageNum,
	isShowSideBar,
	currentRoles,
	fflFilter,
})

const MapDispatchToProps = dispatch => ({
	getProductItems: getProductItems(dispatch),
	loadPage: flag => dispatch(loadPage(flag)),
	setLimitPerPage: count => dispatch(setLimitPerPage(count)),
	setProductPageNum: num => dispatch(setProductPageNum(num)),
	setFilterString: str => dispatch(setFilterString(str)),
	getCurrentRoles: getCurrentRoles(dispatch),
	createNewUser: createNewUser(dispatch),
	getFFLLicencesFiltered: getFFLLicencesFiltered(dispatch),
	updateUserFFLPermanent: updateUserFFLPermanent(dispatch),
	setFFLFilter: str => dispatch(setFFLFilter(str)),
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(UserManagementPage));