import React, { useState, useEffect } from 'react';
import './report-page.style.scss';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import FormButton from '../../components/form-button/form-button.component';
import DatePicker from "react-datepicker"; 
import ReactExport from "react-export-excel";
import { useAlert } from 'react-alert';
import { connect } from 'react-redux';
import { getReportItems } from '../../redux/report/report.action';
import { getLipseysProducts } from '../../redux/product-list/product-list.action';
import moment from 'moment-timezone';
// import withPermissionChecking from '../../utils/HOC/withPermissionCheck';

const ReportPage = ({ report_items, getReportItems,getLipseysProducts, isShowSideBar}) => {

    const alert = useAlert();
    
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const [reportItemData, setReportItemData]  = useState([]);

    const [isExportExcel, setIsExportExcel] = useState(false);

    const startDatePickerLink = React.createRef();
    const [startDate, setStartDate] = useState();

    const endDatePickerLink = React.createRef();
    const [endDate, setEndDate] = useState();

    useEffect(() => {
        setIsExportExcel(false);
    }, [startDate, endDate]);

    const [isBtnLoading, setIsBtnLoading] = useState(false);

    useEffect(() => {
        if (reportItemData.length > 0)
            setIsExportExcel(true);
    }, [reportItemData]);

    const getDateForList = (dateStr) => {
        const date = new Date(dateStr);
        return `${date.getMonth()<9 ? "0"+(date.getMonth()+1) : date.getMonth()+1}/${date.getDate()<10 ? "0"+date.getDate() : date.getDate()}/${date.getFullYear()}`;
    }
    
    const exportExcelDataFunc = async () => {
        
        if (isBtnLoading)
            return;
        if (!startDate || !endDate){
            alert.error("No date range");
            return;
        }
        else if (startDate.getTime() > endDate.getTime()) {
            alert.error("Wrong date range");
            return;
        }
        else if (isExportExcel)
        {
            alert.error("Already exported. Select another date range");
            return;
        }
        else {
            setIsBtnLoading(true);
            
            let startDateStr = moment(startDate).format('YYYY-MM-DD 00:00:00');
            let endDateStr = moment(endDate).format('YYYY-MM-DD 23:59:59');
            
            
            const result = await getReportItems(startDateStr, endDateStr);
            setIsBtnLoading(false);  
            if ((result.physical_results.length < 1) && (result.webinar_results.length < 1)){
                alert.error("No sold out products");
                return;
            }
            else {
                if (!result) {
                    alert.error("Failed loading sold out products");
                    return;
                }
                else {
                    const tempArray = [];                    
                    result.webinar_results.map( item => {
                        item.sold_date = getDateForList(item.sold_date);
                        tempArray.push(item); 
                    });
                    result.physical_results.map( item => {
                        item.sold_date = getDateForList(item.sold_date);
                        tempArray.push(item); 
                    });
                    console.log("REPORT::::")
                    console.log(tempArray)
                    setReportItemData(tempArray);
                }
            }
        }        
    }

    const setFFLName = (item) => {
        if(item.ffl_assignment_completed) {        
            if(item.ffl_not_required) {
                return "FFL NOT REQUIRED"
            } else {
                if(item.ffl_database && item.ffl_database?.id){
                    if(item.ffl_database.businessName) return item.ffl_database.businessName;
                    else return item.ffl_database.licenseName;
                } else{
                    return "FFL MISSING";
                }
            }
        } else {
            return "FFL CONFIRMATION PENDING";
        }
    }

    const setFFLAddress = (item) => {
        if(item.ffl_assignment_completed) {        
            if(item.ffl_not_required) {
                return item.user_address
            } else {

                if(item.ffl_database && item.ffl_database?.id){
                    if(item.ffl_database.premiseState) return `${item.ffl_database.premiseStreet}, ${item.ffl_database.premiseCity}, ${item.ffl_database.premiseState}, ${item.ffl_database.premiseZipCode}, USA`;
                    else return `${item.ffl_database.premiseStreet}, ${item.ffl_database.premiseCity}, ${item.ffl_database.premiseZipCode}, USA`;
                } else{
                    return "MISSING ADDRESS";
                }
            }
        } else {
            return "FFL CONFIRMATION PENDING";
        }
    }    

    return (
        <div className="report-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
            <div className="report-wrapper">
                <h1 className="text-white text-center font-weight-bold">Report Page</h1>
                <h5 className="text-center">Select date range</h5>
                <MDBRow center className="mb-4">
                    <MDBCol size="8" sm="5" md="5" lg="5" className="background-date-wrapper ml-2 mr-2">
                        <DatePicker
                            ref={startDatePickerLink} 
                            selected={startDate} 
                            onChange={date => setStartDate(date)}
                            placeholderText="Start Date"
                            style={{backgroundColor: "transparent"}}
                        />
                        <MDBIcon icon="calendar-alt" className="datePickerIcon" onClick={()=>startDatePickerLink.current.setOpen(true)}/>  
                    </MDBCol>
                    <MDBCol size="8" sm="5" md="5" lg="5" className="background-date-wrapper ml-2 mr-2">
                        <DatePicker
                            ref={endDatePickerLink} 
                            selected={endDate} 
                            onChange={date => setEndDate(date)}
                            placeholderText="End Date"
                            style={{backgroundColor: "transparent"}}
                        />
                        <MDBIcon icon="calendar-alt" className="datePickerIcon" onClick={()=>endDatePickerLink.current.setOpen(true)}/>  
                    </MDBCol>
                </MDBRow>
                <MDBRow center>
                    <MDBCol size="6">
                        <FormButton isLoading={isBtnLoading} onClickFunc={()=>exportExcelDataFunc()}>EXPORT</FormButton>
                    </MDBCol>
                </MDBRow>
                {
                    isExportExcel && <ExcelFile hideElement={true} filename="Report">
                        <ExcelSheet data={reportItemData} name="Report">
                            <ExcelColumn label="Item #" value="item_no"/>
                            <ExcelColumn label="Item Type" value="column_name"/>
                            <ExcelColumn label="Item Name" value="item_name"/>
                            <ExcelColumn label="Buyer Name" value="buyer_name"/>
                            <ExcelColumn label="Phone" value="buyer_phone_number"/>
                            <ExcelColumn label="Bought For" value="bought_for"/>
                            <ExcelColumn label="Sale Price + Tax" value="sale_price_and_tax"/>
                            <ExcelColumn label="Tax" value="tax"/>
                            <ExcelColumn label="Sold For" value="sold_for"/>
                            <ExcelColumn label="Profit" value="profit"/>
                            <ExcelColumn label="Address" 
                                        value={(address) => setFFLAddress(address)}/>                     
                            <ExcelColumn label="FFL"
                                        value={(col) => setFFLName(col)}/>
                            <ExcelColumn label="Date" value="sold_date"/>
                            <ExcelColumn label="Book Number" value="book_number"/>      
                        </ExcelSheet>
                    </ExcelFile>
                }                
            </div>            
        </div>
    )
}

const MapStateToProps = ({ report_reducer: { report_items }, user: {isShowSideBar}}) => ({
    report_items,
    isShowSideBar
})

const MapDispatchToProps = dispatch => ({
    getReportItems: getReportItems(dispatch),
    getLipseysProducts: getLipseysProducts(dispatch)
})

export default connect(MapStateToProps, MapDispatchToProps)(ReportPage);