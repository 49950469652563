import OfflineSalesActionTypes from './offline-sales.types';

const INITIAL_STATE = {
    product_items_count:1,
    product_items: {
        count: 1,
        ffl_data: [],
        rows: []    
    },
    filterStr: "",
    limitPerPage: 10,
    fflFilter: "",
    pageNum: 1
}

const OfflineSalesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OfflineSalesActionTypes.SET_PRODUCT_LIST_ITEMS:
            return {
                ...state, 
                product_items : action.payload, 
            }
        case OfflineSalesActionTypes.SET_FILTER_STRING:
            return {
                ...state, filterStr: action.payload
            }
        case OfflineSalesActionTypes.SET_LIMIT_PER_PAGE:
            return {
                ...state, limitPerPage: action.payload
            }         
        case OfflineSalesActionTypes.SET_PRODUCT_LIST_PAGE_NUM:
            return {
                ...state, pageNum: action.payload
            }
        case OfflineSalesActionTypes.SET_PRODUCT_LIST_ITEMS_COUNT:

                return {
                    ...state, product_items_count: action.payload
                }
        case OfflineSalesActionTypes.SET_FFL_FILTER:
            return {
                ...state, fflFilter: action.payload
            }

        default:
            return state;
    }
}

export default OfflineSalesReducer;
