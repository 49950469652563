import { AdminRolesActionTypes } from './admin-roles.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';

export const getPermissions = dispatch => async () => {
    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/roles-permissions`, { 
            headers: (await getMyTokenFunc())
        });
        const list = result?.data;
        list.sort((a, b) => (a.title > b.title) ? 1 : -1)
        dispatch(setPermissions(list));
    } catch (error) {
        console.log(error?.response?.data);
    }
}

export const getCurrentRoles = dispatch => async (filterString="") => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/roles?filterString=${filterString}`, { 
            headers: (await getMyTokenFunc())
        });
        dispatch(setCurrentRoles(result.data.data));

    } catch (error) {
        console.log(error?.response?.data?.message);
    }
}

export const addRole = dispatch => async (body) => {

    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + "admin/roles", body, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.data;

    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const updateRole = dispatch => async (id, body) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + "admin/roles/" + id, body, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
       
    } catch (error) {
        console.log(error?.response?.data?.message);
        return error?.response?.data?.message;
    }
}

const setPermissions = list => ({
    type: AdminRolesActionTypes.SET_PERMISSIONS,
    payload: list
});

const setCurrentRoles = list => ({
    type: AdminRolesActionTypes.SET_CURRENT_ROLES,
    payload: list
});


export const setFilterStr = filter => ({
    type: AdminRolesActionTypes.SET_FILTER_STRING,
    payload: filter
})