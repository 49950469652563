import React, {useState, useEffect} from 'react';
import './user-edit.style.scss';
import { withRouter } from 'react-router-dom';
import { MDBRow, MDBCol} from 'mdbreact';
import FormInput from '../../../components/form-input/form-input.component';
import FormButton from '../../../components/form-button/form-button.component';
import { connect } from 'react-redux';
import { editUserInfo } from '../../../redux/user-management/user-management.action';
import Input from 'react-phone-number-input/input';
import Autocomplete from 'react-google-autocomplete';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Alert, Select } from 'antd';
import router from '../../../router';

const { Option } = Select;

const UserEditPage = withRouter(({location, editUserInfo, isShowSideBar, currentRoles}) => {

    const historyURL = useHistory();
    const alert = useAlert();

    const [editFaqInfo, setEditFaqInfo] = useState({
        id: "",
        firstname: "",
        lastname: "",
        email: "",
        address: "",
        street_address: '', 
        city: '', 
        state: '', 
        zipcode: '',
        role: null

    });
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);

    const [phone, setPhone] = useState(null);
    const [isValidPhone, setIsValidPhone] = useState(false);

    // for phone validataion
    const validatePhoneNumber = (num) => {
        var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        return phoneNumberPattern.test(num);
    }

    const [isEmailValid, setIsEmailValid] = useState(false);
    // for email validation
    const validateEmail = (email) => {
        var emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return emailPattern.test(email); 
    }

    useEffect(() => {
        if (location?.state?.item) {
            setEditFaqInfo({
                id: location.state.item.id,
                firstname: location.state.item.first_name ? location.state.item.first_name : "",
                lastname: location.state.item.last_name ? location.state.item.last_name : "",
                email: location.state.item.email ? location.state.item.email : "",
                address: location.state.item.address ? location.state.item.address : "",
                street_address: location.state.item.street_address ? location.state.item.street_address : "",
                city: location.state.item.city ? location.state.item.city : "",
                state: location.state.item.state ? location.state.item.state : "",
                zipcode: location.state.item.zipcode ? location.state.item.zipcode : "",
                role: location.state.item.role ? location.state.item.role.id : null
            });
            setPhone(location.state.item.phone_number);
        }
    }, []);

    const editUserInfoFunc = async (event) => {
        if (isLoadingBtn)
            return;        
        event.preventDefault();

        setIsValidPhone(false);
        setIsEmailValid(false);

        if(!validatePhoneNumber(phone.slice(2))) {
            setIsValidPhone(true);
            return;
        }

        if(!validateEmail(editFaqInfo.email)) {
            setIsEmailValid(true);
            return;
        }

        setIsLoadingBtn(true);
        const obj = {
            id: editFaqInfo.id,
            first_name: editFaqInfo.firstname,
            last_name: editFaqInfo.lastname,
            username: editFaqInfo.firstname + " " + editFaqInfo.lastname,
            phone_number: phone,
            email: editFaqInfo.email.toLowerCase(),
            address: editFaqInfo.street_address + ', ' + editFaqInfo.city + ', ' + editFaqInfo.state + " " + editFaqInfo.zipcode + ", USA",
            street_address: editFaqInfo.street_address,
            city: editFaqInfo.city,
            state: editFaqInfo.state,
            zipcode: editFaqInfo.zipcode,
            role_id: editFaqInfo.role
        }

        const result = await editUserInfo(obj);
        setIsLoadingBtn(false);

        if (result === "success") {
            alert.success("Edited successfully");
            historyURL.push(router.userManagement.path);
        }
        else
            alert.error(result);
       
    }

    const cancelEditFunc = () => {
        if (isLoadingBtn)
            return;
        historyURL.push(router.userManagement.path);
    }
    const alertFunc = () => {
        console.log("🚀 ~ file: user-edit.component.jsx ~ line 126 ~ alertFunc ~ alertFunc")
        setEditFaqInfo({
            ...editFaqInfo, 
            role: null
        });
        console.log("🚀 ~ file: user-edit.component.jsx ~ line 129 ~ alertFunc ~ editFaqInfo", editFaqInfo)
        
              
       // Alert('ellla')
    }
  
    const getAddressFunc = place => {   
        const newArray = place.formatted_address.split(", ");
        let tempStreet = "";
        let tempCity = "";
        let tempState = "";
        let tempZip = "";
        if (newArray.length === 4) {
            tempStreet =  newArray[newArray.length - 4];
            tempCity = newArray[newArray.length - 3];
            tempState = newArray[newArray.length - 2].split(" ")[0];
            tempZip = newArray[newArray.length - 2].split(" ")[1] ? newArray[newArray.length - 2].split(" ")[1] : "";
           
        }
        else {
            tempStreet =  newArray[0];

        }
        setEditFaqInfo({
            ...editFaqInfo, 
            street_address: tempStreet,
            city: tempCity,
            state: tempState,
            zipcode: tempZip
        });
    }
    
    return (
        <div className="user-edit-component" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
            <div className="edit-section">
                <h2 className="text-white text-center font-weight-bold">Edit User Information</h2>
                <form onSubmit={editUserInfoFunc}>
                    <MDBRow className="no-margin-form-input mb-4">
                        <MDBCol size="12" sm="6" md="6" lg="6" className="mt-4">
                            <FormInput type="text" label = 'First Name' value = {editFaqInfo.firstname} handleChange = {(event) => setEditFaqInfo({...editFaqInfo, firstname: event.target.value})} required/>
                        </MDBCol>
                        <MDBCol size="12" sm="6" md="6" lg="6" className="mt-4">
                            <FormInput type="text" label = 'Last Name' value = {editFaqInfo.lastname} handleChange = {(event) => setEditFaqInfo({...editFaqInfo, lastname: event.target.value})} required/>
                        </MDBCol>
                    </MDBRow>                  
                    <div className='phone-group'>
                        <Input
                            className={`${isValidPhone ? 'red-outline' : ''} phone-input`}
                            country="US"
                            value={phone}
                            onChange={setPhone}
                            autocomplete="tel"
                            required
                        />
                        <label className={`${phone ? 'shrink' : ''} ${isValidPhone ? 'red-label' : ''} phone-input-label`}>Phone Number</label>
                    </div>
                    <FormInput type="text" label = 'Email' value = {editFaqInfo.email} handleChange = {(event) => setEditFaqInfo({...editFaqInfo, email: event.target.value})} changeemail={isEmailValid} required/>
                    <div className="phone-group">
                        <Autocomplete
                            className="phone-input"
                            placeholder=""
                            value = {editFaqInfo.street_address}
                            onChange = {(e)=> setEditFaqInfo({...editFaqInfo, street_address: e.target.value})}
                            onPlaceSelected={ place => getAddressFunc(place)}
                            types={['address']}
                            componentRestrictions={{country: "us"}}
                            required
                        />
                        <label className = {`${editFaqInfo.street_address ? 'shrink' : ''} phone-input-label`}>Street Address*</label>
                    </div>
                    <FormInput type="text" name='city' label='City*' value={editFaqInfo.city} handleChange={(event) => setEditFaqInfo({ ...editFaqInfo, city: event.target.value })} required />
                    <MDBRow className="no-margin-form-input">
                        <MDBCol size="12" sm="12" md="6" className="mb-4">
                            <FormInput type="text" name='state' label='State*' value={editFaqInfo.state} handleChange={(event) => setEditFaqInfo({ ...editFaqInfo, state: event.target.value })} required />
                        </MDBCol>
                        <MDBCol size="12" sm="12" md="6" className="mb-4">
                            <FormInput type="text" name='zip' label='Zipcode*' value={editFaqInfo.zipcode} handleChange={(event) => setEditFaqInfo({ ...editFaqInfo, zipcode: event.target.value })} required />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="no-margin-form-input">
                        <MDBCol size="12" sm="12" md="10" className="mb-4">
                            <Select
                                className="antdCustomSingleSel"
                                bordered={false}								
                                placeholder="Select role"
                                value={editFaqInfo.role}
                                onChange={(id) => setEditFaqInfo({
                                    ...editFaqInfo,
                                    role: id
                                })}
                                showArrow
                            >
                                {currentRoles?.length > 0 &&
                                    currentRoles.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                ))}
                            </Select>
                        </MDBCol>
                        <MDBCol size="4" sm="4" md="3" lg="2">
                            <FormButton type="button" onClickFunc={()=>alertFunc()}>X</FormButton>
                        </MDBCol>
                    </MDBRow>                    
                    <MDBRow center>
                        <MDBCol size="6" sm="6" md="5" lg="4">
                            <FormButton type="submit" isLoading = {isLoadingBtn}>SAVE NOW</FormButton>
                        </MDBCol>
                        <MDBCol size="6" sm="6" md="5" lg="4">
                            <FormButton greyCol={true} onClickFunc={()=>cancelEditFunc()}>CANCEL</FormButton>
                        </MDBCol>
                    </MDBRow>                                     
                </form>
            </div>
        </div>
    )
})

const MapStateToProps = ({ user: { isShowSideBar }, roles: { currentRoles }}) => ({
    isShowSideBar,
    currentRoles
})

const MapDispatchToProps = dispatch => ({
    editUserInfo: editUserInfo(dispatch)
})

export default connect(MapStateToProps, MapDispatchToProps)(UserEditPage);