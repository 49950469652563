import LiveStreamActionTypes from './live-stream.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getProductItems = dispatch => async (webinarID, limit, offset) => {

    try {
        dispatch(clearProductItems());
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/webinar/${webinarID}/users?limit=${limit}&offset=${offset}`, { 
            headers: (await getMyTokenFunc())
        });
        dispatch(setProductItems(result.data.users));
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const selectWinner = dispatch => async (obj) => {
    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + `admin/products/winners`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
        
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const setFilterString = filterStr => ({
    type: LiveStreamActionTypes.SET_FILTER_STRING,
    payload: filterStr
})

export const setLimitPerPage = limit => ({
    type: LiveStreamActionTypes.SET_LIMIT_PER_PAGE,
    payload: limit
})

export const setProductPageNum = num => ({
    type: LiveStreamActionTypes.SET_PRODUCT_LIST_PAGE_NUM,
    payload: num
})

export const setWinnerId = id => ({
    type: LiveStreamActionTypes.SET_WINNER_ID,
    payload: id
})

export const clearWinnerID = () => ({
    type: LiveStreamActionTypes.CLEAR_WINNER_ID
})

const setProductItems = items => ({
    type: LiveStreamActionTypes.SET_PRODUCT_LIST_ITEMS,
    payload: items
})

const clearProductItems = () => ({
    type: LiveStreamActionTypes.CLEAR_PRODUCT_LIST_ITEMS,
})

export const getHotSeatWinner = dispatch => async (webinarID, limit, offset) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/webinar/${webinarID}/users?limit=${limit}&offset=${offset}`, {
            headers: (await getMyTokenFunc())
        });
        return(result.data.hotSeatUserId);
    } catch (error) {
        console.log(error?.response?.data?.message);
    }
}


export const getHotSeatEmail = dispatch => async (webinarID) => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/webinar/${webinarID}/users/email`, {
            headers: (await getMyTokenFunc())
        });
        return(result.response);
        // Object.entries(result).forEach(entry => {
        //     const [key, value] = entry;
        //     console.log('email eshte ', key, value);
        //   });
    } catch (error) {
        console.log(error?.response?.data?.message);
    }
}