import React, { useState, useEffect, Fragment, useRef, useMemo } from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import FormButton from '../../../components/form-button/form-button.component';
import PopUp from '../../../components/pop-up/popup';

import { Storage } from 'aws-amplify';
import { useAlert } from 'react-alert';
import './item-row.style.scss';
import moment from 'moment-timezone';
import Select from 'react-select'
import { connect } from 'react-redux';
import { getEmailList, changeWebinarLink, getAllUsers} from '../../../redux/completed-webinar/completed-webinar.action';
import { loadPage } from '../../../redux/user/user.action';
import { useHistory } from 'react-router-dom';
import router from '../../../router';
import { getProductItemsCount } from '../../../redux/sold-out-webinars/sold-out-webinars.action';
import Tooltip from '../../../components/tooltip/tooltip';
import clickOutside from '../../../hooks/clickOutside';
import LongItemWithTooltip from '../../../components/tooltip/long-item-with-tooltip';
import { isValidLoaExpirationDate } from '../../../utils/fflUtils';

const getFflBgColor = fflItem => {
	const expDate = new Date(fflItem.expiration_date);
	const now = new Date(Date.now());
	const isExpired = expDate < now;
	return isExpired ? red : white;
}
const red = '#912c2c'
const white = 'white'

const CompletedWebinarItemRow = ({handleFFLChange, handleFFLNotRequired, isEdit, item, isMobile, ffl, loadPage, getEmailList, getAllUsers, changeWebinarLink, getItemsAgain,getProductItemsCount, showModal, currentRow, setErrorWithFFL }) => {

	const alert = useAlert();
	const urlHistory = useHistory();
	const [showConfirmationModal, setShowConfirmationModal] = useState(false)
	const [fflName, setFFLName] = useState('')
	const [FFLNotRequired, setFFLNotRequired] = useState(false)
	const [showTooltip, setShowTooltip] = useState(false);
	const [imageUrl, setImageUrl] = useState(null);

	const hasLoaExpirationDate = useMemo(() => isValidLoaExpirationDate(item.ffl_databases), [item.ffl_databases])
	const expiredDate = useMemo(() => hasLoaExpirationDate? item.ffl_databases?.loaExpirationDate : item.ffl_databases?.expiration, [hasLoaExpirationDate, item.ffl_databases])
	const isExpired = useMemo(() => Date.parse(expiredDate) < Date.parse(new Date()), [expiredDate])

	const dropDownCustomStyles = {
		option: (styles, { data }) => ({
			...styles,
			backgroundColor: data.bgColor,
			color: data.bgColor === red ? white : undefined,
			borderBottom: '1px solid #c0c0c0',
		}),
	}

	const setAllStatesToFalse = () => {
		setShowConfirmationModal(false)
	}

	useEffect(() => {
		// if (ffl && (ffl.length > 0)) {

		// 	let tempArray = [];

		// 	item?.user_data?.prefered_ffl_id && tempArray.push({
		// 		value: item.user_data.prefered_ffl_id,
		// 		label: <p style={{ margin: '0', color: 'blue', fontWeight: '700' }}>{ffl.find(el => el.id === item.user_data.prefered_ffl_id)?.ffl_name}</p>,
		// 		expDate: ffl.find(el => el.id === item.user_data.prefered_ffl_id)?.expiration_date
		// 	});

		// 	tempArray.push({
		// 		value: "non-ffl",
		// 		label: "FFL not required"
		// 	});

		// 	if (item?.user_data?.prefered_ffl_id)
		// 		ffl.map(el => {
		// 			el.id !== item.user_data.prefered_ffl_id && tempArray.push({
		// 				value: el.id,
		// 				label: el.ffl_name,
		// 				bgColor: getFflBgColor(el),
		// 				expDate: el.expiration_date
		// 			});
		// 		});
		// 	else
		// 		ffl.map(el => {
		// 			tempArray.push({
		// 				value: el.id,
		// 				label: el.ffl_name,
		// 				bgColor: getFflBgColor(el),
		// 				expDate: el.expiration_date
		// 			});
		// 		});
		// 	setFflList([...tempArray]);
		// }


		// if (item && item.ffl_database && item.ffl_database.id) {
		// 	setSelectedFflItem({
		// 		value: item.ffl_database.id,
		// 		label: item.ffl_database.ffl_name
		// 	})
		// }

		// if (item && !item.ffl_not_required) {
		// 	setSelectedFflScopeItem({
		// 		value: item.ffl_one_time? item.ffl_id: item.ffl_scope_tables?.id,
		// 		ffl_not_required:false,
		// 		// label: item.ffl_scope_tables.businessName,
		// 		// fflNumber: item.ffl_scope_tables.fflNumber,
		// 		// businessName: item.ffl_scope_tables.businessName,
		// 		// expiration: item.ffl_scope_tables.expiration,
		// 		completed: item.ffl_assignment_completed
		// 	})
		// }

		// if (item && (!item.ffl_database || !item.ffl_database.id) && item.ffl_not_required) {
		// 	setSelectedFflItem({
		// 		value: "non-ffl",
		// 		label: "FFL not required"
		// 	})
		// }

		// if (item && item.ffl_not_required) {
		// 	setSelectedFflScopeItem({
		// 		value: "non-ffl-scope",
		// 		ffl_not_required:true,
		// 		// label: "FFL NOT REQUIRED",
		// 		// fflNumber: null,
		// 		// businessName: null,
		// 		// expiration: null
		// 		completed: item.ffl_assignment_completed
		// 	})
		// }		

		async function load() {
		

			checkImage( await Storage.get("thumbnail-"+(item.webinar.thumbnail_300.image_url)),  async function(){ setImageUrl( await Storage.get("thumbnail-"+(item.webinar.thumbnail_300.image_url))) }, async function(){ 
				item?.webinar?.thumbnail_300?.image_url
             ?
              setImageUrl(await Storage.get(item.webinar.thumbnail_300.image_url))
             :
                
                setImageUrl( await Storage.get(item.webinar.main_image.image_url));} )

		}
		load();

	}, [item]);

	
    async function checkImage(imageSrc, good, bad) {
        var img = new Image();
        img.onload = good; 
        img.onerror = bad;
        img.src = imageSrc;
    }

	const [isShowWonList, setShowWonList] = useState(false);

	const getCustomDate = () => {
		const customDate = new Date(item.createdAt);
		Date.shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		return Date.shortMonths[customDate.getMonth()] + " " + customDate.getDate() + ", " + customDate.getFullYear();
	}

	const [isOpenTable, setIsOpenTable] = useState(false);

	// for extracting emails of all users
	const [isExtractDlgShow, setIsExtractDlgShow] = useState(false);
	const [extractEmailList, setExtractEmailList] = useState(null);

	const ref = useRef();
	clickOutside(() => {
		setShowTooltip(false);
	}, ref);

	const extractEmailsFunc = async () => {
		setIsExtractDlgShow(true);
		const result = await getEmailList(item?.webinar_id);
		if (result) {
			setExtractEmailList(result.message);
		}
	}

	const [isLoading, setIsLoading] = useState(false);

	const copyFFL = async () => {
		var copyText = item.ffl_databases.fflNumber;
		navigator.clipboard.writeText(copyText);
	}

	const setTooltipByColor = (item) => {
		let gray = {
			textColor: "",
			color: "Gray",
			text: "No change",
			backgroundColor: "gray"
		}
		let white = {
			textColor: "white",
			color: "White",
			text: "Permanent Change",
			backgroundColor: "white"
		}
		let blue = {
			textColor: "#46A9C7",
			color: "Light Blue",
			text: "One-time Change",
			backgroundColor: "blue"
		}
		if(item.ffl_one_time!=null){
			if(item.ffl_one_time==1){
				return blue;
			} else{
				return white;
			}
		}  else{
			return gray;
		}
	}

	return (
		<Fragment>
            {
                showConfirmationModal && (
                    <div className="modal-profile-form">
                    <div className="content">
                      <div className="removeDialogWrapper">
                        <div className="removeDialog">
                          <div>
                          <div className="close-icon">
                          <i onClick={() => setShowConfirmationModal(false)} class="far fa-times-circle"></i>
                        </div>
							{FFLNotRequired?
								<>							
									<h2 className="text-white text-center ">
									This item is not requiring an FFL
									</h2>
									<p></p>
								</>
								:
								<>							
									<h2 className="text-white text-center ">
									FFL selected for this item: {fflName}
									</h2>
									<p></p>
								</>
							}
                            <h2 className="text-white text-center ">
                              Are you sure you want to proceed?
                            </h2>
                            <p></p>
              
                            <MDBRow center className="mt-4">
                                <MDBCol size="8" sm="6" md="5" lg="6">
                                    <FormButton
										isLoading={isLoading}
										onClickFunc={()=>{
											setIsLoading(true)
											handleFFLChange(item,false,null,FFLNotRequired)
											setShowConfirmationModal(false)
											
										}
									}>
                                    <h5>Yes</h5>
                                    </FormButton>
                                </MDBCol>
                                <MDBCol size="8" sm="6" md="5" lg="6">
                                <FormButton 
                                    greyCol={true}
                                    onClickFunc={()=>{
                                        setIsLoading(false);
										setShowConfirmationModal(false)
                                    }}> 
                                    <h5>No</h5>
                                </FormButton>
                                </MDBCol>
                            </MDBRow>
                          </div>
                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>                    
                )
            }			
			{
				isExtractDlgShow && <div className="removeDialogWrapper">
					<div className="removeDialog">
						<MDBIcon className="float-right text-white closeIcon" icon="times" onClick={() => setIsExtractDlgShow(false)} />
						<br />
						<h3 className="text-white text-center font-weight-bold mb-4">Emails of users</h3>
						<p className="text-white text-center mb-4 emailList">{extractEmailList ? extractEmailList : 'Loading...'}</p>
					</div>
				</div>
			}
			{
			isMobile ? 
				<Fragment>
					<MDBRow className="table-row" style={item.ffl_assignment_completed==1? { backgroundColor: '#53893eb3', borderBottom: '1px solid grey' }:{}}>
						<MDBCol size="5" middle className="text-center">
							{
								imageUrl ? <img src={imageUrl} alt={item.name} />
									:
									<div className="non-img">
										<MDBIcon far icon="file-image" />
									</div>
							}
						</MDBCol>
						<MDBCol size="5" middle className="text-center">
							<LongItemWithTooltip text={item && item.webinar && item.webinar.name && item.webinar.name} />
						</MDBCol>
						<MDBCol size="2" middle className="text-center">
							<button className="openBtn" onClick={() => {
								setIsOpenTable(true);
							}}><MDBIcon icon="plus" /></button>						
						</MDBCol>
					</MDBRow>
					{
						isOpenTable && 
						<Fragment>
							<MDBRow className="table-row mobile-opend-table">
								<MDBCol size="5" middle className="text-center text-white">Winner Name</MDBCol>
								<MDBCol size="5" middle className="text-center">
									{item && item.user_data && item.user_data.username && item.user_data.username}
								</MDBCol>
								<MDBCol size="2" middle className="text-center">
									<button className="openBtn" onClick={() => {
										setIsOpenTable(false);
										setShowWonList(false);
									}}><MDBIcon icon="minus" /></button>
								</MDBCol>
							</MDBRow>				
							<MDBRow className="table-row mobile-opend-table">
								<MDBCol size="5" middle className="text-center text-white">UPC</MDBCol>
								<MDBCol size="7" middle className="text-center">
									{item?.webinar?.upc_code ? item.webinar.upc_code : 'unassigned'}
								</MDBCol>
							</MDBRow>		
						{/* 	<MDBRow className="table-row mobile-opend-table">
								<MDBCol size="5" middle className="text-center text-white">Location</MDBCol>
								<MDBCol size="7" middle className="text-center">
									{item && item.user_data && item.user_data.address && item.user_data.address}
								</MDBCol>
							</MDBRow> */}
							<MDBRow className="table-row mobile-opend-table">

									<MDBCol size="5" middle className="text-center text-white">FFL Selected</MDBCol>
									<MDBCol size="7" middle className="text-center" style={
										{
											color: setTooltipByColor(item).textColor,
										}}>
									<div
											onClick={() => {
												setShowTooltip(!showTooltip);
											}}
											style={{
												width: '100%',
											}}
										>
											{
													showTooltip && (
															<Tooltip 
																innerRef={ref}
																closeModal={() => setShowTooltip(false)} 
																text={setTooltipByColor(item).text} 
																color={setTooltipByColor(item).color} 
																backgroundColor={setTooltipByColor(item).backgroundColor}
															/>
													)
											}
											{
												item.user_data?.ffl_scope_id==null? "FFL NOT SELECTED" :
												(item.ffl_databases?.businessName!=='' && item.ffl_databases?.businessName!==null) ? item.ffl_databases?.businessName : item.ffl_databases?.licenseName
											}
										</div>
									</MDBCol>
							</MDBRow>
							<MDBRow className="table-row mobile-opend-table">
									<MDBCol size="5" middle className="text-center text-white">FFL Expiration Date</MDBCol>
									<MDBCol size="7" middle className="text-center">
										{
											(item?.ffl_databases?.expiration!=='' && item?.ffl_databases?.expiration!==null)?
												moment(item?.ffl_databases?.expiration.substring(0,10)).format('MM/DD/YYYY') : ''
										}
									</MDBCol>
							</MDBRow>
							<MDBRow className="table-row mobile-opend-table">
								<MDBCol size="5" middle className="text-center text-white">FFL Number</MDBCol>
					
							<MDBCol size="1" middle className="text-center">
							
									<MDBIcon far icon="fa-thin fa-copy"  onClick={()=>copyFFL()}/>
							
                            </MDBCol>
							</MDBRow>
							{
								!item.ffl_assignment_completed &&
								<>
									<MDBRow className="table-row mobile-opend-table">
										<MDBCol size="5" middle className="text-center text-white">Edit FFL</MDBCol>
											<MDBCol size="7" className="text-center">
												<div class="fas fa-pencil-alt" onClick={() => {
													showModal(true); 
													currentRow(item)
													setErrorWithFFL(false)
												}
												}> </div>
											</MDBCol>
										</MDBRow>
									<MDBRow className="table-row mobile-opend-table">
									<MDBCol size="5" middle className="text-center text-white">Confirm</MDBCol>
										<MDBCol size="7" className="text-center">
											<button className="streamBtnShip" onClick={()=>{
												setFFLNotRequired(false);
												setShowConfirmationModal(true);
												setFFLName(
													item.user_data?.ffl_scope_id==null? "FFL NOT SELECTED" : (item.ffl_databases?.businessName!=='' && item.ffl_databases?.businessName!==null) ? item.ffl_databases?.businessName : item.ffl_databases?.licenseName
												)
											}}>Send to shipping</button>
										</MDBCol>
									</MDBRow>	
									<MDBRow className="table-row mobile-opend-table">
										<MDBCol size="5" middle className="text-center text-white">Not Required</MDBCol>
											<MDBCol size="7" className="text-center">
											<button className="streamBtnShip" onClick={()=> {setFFLNotRequired(true); setShowConfirmationModal(true)}}>FFL Not Required</button>	
											</MDBCol>
								
									</MDBRow>					
									
									
								</>
							}


							
							{/* <MDBRow className="table-row mobile-opend-table">
								<MDBCol size="5" middle className="text-center text-white">FFL Name</MDBCol>
								<MDBCol size="7" middle className="text-center">
									<Select value={selectedFflItem} isDisabled={!isEdit} onChange={selectedItem => handleFFLChange(selectedItem)} options={fflList} styles={dropDownCustomStyles} />
								</MDBCol>
							</MDBRow> */}
							<MDBRow className="table-row mobile-opend-table">
								<MDBCol size="5" middle className="text-center text-white">More Detail</MDBCol>
								<MDBCol size="7" middle className="text-center">
									<button onClick={() => setShowWonList(!isShowWonList)}>Show / Hide</button>
								</MDBCol>
							</MDBRow>
						</Fragment>
					}
				</Fragment>
				:
				<MDBRow className="table-row" style={item.ffl_assignment_completed==1? { backgroundColor: '#53893eb3', borderBottom: '1px solid grey' }:{}} onClick={() => setShowWonList(!isShowWonList)}>
					<MDBCol size="1">
						{
							imageUrl ? <img src={imageUrl} alt={item.name} />
								:
								<div className="non-img">
									<MDBIcon far icon="file-image" />
								</div>
						}
					</MDBCol>
					<MDBCol size="1" className="text-center email-col">
						{item?.webinar?.upc_code ? item.webinar.upc_code : 'unassigned'}
					</MDBCol>
					<MDBCol size="2" className="text-center">
						<LongItemWithTooltip text={item && item.webinar && item.webinar.name && item.webinar.name} />
					</MDBCol>
					<MDBCol size="2" className="text-center">
						{item && item.user_data && item.user_data.username && item.user_data.username}
					</MDBCol>
					
					{/* {isEdit && 
						<MDBCol size="1" className="text-center">
							{
								!item?.webinar?.webinar_link || item?.webinar?.webinar_link.length < 1 ? 
									<div className="trackingWrapper">
										<input type="text" value={newLink} onChange={event => setNewLink(event.target.value)} />
										<button onClick={addNewLinkFunc}><MDBIcon icon="check" /></button>
									</div>
									:
									<button className="streamBtn" onClick={()=>setIsConfirmDelete(true)}>Delete</button>
							}
						</MDBCol>
					} */}
					{
					item.ffl_assignment_completed==1 ?
						<MDBCol size="1" className="text-center" style={
						{
							color: setTooltipByColor(item).textColor,
						}	
						}>
							<div
								onMouseOver={() => {
									setShowTooltip(true);
								}}
								onMouseLeave={() => {
									setShowTooltip(false);
								}}
								onClick={() => {
									setShowTooltip(!showTooltip);
								}}
								style={{
									width: '100%',
								}}
							>
								{
									showTooltip && (
											<Tooltip 
												innerRef={ref}
												closeModal={() => setShowTooltip(false)} 
												text={setTooltipByColor(item).text} 
												color={setTooltipByColor(item).color} 
												backgroundColor={setTooltipByColor(item).backgroundColor}
											/>
									)
            	}
									{
										item.ffl_not_required==1? "FFL NOT REQUIRED" : 
										(item.assigned_ffl?.businessName!=='' && item.assigned_ffl?.businessName!==null) ? item.assigned_ffl?.businessName : item.assigned_ffl?.licenseName
									}			
							</div>
						</MDBCol>
					:
						<MDBCol size="1" className="text-center">
							{	
								item.user_data?.ffl_scope_id==null? "FFL NOT SELECTED" :
								(item.ffl_databases?.businessName!=='' && item.ffl_databases?.businessName!==null) ? item.ffl_databases?.businessName : item.ffl_databases?.licenseName
							}
						</MDBCol>
					}

						<MDBCol size="1" className="text-center" style={isExpired? {color: red}: {}}>
							{
								expiredDate?
									moment(expiredDate.substring(0,10)).format('MM/DD/YYYY') : ''
							}
						</MDBCol>
			

					<MDBCol size="1" middle className="text-center">
							
						<MDBIcon far icon="fa-thin fa-copy"  onClick={()=>copyFFL()}/>
							
                    </MDBCol>
							<MDBCol size="1" className="text-center">
								<div class="fas fa-pencil-alt" onClick={() => {showModal(true); currentRow(item)}}> </div>
							</MDBCol>							
								
									<MDBCol size="1" className="text-center">
										{
											!item.ffl_assignment_completed && (
												<button className="streamBtnShip" onClick={()=>{
													setFFLNotRequired(false);
													setShowConfirmationModal(true);
													setFFLName(
														item.user_data?.ffl_scope_id==null? "FFL NOT SELECTED" : (item.ffl_databases?.businessName!=='' && item.ffl_databases?.businessName!==null) ? item.ffl_databases?.businessName : item.ffl_databases?.licenseName
													)
												}}>Send to shipping</button>
											)
										}
									</MDBCol>
							<MDBCol size="1" className="text-center">
								<button className="streamBtnShip" onClick={()=> {setFFLNotRequired(true); setShowConfirmationModal(true)}}>FFL Not Required</button>
							</MDBCol>
					{/* <MDBCol size={isEdit ? '1' : '2'} className="text-center">
						<Select value={selectedFflItem} isDisabled={!isEdit} onChange={selectedItem => handleFFLChange(selectedItem)} options={fflList} styles={dropDownCustomStyles} />
					</MDBCol> */}						
				</MDBRow>
			}
			{
				isShowWonList && 
			   ! isMobile  &&
			  				
				<Fragment>
					<MDBRow className="ffl-row">				
						<MDBCol size={isEdit ? '1' : '2'} className="text-center">
							Phone
						</MDBCol>
						<MDBCol size={isEdit ? '2' : '2'} className="text-center">
							Email
						</MDBCol>
						<MDBCol size={isEdit ? '3' : '3'} className="text-center">
							Location
						</MDBCol>
						<MDBCol size={isEdit ? '2' : '2'} className="text-center">
							Date
						</MDBCol>
						{ item && item.consumers && item.consumers.username?
							<MDBCol size={isEdit ? '2' : '2'} className="text-center">
								Hot Seat
							</MDBCol>
							:""	
						}
						{isEdit && <MDBCol size="2" className="text-center">
							Duplicate
						</MDBCol>}
					</MDBRow>

					<MDBRow className="ffl-row bottom-border">
						<MDBCol size={isEdit ? '1' : '2'} className="text-center">
							{item && item.user_data && item.user_data.phone_number && <span>({item.user_data.phone_number.slice(2, 5)}) {item.user_data.phone_number.slice(5, 8)}-{item.user_data.phone_number.slice(8)}</span>}
						</MDBCol>
						<MDBCol size={isEdit ? '2' : '2'} className="text-center email-col elipsis">
							{item && item.user_data && item.user_data.email && <LongItemWithTooltip text={item.user_data.email} maxlenght={40}/>}
						</MDBCol>
						<MDBCol size={isEdit ? '3' : '3'} className="text-center email-col elipsis">
							{item && item.user_data && item.user_data.address && <LongItemWithTooltip text={item.user_data.address} maxlenght={40}/>}
						</MDBCol>
						<MDBCol size={isEdit ? '2' : '2'} className="text-center elipsis">
							{item && item.createdAt && getCustomDate()}
						</MDBCol>
						{ item && item.consumers && item.consumers.username?
							<MDBCol size={isEdit ? '2' : '2'} className="text-center email-col elipsis">
							{item && item.consumers && item.consumers.username}
							</MDBCol>
							:""
						}
						
						{isEdit && <MDBCol size="2" className="text-center">
							<button onClick={() => urlHistory.push(router.editProduct.path, {
									prodType: 'webinar', 
									id: item?.webinar_id,
									duplicate: true
							})}>
									Duplicate
							</button>
						</MDBCol>}
					</MDBRow>

				</Fragment>
			}

			{
				isShowWonList && 
			     isMobile  &&
				 <Fragment>
				 <MDBRow className="ffl-row">				
					 <MDBCol size="5"className="text-center text-white">
						 Phone
					 </MDBCol>

					 <MDBCol size="7" className="text-center">
						 {item && item.user_data && item.user_data.phone_number && <span>({item.user_data.phone_number.slice(2, 5)}) {item.user_data.phone_number.slice(5, 8)}-{item.user_data.phone_number.slice(8)}</span>}
					 </MDBCol>
				 </MDBRow>
				 <MDBRow className="ffl-row">				
					 <MDBCol size="5"className="text-center text-white">
						 Email
					 </MDBCol>

					 <MDBCol size="7" className="text-center">
						 {item && item.user_data && item.user_data.email && item.user_data.email}
					 </MDBCol>
				 </MDBRow>
				 <MDBRow className="ffl-row">				
					 <MDBCol size="5"className="text-center text-white">
						 Location
					 </MDBCol>

					 <MDBCol size="7" className="text-center">
						 {item && item.user_data && item.user_data.address && item.user_data.address}
					 </MDBCol>
				 </MDBRow>	
				 <MDBRow className="ffl-row">				
					 <MDBCol size="5"className="text-center text-white">
						 Date
					 </MDBCol>

					 <MDBCol size="7" className="text-center">
						 {item && item.createdAt && getCustomDate()}
					 </MDBCol>
				 </MDBRow>	
				 { item && item.consumers && item.consumers.username?
				 <MDBRow className="ffl-row">
					<MDBCol size="5"className="text-center">
							 Hot Seat
						 </MDBCol>
						 <MDBCol size="7" className="text-center">
						<MDBCol size={isEdit ? '12' : '12'} className="text-center email-col elipsis">
							{item && item.consumers && item.consumers.username}
							</MDBCol>
						
						
					 </MDBCol>	
					 

				 </MDBRow>
				 : ""
				}
			{/* 	<MDBRow className="ffl-row">
					{ item && item.consumers && item.consumers.username?
						 <MDBCol size="5"className="text-center">
							 Hot Seat
						 </MDBCol>
						 :""	
					 }
					 <MDBCol size="7" className="text-center">
						 {  item && item.consumers && item.consumers.username?
							<MDBCol size={isEdit ? '12' : '12'} className="text-center email-col elipsis">
							{item && item.consumers && item.consumers.username}
							</MDBCol>
							:""
						}
					 </MDBCol>	
					 
				</MDBRow> */}
					 <MDBRow className="ffl-row">	
					
					 {isEdit && <MDBCol  size="5" className="text-center">
						 Duplicate
					 </MDBCol>}

					 <MDBCol size="7" className="text-center">
					
						
						{isEdit && <MDBCol size="12" className="text-center">
							<button onClick={() => urlHistory.push(router.editProduct.path, {
									prodType: 'webinar', 
									id: item?.webinar_id,
									duplicate: true
							})}>
									Duplicate
							</button>
						</MDBCol>}
					 </MDBCol>
				 </MDBRow>


			 </Fragment>			
				
			}
			
			{/* {
				isShowWonList && <Fragment>
					<MDBRow className="ffl-row">				
						<MDBCol size={isEdit ? '1' : '2'} className="text-center">
							Phone
						</MDBCol>
						<MDBCol size={isEdit ? '2' : '2'} className="text-center">
							Email
						</MDBCol>
						<MDBCol size={isEdit ? '3' : '3'} className="text-center">
							Location
						</MDBCol>
						<MDBCol size={isEdit ? '2' : '2'} className="text-center">
							Date
						</MDBCol>
						{ item && item.consumers && item.consumers.username?
							<MDBCol size={isEdit ? '2' : '2'} className="text-center">
								Hot Seat
							</MDBCol>
							:""	
						}
						{isEdit && <MDBCol size="2" className="text-center">
							Duplicate
						</MDBCol>}
					</MDBRow>

					<MDBRow className="ffl-row bottom-border">
						<MDBCol size={isEdit ? '1' : '2'} className="text-center">
							{item && item.user_data && item.user_data.phone_number && <span>({item.user_data.phone_number.slice(2, 5)}) {item.user_data.phone_number.slice(5, 8)}-{item.user_data.phone_number.slice(8)}</span>}
						</MDBCol>
						<MDBCol size={isEdit ? '2' : '2'} className="text-center email-col elipsis">
							{item && item.user_data && item.user_data.email && item.user_data.email}
						</MDBCol>
						<MDBCol size={isEdit ? '3' : '3'} className="text-center email-col elipsis">
							{item && item.user_data && item.user_data.address && item.user_data.address}
						</MDBCol>
						<MDBCol size={isEdit ? '2' : '2'} className="text-center elipsis">
							{item && item.createdAt && getCustomDate()}
						</MDBCol>
						{ item && item.consumers && item.consumers.username?
							<MDBCol size={isEdit ? '2' : '2'} className="text-center email-col elipsis">
							{item && item.consumers && item.consumers.username}
							</MDBCol>
							:""
						}
						
						{isEdit && <MDBCol size="2" className="text-center">
							<button onClick={() => urlHistory.push(router.editProduct.path, {
									prodType: 'webinar', 
									id: item?.webinar_id,
									duplicate: true
							})}>
									Duplicate
							</button>
						</MDBCol>}
					</MDBRow>

				</Fragment>
			} */}
		</Fragment>
	)
}

const MapDispatchToProps = dispatch => ({
	getEmailList: getEmailList(dispatch),
	getAllUsers: getAllUsers(dispatch),
	loadPage: flag => dispatch(loadPage(flag)),
	changeWebinarLink: changeWebinarLink(dispatch),
	getProductItemsCount: getProductItemsCount(dispatch),
})

export default connect(null, MapDispatchToProps)(CompletedWebinarItemRow);
