import React, { useState, useEffect, Fragment } from 'react';
import './markups-page.style.scss';
import FormButton from '../../components/form-button/form-button.component';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import MarkupTableRow from '../../components/table/markup-table/row-item/row-item.component';
import TableFilterInput from '../../components/table/filter-input/filter-input.component';
import FormInput from '../../components/form-input/form-input.component';
import FormSelect from '../../components/form-select/form-select.component';
import { connect } from 'react-redux';
import { getCurrentMarkups, setFilterStr } from '../../redux/markups/markups.action';
import { loadPage } from '../../redux/user/user.action';
import { useAlert } from 'react-alert';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';

const ProvidersMarkupPage = ({currentMarkups, getCurrentMarkups, loadPage, isShowSideBar, filterString, setFilterString}) => {

    const alert = useAlert();

    const [isEdit, setIsEdit] = useState(false);

    const [isAllChecked, setIsAllChecked] = useState(false);
    const [deleteList, setDeleteList] = useState([]);

    useEffect(() => {
        return () => {
            setFilterString("");
        }
    }, [])

    useEffect(() => {
        async function loadProvidersMarkups () {
            loadPage(true);
            await getCurrentMarkups(false, filterString);
            console.log("currentMarkups")
            console.log(currentMarkups)
            loadPage(false); 
        }
        loadProvidersMarkups();
    }, [filterString])

    const addToDelList = (id) => {
        let delList = deleteList;
        delList.push(id);
        setDeleteList([...delList]);
    }

    const removeFromDelList = (id) => {
        let delList = deleteList;
        let index = delList.indexOf(id);
        if (index > -1){
            delList.splice(index,1);
            setDeleteList([...delList]);
        }
    }

    return (
        <div className="markups-page" style={{width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)'}}>
            {
                <Fragment>
                    <div className="title">
                        <h2 className="text-white font-weight-bold mb-0">Markups</h2>
                    </div>                    
                    <div className="mt-3 section">
                        <div className="toolbar" >
                        {isEdit && <p>Action: <i class="fas fa-trash-alt" onClick={()=>deleteList.length > 0}></i></p>}
                        <TableFilterInput str={filterString} setFilterString={setFilterString} placeholder="search by distributor"/>
                        </div>
                        <MDBRow className="table-header">
                            <MDBCol size="3" sm="3" md="3" className="text-white">
                                Distributor Name
                            </MDBCol>
                            <MDBCol size="3" sm="3" md="3" className="text-center">
                                Markup Value
                            </MDBCol>
                            <MDBCol size="3" sm="3" md="3" className="text-center">
                                Retail map Markup Value
                            </MDBCol>
                            {isEdit && <MDBCol size="3" sm="3" md="3" className="text-white text-center">
                                Action
                            </MDBCol>}
                        </MDBRow>
                        {
                            currentMarkups && currentMarkups.map( markup => <MarkupTableRow
                                key={markup.id}
                                id = {markup.id}
                                name={markup.distributor} 
                                markupValue={markup.value}
                                retailMarkupValue={markup.retail_map_markup}
                                addToDelList = {(id)=>addToDelList(id)}
                                removeFromDelList = {(id)=>removeFromDelList(id)}
                                isAllChecked = {isAllChecked}/>  )
                        }
                    </div>
                </Fragment>
            }            
        </div>
    )
}

const MapStateToProps = ({markups: {currentMarkups, filterString}, user: {isShowSideBar}}) => ({
    filterString,
    currentMarkups,
    isShowSideBar
});

const MapDispatchToProps = dispatch => ({
    getCurrentMarkups: getCurrentMarkups(dispatch),
    setFilterString: str => dispatch(setFilterStr(str)),
    loadPage: flag => dispatch(loadPage(flag))
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(ProvidersMarkupPage));