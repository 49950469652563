const OfflineSalesActionTypes = {
    SET_PRODUCT_LIST_ITEMS: "SET_OFFLINE_SALES_LIST_ITEMS",
    SET_FILTER_STRING: "SET_OFFLINE_SALES_FILTER_STRING",
    SET_LIMIT_PER_PAGE: "SET_OFFLINE_SALES_LIMIT_PER_PAGE",
    SET_PRODUCT_LIST_PAGE_NUM: "SET_OFFLINE_SALES_PAGE_NUM",
    SET_PRODUCT_LIST_ITEMS_COUNT: "SET_OFFLINE_SALES_LIST_ITEMS_COUNT",
    SET_FFL_FILTER: "SET_OFFLINE_SALES_FFL_FILTER",

};

export default OfflineSalesActionTypes;
