import ZoomMeetingsActionTypes from './zoom-meetings.types';

const INITIAL_STATE = {
    meetings: [],
    limitPerPage: 10,
    pageNum: 1,
    currentTypeFilter: "Upcoming",
    totalCount: null
}

const ZoomWebinarsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ZoomMeetingsActionTypes.SET_ZOOM_MEETINGS_LIST_ITEMS:
            return {
                ...state,
                meetings: action.payload.webinars,
                totalCount: action.payload.total_records
            }
        case ZoomMeetingsActionTypes.SET_LIMIT_PER_PAGE:
            return {
                ...state, 
                limitPerPage: +action.payload
            }
        case ZoomMeetingsActionTypes.SET_TYPE_FILTER:
            return {
                ...state, 
                currentTypeFilter: action.payload
            }
        case ZoomMeetingsActionTypes.SET_PRODUCT_LIST_PAGE_NUM:
            return {
                ...state, pageNum: action.payload
            }
        default:
            return state;
    }
}

export default ZoomWebinarsReducer;