import React, { useState, useEffect, Fragment } from 'react';
import logo from '../../assets/imgs/gun-logo.png';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse,
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon
} from "mdbreact";
import { connect } from 'react-redux';
import { alertsShow, hideAlerts, getNotifications } from '../../redux/alerts/alerts.action';
import { delCurrentUser } from '../../redux/user/user.action'; 
import NotificationAlerts from '../../components/alerts/alerts.component';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './header.style.scss';
import router from '../../router';

const HeaderComponent = ({ isShow, alertItems, alertsToggle, hideAlerts, getNotifications, delCurrentUser }) => {

    const urlHistory = useHistory();

    const userData = JSON.parse(localStorage.getItem("userData"));

    const [isMobileSize, setIsMobileSize] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const [flag, setFlag] = useState(-1);    // for 2 minutes setInterval
    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 768)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();

        window.addEventListener('resize', handleResize);
        if (userData) {

            // for get notification every 2 minutes
            if (flag < 0) {
                setFlag(setInterval(async () => {
     
                        await getNotifications();
                                       
                }, 120*1000));
            }            
        }
    }, []);
    
    const [scrollTop, setScrollTop] = useState(0);
    useEffect(() => {
        const onScroll = e => {
          setScrollTop(e.target.documentElement.scrollTop);
          setIsOpen(false)
          
        };
        window.addEventListener("scroll", onScroll);
    
        return () => window.removeEventListener("scroll", onScroll);
        
      }, [scrollTop]);

    return (
            <MDBNavbar className='customNav' color='white' light expand="md">
                <MDBNavbarBrand href={router.dashboard.path} onClick={hideAlerts}>
                    <img src={logo} alt="Mata's Tactical Supply" />
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={() => setIsOpen(!isOpen)} />
                <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
                    {
                        userData && 
                        <MDBNavbarNav right>                       
                            <MDBNavItem className="alerts-nav">
                                <MDBNavLink to='#' className='notification-div' onClick={()=>alertsToggle()}>
                                    <MDBIcon className='notification-bell' far icon="bell"/>
                                    {
                                        alertItems.length > 0 && <button className='notification-button'>{alertItems.length}</button>
                                    }
                                </MDBNavLink>
                                {
                                    isShow && <div className="alerts-container">
                                        <NotificationAlerts alertItems={alertItems} toggleMenu={() => window.innerWidth < 600 && setIsOpen(!isOpen)} />
                                    </div>
                                }
                            </MDBNavItem>
                            {
                                isMobileSize && 
                                <Fragment>
                                <MDBNavItem onClick={()=>urlHistory.push(router.dashboard.path)}>
                                    <MDBNavLink to="#" className="menu-item" onClick={()=> {
                                        hideAlerts();
                                        window.innerWidth < 600 && setIsOpen(!isOpen);                                
                                    }}>Dashboard</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBDropdown >
                                        <MDBDropdownToggle nav className="menu-item">
                                           Distributors Management
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu >
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.lipseyDashboard.path);
                                            }}>Dashboard</MDBDropdownItem>
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.lipseyMarkup.path);
                                            }}>Markups</MDBDropdownItem>
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.lipseySoldProducts.path);
                                            }}>Sold Products</MDBDropdownItem>
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.lipseyShipping.path);
                                            }}>Shipping</MDBDropdownItem>
                                        </MDBDropdownMenu>                                        
                                    </MDBDropdown>
                                </MDBNavItem>                                
                                <MDBNavItem>
                                    <MDBDropdown >
                                        <MDBDropdownToggle nav className="menu-item">
                                           Product Management
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu >
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.categories.path);
                                            }}>Categories</MDBDropdownItem>
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.productListing.path);
                                            }}>Product Listing</MDBDropdownItem>
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.webinarQueues.path);
                                            }}>Webinar Queues</MDBDropdownItem>
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.soldOutWebinars.path);
                                            }}>Sold out webinars</MDBDropdownItem>
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.fflDb.path);
                                            }}>FFL management</MDBDropdownItem>
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.completedWebinars.path);
                                            }}>Completed webinars</MDBDropdownItem>
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.zoomMeetings.path);
                                            }}>Zoom Webinars</MDBDropdownItem>
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.soldPhysicalProducts.path);
                                            }}>Sold physical products</MDBDropdownItem>
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.shipping.path);
                                            }}>Shipping</MDBDropdownItem>
                                            <MDBDropdownItem className="menu-subitem" onClick={() => {
                                                hideAlerts();
                                                window.innerWidth < 600 && setIsOpen(!isOpen);
                                                urlHistory.push(router.report.path);
                                            }}>Report</MDBDropdownItem>
                                        </MDBDropdownMenu>                                        
                                    </MDBDropdown>
                                </MDBNavItem>
                                <MDBNavItem onClick={()=>urlHistory.push(router.userManagement.path)}>
                                    <MDBNavLink to="#" className="menu-item" onClick={()=> {
                                        hideAlerts();                                
                                        window.innerWidth < 600 && setIsOpen(!isOpen);
                                    }}>User Management</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem onClick={()=>urlHistory.push(router.faq.path)}>
                                    <MDBNavLink to="#" className="menu-item" onClick={()=> {
                                        hideAlerts();
                                        window.innerWidth < 600 && setIsOpen(!isOpen);                                
                                    }}>FAQ</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem onClick={()=>urlHistory.push(router.setting.path)}>
                                    <MDBNavLink to="#" className="menu-item" onClick={()=> {
                                        hideAlerts();
                                        window.innerWidth < 600 && setIsOpen(!isOpen);                                   
                                    }}>Settings</MDBNavLink>
                                </MDBNavItem>
                                </Fragment>
                            }
                            <MDBNavItem onClick={()=>{
                                hideAlerts();
                                Auth.signOut();
                                localStorage.clear();
                                delCurrentUser();                                
                            }}>
                                <MDBNavLink to="#" className="menu-item">
                                    <MDBIcon icon="sign-in-alt" className="logOutIcon"/> 
                                </MDBNavLink>
                            </MDBNavItem>
                        </MDBNavbarNav>
                    }
                </MDBCollapse>
            </MDBNavbar>
    )
}

const MapStateToProps = ({ alerts }) => ({
    isShow: alerts.isShow,
    alertItems: alerts.alertItems
});

const MapDispatchToProps = dispatch => ({
    alertsToggle: () => dispatch(alertsShow()),
    hideAlerts: () => dispatch(hideAlerts()),
    getNotifications: getNotifications(dispatch),
    delCurrentUser: () => dispatch(delCurrentUser())
})

export default connect(MapStateToProps, MapDispatchToProps)(HeaderComponent);