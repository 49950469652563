import React, { useState, useEffect, Fragment } from 'react';
import './add-new.style.scss';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { connect } from 'react-redux';
import { getCurrentCategories } from '../../../redux/category/category.action';
import AddNewProductTypeButton from './add-type-button/add-type-btn.component';
import FormInput from '../../form-input/form-input.component';
import DateSelect from '../../form-input/date-picker.component';
import FormSelect from '../../form-select/form-select.component';
import { useDropzone } from 'react-dropzone';
import FormButton from '../../form-button/form-button.component';
import { useAlert } from 'react-alert';
import { Storage } from 'aws-amplify';
import { DatePicker, Space } from 'antd';

import { setAddNewClicked, addNewWebinar, addNewOfflineSale, addFreeWebinar , getFreeWebinarParticipants } from '../../../redux/product-list/product-list.action';
import { loadPage } from '../../../redux/user/user.action';
import { useRef } from 'react';
import moment from 'moment-timezone';
import { getSiteSettings } from '../../../redux/colorChnage/colorChange.action';
import Switch from 'react-switch';
import Resizer from "react-image-file-resizer";
import { ProductTypeEnum } from '../productTypeEnum';

const thumbsContainer = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const AddNewProductForm = ({
    getCurrentCategories,
    setAddNewClicked,
    addNewWebinar,
    getFreeWebinarParticipants,
    addFreeWebinar,
    addNewOfflineSale,
    loadPage,
    getSiteSettings
}) => {

    const alert = useAlert();
    const dateFormat ='MM/DD/YY';
const monthFormat = 'YYYY/MM';

const dateFormatList = ['MM/DD/YY', 'MM/DD/YY'];

    // for add new clicked

    const [productType, setProductType] = useState(ProductTypeEnum.WEBINAR);

    const [newWebinarInfo, setNewWebinarInfo] = useState({
        name: '',
        seatNumber: '',
        hotSeatNumber: null,
        seatPrice: '',
        boughtPrice: '',
        description: '',
        scheduleTime: '',
        shippingPrice: '',
        UPCCode: '',
        fromDate: '',
        toDate: '',
        taxable: true
    });

    const [newOfflineInfo, setNewOfflineInfo] = useState({
        name: '',
        buyerName: '',
        buyerEmail: '',
        buyerPhone: '',
        soldDate: '',
        soldPrice: '',
        boughtPrice: '',
        shippingAddress: '',
        UPCCode: '',
        units: '',
    });

    const [invalidInput, setInvalidInput] = useState({
        name: false,
        seatNumber: false,
        hotSeatNumber: false,
        seatPrice: false,
        boughtPrice: false,
        shippingPrice: false,
        description: false,
        category: false,
        UPCCode: false,
        fromDate: false,
        toDate: false,
    });

    const [invalidOffline, setInvalidOffline] = useState({
        productName: false,
        buyerName: false,
        buyerEmail: false,
        buyerPhone: false,
        soldPrice: false,
        boughtPrice: false,
        upcCode: false,
        units: false,
        soldDate: false,
        shippingAddress: false
    });

    const decimalFormat = (e, kind) => {
        const re = /^\d*\.?\d*$/;
        // if value is not blank, then test the regex

        if (e.target.value === '' || re.test(e.target.value)) {
            switch (kind) {
                case "seatPrice":
                    setNewWebinarInfo({ ...newWebinarInfo, seatPrice: e.target.value });
                    break;
                case "boughtPrice":
                    setNewWebinarInfo({ ...newWebinarInfo, boughtPrice: e.target.value });
                    break;
                case "shippingPrice":
                    setNewWebinarInfo({ ...newWebinarInfo, shippingPrice: e.target.value });
                    break;
                case "offlineSoldPrice":
                    setNewOfflineInfo({ ...newOfflineInfo, soldPrice: e.target.value });
                    break;
                case "offlineBoughtPrice":
                    setNewOfflineInfo({ ...newOfflineInfo, boughtPrice: e.target.value });
                    break;
                // case "hotSeatNumber":
                //     setNewWebinarInfo({ ...newWebinarInfo, hotSeatNumber: e.target.value });
                //     break;
                default:
                    break;
            }
        }
    }

    const numberFormat = (e, kind) => {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex

        if (e.target.value === '' || re.test(e.target.value)) {
            switch (kind) {
                case "seatNumber":
                    setNewWebinarInfo({ ...newWebinarInfo, seatNumber: e.target.value });
                    break;
                case "hotSeatNumber":
                    setNewWebinarInfo({ ...newWebinarInfo, hotSeatNumber: e.target.value });
                    break;
                case "units":
                    setNewOfflineInfo({ ...newOfflineInfo, units: e.target.value });
                    break;
                default:
                    break;
            }
        }
    }

    // for select box of category
    const categoryList = useRef(null);
    const [categorySelect, setCategorySelect] = useState([]);
    const [categorySelectShow, setCategorySelectShow] = useState(false);
    const [currentCategorySelect, setCurrentCategorySelect] = useState("");
    const [userConfirm, setUserConfirm] = useState(false);
    const [participants, setParticipants] = useState();

    // columns for the second row
    let colNumber = "6";
    if (newWebinarInfo.seatNumber !== '') {
        colNumber = "4";
    }

    useEffect(() => {
        if (productType !== ProductTypeEnum.OFFLINE) {
            async function loadCategories() {
                const categories = await getCurrentCategories(true);
                categoryList.current = categories;

                if (categories) {
                    const webinarCategories = [];
                    categories.map(category => {
                        //const typeStr = isWebinarTypeBtn ? "Webinar" : "Physical";
                        const typeStr = productType === ProductTypeEnum.WEBINAR ? "Webinar" : ProductTypeEnum.PRODUCT ? "Physical" : "Offline";
                        if ((category.product_type.includes(typeStr)) || (category.product_type.includes("Both")))
                            webinarCategories.push(category.category_name);
                    })
                    setCategorySelect([...webinarCategories]);
                    setCurrentCategorySelect("");
                }
            }
            loadCategories();
        }
    }, [productType]);

    // for image pick with drag and drop file

    const [files, setFiles] = useState([]);
    const [activeImage, setActiveImage] = useState(null);

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.jpg, .jpeg, .png, .webp, .tiff, .tif, .gif, .svg',
        onDrop: acceptedFiles => {
            setFiles((acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))).slice(0, 10));
            if ((acceptedFiles.length) < 11)
                setActiveImage(Object.assign(acceptedFiles[0], {
                    preview: URL.createObjectURL(acceptedFiles[0])
                }))
        }
    });

    const thumbs = files.map(file => (
        <div className="thumbImg" key={file.name} onClick={() => {
            setActiveImage(file);
        }
        }>
            <div style={thumbInner} >
                <img
                    src={file.preview}
                    style={img}
                />
            </div>
            <MDBIcon className="checkIcon" far icon="check-circle" />
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    // for list status
    const [listStatus, setListStatus] = useState("later");

    const imagesUpload = async (files) => {
        const imagesList = [];
        files.splice(files.indexOf(activeImage), 1);
        for (let i = 0; i < files.length; i++) {
            const stored = await Storage.put("products-" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + "-" + files[i].name, files[i], {
                contentType: files[i].type,
                cacheControl: 'max-age=31536000'
            });

            imagesList.push(stored.key);
        }
        return imagesList;
    }

    const activeImageUpload = async file => {
        const stored = await Storage.put("products-" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + "-" + file.name, file, {
            contentType: file.type,
            cacheControl: 'max-age=31536000'
        });
        return stored.key;
    }

    const getCurrentCategoryId = () => {
        const result = categoryList.current.filter(category => category.category_name === currentCategorySelect);
        return result[0]?.id;
    }

    const resizeImage = (file, size) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                size,
                size,
                file.type.split('/')[1],
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });
    }

    const addProductFunction = async (method) => {

        const mainImage = await activeImageUpload(activeImage);
        const thumbnailSmall = await resizeImage(activeImage, 300);
        const thumbnailLarge = await resizeImage(activeImage, 1200);

        const thumbnail300 = await activeImageUpload(thumbnailSmall);
        const thumbnail1200 = await activeImageUpload(thumbnailLarge);

        const imageLists = await imagesUpload(files);
        imageLists.push(mainImage);
        imageLists.push(thumbnail300);
        imageLists.push(thumbnail1200);

        const categoryID = getCurrentCategoryId();

        let obj = {};

        if (productType === ProductTypeEnum.WEBINAR || productType === ProductTypeEnum.FREE) {
            obj = {
                name: newWebinarInfo.name,
                price_per_seats: (Math.round(newWebinarInfo.seatPrice * 100) / 100).toFixed(2),
                publish_method: method === "now" ? "instant" : method === "queue" ? "queued" : "scheduled",
                hot_seat_number: newWebinarInfo.hotSeatNumber,
                mainImage: mainImage,
                thumbnail300: thumbnail300,
                thumbnail1200: thumbnail1200,
                imageLists: imageLists,
                seats: newWebinarInfo.seatNumber,
                shortDescription: newWebinarInfo.description,
                category_id: categoryID,
                webinar_type: "webinar",
                bought_for: (Math.round(newWebinarInfo.boughtPrice * 100) / 100).toFixed(2),
                shipping_price: (Math.round(newWebinarInfo.shippingPrice * 100) / 100).toFixed(2),
                taxable: newWebinarInfo.taxable,
                upc_code: newWebinarInfo.UPCCode,
                from_date: newWebinarInfo.fromDate,
                to_date: newWebinarInfo.toDate
            }

            if (method === "later") {
                const date = new Date(currentDate.year, currentDate.month - 1, currentDate.day, currentDate.hour, currentDate.minute);
                obj.scheduled_time = date.toISOString();
            }
            if (productType === ProductTypeEnum.FREE) {
                const dataFreeW = await addFreeWebinar("webinar", obj);
                if (dataFreeW === "success")
                    alert.success("Added successfully");

            } else {
                const result = await addNewWebinar("webinar", obj);
                if (result === "success")
                    alert.success("Added successfully");
            }




        } else if (productType === ProductTypeEnum.PRODUCT) {
            obj = {
                productName: newWebinarInfo.name,
                pricePerItem: (Math.round(newWebinarInfo.seatPrice * 100) / 100).toFixed(2),
                hot_seat_number: newWebinarInfo.hotSeatNumber,
                publish_method: method === "now" ? "instant" : "scheduled",
                mainImage: mainImage,
                thumbnail300: thumbnail300,
                thumbnail1200: thumbnail1200,
                imageLists: imageLists,
                amount: newWebinarInfo.seatNumber,
                shortDescription: newWebinarInfo.description,
                category_id: categoryID,
                bought_for: (Math.round(newWebinarInfo.boughtPrice * 100) / 100).toFixed(2),
                shipping_price: (Math.round(newWebinarInfo.shippingPrice * 100) / 100).toFixed(2),
                taxable: newWebinarInfo.taxable,
                upc_code: newWebinarInfo.UPCCode,
                from_date: newWebinarInfo.fromDate,
                to_date: newWebinarInfo.toDate
            }

            if (method === "later") {
                const settingsInfo = await getSiteSettings();

                if (settingsInfo && settingsInfo.time_zone) {
                    // const date = new Date(moment(currentDate).tz(settingsInfo.time_zone).format('MM/DD/YY HH:mm'));
                    // obj.scheduled_time = date.toISOString();
                    const date = new Date(currentDate.year, currentDate.month - 1, currentDate.day, currentDate.hour, currentDate.minute);
                    obj.scheduled_time = date.toISOString();
                }
            }

            const result = await addNewWebinar("physical", obj);
            if (result === "success") {
                alert.success("Added successfully");
            }
            else {
                alert.error("It is not added correctly");
            }

        }

    }

    const goToListFunc = async listType => {
        setListStatus(listType);
        if (productType === ProductTypeEnum.FREE) {
            setUserConfirm(false)
        }



        if (currentCategorySelect === "" && productType != ProductTypeEnum.FREE) {
            setInvalidInput({
                name: false,
                category: true,
                seatNumber: false,
                hotSeatNumber: false,
                seatPrice: false,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: false
            });
            return;
        }
        if (newWebinarInfo.name.trim() === "" && productType != ProductTypeEnum.FREE) {
            setInvalidInput({
                name: true,
                category: false,
                seatNumber: false,
                hotSeatNumber: false,
                seatPrice: false,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: false
            });
            return;
        }
        if (newWebinarInfo.name.trim() === "" && productType === ProductTypeEnum.FREE) {
            setInvalidInput({
                name: true,
                category: false,
                seatNumber: false,
                hotSeatNumber: false,
                seatPrice: false,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: false,
                fromDate: false,
                toDate: false
            });
            return;
        }
        if (newWebinarInfo.UPCCode.trim() === "" && productType === ProductTypeEnum.FREE) {
            setInvalidInput({
                name: false,
                category: false,
                seatNumber: false,
                hotSeatNumber: false,
                seatPrice: false,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: true,
                fromDate: false,
                toDate: false
            });
            return;
        }
        if (newWebinarInfo.fromDate.toString() === "" && productType === ProductTypeEnum.FREE) {
            setInvalidInput({
                name: false,
                category: false,
                seatNumber: false,
                hotSeatNumber: false,
                seatPrice: false,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: false,
                fromDate: true,
                toDate: false
            });
            return;
        }
        if (newWebinarInfo.toDate.toString() === "" && productType === ProductTypeEnum.FREE) {
            setInvalidInput({
                name: false,
                category: false,
                seatNumber: false,
                hotSeatNumber: false,
                seatPrice: false,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: false,
                fromDate: false,
                toDate: true
            });
            return;
        }
        if (newWebinarInfo.seatNumber.trim() === "" && productType != ProductTypeEnum.FREE) {
            setInvalidInput({
                name: false,
                category: false,
                seatNumber: true,
                hotSeatNumber: false,
                seatPrice: false,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: false
            });
            return;
        }

        if (parseInt(newWebinarInfo.hotSeatNumber) > parseInt(newWebinarInfo.seatNumber)) {
            alert.error("error hot seat number must be equal or smaller than seat number");
            setInvalidInput({
                name: false,
                category: false,
                seatNumber: false,
                hotSeatNumber: true,
                seatPrice: false,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: false
            });
            return;
        }

        if (productType === ProductTypeEnum.WEBINAR && (parseInt(newWebinarInfo.seatNumber) > 1000)) {
            alert.error("Seats number should be less than 1000");
            setInvalidInput({
                name: false,
                category: false,
                seatNumber: true,
                hotSeatNumber: false,
                seatPrice: false,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: false
            });
            return;
        }

        if (productType === ProductTypeEnum.FREE && new Date(newWebinarInfo.fromDate) > new Date(newWebinarInfo.toDate)) {
            alert.error("Date Invalid");
            setInvalidInput({
                name: false,
                category: false,
                seatNumber: false,
                hotSeatNumber: false,
                seatPrice: false,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: false,
                fromDate: true,
                toDate: false
            });
            return;
        }

        if (productType === ProductTypeEnum.FREE && new Date(newWebinarInfo.toDate) > new Date()) {
            alert.error("Date Invalid");
            setInvalidInput({
                name: false,
                category: false,
                seatNumber: false,
                hotSeatNumber: false,
                seatPrice: false,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: false,
                fromDate: false,
                toDate: true
            });
            return;
        }

        if (newWebinarInfo.seatPrice.trim() === "" && productType != ProductTypeEnum.FREE) {
            setInvalidInput({
                name: false,
                category: false,
                seatNumber: false,
                hotSeatNumber: false,
                seatPrice: true,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: false
            });
            return;
        }
        if (newWebinarInfo.boughtPrice.trim() === "" && productType != ProductTypeEnum.FREE) {
            setInvalidInput({
                name: false,
                category: false,
                seatNumber: false,
                hotSeatNumber: false,
                seatPrice: false,
                boughtPrice: true,
                description: false,
                shippingPrice: false,
                UPCCode: false
            });
            return;
        }

        if (newWebinarInfo.UPCCode.trim() === "" && productType != ProductTypeEnum.FREE) {
            setInvalidInput({
                name: false,
                category: false,
                seatNumber: false,
                hotSeatNumber: false,
                seatPrice: false,
                boughtPrice: false,
                description: false,
                shippingPrice: false,
                UPCCode: true
            });
            return;
        }

        if (files.length < 1) {
            alert.error("Please select the product photos");
            return;
        }

        loadPage(true);
        await addProductFunction(listType);
        loadPage(false);
        setAddNewClicked(false);

    }
    const participantNumber =  async () => {
        loadPage(true);
        let obj = {
            from_date: newWebinarInfo.fromDate,
            to_date: newWebinarInfo.toDate
        };
        console.log('obj',obj)
        const participantsFW = await getFreeWebinarParticipants(obj);
        setParticipants(participantsFW);
        setUserConfirm(true);
        loadPage(false);
    };
    const participantNumberChange = () => {
        setUserConfirm(false);
    };

    const addOfflineSaleHandler = async () => {
        
        if (areOfflineSalesFieldsInvalid()) return;
        loadPage(true);
        const obj = {
            product_name: newOfflineInfo.name,
            buyer_name: newOfflineInfo.buyerName,
            buyer_email: newOfflineInfo.buyerEmail,
            buyer_phone: newOfflineInfo.buyerPhone,
            upc_code: newOfflineInfo.UPCCode,
            bought_price: newOfflineInfo.boughtPrice,
            sold_price: newOfflineInfo.soldPrice,
            date_purchased: newOfflineInfo.soldDate,
            units: newOfflineInfo.units,
            shipping_address: newOfflineInfo.shippingAddress,
        }
        const result = await addNewOfflineSale(obj);
        loadPage(false);
        if (result === "success") {
            alert.success("Offline Sale Added Successfully");
            setAddNewClicked(false);
        } else {
            alert.error("Failed to add product");
        }
    }
    const areOfflineSalesFieldsInvalid = () => {
        let isInvalid = false;
        let fieldsValidated = {
            productName: false,
            buyerName: false,
            buyerEmail: false,
            buyerPhone: false,
            soldPrice: false,
            boughtPrice: false,
            upcCode: false,
            units: false,
            soldDate: false,
            shippingAddress: false,
        }
        if (newOfflineInfo.name.trim() === '') {
            fieldsValidated = { ...fieldsValidated, productName: true }
            isInvalid = true;
        }
        if (newOfflineInfo.buyerName.trim() === '') {
            fieldsValidated = { ...fieldsValidated, buyerName: true }
            isInvalid = true;
        }
        if (newOfflineInfo.buyerEmail.trim() === '') {
            fieldsValidated = { ...fieldsValidated, buyerEmail: true }
            isInvalid = true;
        }
        if (newOfflineInfo.buyerPhone.trim() === '') {
            fieldsValidated = { ...fieldsValidated, buyerPhone: true }
            isInvalid = true;
        }
        if (newOfflineInfo.shippingAddress === '') {
            fieldsValidated = { ...fieldsValidated, shippingAddress: true }
            isInvalid = true;
        }
        if (newOfflineInfo.soldPrice === '') {
            fieldsValidated = { ...fieldsValidated, soldPrice: true }
            isInvalid = true;
        }
        if (newOfflineInfo.boughtPrice === '') {
            fieldsValidated = { ...fieldsValidated, boughtPrice: true }
            isInvalid = true;
        }
        if (newOfflineInfo.UPCCode.trim() === '') {
            fieldsValidated = { ...fieldsValidated, upcCode: true }
            isInvalid = true;
        }
        if (newOfflineInfo.units === '' || (parseInt(newOfflineInfo.units) < 1)) {
            fieldsValidated = { ...fieldsValidated, units: true }
            isInvalid = true;
        }
        if (newOfflineInfo.soldDate === '') {
            fieldsValidated = { ...fieldsValidated, soldDate: true }
            isInvalid = true;
        }
        setInvalidOffline({ ...fieldsValidated });
        return isInvalid;
    }

    // for custom date picker
    let today = new Date();
    Date.shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    Date.shortWeeks = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const [currentDate, setCurrentDate] = useState({
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        shortWeek: Date.shortWeeks[today.getDay() - 1],
        day: today.getDate(),
        hour: today.getHours(),
        minute: today.getMinutes()
    });

    useEffect(() => {
        async function load() {
            loadPage(true);
            const settingsInfo = await getSiteSettings();

            if (settingsInfo && settingsInfo.time_zone)
                today = new Date(moment().tz(settingsInfo.time_zone).format('MM/DD/YY HH:mm'));

            setCurrentDate({
                year: today.getFullYear(),
                month: today.getMonth() + 1,
                shortWeek: Date.shortWeeks[today.getDay() - 1],
                day: today.getDate(),
                hour: today.getHours(),
                minute: today.getMinutes()
            });
            loadPage(false);
        }
        load();
    }, []);

    const plusDateFunc = (type) => {
        switch (type) {
            case "day":
                if (currentDate.day === 31)
                    setCurrentDate({ ...currentDate, day: 1 });
                else
                    setCurrentDate({ ...currentDate, day: currentDate.day + 1 });
                break;

            case "month":
                if (currentDate.month === 12)
                    setCurrentDate({ ...currentDate, month: 1 });
                else
                    setCurrentDate({ ...currentDate, month: currentDate.month + 1 });
                break;

            case "year":
                setCurrentDate({ ...currentDate, year: currentDate.year + 1 });
                break;

            case "hour":
                if (currentDate.hour === 23)
                    setCurrentDate({ ...currentDate, hour: 0 });
                else
                    setCurrentDate({ ...currentDate, hour: currentDate.hour + 1 });
                break;

            case "minute":
                if (currentDate.minute === 59)
                    setCurrentDate({ ...currentDate, minute: 0 });
                else
                    setCurrentDate({ ...currentDate, minute: currentDate.minute + 1 });
                break;

            default:
                break;
        }
    }

    const minusDateFunc = (type) => {
        switch (type) {
            case "day":
                if (currentDate.day === 1)
                    setCurrentDate({ ...currentDate, day: 31 });
                else
                    setCurrentDate({ ...currentDate, day: currentDate.day - 1 });
                break;

            case "month":
                if (currentDate.month === 1)
                    setCurrentDate({ ...currentDate, month: 12 });
                else
                    setCurrentDate({ ...currentDate, month: currentDate.month - 1 });
                break;

            case "year":
                if (currentDate.year === today.getFullYear())
                    return;
                else
                    setCurrentDate({ ...currentDate, year: currentDate.year - 1 });
                break;

            case "hour":
                if (currentDate.hour === 0)
                    setCurrentDate({ ...currentDate, hour: 23 });
                else
                    setCurrentDate({ ...currentDate, hour: currentDate.hour - 1 });
                break;

            case "minute":
                if (currentDate.minute === 0)
                    setCurrentDate({ ...currentDate, minute: 59 });
                else
                    setCurrentDate({ ...currentDate, minute: currentDate.minute - 1 });
                break;

            default:
                break;
        }
    }

    const saveBtnClicked = async () => {

        let today = new Date();
        loadPage(true);
        const settingsInfo = await getSiteSettings();
        loadPage(false);
        if (settingsInfo && settingsInfo.time_zone)
            today = new Date(moment().tz(settingsInfo.time_zone).format('MM/DD/YY HH:mm'));

        const laterDate = new Date();
        laterDate.setFullYear(currentDate.year);
        laterDate.setMonth(currentDate.month - 1);
        laterDate.setDate(currentDate.day);
        laterDate.setHours(currentDate.hour);
        laterDate.setMinutes(currentDate.minute);

        if (laterDate.getTime() < today.getTime()) {
            alert.error("You selected past date");
            return;
        }
        else {
            if (currentDate.day > new Date(currentDate.year, currentDate.month, 0).getDate()) {
                alert.error("Invalid date");
                return;
            }
            else {
                goToListFunc("later");
            }
        }
    }

    const clearBtnClicked = async () => {
        let date = new Date();
        loadPage(true);
        const settingsInfo = await getSiteSettings();
        loadPage(false);
        if (settingsInfo && settingsInfo.time_zone)
            date = new Date(moment().tz(settingsInfo.time_zone).format('MM/DD/YY HH:mm'));

        setCurrentDate({
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            shortWeek: Date.shortWeeks[date.getDay() - 1],
            day: date.getDate(),
            hour: date.getHours(),
            minute: date.getMinutes()
        })
    }

    const renderOfflineProductForm = () => (
        <>
            <MDBRow>
                <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                    <FormInput changeemail={invalidOffline.productName}
                        type="text"
                        change
                        label={'Product Name*'}
                        value={newOfflineInfo.name}
                        handleChange={(event) => setNewOfflineInfo({ ...newOfflineInfo, name: event.target.value })}
                        required />
                </MDBCol>
                <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                    <FormInput changeemail={invalidOffline.buyerName}
                        type="text"
                        label={"Buyer's name*"}
                        value={newOfflineInfo.buyerName}
                        handleChange={(event) => setNewOfflineInfo({ ...newOfflineInfo, buyerName: event.target.value })}
                        required />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                    <FormInput changeemail={invalidOffline.buyerEmail}
                        type="text" label={"Buyer's e-mail*"}
                        value={newOfflineInfo.buyerEmail}
                        handleChange={(event) => setNewOfflineInfo({ ...newOfflineInfo, buyerEmail: event.target.value })}
                        required />
                </MDBCol>
                <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                    <FormInput changeemail={invalidOffline.buyerPhone}
                        type="text" label={"Buyer's phone*"}
                        value={newOfflineInfo.buyerPhone}
                        handleChange={(event) => setNewOfflineInfo({ ...newOfflineInfo, buyerPhone: event.target.value })}
                        required />
                </MDBCol>

            </MDBRow>
            <MDBRow>
                <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                    <FormInput changeemail={invalidOffline.shippingAddress}
                        type="text" label={"Shipping address*"}
                        value={newOfflineInfo.shippingAddress}
                        handleChange={(event) => setNewOfflineInfo({ ...newOfflineInfo, shippingAddress: event.target.value })}
                        required />
                </MDBCol>
                <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                    <FormInput changeemail={invalidOffline.boughtPrice}
                        type="text" label={'Bought Price*'}
                        value={newOfflineInfo.boughtPrice}
                        handleChange={(event) => decimalFormat(event, "offlineBoughtPrice")}
                        required />
                </MDBCol>

            </MDBRow>
            <MDBRow>
                <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                    <FormInput changeemail={invalidOffline.soldPrice}
                        type="text" label={'Sold Price*'}
                        value={newOfflineInfo.soldPrice}
                        handleChange={(event) => decimalFormat(event, "offlineSoldPrice")}
                        required />
                </MDBCol>
                <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                    <FormInput changeemail={invalidOffline.upcCode}
                        type="text" label='UPC Code*'
                        value={newOfflineInfo.UPCCode}
                        handleChange={(event) => setNewOfflineInfo({ ...newOfflineInfo, UPCCode: event.target.value })}
                        required />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                    <FormInput changeemail={invalidOffline.units}
                        type="text"
                        label='Units*'
                        value={newOfflineInfo.units}
                        handleChange={(event) => numberFormat(event, "units")}
                        required />
                </MDBCol>

                <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                        <DatePicker changeemail={invalidInput.soldDate}
                             defaultvalue={moment('01/01/2022', dateFormat)}
                             
                          //  value={newWebinarInfo.fromDate}
                            format={dateFormat}
                            onChange={(date) =>
                                setNewOfflineInfo({ ...newWebinarInfo, soldDate: moment(date).format('YYYY-MM-DD')})
                            } 
                            required />
                            


                    </MDBCol>

{/* 

                <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                    <DateSelect changeemail={invalidOffline.soldDate}
                        label='Sold Date*'
                        value={newOfflineInfo.soldDate}
                        handleChange={(date) => setNewOfflineInfo({ ...newOfflineInfo, soldDate: date?.format('YYYY-MM-DD') })} /> */}
                    {/* <DatePicker onChange={(date) => setNewOfflineInfo({...newOfflineInfo, soldDate: date?.format('YYYY-MM-DD')})}
                                placeholder='Sold Date*'
                                style={{
                                    height: '50px',
                                    width: '100%',
                                    marginTop: 10,
                                    marginBottom: 20,
                                    backgroundColor: 'transparent',
                                    borderRadius: 50,
                                    color: '#a3a3a3',
                                    borderColor: '#a3a3a3',
                                    fontSize: '20px',
                                }} /> */}
                {/* </MDBCol> */}
            </MDBRow>
        </>
    )

    const renderOnlineProductForm = () => (
        <>
                    {
                userConfirm?
                    <div className="removeDialogWrapper">
                        <div className="removeDialog">
                            <h3 className="text-white text-center font-weight-bold mb-4">There are {participants ? participants : 'no'} participants, do you wish to continue?</h3>
                            <MDBRow center className="mobile-row">
                                <MDBCol size="6" md="4">
                                    <FormButton onClickFunc={() => goToListFunc("now")}>YES</FormButton>
                                </MDBCol>
                                <MDBCol size="6" md="4">
                                    <FormButton greyCol={true} onClickFunc={() => participantNumberChange()}>NO</FormButton>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </div> : null
            }
            <MDBRow>
                <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                    <FormInput changeemail={invalidInput.name}
                        type="text"
                        change
                        label={productType === ProductTypeEnum.WEBINAR ? 'Webinar Name*' : productType === ProductTypeEnum.PRODUCT ? 'Product Name*' : productType === ProductTypeEnum.FREE ? 'Webinar Name*' : ''}
                        value={newWebinarInfo.name}
                        handleChange={(event) => setNewWebinarInfo({ ...newWebinarInfo, name: event.target.value })}
                        required
                    />
                </MDBCol>
                {productType === ProductTypeEnum.FREE ?

                    <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                        <FormInput changeemail={invalidInput.UPCCode}
                            type="text" label='UPC Code*'
                            value={newWebinarInfo.UPCCode}
                            handleChange={(event) => setNewWebinarInfo({ ...newWebinarInfo, UPCCode: event.target.value })}
                            required
                        />
                    </MDBCol>
                    :
                    <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                        <FormSelect isInvalid={invalidInput.category}
                            options={categorySelect} label="Category*"
                            showSelectBox={() => setCategorySelectShow(!categorySelectShow)}
                            selectOption={(event) => {
                                setCategorySelectShow(false);
                                setCurrentCategorySelect(event.target.id);
                            }}
                            optionShow={categorySelectShow}
                            placeholder={currentCategorySelect} />
                    </MDBCol>
                }

            </MDBRow>
            {productType === ProductTypeEnum.FREE ?
                <MDBRow>
                    <MDBCol className="formCol">
                        <textarea rows="5"
                            className={`${invalidInput.description && 'invalid'}`}
                            value={newWebinarInfo.description}
                            onChange={(event) => setNewWebinarInfo({ ...newWebinarInfo, description: event.target.value })}
                            placeholder="Description">
                        </textarea>
                    </MDBCol>

                </MDBRow>

                :
                <MDBRow>
                    <MDBCol className="formCol" size="12" sm="12" md={colNumber} lg={colNumber}>
                        <FormInput changeemail={invalidInput.seatNumber}
                            type="text"
                            label={productType === ProductTypeEnum.WEBINAR ? 'Number of Seats*' : productType === ProductTypeEnum.PRODUCT ? 'Inventory*' : ''}
                            value={newWebinarInfo.seatNumber}
                            handleChange={(event) => numberFormat(event, "seatNumber")}
                            required />
                    </MDBCol>
                    {
                        newWebinarInfo.seatNumber !== '' ?
                            <MDBCol className="formCol" size="12" sm="12" md="4" lg="4">
                                <FormInput
                                    changeemail={invalidInput.hotSeatNumber}
                                    type="text"
                                    label='Hot Seat Number'
                                    value={newWebinarInfo.hotSeatNumber}
                                    handleChange={(event) => numberFormat(event, "hotSeatNumber")}
                                />
                            </MDBCol>
                            :
                            ""

                    }
                    <MDBCol className="formCol" size="12" sm="12" md={colNumber} lg={colNumber}>
                        <FormInput changeemail={invalidInput.seatPrice}
                            type="text"
                            label={productType === ProductTypeEnum.WEBINAR ? 'Price per Seat*' : productType === ProductTypeEnum.PRODUCT ? 'Price per Unit*' : ''}
                            value={newWebinarInfo.seatPrice}
                            handleChange={(event) => decimalFormat(event, "seatPrice")}
                            required />
                    </MDBCol>
                </MDBRow>}
            {productType === ProductTypeEnum.FREE ?
                <MDBRow>
                  {/*   <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                        <DateSelect changeemail={invalidInput.fromDate}
                            label='From*'
                            value={newWebinarInfo.fromDate}
                            handleChange={(date) => setNewWebinarInfo({ ...newWebinarInfo, fromDate: date?.format('MM/DD/YYYY') })} 
                            required />
                    </MDBCol>
                    <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                        <DateSelect changeemail={invalidOffline.toDate}
                            label='To*'
                            value={newWebinarInfo.toDate}
                            handleChange={(date) => setNewWebinarInfo({ ...newWebinarInfo, toDate: date?.format('MM/DD/YYYY') })}
                            required />
                    </MDBCol>  */}
                    <>
              {console.log("dateFormat."+newWebinarInfo)}
              
              
               </>
                    

                        <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                        <DatePicker changeemail={invalidInput.fromDate}
                             defaultvalue={moment('01/01/2022', dateFormat)}
                             
                          //  value={newWebinarInfo.fromDate}
                            format={dateFormat}
                            onChange={(date) =>
                               setNewWebinarInfo({ ...newWebinarInfo, fromDate: moment(date).format('YYYY-MM-DD')})
                            } 
                            required />
                            


                    </MDBCol>
            
                    <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                        <DatePicker changeemail={invalidOffline.toDate}
                        format={dateFormat}
                            //label='To*'
                             defaultvalue={moment('10/05/2022', dateFormat)}
                           // value={newWebinarInfo.fromDate}
                               onChange={(date) =>
                               setNewWebinarInfo({ ...newWebinarInfo, toDate: moment(date).format('YYYY-MM-DD')})
                            } 
                            required />
                    </MDBCol>  
                </MDBRow> :
                <MDBRow>
                    <MDBCol className="formCol" size="12" sm="12" md="6" lg="6" xl={productType === ProductTypeEnum.WEBINAR ? "6" : "4"}>
                        <FormInput changeemail={invalidInput.boughtPrice}
                            type="text"
                            label='Bought for*'
                            value={newWebinarInfo.boughtPrice}
                            handleChange={(event) => decimalFormat(event, "boughtPrice")}
                            required />
                    </MDBCol>
                    {productType === ProductTypeEnum.WEBINAR &&
                        <MDBCol className="formCol" size="12" sm="12" md="6" lg="6" xl="6">
                            <FormInput changeemail={invalidInput.UPCCode}
                                type="text"
                                label='UPC Code*'
                                value={newWebinarInfo.UPCCode}
                                handleChange={(event) => setNewWebinarInfo({ ...newWebinarInfo, UPCCode: event.target.value })}
                                required />
                        </MDBCol>
                    }
                    {productType === ProductTypeEnum.PRODUCT &&
                        <Fragment>
                            <MDBCol className="formCol" size="12" sm="12" md="6" lg="6" xl="4">
                                <FormInput changeemail={invalidInput.shippingPrice}
                                    type="text"
                                    label='Shipping Price'
                                    value={newWebinarInfo.shippingPrice}
                                    handleChange={(event) => decimalFormat(event, "shippingPrice")}
                                    required />
                            </MDBCol>
                            <MDBCol middle className="formCol" size="12" sm="12" md="6" lg="6" xl="4">
                                <div className="taxable-wrapper">
                                    <p>Item Not Taxable</p>
                                    <Switch onColor="#57bd7a"
                                        onChange={() => setNewWebinarInfo({ ...newWebinarInfo, taxable: !newWebinarInfo.taxable })}
                                        checked={!newWebinarInfo.taxable} />
                                </div>
                            </MDBCol>
                        </Fragment>
                    }
                </MDBRow>}
            {productType === ProductTypeEnum.PRODUCT &&
                <MDBRow>
                    <MDBCol className="formCol" size="12" sm="12" md="6" lg="6">
                        <FormInput changeemail={invalidInput.UPCCode}
                            type="text" label='UPC Code*'
                            value={newWebinarInfo.UPCCode}
                            handleChange={(event) => setNewWebinarInfo({ ...newWebinarInfo, UPCCode: event.target.value })}
                            required />
                    </MDBCol>
                </MDBRow>
            }
            {productType === ProductTypeEnum.FREE ?
                <MDBCol>
                </MDBCol> :
                <MDBRow>
                    <MDBCol className="formCol">
                        <textarea rows="5"
                            className={`${invalidInput.description && 'invalid'}`}
                            value={newWebinarInfo.description}
                            onChange={(event) => setNewWebinarInfo({ ...newWebinarInfo, description: event.target.value })}
                            placeholder="Description">
                        </textarea>
                    </MDBCol>
                </MDBRow>}
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className="dragBorder">
                    <div className="dragWrapper text-center">
                        <p>DROP PRODUCT PICUTRE HERE </p>
                        <p>OR <span className="text-white">CHOOSE PICTURE</span></p>
                    </div>
                    {
                        activeImage && <div className="activeImg">
                            <img
                                src={activeImage.preview}
                            />
                            <label>Active</label>
                        </div>
                    }
                </div>
            </div>
            <aside style={thumbsContainer}>
                {thumbs}
            </aside>
        </>
    )


    return (
        <div className="addNewProduct">
            <MDBRow end>
                <MDBCol size="6" sm="6" md="4" lg="3" xl="2">
                    <FormButton greyCol={true} onClickFunc={() => setAddNewClicked(false)}><MDBIcon icon="reply-all" /> CANCEL</FormButton>
                </MDBCol>
            </MDBRow>
            <h3 className="text-center text-white font-weight-bold mb-4">Add New Product</h3>
            <MDBRow center className="mb-4">
                <MDBCol size="12" sm="12" md="6" lg="5">
                    <AddNewProductTypeButton prodType={ProductTypeEnum.WEBINAR} active={productType === ProductTypeEnum.WEBINAR} onClickFunc={() => {
                        setProductType(ProductTypeEnum.WEBINAR);
                    }} />
                </MDBCol>
                <MDBCol size="12" sm="12" md="6" lg="5">
                    <AddNewProductTypeButton prodType={ProductTypeEnum.PRODUCT} active={productType === ProductTypeEnum.PRODUCT} onClickFunc={() => {
                        setProductType(ProductTypeEnum.PRODUCT);
                    }} />
                </MDBCol>
                <MDBCol size="12" sm="12" md="6" lg="5">
                    <AddNewProductTypeButton prodType={ProductTypeEnum.OFFLINE} active={productType === ProductTypeEnum.OFFLINE} onClickFunc={() => {
                        setProductType(ProductTypeEnum.OFFLINE);
                    }} />
                </MDBCol>
                <MDBCol size="12" sm="12" md="6" lg="5">
                    <AddNewProductTypeButton prodType={ProductTypeEnum.FREE} active={productType === ProductTypeEnum.FREE} onClickFunc={() => {
                        setProductType(ProductTypeEnum.FREE);
                    }} />
                </MDBCol>
            </MDBRow>

            

            {
                productType === ProductTypeEnum.OFFLINE
                    ? renderOfflineProductForm()
                    : renderOnlineProductForm()
            }

            {
                productType === ProductTypeEnum.OFFLINE ?
                    <MDBRow center>
                        <MDBCol size="10" sm="7" md="7" lg="5">
                            <FormButton onClickFunc={addOfflineSaleHandler}>ADD OFFLINE SALE</FormButton>
                        </MDBCol>
                    </MDBRow> 
                    
                    :

                    <MDBRow center>
                        <MDBCol size="10" sm="5" md="5" lg="3">
                            <FormButton greyCol={ productType === ProductTypeEnum.FREE ? listStatus !== "now" && false: listStatus !== "now" && true} onClickFunc={() => productType === ProductTypeEnum.FREE ? participantNumber() : goToListFunc("now")}>{
	                        productType === ProductTypeEnum.FREE ? "NEXT" : "LIST NOW" }</FormButton>
                        </MDBCol>
                        {productType === ProductTypeEnum.FREE? null :
                                  <MDBCol size="10" sm="5" md="5" lg="3">
                                  <FormButton greyCol={listStatus !== "later" && true} onClickFunc={() => setListStatus("later")}>LIST LATER</FormButton>
                                  
                              </MDBCol>
                        }
                  
                        {
                            productType === ProductTypeEnum.WEBINAR && <MDBCol size="10" sm="5" md="5" lg="3">
                                <FormButton greyCol={listStatus !== "queue" && true} onClickFunc={() => goToListFunc("queue")}>ADD TO QUEUE</FormButton>
                            </MDBCol>
                        }
                        
                    </MDBRow>
            }

            {
                productType !== ProductTypeEnum.OFFLINE && productType !== ProductTypeEnum.FREE && listStatus === "later" && <MDBRow center>
                    <MDBCol size="12" sm="12" md="10" lg="8">
                        <div className="custom-datepicker mt-3">
                            <div className="datepicker-header">
                                <label className="text-white">{`${currentDate.month < 10 ? "0" : ""}${currentDate.month}`}/{`${currentDate.day < 10 ? "0" : ""}${currentDate.day}`}/{currentDate.year}</label>
                                <label className="text-white">{`${currentDate.hour < 10 ? "0" : ""}${currentDate.hour}`}:{`${currentDate.minute < 10 ? "0" : ""}${currentDate.minute}`}</label>
                            </div>
                            <div className="mt-4 text-center">
                                <p className="text-white mb-1">State Date &amp; Time</p>
                                <p>{currentDate.shortWeek}, {Date.shortMonths[currentDate.month - 1]} {`${currentDate.day < 10 ? "0" : ""}${currentDate.day}`}, {currentDate.year} <span>{`${currentDate.hour < 10 ? "0" : ""}${currentDate.hour}`}:{`${currentDate.minute < 10 ? "0" : ""}${currentDate.minute}`}</span></p>
                            </div>
                            <div className="date-select mt-4 mb-4">
                            
                                <div className="text-center">
                                    <p className="pointer" onClick={() => plusDateFunc("month")}>+</p>
                                    <p>{Date.shortMonths[currentDate.month - 1]}</p>
                                    <p className="pointer" onClick={() => minusDateFunc("month")}>-</p>
                                </div>
                                <div className="text-center">
                                    <p className="pointer" onClick={() => plusDateFunc("day")}>+</p>
                                    <p>{`${currentDate.day < 10 ? "0" : ""}${currentDate.day}`}</p>
                                    <p className="pointer" onClick={() => minusDateFunc("day")}>-</p>
                                </div>
                                <div className="text-center">
                                    <p className="pointer" onClick={() => plusDateFunc("year")}>+</p>
                                    <p>{currentDate.year}</p>
                                    <p className="pointer" onClick={() => minusDateFunc("year")}>-</p>
                                </div>
                                <div className="text-center">
                                    <p className="pointer" onClick={() => plusDateFunc("hour")}>+</p>
                                    <p>{`${currentDate.hour < 10 ? "0" : ""}${currentDate.hour}`}</p>
                                    <p className="pointer" onClick={() => minusDateFunc("hour")}>-</p>
                                </div>
                                <div className="text-center">
                                    <p className="pointer" onClick={() => plusDateFunc("minute")}>+</p>
                                    <p>{`${currentDate.minute < 10 ? "0" : ""}${currentDate.minute}`}</p>
                                    <p className="pointer" onClick={() => minusDateFunc("minute")}>-</p>
                                </div>
                            </div>
                            <MDBRow center>
                                <MDBCol size="5">
                                    <FormButton onClickFunc={() => saveBtnClicked()}>SAVE</FormButton>
                                </MDBCol>
                                <MDBCol size="5">
                                    <FormButton onClickFunc={() => clearBtnClicked()}>CLEAR</FormButton>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>
            }
        </div>
    )
}

const MapDispatchToProps = dispatch => ({
    getCurrentCategories: getCurrentCategories(dispatch),
    getFreeWebinarParticipants: getFreeWebinarParticipants(dispatch),
    addNewWebinar: addNewWebinar(dispatch),
    addFreeWebinar: addFreeWebinar(dispatch),
    addNewOfflineSale: addNewOfflineSale(dispatch),
    setAddNewClicked: flag => dispatch(setAddNewClicked(flag)),
    loadPage: flag => dispatch(loadPage(flag)),
    getSiteSettings: getSiteSettings(dispatch)
})

export default connect(null, MapDispatchToProps)(AddNewProductForm);
