import { AdminRolesActionTypes } from './admin-roles.types';

const INITIAL_SATE = {
    permissions: null,
    currentRoles: null,
    filterString: "",
}

const AdminRolesReducer = (state = INITIAL_SATE, action) => {
    switch (action.type) {
        case AdminRolesActionTypes.SET_PERMISSIONS:
            return {
                ...state, permissions: action.payload 
            }
        case AdminRolesActionTypes.SET_CURRENT_ROLES:
            return {
                ...state, currentRoles: action.payload 
            }
        case AdminRolesActionTypes.SET_FILTER_STRING: 
            return {
                ...state, filterString: action.payload
            }
        default:
            return state;
    }
}

export default AdminRolesReducer;