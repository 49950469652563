import UserManagementActionTypes from './user-management.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getProductItems = dispatch => async ( filterStr = null, limit = 10, offset = 0, fflFilter= '') => {

    try {
        const result = await axios.get( RestAPI.ORIGINAL_ENDPOINT + `admin/users-management?query_search=${filterStr}&&limit=${limit}&offset=${offset}&fflFilter=${fflFilter}`, { 
            headers: (await getMyTokenFunc())
        });
        dispatch(setProductItems(result.data));
        return result.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const createNewUser = dispatch => async (body) => {

    try {
        const result = await axios.post( RestAPI.ORIGINAL_ENDPOINT + `admin/roles/attach/${body.role}`, { userData: { ...body, email: body.email.toLowerCase()} }, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.data;
    } catch (error) {
        return error.response.data.message;    
    }
}

export const editUserInfo = dispatch => async (obj) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/users-management`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        return error.response.data.message;    
    }
}

export const deleteUserInfo = dispatch => async (id) => {

    try {
        const result = await axios.delete( RestAPI.ORIGINAL_ENDPOINT + `admin/users-management/${id}`, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const removeFFLSelected = dispatch => async (obj) => {

    try {
        const result = await axios.put( RestAPI.ORIGINAL_ENDPOINT + `admin/users-management/remove-ffl/`, obj, { 
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const setFilterString = filterStr => ({
    type: UserManagementActionTypes.SET_FILTER_STRING,
    payload: filterStr
})

export const setLimitPerPage = limit => ({
    type: UserManagementActionTypes.SET_LIMIT_PER_PAGE,
    payload: limit
})

export const setProductPageNum = num => ({
    type: UserManagementActionTypes.SET_PRODUCT_LIST_PAGE_NUM,
    payload: num
})


const setProductItems = items => ({
    type: UserManagementActionTypes.SET_PRODUCT_LIST_ITEMS,
    payload: items
})

export const setFFLFilter = check => ({
    type: UserManagementActionTypes.SET_FFL_FILTER,
    payload: check
})