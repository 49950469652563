import React, { useState, useEffect, Fragment} from 'react';
import { MDBIcon } from 'mdbreact';
import { getShippingItems, setNotesAction } from '../../../redux/shipping/shipping.action';
import { connect } from 'react-redux';
import { loadPage } from '../../../redux/user/user.action';
import { useAlert } from 'react-alert';

const NotesComponent = ({id, notesString, status, convertState, limit, offset, isNotFFL, setNotesAction, getShippingItems, loadPage, loadParent, setIsLoading}) => {
  
    const alert = useAlert();

    const [notesAdd, setNotesAdd] = useState("");

    useEffect(() => {
     notesString ? setNotesAdd(notesString) : setNotesAdd("");
    }, [notesString]);

    const setNotesFunc = async () => {
        const obj = {
            id: id,
            notes: notesAdd
        }
        setIsLoading(true)
        const result = await setNotesAction(obj);
        setIsLoading(false)
        if (result === "success"){
            await loadParent()
            alert.success("Set notes successfully",{
                delay: 2000,
                timeout: 2400
            });
        }
        else
            alert.error("Setting failed");
    }

    return (
        <div className="trackingWrapper">
            <input disabled={convertState !== "not_printed" } type="text" value={ notesAdd } onChange={event => setNotesAdd(event.target.value)}/>
            <button disabled={convertState !== "not_printed" } onClick={()=>setNotesFunc()}><MDBIcon icon="check" /></button>
        </div>
    )
}

const MapDispatchToProps = dispatch => ({
     setNotesAction: setNotesAction(dispatch),
    getShippingItems: getShippingItems(dispatch),
    loadPage: flag => dispatch(loadPage(flag))
})
export default connect(null, MapDispatchToProps)(NotesComponent);