import React, { useState, useEffect } from "react";
import "./shipping-page.style.scss";
import { MDBRow, MDBCol } from "mdbreact";
import CountPerPage from "../../components/table/count-per-page/count-per-page.component";
import ShippingPageButtons from "./pagination/pagination.component";
import { connect } from "react-redux";
import FormSelect from "../../components/form-select/form-select.component";
import TableFilterInput from "../../components/table/filter-input/filter-input.component";
import {
  getShippingItems,
  setLimitPerPage,
  setCurrentStateFilter,
  setProductPageNum,
  setFilterStr,
  setOrder,
  sendToShippingFiltered,
} from "../../redux/shipping/shipping.action";
import ShippingRowItem from "./row-item/row-item.component";
import LoaderComponent from "../../components/Loader/loader.component";
import { getFFLLicencesFiltered } from "../../redux/ffl-db/ffl-db.action";
import { useAlert } from "react-alert";
import FFLModal from "../../components/ffl-modal/ffl-modal.component";
import { sendToShipping } from "../../redux/completed-webinar/completed-webinar.action";
const ShippingPage = ({
  product_items,
  limitPerPage,
  pageNum,
  getShippingItems,
  setLimitPerPage,
  isShowSideBar,
  currentStateFilter,
  setCurrentStateFilter,
  setProductPageNum,
  filterString,
  getFFLLicencesFiltered,
  isDistributor,
  setFilterString,
  not_printed_count,
  order,
  setOrder,
  sendToShipping,
  sendToShippingFiltered
}) => {

  const sum = product_items.pendingItems || 0;
  const alert = useAlert();

  // for select box of convert status
  const stateFilter = ["All", "Not Printed", "Printed", "Shipped"];
  const [stateFilterShow, setStateFilterShow] = useState(false);
  const [productsOrdered, setProductsOrdered] = useState([])
  // for row counts per page
  const countSelect = [10, 20, 30, 50];
  const [countSelectShow, setCountSelectShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([])
  const [showFFLModal, setShowFFLModal] = useState(false)
  const [errorWithFFL, setErrorWithFFL] = useState(false);
  const [childNeedToBeUpdated, setChildNeedToBeUpdated] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [type, setType] = useState("webinar");

  useEffect(() => {
      return () => {
        setProductPageNum(1)
        setFilterString("")
      }
  }, [])

  useEffect(() => {
    setProductPageNum(1)
  }, [])

  /* useEffect(() => {
    if(currentStateFilter === null){
      setCurrentStateFilter("All")
    }
  }, []) */
  
  const load = async (currentStateFilter, limitPerPage, pageNum, filterString, isDistributor, order) => {
    setLoading(true);
    const result = await getShippingItems(
      currentStateFilter === "Not Printed"
        ? "not_printed"
        : currentStateFilter === "Printed"
        ? "printed"
        : currentStateFilter === "Shipped"
        ? "shipped"
        : "all",
      limitPerPage === "All" ? "all" : limitPerPage,
      limitPerPage === "All" ? 0 : limitPerPage * (pageNum - 1),
      filterString,
      (isDistributor = "false"),
      order
    );
    setProducts(result)
    setLoading(false);
    if(((pageNum-1)*limitPerPage)>result?.count){setProductPageNum(1)};
  }

  const handleFFLChange = async (modifiedFFL,isEdit,oneTimeChange,fflNotRequired) => {
    
    let purchase_or_winner_id
    let ffl_id
    let ffl_one_time
    let ffl_not_required

    if(type === "webinar"){
      purchase_or_winner_id=currentRow?.webinar_shipping?.id
    } 
    // else if(type === "physical"){
    //   purchase_or_winner_id=currentRow?.physical_shipping?.id
    // } else if(type === "offline"){
    //   purchase_or_winner_id=currentRow?.offline_shipping?.id
    // }

    if(isEdit){
        ffl_id=modifiedFFL.id
        oneTimeChange?ffl_one_time=true:ffl_one_time=false
    } else {
        purchase_or_winner_id=modifiedFFL.id
        oneTimeChange=null
        fflNotRequired? ffl_id=null : ffl_id=modifiedFFL.ffl_databases.id
    }

    const obj = {
        purchase_or_winner_id,
        ffl_id: ffl_id,
        ffl_not_required: fflNotRequired,
        ffl_one_time:oneTimeChange,
        ffl_edited:isEdit,
        type,
    }
    
    try {
      setLoading(true);
      const result = await sendToShippingFiltered(obj);
      setLoading(false);
      if (result === "success"){
        setLoading(true);
        await load(currentStateFilter, limitPerPage, pageNum, filterString, isDistributor, order);
        setLoading(false);
        alert.success("FFL is assigned successfully",{
          timeout: 3000,
        });
        setShowFFLModal(false)
      } else{
          alert.error(result || "Failed assigning",{
            timeout: 2000,
          });
      }
    } catch (error) {
      alert.error("Failed assigning",{
        timeout: 2000,
      });
    }
    setShowFFLModal(false)
}

  useEffect(() => {
    if(currentStateFilter !== null) {
      load(currentStateFilter, limitPerPage, pageNum, filterString, isDistributor, order);
    }
  }, [currentStateFilter, limitPerPage, pageNum, filterString, order]);

  const [isMobileSize, setIsMobileSize] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1025) setIsMobileSize(true);
      else setIsMobileSize(false);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);
  
  useEffect(() => {
    if(products && products?.rows?.length > 0){
      let filtered = products.rows.map((item,indexTemp) => {
        return {
          ...item,
          indexParent: indexTemp + 1,
          children: item.children.map((child, index) => {
            return {
              ...child,
              indexParent: indexTemp + 1
            }
          })
        }
      })
      setProductsOrdered(filtered)
    }
  }, [products])
  

  return (
    <>
    
    {
      showFFLModal && (
        <FFLModal
          setErrorWithFFL={setErrorWithFFL}
          changeFFL={handleFFLChange}
          alert={alert} 
          getFFLLicencesFiltered={getFFLLicencesFiltered} 
          closeModal={() => setShowFFLModal(false)} 
          loading={loading} 
          setLoading={setLoading}
          errorWithFFL={errorWithFFL}
        />
      )
    }
    
    {
      loading && (
        <LoaderComponent />
      )
    }
    <div
      className="product-listing-page"
      style={{
        width: isShowSideBar && window.innerWidth > 600 && "calc(100% - 300px)",
      }}
    >
      <MDBRow className="headerRow">
        <MDBCol size="4" className="text-white text-left">
          <h2 className="text-white font-weight-bold">Shipping Page</h2>
        </MDBCol>
        <MDBCol size="6" className="text-white text-left right-title-texts">
          <h3 className="font-weight-bold text-1">Pending shipments: <span>{sum}</span></h3>
          <h3 className="font-weight-bold text-2">/</h3>
          <h3 className="font-weight-bold text-3">Not Printed: <span>{not_printed_count}</span></h3>

        </MDBCol>
      </MDBRow>

      <div className="section">
        <div className="toolbar pt-1">
          <div className="leftDiv flexTop">
            <div>
              <CountPerPage
                options={countSelect}
                showSelectBox={() => setCountSelectShow(!countSelectShow)}
                selectOption={(event) => {
                  setCountSelectShow(false);
                  setLimitPerPage(event.target.id);
                }}
                optionShow={countSelectShow}
                placeholder={limitPerPage}
              />
              <label className="ml-1">Per Page</label>
            </div>
            <div className="filterIndex shipping search-filter-input-custom">
              <FormSelect
                options={stateFilter}
                showSelectBox={() => setStateFilterShow(!stateFilterShow)}
                selectOption={(event) => {
                  setStateFilterShow(false);
                  setCurrentStateFilter(event.target.id);
                }}
                optionShow={stateFilterShow}
                placeholder={currentStateFilter}
              />
            </div>
            <div className="search-filter-input-custom">
              <TableFilterInput
                placeholder="search shipping"
                str={filterString}
                setFilterString={setFilterString}
              />
            </div>
          <button
            className='order-products-button'
            onClick={() => {
              let temp = order === 'new' ? 'ASC' : order === 'ASC' ? 'DESC' : 'ASC'
              setOrder(temp)
            }}>
            {order === 'ASC' ? 'order by new' : 'order by old'}
          </button>
          </div>
          <ShippingPageButtons
            count={
              limitPerPage === "All"
                ? 1
                : Math.ceil(products?.count / limitPerPage)
            }
            currentIndex={pageNum}
            setProductPageNum={setProductPageNum}
          />
        </div>
        {isMobileSize ? (
          <MDBRow className="table-header">
            <MDBCol size="6" className="text-white text-center">
              Shipping Address
            </MDBCol>
            <MDBCol size="4" className="text-white text-center">
              FFL Name
            </MDBCol>
          </MDBRow>
        ) : (
          <MDBRow className="table-header">
            <MDBCol size="4" className="text-white thumb">
              Shipping Address
            </MDBCol>
            <MDBCol size="4" className="text-white text-left">
              FFL Number
            </MDBCol>
            <MDBCol size="2" className="text-white text-center">
              Item Count
            </MDBCol>
            <MDBCol size="1" className="text-white text-center">
              Convert Status
            </MDBCol>
          </MDBRow>
        )}
        {products &&
        products.rows &&
        products.rows.length > 0 &&
        productsOrdered && productsOrdered.length > 0
        ? (
          productsOrdered.map((item, index) => (
            <ShippingRowItem
              key={index}
              item={item}
              index={index}
              statusFilter={currentStateFilter}
                pageLimit={limitPerPage === "All" ? "all" : limitPerPage}
              pageOffset={
                  limitPerPage === "All" ? 0 : limitPerPage * (pageNum - 1)
              }
              showEditFFLModal={(type)=> {
                setShowFFLModal(true)
                setType(type?.product_type)
              }}
              setCurrentRow={setCurrentRow}
              loadDataFromParent={async () => {
                await load(currentStateFilter, limitPerPage, pageNum, filterString, isDistributor, order);
                alert.success("Convert status successfully");
              }}
              // trackingNumber = {item.tracking_number}
              loadParent={async () => {
                setLoading(true);
                await load(currentStateFilter, limitPerPage, pageNum, filterString, isDistributor, order);
                setLoading(false);
              }}
              setIsLoading={setLoading}
              isMobile={isMobileSize}
              setLoading={setLoading}
              loading={loading}
            />
            
          ))
        ) : (
          <div className="non-row text-center">No Data</div>
        )}
      </div>
    </div>
    </>
  );
};

const MapStateToProps = ({
  shipping_reducer: {
    product_items,
    limitPerPage,
    pageNum,
    currentStateFilter,
    filterString,
    order
  },
  user: { isShowSideBar },
}) => ({
  product_items,
  limitPerPage,
  filterString,
  pageNum,
  isShowSideBar,
  currentStateFilter,
  not_printed_count: product_items.not_printed_count,
  order
});

const MapDispatchToProps = (dispatch) => ({
  getShippingItems: getShippingItems(dispatch),
  setFilterString: (str) => dispatch(setFilterStr(str)),
  setLimitPerPage: (count) => dispatch(setLimitPerPage(count)),
  setCurrentStateFilter: (str) => dispatch(setCurrentStateFilter(str)),
  setProductPageNum: (num) => dispatch(setProductPageNum(num)),
  setOrder: (str) => dispatch(setOrder(str)),
  getFFLLicencesFiltered : getFFLLicencesFiltered(dispatch),
  sendToShipping: sendToShipping(dispatch),
  sendToShippingFiltered: sendToShippingFiltered(dispatch),
});

export default connect(MapStateToProps, MapDispatchToProps)(ShippingPage);

