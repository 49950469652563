import React, { useState, useEffect, Fragment } from 'react';
import { useForm, Controller } from "react-hook-form";
import './email-settings-page.style.scss';
import FormButton from '../../components/form-button/form-button.component';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import FormInput from '../../components/form-input/form-input.component';
import FormSelect from '../../components/form-select/form-select.component';
import { connect } from 'react-redux';
import { getEmailSettings,updateEmailSettings } from '../../redux/email-settings/email-settings.action';
import { loadPage } from '../../redux/user/user.action';
import { useAlert } from 'react-alert';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';
import EditorConvertToHTML from '../../components/rich-text-editor/rich-text-editor.component';


// import React, { Component } from 'react';
// import { EditorState, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';

const EmailSettingsPage = ({loadPage, isShowSideBar,getEmailSettings,updateEmailSettings}) => {
    const [newProductTemplate, setNewProductTemplate] = useState('');
    const [winnerTemplate, setWinnerTemplate] = useState('');
    const [freeTemplate, setFreeTemplate] = useState('');
    const alert = useAlert();
    const [isLoading, setIsLoading] = useState({
        newProductTemplate: false,
        winnerTemplate: false,
        freeTemplate: false
    });
    const [isMobileSize, setIsMobileSize] = useState(false);
    const [isEdit, setIsEdit] = useState(false);    

    useEffect(() => {
        
        setIsEdit(JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'settingsEdit'));

        function handleResize() {
            if (window.innerWidth < 767)
                setIsMobileSize(true);
            else
                setIsMobileSize(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        
        async function load() {
            loadPage(true);
            const settingsInfo = await getEmailSettings();
            console.log("settingsInfo");
            console.log(settingsInfo);
            console.log(settingsInfo.find(e=>e.template_name=='new_product_template')!=undefined)
            console.log(settingsInfo.find(e=>e.template_name=='free_webinar_template')!=undefined)

            setNewProductTemplate(settingsInfo.find(e=>e.template_name=='new_product_template')!=undefined ? settingsInfo.find(e=>e.template_name=='new_product_template').HtmlPart : '');
            setWinnerTemplate(settingsInfo.find(e=>e.template_name=='matas_winner')!=undefined ? settingsInfo.find(e=>e.template_name=='matas_winner').HtmlPart : '');
            setFreeTemplate(settingsInfo.find(e=>e.template_name=='free_webinar_template')!=undefined ? settingsInfo.find(e=>e.template_name=='free_webinar_template').HtmlPart : '')
            loadPage(false);
        }
        load();
    }, []);


    const updateNewProductTemplate = async () => {
        if (isLoading.newProductTemplate)
            return;
        
        const newTemplateValue = {
            template_value: newProductTemplate,
            template_name:'new_product_template'
        }

        setIsLoading({...isLoading, newProductTemplate: true});
        const result = await updateEmailSettings(newTemplateValue);
        
        if (result === "success")
            alert.success("Updated New Product Template successfully");
        else
            alert.error("Failed Updating");

        setIsLoading({...isLoading, newProductTemplate: false}); 
    }

    const changeNewProductTemplateEditorHandler=(value)=>{
        const newProductEditorValue=value;
        setNewProductTemplate(newProductEditorValue)
    }


    const updateWinnerTemplate = async () => {
        if (isLoading.winnerTemplate)
            return;
        
        const newTemplateValue = {
            template_value: winnerTemplate,
            template_name:'matas_winner'
        }

        setIsLoading({...isLoading, winnerTemplate: true});
        const result = await updateEmailSettings(newTemplateValue);

        if (result === "success")
            alert.success("Updated Winners template successfully");
        else
            alert.error("Failed Updating");

        setIsLoading({...isLoading, winnerTemplate: false}); 
    }


    const changeWinnerTemplateEditorHandler=(value)=>{
        const winnerEditorValue=value;
        setWinnerTemplate(winnerEditorValue)
    }

    const updateFreeTemplate = async () => {
        if (isLoading.freeTemplate)
            return;
        
        const newTemplateValue = {
            template_value: freeTemplate,
            template_name:'free_webinar_template'
        }

        setIsLoading({...isLoading, freeTemplate: true});
        const result = await updateEmailSettings(newTemplateValue);

        if (result === "success")
            alert.success("Updated Free Webinar template successfully");
        else
            alert.error("Failed Updating");

        setIsLoading({...isLoading, freeTemplate: false}); 
    }

    
    const changeFreeTemplateEditorHandler=(value)=>{
        const freeEditorValue =value;
        setFreeTemplate (freeEditorValue)
    }

    return (
        <div className="settings-page" style={{width: isShowSideBar && !isMobileSize && 'calc(100% - 300px)'}}>
            <div className="general-setting mb-4">
                <h2 className="text-white font-weight-bold mb-4">Email Settings</h2>           

                <h4 className="text-white font-weight-bolder mt-4">New Product Template</h4>
                <p className="grey-text mb-4">The admin can update the template for the sending email for new posted products (webinars/physical).</p>
                {
                    newProductTemplate && 
                    <EditorConvertToHTML 
                        onChangeEditor={changeNewProductTemplateEditorHandler}
                        value={newProductTemplate}
                    />
                }

                {
                    isEdit && 
                    <div className="btn-200">
                        <FormButton isLoading={isLoading.newProductTemplate} onClickFunc={()=>updateNewProductTemplate()}>UPDATE</FormButton>
                    </div>
                }
                <hr className="mt-4"/>

                <h4 className="text-white font-weight-bolder mt-4">Winner Template</h4>
                <p className="grey-text mb-4">The admin can update the template for the sending email to selected webinar's winners.</p>

                {
                    winnerTemplate && 
                    <EditorConvertToHTML 
                        onChangeEditor={changeWinnerTemplateEditorHandler}
                        value={winnerTemplate}
                    />
                }

                {
                    isEdit && 
                    <div className="btn-200">
                        <FormButton isLoading={isLoading.winnerTemplate} onClickFunc={()=>updateWinnerTemplate()}>UPDATE</FormButton>
                    </div>
                }

                <h4 className="text-white font-weight-bolder mt-4">Free Webinars Template</h4>
                <p className="grey-text mb-4">The admin can update the template for free webinars.</p>

                {
                    freeTemplate && 
                    <EditorConvertToHTML 
                        onChangeEditor={changeFreeTemplateEditorHandler}
                        value={freeTemplate}
                    />
                }

                {
                    isEdit && 
                    <div className="btn-200">
                        <FormButton isLoading={isLoading.freeTemplate} onClickFunc={()=>updateFreeTemplate()}>UPDATE</FormButton>
                    </div>
                }

            </div>
        </div>
    )
}





const MapStateToProps = ({ user: {isShowSideBar}}) => ({
    isShowSideBar
})

const MapDispatchToProps = dispatch => ({
    loadPage: flag => dispatch(loadPage(flag)),
    getEmailSettings: getEmailSettings(dispatch),
    updateEmailSettings: updateEmailSettings(dispatch),
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(EmailSettingsPage));