import React, { useEffect, useState } from 'react';
import './filter-input.style.scss';
import { MDBIcon } from 'mdbreact';

const TableFilterInput = ({ str, setFilterString, placeholder = "Search by product name" }) => {

    const [filterStr, setFilterStr] = useState( str || "" );
    
    useEffect(() => {
        setFilterStr("");
        setFilterString("");
    }, [])
    
    
    return (
        <div className="table-filter-input">
            <input type="text" placeholder={placeholder} value={decodeURIComponent(filterStr)} onChange={(e)=>setFilterStr(encodeURIComponent(e.target.value))}/>
            <button onClick={()=>setFilterString(deleteSpace(filterStr))}><span className="searchSpan">SEARCH</span><MDBIcon icon="search" className="searchBtn"/></button>
        </div>
    )
}

function deleteSpace(x){
    if(x.slice(-3)=='%20'){
        return x.slice(0,-3); 
    } else{
        return x;
    }
     
}
export default TableFilterInput;