import React, { useEffect, useState } from "react";

// Components
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './rich-text-editor.style.scss';

const EditorConvertToHTML=(props) => {
  const [editorState, setEditorState] = useState('');
  
  useEffect(() => {
    setEditorState(
      props.value?
        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.value))):EditorState.createEmpty()
    )
  }, []);

  const onEditorStateChange=(editorState) => {
    setEditorState(editorState);
    return props.onChangeEditor(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  return (
    <React.Fragment>
        <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
        />
    </React.Fragment>
  );
};

export default EditorConvertToHTML;


// import React, { Component } from 'react';
// import { EditorState, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import './rich-text-editor.style.scss';

// class EditorConvertToHTML extends Component {
//     constructor(props) {
//         super(...arguments);
//         this.state = {
//             editorState: EditorState.createEmpty(),
//         };
//         this.onEditorStateChange = (editorState) => {
//             console.log("this.onEditorStateChange ")
//             console.log(editorState)
//             console.log("editorState.getCurrentContent()")
//             console.log(editorState.getCurrentContent())
//             this.setState({
//                 editorState,
//             });
//         };
//     }
//     render() {
//         const { editorState } = this.state;
//         return (
//             <div>
//                 <Editor
//                     editorState={editorState}
//                     toolbarClassName="toolbarClassName"
//                     wrapperClassName="wrapperClassName"
//                     editorClassName="editorClassName"
//                     onEditorStateChange={this.onEditorStateChange}                  
//                 />
//                 <textarea
//                     disabled
//                     value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
//                 />
//             </div>
//         );
//     }
// }

// export default EditorConvertToHTML;




// import React, { Component } from 'react';
// import { EditorState, Modifier } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// // import styled from 'styled-components';

// // Following sample is based the article https://dev.to/rose/draft-js-simple-content-manipulation-b7a

// class EditorConvertToHTML extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {editorState: EditorState.createEmpty()};
//   }

//   componentDidMount() {
//     this.focusEditor();
//   }

//   setEditor = (editor) => {
//     this.editor = editor;
//   };

//   focusEditor = () => {
//     if (this.editor) {
//       this.editor.focusEditor();
//       console.log("1. Editor has the focus now");
//     }
//   };

//   onEditorStateChange = (editorState) => {
//     this.setState({
//       editorState,
//     });
//   };

//   sendTextToEditor = (text) => {
//     this.setState({editorState: this.insertText(text, this.state.editorState)});
//     this.focusEditor();
//   }

//   insertText = (text, editorState) => {
//     const currentContent = editorState.getCurrentContent(),
//           currentSelection = editorState.getSelection();

//     const newContent = Modifier.replaceText(
//       currentContent,
//       currentSelection,
//       text
//     );

//     const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
//     return  EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
//   }

//   render() {
//     const { editorState } = this.state;
//     return (
//       <>
//         {/* <EditorStyled> */}
//           <Editor
//             ref={this.setEditor}
//             editorState={editorState}
//             wrapperClassName="demo-wrapper"
//             editorClassName="demo-editor"
//             onEditorStateChange={this.onEditorStateChange}
//           />
//         {/* </EditorStyled> */}
//         <button type="button" onClick={this.sendTextToEditor.bind(this, 'Sample text to put in editor')}>Copy sample text to editor</button>
//       </>
//     );
//   }
// }

// export default EditorConvertToHTML;

// // const EditorStyled = styled.div`
// //   width: ${() => "calc(100% - 40px)"};
// //   min-height: 400px;
// //   margin: 20px;
// //   border: 1px solid black;
// // `;
