import { FaqItemsActionTypes } from './faq-items.types';
import axios from 'axios';
import { getMyTokenFunc, RestAPI, logOutFunc } from '../api-config';
import store from '../store';

export const getFaqItems = dispatch => async () => {
    try {
        const result = await axios.get(RestAPI.ORIGINAL_ENDPOINT + "general/faq");
        dispatch(setFaqItems(result.data.data));
    } catch (error) {
        console.log(error.message);
    }
}

export const addNewFaqItem = dispatch => async (obj) => {
    try {
        const result = await axios.post(RestAPI.ORIGINAL_ENDPOINT + "admin/faq", obj, {
            headers: (await getMyTokenFunc())
        });
        return result.data.data;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const editNewFaqItem = dispatch => async (id, obj) => {
    try {
        const result = await axios.patch(RestAPI.ORIGINAL_ENDPOINT + `admin/faq/${id}`, obj, {
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

export const deleteFaqItem = dispatch => async (id) => {
    try {
        const result = await axios.delete(RestAPI.ORIGINAL_ENDPOINT + `admin/faq/${id}`, {
            headers: (await getMyTokenFunc())
        });
        return result.data.message;
    } catch (error) {
        console.log(error?.response?.data?.message);    
    }
}

const setFaqItems = items => ({
    type: FaqItemsActionTypes.SET_FAQ_ITEMS,
    payload: items
})