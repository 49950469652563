import React, { useState, useEffect, Fragment, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import './admin-roles.style.scss';
import FormButton from '../../components/form-button/form-button.component';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import TableFilterInput from '../../components/table/filter-input/filter-input.component';
import FormInput from '../../components/form-input/form-input.component';
import { connect } from 'react-redux';
import { getCurrentRoles, addRole, updateRole, setFilterStr, getPermissions } from '../../redux/admin-roles/admin-roles.action';
import { loadPage } from '../../redux/user/user.action';
import { useAlert } from 'react-alert';
import { Select, Table } from 'antd';
import { xor } from 'lodash';
import { logOutFunc } from '../../redux/api-config';
import withPermissionChecking from '../../utils/HOC/withPermissionCheck';

const { Option } = Select;
const { Column } = Table;

function RoleDetails({ 
	item, 
	permissionsAll,
	updateRole,
	getCurrentRoles
}) {
	const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [selectPermissions, setSelectPermissions] = useState(
    item?.permissions?.map(({ id }) => id),
  );

	const [isEdit, setIsEdit] = useState(false);
	useEffect(() => {
		setIsEdit(JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'rolesEdit'));
	}, []);

  const userRoleID = JSON.parse(localStorage.getItem('userData'))?.role_id;

  const updateRoleFunc = useCallback(async() => {

    const body = {
      permissionIds: selectPermissions,
    };

    const { id } = item;
    setLoading(true);

    const result = await updateRole(id, body);
		if (result === "success") {
			alert.success("Updated Successfully");
			if (userRoleID === item.id) {
				logOutFunc();
			}
			await getCurrentRoles();
		}
		else
			alert.error(result);

		setLoading(false);

  }, [selectPermissions]);

  const newValue = useMemo(() => {
    return (
      selectPermissions.length === item.permissions.length &&
      selectPermissions.every((el) => item.permissions.map(({ id }) => id).includes(el))
    );
  }, [selectPermissions, item]);

  const changeSelect = useCallback(
    (newSelects) => {
      const id = xor(newSelects, selectPermissions);
      if (newSelects.length > selectPermissions.length) {
        const currentItem = permissionsAll.find((el) => el.id === id[0]);
        if (currentItem.name.includes('View')) {
          if (currentItem.name === 'seatsRefundView') {
            newSelects.push(permissionsAll.find((el) => el.name === 'productView').id);
          }
        } else if (currentItem.name.includes('Edit')) {
          const parent = permissionsAll.find(
            (el) => el.name === currentItem.name.replace('Edit', 'View'),
          );
          if (!newSelects.includes(parent.id)) {
            newSelects.push(parent.id);
            setSelectPermissions(newSelects);
          }
        } else if (currentItem.name.includes('Delete')) {
          const parent = permissionsAll.find(
            (el) => el.name === currentItem.name.replace('Delete', 'View'),
          );
          if (!newSelects.includes(parent.id)) {
            newSelects.push(parent.id);
          }
        } else if (currentItem.name.includes('Create')) {
          const parent = permissionsAll.find(
            (el) => el.name === currentItem.name.replace('Create', 'sView'),
          );
          if (!newSelects.includes(parent.id)) {
            newSelects.push(parent.id);
          }
          setSelectPermissions(newSelects);
        }
      } else {
        const currentItem = permissionsAll.find((el) => el.id === id[0]);
        if (currentItem.name.includes('View')) {
          const ids = permissionsAll
            .filter(
              ({ name }) =>
                name.includes(currentItem.name.replace('View', 'Edit')) ||
                name.includes(currentItem.name.replace('View', 'Create')) ||
                name.includes(currentItem.name.replace('View', 'Delete')) ||
                name.includes(currentItem.name.replace('sView', 'Create')),
            )
            .map((el) => el.id);
          ids.push(item);
          if (currentItem.name === 'productView') {
            ids.push(permissionsAll.find((el) => el.name === 'seatsRefundView').id);
          }
          newSelects = newSelects.filter((el) => !ids.includes(el));
        }
      }
      setSelectPermissions(newSelects);
    },
    [selectPermissions, setSelectPermissions],
  );

  return (
    <Table
      pagination={false}
      dataSource={permissionsAll}
      title={() => (
        <div className="titleTable">
          <p>Permissions</p>
					{isEdit && <FormButton onClickFunc={() => updateRoleFunc()} disabled={newValue} isLoading={loading}>UPDATE</FormButton>}
        </div>
      )}
      rowKey="id"
      rowSelection={{
        type: 'checkbox',
        onChange: changeSelect,
        selectedRowKeys: selectPermissions,
      }}
    >
      <Column dataIndex="title" title="Permission Name" />
    </Table>
  );
}

const AdminRolesPage = ({
	permissions,
	currentRoles,
	getPermissions,
	getCurrentRoles,
	addRole,
	updateRole,
	loadPage,
	isShowSideBar,
	filterString,
	setFilterString
}) => {

	const alert = useAlert();

	const [isAddNewClicked, setIsAddNewClicked] = useState(false);
	const [newName, setNewName] = useState('');
	const [isLoadingBtn, setIsLoadingBtn] = useState(false);
	const [isEmptyName, setIsEmptyName] = useState(false);
	const [permissionIds, setPermissionIds] = useState([]);

	useEffect(() => {
		return () => {
				setFilterString("");
		}
}, [])

	const [isEdit, setIsEdit] = useState(false);
	useEffect(() => {
		setIsEdit(JSON.parse(localStorage.getItem('userPermissions'))?.some((item) => item === 'rolesEdit'));
	}, []);

	useEffect(() => {
		async function loadCateogries() {
			loadPage(true);
			getPermissions();
			await getCurrentRoles(filterString);
			console.log(currentRoles);
			loadPage(false);
		}
		loadCateogries();
	}, [filterString])

	const handleSelect = (item) => {
		console.log(item);
		const currentItem = permissions.find((el) => el.id === item);
		console.log('currentItem', currentItem)
		if (currentItem.name.includes('View')) {
			if (currentItem.name === 'seatsRefundView') {
				setPermissionIds([
					...permissionIds,
					permissions.find((el) => el.name === 'productView').id,
					item,
				]);
			} else {
				setPermissionIds([...permissionIds, item]);
			}
		} else if (currentItem.name.includes('Edit')) {
			const parrent = permissions.find(
				(el) => el.name === currentItem.name.replace('Edit', 'View'),
			);

			if (!permissionIds.includes(parrent.id)) {
				setPermissionIds([...permissionIds, parrent.id, item]);
			} else {
				setPermissionIds([...permissionIds, item]);
			}
		} else if (currentItem.name.includes('Delete')) {
			const parrent = permissions.find(
				(el) => el.name === currentItem.name.replace('Delete', 'View'),
			);
			if (!permissionIds.includes(parrent.id)) {
				setPermissionIds([...permissionIds, parrent.id, item]);
			} else {
				setPermissionIds([...permissionIds, item]);
			}
		} else if (currentItem.name.includes('Create')) {
			const parrent = permissions.find(
				(el) => el.name === currentItem.name.replace('Create', 'sView'),
			);
			console.log('parrent', parrent)
			if (!permissionIds.includes(parrent.id)) {
				setPermissionIds([...permissionIds, parrent.id, item]);
			} else {
				setPermissionIds([...permissionIds, item]);
			}
		}
	};

	const handlerDeSelect = (item) => {
		const currentItem = permissions.find((el) => el.id === item);
		if (currentItem.name.includes('View')) {
			const ids = permissions
				.filter(
					({ name }) =>
						name.includes(currentItem.name.replace('View', 'Edit')) ||
						name.includes(currentItem.name.replace('View', 'Create')) ||
						name.includes(currentItem.name.replace('View', 'Delete')) ||
						name.includes(currentItem.name.replace('sView', 'Create'))
				)
				.map((el) => el.id);
			ids.push(item);
			if (currentItem.name === 'productView') {
				ids.push(permissions.find((el) => el.name === 'seatsRefundView').id);
			}
			setPermissionIds(permissionIds.filter((el) => !ids.includes(el)));
		}
	};

	const addFunction = async () => {
		if (isLoadingBtn)
			return

		setIsEmptyName(false);
		if ((newName === '') || (newName.trim() === "")) {
			setIsEmptyName(true);
			alert.error("Empty Role name");
			return;
		}
		setIsLoadingBtn(true);
		const result = await addRole(
			{
				name: newName,
				permissionIds,
			}
		);
		setIsLoadingBtn(false);

		if (result?.id) {
			alert.success("Added successfully");
			await getCurrentRoles();
			setIsAddNewClicked(false);
		}
		else 
			alert.error("Adding Failed");

	}

	return (
		<div className="categories-page" style={{ width: isShowSideBar && (window.innerWidth > 600) && 'calc(100% - 300px)' }}>
			{
				isAddNewClicked ? <div className="add-new">
					<h2 className="text-white text-center font-weight-bold mb-4">Add New Role</h2>
					<MDBIcon className="closeIcon" icon="times" onClick={()=>{setIsAddNewClicked(false)}}/>
					<MDBRow center className="mt-4">
						<MDBCol size="10" sm="10" md="7" lg="5">
							<FormInput type="text" label='Role Name*' value={newName} handleChange={(event) => setNewName(event.target.value)} changeemail={isEmptyName} required />
						</MDBCol>
					</MDBRow>
					<MDBRow center>
						<MDBCol className="mb-4" size="10" sm="10" md="7" lg="5">
							<Select
								mode="multiple"
								className="antdCustomSel"
								size="large"
								placeholder="Please select"
								showArrow
								value={permissionIds}
								onSelect={handleSelect}
								onChange={(ids) => setPermissionIds(ids)}
								onDeselect={handlerDeSelect}
							>
								{permissions.map((item) => (
									<Option key={item.id} value={item.id}>
										{item.title}
									</Option>
								))}
							</Select>
						</MDBCol>
					</MDBRow>
					<MDBRow center className="mt-4">
						<MDBCol className="mb-4" size="10" sm="10" md="5" lg="3">
							<FormButton onClickFunc={() => addFunction()} isLoading={isLoadingBtn}>SAVE NOW</FormButton>
						</MDBCol>
					</MDBRow>
				</div>
				:
				<Fragment>
					<div className="title">
						<h2 className="text-white font-weight-bold mb-0">Roles of Admin</h2>
						{isEdit && <FormButton onClickFunc={() => setIsAddNewClicked(true)}>ADD NEW</FormButton>}
					</div>
					<div className="mt-3 section">
						<div className="toolbar" >
							<TableFilterInput str={filterString} setFilterString={setFilterString} placeholder="search by name" />
						</div>
						<Table
							dataSource={currentRoles}
							pagination={false}
							className="expanded-table"
							rowClassName={(record, i) => (record.expanded ? 'open' : i % 2 ? 'dark' : ' light')}
							rowKey={(record) => record.id}
							expandable={{
								expandedRowRender: (record) => (
									<RoleDetails item={record} permissionsAll={permissions} updateRole={updateRole} getCurrentRoles={getCurrentRoles}/>
								),
								expandRowByClick: true,
								onExpand: (expanded, record) => {
									record.expanded = expanded;
								},
							}}
						>
							<Column title="Role Name" dataIndex="name" />					
						</Table>
					</div>
				</Fragment>
			}
		</div>
	)
}

const MapStateToProps = ({ roles: { permissions, currentRoles, filterString }, user: { isShowSideBar } }) => ({
	filterString,
	permissions,
	currentRoles,
	isShowSideBar
});

const MapDispatchToProps = dispatch => ({
	getPermissions: getPermissions(dispatch),
	getCurrentRoles: getCurrentRoles(dispatch),
	addRole: addRole(dispatch),
	updateRole: updateRole(dispatch),
	setFilterString: str => dispatch(setFilterStr(str)),
	loadPage: flag => dispatch(loadPage(flag))
})

export default withPermissionChecking(connect(MapStateToProps, MapDispatchToProps)(AdminRolesPage));