import SoldDistributorsProductsActionTypes from './sold-distributors-products.types';

const INITIAL_STATE = {
    product_items: {
        count: 1,
        ffl_data: [],
        rows: []    
    },
    filterStr: "",
    limitPerPage: 10,
    pageNum: 1,
    rowsToCounter: 0,
}

const SoldDistributorsProductsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SoldDistributorsProductsActionTypes.SET_PRODUCT_LIST_ITEMS:
            return {
                ...state, 
                product_items : action.payload, 
            }
        case SoldDistributorsProductsActionTypes.SET_PRODUCT_LIST_ITEMS_COUNT:
            return {
                ...state,
                rowsToCounter: action.payload,
            }   

        case SoldDistributorsProductsActionTypes.SET_FILTER_STRING:
            return {
                ...state, filterStr: action.payload
            }
        case SoldDistributorsProductsActionTypes.SET_LIMIT_PER_PAGE:
            return {
                ...state, limitPerPage: action.payload
            }         
        case SoldDistributorsProductsActionTypes.SET_PRODUCT_LIST_PAGE_NUM:
            return {
                ...state, pageNum: action.payload
            }
        default:
            return state;
    }
}

export default SoldDistributorsProductsReducer;