import React, {useState, useEffect} from 'react';
import './faq-edit.style.scss';
import { withRouter } from 'react-router-dom';
import { MDBRow, MDBCol} from 'mdbreact';
import FormInput from '../../../components/form-input/form-input.component';
import FormButton from '../../../components/form-button/form-button.component';
import { connect } from 'react-redux';
import { editNewFaqItem } from '../../../redux/faq-items/faq-items.action';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import router from '../../../router';

const FaqEditPage = withRouter(({location, editNewFaqItem}) => {

    const historyURL = useHistory();
    const alert = useAlert();

    const [editFaqInfo, setEditFaqInfo] = useState({
        id: "",
        question: "",
        answer: ""
    });
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);

    useEffect(() => {
        if (location && location.state && location.state.item) {    
            setEditFaqInfo({
                id: location.state.item.id,
                question: location.state.item.question,
                answer: location.state.item.answer
            })
        }
    }, []);

    const editFaqFunc = async (event) => {
        if (isLoadingBtn)
            return;        
        event.preventDefault();

        setIsLoadingBtn(true);
        const result = await editNewFaqItem(editFaqInfo.id, {
            question: editFaqInfo.question,
            answer: editFaqInfo.answer
        });
        setIsLoadingBtn(false);
        
        if (result === "success") {
            alert.success("Edited successfully");
            historyURL.push(router.faq.path);
        }
        else
            alert.error("Failed editing");
       
    }

    const cancelEditFunc = () => {
        if (isLoadingBtn)
            return;
        historyURL.push(router.faq.path);
    }
    
    return (
        <div className="faq-edit-component">
            <div className="add-new">
                <h2 className="text-white text-center font-weight-bold">Edit FAQ Item</h2>
                <form onSubmit={editFaqFunc} className="add-section">
                    <FormInput type="text" label = 'FAQ Question' value = {editFaqInfo.question} handleChange = {(event) => setEditFaqInfo({...editFaqInfo, question: event.target.value})} required/>
                    <FormInput type="text" label = 'FAQ Answer' value = {editFaqInfo.answer} handleChange = {(event) => setEditFaqInfo({...editFaqInfo, answer: event.target.value})} required/>
                    <MDBRow center>
                        <MDBCol size="6" sm="6" md="4" lg="3">
                            <FormButton type="submit" isLoading = {isLoadingBtn}>SAVE NOW</FormButton>
                        </MDBCol>
                        <MDBCol size="6" sm="6" md="4" lg="3">
                            <FormButton greyCol={true} onClickFunc={()=>cancelEditFunc()}>CANCEL</FormButton>
                        </MDBCol>
                    </MDBRow>
                </form>
            </div>
        </div>
    )
})

const MapDispatchToProps = dispatch => ({
    editNewFaqItem: editNewFaqItem(dispatch)
})

export default connect(null, MapDispatchToProps)(FaqEditPage);