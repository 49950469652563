import React, { useState, useEffect, Fragment } from 'react';
import './row-item.style.scss';
import FormSelect from '../../../form-select/form-select.component';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { useAlert } from 'react-alert';
import { connect } from 'react-redux';
import { updateMarkup, getCurrentMarkups } from '../../../../redux/markups/markups.action';
import { loadPage } from '../../../../redux/user/user.action';
import NumberFormat from 'react-number-format';

const MarkupTableRow = ({ id, name,retailMarkupValue, markupValue, addToDelList, removeFromDelList, isAllChecked, updateMarkup, getCurrentMarkups, loadPage }) => {

    const alert = useAlert();

    const [isChecked, setIsChecked] = useState(false);
    const [isEditClicked, setIsEditClicked] = useState(false);
    const [editMarkupValue, setEditMarkupValue] = useState(markupValue);
    const [editRetailMarkupValue, setEditRetailMarkupValue] = useState(retailMarkupValue);
    const [isEdit, setIsEdit] = useState(false);
    
    

    useEffect(() => {
        setIsEdit(true);
    }, []);

    useEffect(() => {
        setIsChecked(isAllChecked);
    }, [isAllChecked]);

    useEffect(() => {
        if (isChecked)
            addToDelList(id);
        else
            removeFromDelList(id);
    }, [isChecked]);

    const updateFunction = async () => {
        console.log("updateFunction")
        console.log(editMarkupValue)
        console.log(editRetailMarkupValue)
        console.log("ID")
        console.log(id)

        loadPage(true);
        const result = await updateMarkup(id, editMarkupValue,editRetailMarkupValue);
        if (result) {
            await getCurrentMarkups();
            alert.success("Edit successfully");
        }
        else
            alert.error("Existing name");
        loadPage(false);
        setIsEditClicked(false);
    }
    const withValueLimit = ({ floatValue }) => floatValue <= 100;
    const changedMarkupValueHandler=(values)=>{
        setEditMarkupValue(values.floatValue)
    }
    const changedRetailMarkupValueHandler=(values)=>{
        setEditRetailMarkupValue(values.floatValue)
    }

    return (
        <MDBRow className="table-row">
            <MDBCol size="3" sm="3" md="3">
                    <span>{name}</span>
            </MDBCol>
            <MDBCol size="3" sm="3" md="3" className="text-center">
                {
                    isEditClicked ? 
                        <NumberFormat
                        thousandsGroupStyle="thousand"
                        defaultValue={markupValue}
                        prefix="%"
                        decimalSeparator="."
                        displayType="input"
                        type="text"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowEmptyFormatting={false} 
                        onValueChange={(values) => {
                            const {formattedValue, value, floatValue} = values;
                            changedMarkupValueHandler(values)
                        }}                        
                        isAllowed={withValueLimit}
                        />
                        :
                        <NumberFormat
                        thousandsGroupStyle="thousand"
                        value={markupValue}
                        prefix="%"
                        decimalSeparator="."
                        displayType="text"
                        type="text"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowEmptyFormatting={false} />
                }                
            </MDBCol>
            <MDBCol size="3" sm="3" md="3" className="text-center">
                {
                    isEditClicked ? 
                        <NumberFormat
                        thousandsGroupStyle="thousand"
                        defaultValue={editRetailMarkupValue}
                        prefix="%"
                        decimalSeparator="."
                        displayType="input"
                        type="text"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowEmptyFormatting={false} 
                        onValueChange={(values) => {
                            const {formattedValue, value, floatValue} = values;
                            changedRetailMarkupValueHandler(values)
                        }}                        
                        isAllowed={withValueLimit}
                        />
                        :
                        <NumberFormat
                        thousandsGroupStyle="thousand"
                        value={editRetailMarkupValue}
                        prefix="%"
                        decimalSeparator="."
                        displayType="text"
                        type="text"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowEmptyFormatting={false} />
                }                
            </MDBCol>            
            {isEdit && <MDBCol size="3" sm="3" md="3" className="text-center">
                {
                    isEditClicked ?
                    <Fragment>
                        <MDBIcon far className="editIcon" icon="check-circle" onClick={() => updateFunction()} />
                        <MDBIcon far className="editIcon" icon="times-circle" onClick={() => setIsEditClicked(false)} />
                    </Fragment>
                    :
                    <i class="fas fa-pencil-alt" onClick={() => {
                        setIsEditClicked(true);
                        setEditMarkupValue(markupValue);
                        setEditRetailMarkupValue(retailMarkupValue)
                        // setPlaceholder(value);
                    }}></i>
                }
            </MDBCol>
            }
        </MDBRow>
    )
}

const MapDispatchToProps = dispatch => ({
    updateMarkup: updateMarkup(dispatch),
    getCurrentMarkups: getCurrentMarkups(dispatch),
    loadPage: flag => dispatch(loadPage(flag))
})

export default connect(null, MapDispatchToProps)(MarkupTableRow);