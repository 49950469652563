const WebinarQueuetListActionTypes = {
    SET_PRODUCT_LIST_ITEMS: "SET_WEBINAR_QUEUE_LIST_ITEMS",
    SET_PRODUCT_LIST_ITEMS_COUNT: "SET_WEBINAR_QUEUE_LIST_ITEMS_COUNT",
    SET_FILTER_STRING: "SET_WEBINAR_QUEUE_FILTER_STRING",
    SET_LIMIT_PER_PAGE: "SET_WEBINAR_QUEUE_LIMIT_PER_PAGE",
    SET_PRODUCT_LIST_PAGE_NUM: "SET_WEBINAR_QUEUE_PAGE_NUM",
    SET_MAX_WEBINAR_COUNT: "SET_MAX_WEBINAR_COUNT",
    SET_TYPE_FILTER: "SET_WEBINAR_QUEUE_TYPE_FILTER"
};

export default WebinarQueuetListActionTypes;