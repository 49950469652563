import React, { useState, useEffect, Fragment } from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { Storage } from 'aws-amplify';
import { updateProduct, getProductItems } from '../../../redux/webinar-queue-list/webinar-queue-list.action';
import { loadPage } from '../../../redux/user/user.action';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import FormButton from '../../form-button/form-button.component';
import { getSiteSettings } from '../../../redux/colorChnage/colorChange.action';
import moment from 'moment-timezone';
import './webinar-queue-row.style.scss';
import { useHistory } from 'react-router-dom';
import router from '../../../router';
import LongItemWithTooltip from '../../tooltip/long-item-with-tooltip';

const WebinarQueueListeRow = ({isEdit, item, isMobile, updateProduct, getProductItems, getSiteSettings, loadPage, loadParentData, ...otherprops }) => {

    const [ showMore, setShowMore ] = useState(null)
    const numberOfCharacters = showMore ? 250 : 30;
    const alert = useAlert();
    const historyUrl = useHistory();

    const [isRemoveDialog, setIsRemoveDialog] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);

    const [scheduledDate, setScheduledDate] = useState();
    const [scheduledTime, setScheduledTime] = useState();

    useEffect(() => {
        async function load() {
            if (item.scheduled_time) {
                const settingsInfo = await getSiteSettings();
                if (settingsInfo && settingsInfo.time_zone) {
                    const calculatedDate = new Date(moment(item.scheduled_time).tz(settingsInfo.time_zone).format('MM/DD/YYYY HH:mm'));
                    setScheduledDate(`${calculatedDate.getMonth() < 9 ? "0" + (calculatedDate.getMonth() + 1) : calculatedDate.getMonth() + 1}-${calculatedDate.getDate() < 10 ? "0" + calculatedDate.getDate() : calculatedDate.getDate()}-${calculatedDate.getFullYear()}`);
                    setScheduledTime(`${calculatedDate.getHours() < 10 ? "0" + calculatedDate.getHours() : calculatedDate.getHours()}:${calculatedDate.getMinutes() < 10 ? "0" + calculatedDate.getMinutes() : calculatedDate.getMinutes()}`);
                }
            }
           
             checkImage( await Storage.get("thumbnail-"+(item.main_image.image_url)),  async function(){ setImageUrl( await Storage.get("thumbnail-"+(item.main_image.image_url))) }, async function(){ 
                item.thumbnail_300 && item.thumbnail_300?.image_url
             ?
              setImageUrl(await Storage.get(item.thumbnail_300.image_url))
             :
                
                setImageUrl( await Storage.get(item.main_image.image_url));} )
        }
        item && item.main_image && load();
    }, [item]);

    async function checkImage(imageSrc, good, bad) {
        var img = new Image();
        img.onload = good; 
        img.onerror = bad;
        img.src = imageSrc;
    }

    // for remove status
    const [removeStatus, setRemoveStatus] = useState("now");

    // for custom date picker
    let today = new Date();
    Date.shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    Date.shortWeeks = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    const [currentDate, setCurrentDate] = useState({
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        shortWeek: Date.shortWeeks[today.getDay() - 1],
        day: today.getDate(),
        hour: today.getHours(),
        minute: today.getMinutes()
    });

    useEffect(() => {
        async function load() {
            loadPage(true);
            const settingsInfo = await getSiteSettings();

            if (settingsInfo && settingsInfo.time_zone)
                today = new Date(moment().tz(settingsInfo.time_zone).format('MM/DD/YY HH:mm'));

            setCurrentDate({
                year: today.getFullYear(),
                month: today.getMonth() + 1,
                shortWeek: Date.shortWeeks[today.getDay() - 1],
                day: today.getDate(),
                hour: today.getHours(),
                minute: today.getMinutes()
            });
            loadPage(false);
        }
        load();
    }, []);

    const plusDateFunc = (type) => {
        switch (type) {
            case "day":
                if (currentDate.day === 31)
                    setCurrentDate({ ...currentDate, day: 1 });
                else
                    setCurrentDate({ ...currentDate, day: currentDate.day + 1 });
                break;

            case "month":
                if (currentDate.month === 12)
                    setCurrentDate({ ...currentDate, month: 1 });
                else
                    setCurrentDate({ ...currentDate, month: currentDate.month + 1 });
                break;

            case "year":
                setCurrentDate({ ...currentDate, year: currentDate.year + 1 });
                break;

            case "hour":
                if (currentDate.hour === 23)
                    setCurrentDate({ ...currentDate, hour: 0 });
                else
                    setCurrentDate({ ...currentDate, hour: currentDate.hour + 1 });
                break;

            case "minute":
                if (currentDate.minute === 59)
                    setCurrentDate({ ...currentDate, minute: 0 });
                else
                    setCurrentDate({ ...currentDate, minute: currentDate.minute + 1 });
                break;

            default:
                break;
        }
    }

    const minusDateFunc = (type) => {
        switch (type) {
            case "day":
                if (currentDate.day === 1)
                    setCurrentDate({ ...currentDate, day: 31 });
                else
                    setCurrentDate({ ...currentDate, day: currentDate.day - 1 });
                break;

            case "month":
                if (currentDate.month === 1)
                    setCurrentDate({ ...currentDate, month: 12 });
                else
                    setCurrentDate({ ...currentDate, month: currentDate.month - 1 });
                break;

            case "year":
                if (currentDate.year === today.getFullYear())
                    return;
                else
                    setCurrentDate({ ...currentDate, year: currentDate.year - 1 });
                break;

            case "hour":
                if (currentDate.hour === 0)
                    setCurrentDate({ ...currentDate, hour: 23 });
                else
                    setCurrentDate({ ...currentDate, hour: currentDate.hour - 1 });
                break;

            case "minute":
                if (currentDate.minute === 0)
                    setCurrentDate({ ...currentDate, minute: 59 });
                else
                    setCurrentDate({ ...currentDate, minute: currentDate.minute - 1 });
                break;

            default:
                break;
        }
    }

    const saveBtnClicked = async () => {

        let today = new Date();
        loadPage(true);
        const settingsInfo = await getSiteSettings();
        loadPage(false);
        if (settingsInfo && settingsInfo.time_zone)
            today = new Date(moment().tz(settingsInfo.time_zone).format('MM/DD/YY HH:mm'));

        const laterDate = new Date();
        laterDate.setFullYear(currentDate.year);
        laterDate.setMonth(currentDate.month - 1);
        laterDate.setDate(currentDate.day);
        laterDate.setHours(currentDate.hour);
        laterDate.setMinutes(currentDate.minute);

        if (laterDate.getTime() < today.getTime()) {
            alert.error("You selected past date");
            return;
        }
        else {
            if (currentDate.day > new Date(currentDate.year, currentDate.month, 0).getDate()) {
                alert.error("Invalid date");
                return;
            }
            else {
                let obj = {
                    product_type: "webinar",
                    product_id: item.id,
                    publish_method: "scheduled"
                }
                const date = new Date(currentDate.year, currentDate.month - 1, currentDate.day, currentDate.hour, currentDate.minute);
                obj.scheduled_time = date.toISOString();
                loadPage(true);
                const result = await updateProduct(obj);
                if (result === "success") {
                    setIsRemoveDialog(false);
                    alert.success("Scheduled successfully");
                    await loadParentData();
                    /* await getProductItems(
                        otherprops.filterStr,
                        otherprops.type,
                        otherprops.pageLimit,
                        otherprops.offset
                    ); */
                }
                else
                    alert.error("Scheduling failed");
                loadPage(false);
            }
        }
    }

    const clearBtnClicked = async () => {
        let date = new Date();
        loadPage(true);
        const settingsInfo = await getSiteSettings();
        loadPage(false);
        if (settingsInfo && settingsInfo.time_zone)
            date = new Date(moment().tz(settingsInfo.time_zone).format('MM/DD/YY HH:mm'));

        setCurrentDate({
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            shortWeek: Date.shortWeeks[date.getDay() - 1],
            day: date.getDate(),
            hour: date.getHours(),
            minute: date.getMinutes()
        })
    }

    const addToQueueFunc = async () => {
        const obj = {
            product_type: "webinar",
            product_id: item.id,
            publish_method: "queued"
        }
        loadPage(true);
        const result = await updateProduct(obj);
        if (result === "success") {
            alert.success("Added to queue successfully");
            await loadParentData();
            /* await getProductItems(
                otherprops.filterStr,
                otherprops.type,
                otherprops.pageLimit,
                otherprops.offset
            ); */
        }
        else
            alert.error("Adding failed");
        loadPage(false);

    }

    const activeWebinarFunc = async () => {
        setRemoveStatus("now");
        const obj = {
            product_type: "webinar",
            product_id: item.id,
            publish_method: "instant",
            product_status: "active"
        }
        loadPage(true);
        const result = await updateProduct(obj);
        if (result === "success") {
            setIsRemoveDialog(false);
            alert.success("Actived successfully");
            await loadParentData();
            /* await getProductItems(
                otherprops.filterStr,
                otherprops.type,
                otherprops.pageLimit,
                otherprops.offset
            ); */
        }
        else
            alert.error("Activation failed");
        loadPage(false);

    }

    const putToHoldFunc = async () => {
        setRemoveStatus("remove");
        const obj = {
            product_type: "webinar",
            product_id: item.id,
            publish_method: "instant",
            product_status: "hold"
        }
        loadPage(true);
        const result = await updateProduct(obj);
        if (result === "success") {
            setIsRemoveDialog(false);
            alert.success("Put to hold successfully");
            await loadParentData();
            /* await getProductItems(
                otherprops.filterStr,
                otherprops.type,
                otherprops.pageLimit,
                otherprops.offset
            ); */
        }
        else
            alert.error("Holding failed");
        loadPage(false);
    }

    // const getDateForList = async () => {
    //     const settingsInfo = await getSiteSettings();
    //     if (settingsInfo && settingsInfo.time_zone) {
    //         const date = new Date(moment(item.scheduled_time).tz(settingsInfo.time_zone).format('YYYY-MM-DD HH:mm'));
    //         console.log(date);
    //         // return `${date.getMonth()<9 ? "0"+(date.getMonth()+1) : date.getMonth()+1}-${date.getDate()<10 ? "0"+date.getDate() : date.getDate()}-${date.getFullYear()}`;
    //     }         
    // }

    // const getTimeForList = async () => {
    //     const settingsInfo = await getSiteSettings();
    //     if (settingsInfo && settingsInfo.time_zone) {
    //         const date = new Date(moment(item.scheduled_time).tz(settingsInfo.time_zone).format('YYYY-MM-DD HH:mm'));
    //         // return `${date.getHours()<10 ? "0"+date.getHours() : date.getHours()}:${date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes()}`;
    //     }         
    // }

    const [isOpenTable, setIsOpenTable] = useState(false);
    const [isOpenTable2, setIsOpenTable2] = useState(false);

    return (
        <Fragment>
            {
                isRemoveDialog &&
                <div className="removeDialogWrapper">
                    <div className="removeDialog">
                        <MDBIcon className="float-right text-white closeIcon" icon="times" onClick={() => setIsRemoveDialog(false)} />
                        <br />
                        <h2 className="text-white text-center font-weight-bold mb-4">Remove from Queue</h2>
                        <MDBRow center className="mb-4 mobile-row">
                            <MDBCol >
                                <FormButton greyCol={removeStatus !== "now" && true} onClickFunc={() => activeWebinarFunc()}>ACTIVE NOW</FormButton>
                            </MDBCol>
                            <MDBCol >
                                <FormButton greyCol={removeStatus !== "later" && true} onClickFunc={() => setRemoveStatus("later")}>SCHEDULE LATER</FormButton>
                            </MDBCol>
                            <MDBCol >
                                <FormButton greyCol={removeStatus !== "remove" && true} onClickFunc={() => putToHoldFunc()}>PUT ON HOLD</FormButton>
                            </MDBCol>
                        </MDBRow>

                        {
                            removeStatus === "later" && <MDBRow center>
                                <MDBCol size="11" sm="11" md="10">
                                    <div className="custom-datepicker mt-3">
                                        <div className="datepicker-header">
                                            <label className="text-white">{`${currentDate.month < 10 ? "0" : ""}${currentDate.month}`}/{`${currentDate.day < 10 ? "0" : ""}${currentDate.day}`}/{currentDate.year}</label>
                                            <label className="text-white">{`${currentDate.hour < 10 ? "0" : ""}${currentDate.hour}`}:{`${currentDate.minute < 10 ? "0" : ""}${currentDate.minute}`}</label>
                                        </div>
                                        <div className="mt-3 text-center">
                                            <p className="text-white mb-1">Schadule Date &amp; Time</p>
                                            <p>{currentDate.shortWeek}, {Date.shortMonths[currentDate.month - 1]} {`${currentDate.day < 10 ? "0" : ""}${currentDate.day}`}, {currentDate.year} <span>{`${currentDate.hour < 10 ? "0" : ""}${currentDate.hour}`}:{`${currentDate.minute < 10 ? "0" : ""}${currentDate.minute}`}</span></p>
                                        </div>
                                        <div className="date-select mt-1 mb-1">
                                            <div className="text-center">
                                                <p className="pointer" onClick={() => plusDateFunc("day")}>+</p>
                                                <p>{`${currentDate.day < 10 ? "0" : ""}${currentDate.day}`}</p>
                                                <p className="pointer" onClick={() => minusDateFunc("day")}>-</p>
                                            </div>
                                            <div className="text-center">
                                                <p className="pointer" onClick={() => plusDateFunc("month")}>+</p>
                                                <p>{Date.shortMonths[currentDate.month - 1]}</p>
                                                <p className="pointer" onClick={() => minusDateFunc("month")}>-</p>
                                            </div>
                                            <div className="text-center">
                                                <p className="pointer" onClick={() => plusDateFunc("year")}>+</p>
                                                <p>{currentDate.year}</p>
                                                <p className="pointer" onClick={() => minusDateFunc("year")}>-</p>
                                            </div>
                                            <div className="text-center">
                                                <p className="pointer" onClick={() => plusDateFunc("hour")}>+</p>
                                                <p>{`${currentDate.hour < 10 ? "0" : ""}${currentDate.hour}`}</p>
                                                <p className="pointer" onClick={() => minusDateFunc("hour")}>-</p>
                                            </div>
                                            <div className="text-center">
                                                <p className="pointer" onClick={() => plusDateFunc("minute")}>+</p>
                                                <p>{`${currentDate.minute < 10 ? "0" : ""}${currentDate.minute}`}</p>
                                                <p className="pointer" onClick={() => minusDateFunc("minute")}>-</p>
                                            </div>
                                        </div>
                                        <MDBRow center className="pb-3">
                                            <MDBCol size="5">
                                                <FormButton onClickFunc={() => saveBtnClicked()}>SAVE</FormButton>
                                            </MDBCol>
                                            <MDBCol size="5">
                                                <FormButton onClickFunc={() => clearBtnClicked()}>CLEAR</FormButton>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        }

                    </div>
                </div>
            }
            {
                item.publish_method === "queued" ?
                    isMobile ? <Fragment>
                        <MDBRow className="table-row">
                            <MDBCol size="3" middle className="text-center thumb">
                                {
                                    imageUrl ? <img src={imageUrl} alt={item.name} />
                                        :
                                        <div className="non-img">
                                            <MDBIcon far icon="file-image" />
                                        </div>
                                }
                            </MDBCol>
                            <MDBCol size="7" middle className="text-center">
                                <LongItemWithTooltip text={item.name && item.name} />
                            </MDBCol>
                            <MDBCol size="2" middle className="text-center">
                                <button className="openBtn" onClick={() => setIsOpenTable(true)}><MDBIcon icon="plus" /></button>
                            </MDBCol>
                        </MDBRow>
                        {
                            isOpenTable && <Fragment>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Thumbnail</MDBCol>
                                    <MDBCol size="5" middle className="text-center">
                                        {
                                            imageUrl ? <img src={imageUrl} alt={item.name} />
                                                :
                                                <div className="non-img">
                                                    <MDBIcon far icon="file-image" />
                                                </div>
                                        }
                                    </MDBCol>
                                    <MDBCol size="2" middle className="text-center">
                                        <button className="openBtn" onClick={() => setIsOpenTable(false)}><MDBIcon icon="minus" /></button>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">UPC</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        {item?.upc_code ? item.upc_code : 'unassigned'}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Name</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        <LongItemWithTooltip text={item.name && item.name} />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Description</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        <LongItemWithTooltip text={item.shortDescription} maxlenght={30}/>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Price</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        {item.price_per_seats && item.price_per_seats}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">No. t Seats</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        {item.seats && item.seats}
                                    </MDBCol>
                                </MDBRow>
                                {isEdit && <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Remove from queue</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        <button onClick={() => setIsRemoveDialog(true)}>Remove</button>
                                    </MDBCol>
                                </MDBRow>}
                                {isEdit && <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Duplicate</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        <button onClick={() => historyUrl.push(router.editProduct.path, {
                                            prodType: 'webinar', 
                                            id: item.id,
                                            duplicate: true
                                        })}>
                                            Duplicate
                                        </button>
                                    </MDBCol>
                                </MDBRow>}
                            </Fragment>
                        }
                    </Fragment>
                        :
                        <MDBRow className="table-row">
                            <MDBCol size="1">
                                {
                                    imageUrl ? <img src={imageUrl} alt={item.name} />
                                        :
                                        <div className="non-img">
                                            <MDBIcon far icon="file-image" />
                                        </div>
                                }
                            </MDBCol>
                            <MDBCol size={isEdit ? '1' : '2'} className="text-center email-col">
                                {item?.upc_code ? item.upc_code : 'unassigned'}
                            </MDBCol>
                            <MDBCol size={isEdit ? '2' : '3'} className="text-center">
                                <LongItemWithTooltip text={item.name && item.name} />
                            </MDBCol>
                            <MDBCol size={isEdit ? '2' : '3'} className="text-center">
                                <LongItemWithTooltip text={item.shortDescription} maxlenght={30}/>                              
                            </MDBCol>
                            <MDBCol size="1" className="text-center">
                                {item.price_per_seats && item.price_per_seats}
                            </MDBCol>
                            <MDBCol size="2" className="text-center">
                                {item.seats && item.seats}
                            </MDBCol>
                            {isEdit && <MDBCol size="2" className="text-center">
                                <button onClick={() => setIsRemoveDialog(true)}>Remove</button>
                            </MDBCol>}
                            {isEdit && <MDBCol size="1" className="text-center">
                                <button onClick={() => historyUrl.push(router.editProduct.path, {
                                    prodType: 'webinar', 
                                    id: item.id,
                                    duplicate: true
                                })}>
                                    Duplicate
                                </button>
                            </MDBCol>}
                        </MDBRow>
                    :
                    isMobile ? <Fragment>
                        <MDBRow className="table-row">
                            <MDBCol size="3" middle className="text-center thumb">
                                {
                                    imageUrl ? <img src={imageUrl} alt={item.name} />
                                        :
                                        <div className="non-img">
                                            <MDBIcon far icon="file-image" />
                                        </div>
                                }
                            </MDBCol>
                            <MDBCol size="7" middle className="text-center">
                                {item.name && item.name}
                            </MDBCol>
                            <MDBCol size="2" middle className="text-center">
                                <button className="openBtn" onClick={() => setIsOpenTable2(true)}><MDBIcon icon="plus" /></button>
                            </MDBCol>
                        </MDBRow>
                        {
                            isOpenTable2 && <Fragment>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Thumbnail</MDBCol>
                                    <MDBCol size="5" middle className="text-center">
                                        {
                                            imageUrl ? <img src={imageUrl} alt={item.name} />
                                                :
                                                <div className="non-img">
                                                    <MDBIcon far icon="file-image" />
                                                </div>
                                        }
                                    </MDBCol>
                                    <MDBCol size="2" middle className="text-center">
                                        <button className="openBtn" onClick={() => setIsOpenTable2(false)}><MDBIcon icon="minus" /></button>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">UPC</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        {item?.upc_code ? item.upc_code : 'unassigned'}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Name</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        <LongItemWithTooltip text={item.name && item.name} />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Description</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        <LongItemWithTooltip text={item.shortDescription} maxlenght={30}/>                                                                        
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Date</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        {scheduledDate}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Time</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        {scheduledTime}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Price</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        {item.price_per_seats && item.price_per_seats}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">No. t Seats</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        {item.seats && item.seats}
                                    </MDBCol>
                                </MDBRow>
                                {isEdit && <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Add to queue</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        <button onClick={() => addToQueueFunc()}>Add</button>
                                    </MDBCol>
                                </MDBRow>}
                                {isEdit && <MDBRow className="table-row mobile-opend-table">
                                    <MDBCol size="5" middle className="text-center text-white">Duplicate</MDBCol>
                                    <MDBCol size="7" middle className="text-center">
                                        <button onClick={() => historyUrl.push(router.editProduct.path, {
                                            prodType: 'webinar', 
                                            id: item.id,
                                            duplicate: true
                                        })}>
                                            Duplicate
                                        </button>
                                    </MDBCol>
                                </MDBRow>}
                            </Fragment>
                        }
                    </Fragment>
                        :
                        <MDBRow className="table-row">
                            <MDBCol size="1">
                                {
                                    imageUrl ? <img src={imageUrl} alt={item.name} />
                                        :
                                        <div className="non-img">
                                            <MDBIcon far icon="file-image" />
                                        </div>
                                }
                            </MDBCol>
                            <MDBCol size={isEdit ? '1' : '2'} className="text-center">
                                {item?.upc_code ? item.upc_code : 'unassigned'}
                            </MDBCol>
                            <MDBCol size={isEdit ? '1' : '2'} className="text-center">
                                {item.name && item.name}
                            </MDBCol>
                            <MDBCol size="2" className="text-center">
                                {
                                    item.shortDescription.length > 30 ?
                                    <div>
                                        {item.shortDescription.slice(0,numberOfCharacters)}
                                        <a className="show-more-button" onClick={() => setShowMore(showMore === null ? item.shortDescription.length : null)}>... show {showMore ? "less" : "more"}</a>
                                    </div>
                                    :
                                    item.shortDescription
                                }                                  
                            </MDBCol>
                            <MDBCol size="2" className="text-center">
                                {scheduledDate}
                            </MDBCol>
                            <MDBCol size="1" className="text-center">
                                {scheduledTime}
                            </MDBCol>
                            <MDBCol size="1" className="text-center">
                                {item.price_per_seats && item.price_per_seats}
                            </MDBCol>
                            <MDBCol size="1" className="text-center">
                                {item.seats && item.seats}
                            </MDBCol>
                            {isEdit && <MDBCol size="1" className="text-center">
                                <button onClick={() => addToQueueFunc()}>Add</button>
                            </MDBCol>}
                            {isEdit && <MDBCol size="1" className="text-center">
                                <button onClick={() => historyUrl.push(router.editProduct.path, {
                                    prodType: 'webinar', 
                                    id: item.id,
                                    duplicate: true
                                })}>
                                    Duplicate
                                </button>
                            </MDBCol>}
                        </MDBRow>
            }
        </Fragment>
    )
}

const MapDispatchToProps = dispatch => ({
    updateProduct: updateProduct(dispatch),
    getProductItems: getProductItems(dispatch),
    loadPage: flag => dispatch(loadPage(flag)),
    getSiteSettings: getSiteSettings(dispatch)
})

export default connect(null, MapDispatchToProps)(WebinarQueueListeRow);